import  * as ActionType from '../actions/ActionType';

const storeUserCentreData = (state = { indexdata: {}}, action) => {
    if (action.type == ActionType.STORE_USER_CENTRE_INDEX_DATA) {
        let result = action.input;
        return { ...state, indexdata: result };
    }else {
        return state
    }
}


export { storeUserCentreData }