import Typography from 'components/Typography';
import React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Card } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { updatePopupObj } from '../../../actions/GameAction';

const mapStateToProps = (state) => {
  return {
    game: state.game,
    alert: state.alert,
  };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    updatePopupObj,
  }, dispatch)
);

class LocalizeAwardResult extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      complete: false,
      rc: this.props.game.popupObj.localizedResultObj,
    };
  }

  componentDidMount() {
    this.init();
  }

  init = async () => {
    document.getElementById('follow-bet-popup-container').style.display = 'block';
  };

  closeAwardResult() {
    this.props.game.popupObj.showAwardLocalizeResult = false;
    this.props.updatePopupObj(this.props.game.popupObj);
  }

  render() {
    const { t } = this.props;

    const getResult = (item) => {
      let aw = item;
      return aw.split(',');
    };

    return (
      <>
        <div className="follow-bet-popup-overlay">
          <div className={'follow-bet-popup-container'} id="follow-bet-popup-container" style={{ display: 'none' }}>
            <div className="follow-bet-popup">
              <div id="followBet2" className="flow-bet-box-outside" style={{ zIndex: 99999 }}>
                <div className="flow-bet-outside" style={{minWidth:'95%'}}>
                  <div className="follow-bet-title" style={{padding:'15px 0 0 0',position:'relative',fontSize:'20px'}}>
                    <label style={{ fontWeight: 'bold' }}>{t('awardResult')}</label>
                    <div className="follow-bet-close"></div>
                  </div>

                  <hr color="#ccc" size="1"/>
                  <div style={{ fontSize: 'initial' }}>
                    <Card body
                          style={{ width: '100%', borderRadius: 0, borderTop: 0, borderBottom: 0, borderBottomWidth: '0.4px',flex:'1 1 auto',padding:'0.8rem'}}>
                      <div style={{ display: 'flex',marginBottom:'5px' }}>
                        <Typography className="mb-0" style={{ lineHeight: '24px' }}>
                          <b>{t(this.state.rc.gameName)}</b>
                        </Typography>
                        <div style={{ marginLeft: 'auto', fontSize: 15, lineHeight: '24px' }}>{this.state.rc.periodNo}</div>
                      </div>
                      { this.state.rc.zongHeType == 'VN' ?
                        <>
                          <table className="awardzhonghe" style={{ width: '100%' }}>
                            <tbody>
                            <tr>
                              <th style={{ background: 'var(--theme-color)', color: 'white',padding:'5px' }}><Trans className="text-white" i18nKey="首奖"></Trans></th>
                              <th colSpan={12} style={{ background: '#FFFFC2', color: 'black',fontSize:'19px' }}>{getResult(this.state.rc.awardResult)[0]}</th>
                            </tr>
                            <tr>
                              <th style={{padding:'5px'}}><Trans className="text-white" i18nKey="一等奖"></Trans></th>
                              <td colSpan={12}>{getResult(this.state.rc.awardResult)[1]}</td>
                            </tr>
                            <tr>
                              <th style={{padding:'5px'}}><Trans className="text-white" i18nKey="二等奖"></Trans></th>
                              <td colSpan={12}>{getResult(this.state.rc.awardResult)[2]}</td>
                            </tr>
                            <tr>
                              <th style={{padding:'5px'}}><Trans className="text-white" i18nKey="三等奖"></Trans></th>
                              <td colSpan={6}>{getResult(this.state.rc.awardResult)[3]}</td>
                              <td colSpan={6}>{getResult(this.state.rc.awardResult)[4]}</td>
                            </tr>
                            <tr>
                              <th rowSpan={2}><Trans className="text-white" i18nKey="四等奖"></Trans></th>
                              <td colSpan={3}>{getResult(this.state.rc.awardResult)[5]}</td>
                              <td colSpan={3}>{getResult(this.state.rc.awardResult)[6]}</td>
                              <td colSpan={3}>{getResult(this.state.rc.awardResult)[7]}</td>
                              <td colSpan={3}>{getResult(this.state.rc.awardResult)[8]}</td>
                            </tr>
                            <tr>
                              <td colSpan={4}>{getResult(this.state.rc.awardResult)[9]}</td>
                              <td colSpan={4}>{getResult(this.state.rc.awardResult)[10]}</td>
                              <td colSpan={4}>{getResult(this.state.rc.awardResult)[11]}</td>
                            </tr>
                            <tr>
                              <th style={{padding:'5px'}}><Trans className="text-white" i18nKey="五等奖"></Trans></th>
                              <td colSpan={12}>{getResult(this.state.rc.awardResult)[12]}</td>
                            </tr>
                            <tr>
                              <th><Trans className="text-white" i18nKey="六等奖"></Trans></th>
                              <td colSpan={4}>{getResult(this.state.rc.awardResult)[13]}</td>
                              <td colSpan={4}>{getResult(this.state.rc.awardResult)[14]}</td>
                              <td colSpan={4}>{getResult(this.state.rc.awardResult)[15]}</td>
                            </tr>
                            <tr>
                              <th style={{padding:'5px'}}><Trans className="text-white" i18nKey="七等奖"></Trans></th>
                              <td colSpan={12}>{getResult(this.state.rc.awardResult)[16]}</td>
                            </tr>
                            <tr>
                              <th style={{padding:'5px'}}><Trans className="text-white" i18nKey="八等奖"></Trans></th>
                              <td colSpan={12}>{getResult(this.state.rc.awardResult)[17]}</td>
                            </tr>
                            </tbody>
                          </table>
                        </>
                        :
                        (this.state.rc.zongHeType == 'ZH_4D' ?
                        <>
                          <table className="awardzhonghe" style={{ width: '100%' }}>
                            <tbody>
                            <tr>
                              <th style={{ background: 'var(--theme-color)', color: 'white',padding:'5px' }}><Trans className="text-white" i18nKey="首奖"></Trans></th>
                              <th colSpan={10} style={{ background: '#FFFFC2', color: 'black',fontSize:'19px' }}>{getResult(this.state.rc.awardResult)[0]}</th>
                            </tr>
                            <tr>
                              <th style={{padding:'5px'}}><Trans className="text-white" i18nKey="二等奖"></Trans></th>
                              <td colSpan={10}>{getResult(this.state.rc.awardResult)[1]}</td>
                            </tr>
                            <tr>
                              <th style={{padding:'5px'}}><Trans className="text-white" i18nKey="三等奖"></Trans></th>
                              <td colSpan={10}>{getResult(this.state.rc.awardResult)[2]}</td>
                            </tr>
                            <tr>
                              <th rowSpan={2}><Trans className="text-white" i18nKey="特别奖"></Trans></th>
                              <td colSpan={2}>{getResult(this.state.rc.awardResult)[3]}</td>
                              <td colSpan={2}>{getResult(this.state.rc.awardResult)[4]}</td>
                              <td colSpan={2}>{getResult(this.state.rc.awardResult)[5]}</td>
                              <td colSpan={2}>{getResult(this.state.rc.awardResult)[6]}</td>
                              <td colSpan={2}>{getResult(this.state.rc.awardResult)[7]}</td>
                            </tr>
                            <tr>
                              <td colSpan={2}>{getResult(this.state.rc.awardResult)[8]}</td>
                              <td colSpan={2}>{getResult(this.state.rc.awardResult)[9]}</td>
                              <td colSpan={2}>{getResult(this.state.rc.awardResult)[10]}</td>
                              <td colSpan={2}>{getResult(this.state.rc.awardResult)[11]}</td>
                              <td colSpan={2}>{getResult(this.state.rc.awardResult)[12]}</td>
                            </tr>
                            <tr>
                              <th rowSpan={2}><Trans className="text-white" i18nKey="安慰奖"></Trans></th>
                              <td colSpan={2}>{getResult(this.state.rc.awardResult)[13]}</td>
                              <td colSpan={2}>{getResult(this.state.rc.awardResult)[14]}</td>
                              <td colSpan={2}>{getResult(this.state.rc.awardResult)[15]}</td>
                              <td colSpan={2}>{getResult(this.state.rc.awardResult)[16]}</td>
                              <td colSpan={2}>{getResult(this.state.rc.awardResult)[17]}</td>
                            </tr>
                            <tr>
                              <td colSpan={2}>{getResult(this.state.rc.awardResult)[18]}</td>
                              <td colSpan={2}>{getResult(this.state.rc.awardResult)[19]}</td>
                              <td colSpan={2}>{getResult(this.state.rc.awardResult)[20]}</td>
                              <td colSpan={2}>{getResult(this.state.rc.awardResult)[21]}</td>
                              <td colSpan={2}>{getResult(this.state.rc.awardResult)[22]}</td>
                            </tr>
                            </tbody>
                          </table>
                        </>
                        :                        
                        <>
                          <table className="awardzhonghe" style={{ width: '100%' }}>
                            <tbody>
                            <tr>
                              <th style={{ background: 'var(--theme-color)', color: 'white',padding:'5px' }}><Trans className="text-white" i18nKey="首奖"></Trans></th>
                              <th colSpan={12} style={{ background: '#FFFFC2', color: 'black',fontSize:'19px' }}>{getResult(this.state.rc.awardResult)[0]}</th>
                            </tr>
                            <tr>
                              <th style={{padding:'5px'}}><Trans className="text-white" i18nKey="一等奖"></Trans></th>
                              <td colSpan={12}>{getResult(this.state.rc.awardResult)[1]}</td>
                            </tr>
                            <tr>
                              <th style={{ padding: '5px' }}><Trans className="text-white" i18nKey="二等奖"></Trans></th>
                              <td colSpan={6}>{getResult(this.state.rc.awardResult)[2]}</td>
                              <td colSpan={6}>{getResult(this.state.rc.awardResult)[3]}</td>
                            </tr>
                            <tr>
                              <th rowSpan={2} style={{ padding: '5px' }}><Trans className="text-white" i18nKey="三等奖"></Trans></th>
                              <td colSpan={4}>{getResult(this.state.rc.awardResult)[4]}</td>
                              <td colSpan={4}>{getResult(this.state.rc.awardResult)[5]}</td>
                              <td colSpan={4}>{getResult(this.state.rc.awardResult)[6]}</td>
                            </tr>
                            <tr>
                              <td colSpan={4}>{getResult(this.state.rc.awardResult)[7]}</td>
                              <td colSpan={4}>{getResult(this.state.rc.awardResult)[8]}</td>
                              <td colSpan={4}>{getResult(this.state.rc.awardResult)[9]}</td>
                            </tr>
                            <tr>
                              <th style={{ padding: '5px' }}><Trans className="text-white" i18nKey="四等奖"></Trans></th>
                              <td colSpan={3}>{getResult(this.state.rc.awardResult)[10]}</td>
                              <td colSpan={3}>{getResult(this.state.rc.awardResult)[11]}</td>
                              <td colSpan={3}>{getResult(this.state.rc.awardResult)[12]}</td>
                              <td colSpan={3}>{getResult(this.state.rc.awardResult)[13]}</td>
                            </tr>
                            <tr>
                              <th rowSpan={2} style={{ padding: '5px' }}><Trans className="text-white" i18nKey="五等奖"></Trans></th>
                              <td colSpan={4}>{getResult(this.state.rc.awardResult)[14]}</td>
                              <td colSpan={4}>{getResult(this.state.rc.awardResult)[15]}</td>
                              <td colSpan={4}>{getResult(this.state.rc.awardResult)[16]}</td>
                            </tr>
                            <tr>
                              <td colSpan={4}>{getResult(this.state.rc.awardResult)[17]}</td>
                              <td colSpan={4}>{getResult(this.state.rc.awardResult)[18]}</td>
                              <td colSpan={4}>{getResult(this.state.rc.awardResult)[19]}</td>
                            </tr>
                            <tr>
                              <th style={{ padding: '5px' }}><Trans className="text-white" i18nKey="六等奖"></Trans></th>
                              <td colSpan={4}>{getResult(this.state.rc.awardResult)[20]}</td>
                              <td colSpan={4}>{getResult(this.state.rc.awardResult)[21]}</td>
                              <td colSpan={4}>{getResult(this.state.rc.awardResult)[22]}</td>
                            </tr>
                            <tr>
                              <th style={{ padding: '5px' }}><Trans className="text-white" i18nKey="七等奖"></Trans></th>
                              <td colSpan={3}>{getResult(this.state.rc.awardResult)[23]}</td>
                              <td colSpan={3}>{getResult(this.state.rc.awardResult)[24]}</td>
                              <td colSpan={3}>{getResult(this.state.rc.awardResult)[25]}</td>
                              <td colSpan={3}>{getResult(this.state.rc.awardResult)[26]}</td>
                            </tr>
                            </tbody>
                          </table>
                        </>
                        )
                      }
                    </Card>
                  </div>
                  <div className="follow-bet-game-btn" style={{display:'flex',textAlign:'center',color:'white',fontSize:'18px',fontWeight:'700',marginBottom:'15px',borderTop:'0',border:'0'}}>
                    <div className="cancelBtn2" onClick={this.closeAwardResult.bind(this)} style={{width:'50%',margin:'0 auto',background:'var(--theme-color)',borderRadius:'5px',padding:'7px 0'}}>{t('common.close')}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const ConnectedLocalizeAwardResult = connect(mapStateToProps, mapDispatchToProps)(LocalizeAwardResult);
export default withTranslation('common')(ConnectedLocalizeAwardResult);
