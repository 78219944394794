import { Content, Header, Sidebar } from 'components/Layout';
import React from 'react';
import { connect } from 'react-redux';
import NotificationSystem from 'react-notification-system-17';
import { NOTIFICATION_SYSTEM_STYLE } from '../../utils/constants';
import { store } from '../../index';
import {
  MdOutlineMenu
} from 'react-icons/md';
import {
  Button, Col, Row,
} from 'reactstrap';
import LanguageSwitcher from 'components/LanguageSwitcher';
import { intToString } from '../../services/HomeService';
import Drawer from 'react-bottom-drawer'
import profile from '../../assets/image/sideMenuIcon/profile.png';
import pwOff from '../../assets/image/sideMenuIcon/pwOff.png';
import pwOn from '../../assets/image/sideMenuIcon/pwOn.png';
import bet from '../../assets/image/sideMenuIcon/bet.png';
import auto from '../../assets/image/sideMenuIcon/auto.png';
import gameRules from '../../assets/image/sideMenuIcon/gameRules.png';
import language from '../../assets/image/sideMenuIcon/language.png';
import pastResult from '../../assets/image/sideMenuIcon/pastResult.png';
import responsible from '../../assets/image/sideMenuIcon/responsible.png';
import setting from 'assets/image/sideMenuIcon/setting.png';
import { GoChevronRight } from "react-icons/go";
import { withTranslation, Trans } from "react-i18next";

const mapStateToProps = (state) => {
  return { 
    memberInfo: state.memberInfo
  };
};

class MainLayout extends React.Component {
  state = {
    showAmount: false,
    rightToggle: false,
  };

  static isSidebarOpen() {
    return document
      .querySelector('.cr-sidebar');
  }

  componentDidMount() {
   
  }

  // close sidebar when
  handleContentClick = event => {
    // close sidebar if sidebar is open and screen size is less than `md`
    if (
      MainLayout.isSidebarOpen() 
    ) {
      this.openSidebar('close');
    }
  };

  openSidebar(openOrClose) {
    this.rightToggle();
    // if (openOrClose === 'open') {
    //   return document
    //     .querySelector('.cr-sidebar')
    //     .classList.add('cr-sidebar--open');
    // }
    // document.querySelector('.cr-sidebar').classList.remove('cr-sidebar--open');
    // document.querySelector('body').classList.remove('overflow-lock');
    // document.querySelector('.cr-overlay').classList.remove('cr-overlay--open');
  }

  rightToggle = () => {
    this.setState({
      rightToggle: !this.state.rightToggle,
    });
  };

  render() {
    const { t } = this.props;
    const { children } = this.props;
    const currency = this.props.memberInfo?.MemberInfo.currency === undefined ? (store.getState().memberInfo.MemberInfo.currency === undefined ?'￥':store.getState().memberInfo.MemberInfo.currency) : this.props.memberInfo?.MemberInfo.currency;
    let live = (window.location.href.includes("/live")?true:false);
    return (
      <main className="cr-app bg-light" style={{backgroundColor:live?'transparent':''}}>
        <Sidebar rightToggle={this.state.rightToggle} rightToggleFunc={this.rightToggle}/>
        <div className="cr-overlay" onClick={this.handleContentClick}></div>
        <Content fluid onClick={this.handleContentClick}>
        <Header rightToggle={this.rightToggle}/>
          {children}
          {/* <Footer /> */}
        </Content>
        <NotificationSystem
          dismissible={false}
          ref={notificationSystem =>
            (this.notificationSystem = notificationSystem)
          }
          style={NOTIFICATION_SYSTEM_STYLE}
        />
      </main>
    );
  }
}

const ConnectedMainLayout = connect(mapStateToProps)(MainLayout);
export default withTranslation('common')(ConnectedMainLayout);
