import React from 'react';
import { Modal, Nav, Navbar, NavItem } from 'reactstrap';
import { withTranslation } from 'react-i18next';

class RegisterTermModal extends React.Component {

  // state = {

  // };

  // constructor(props) {
  //   super(props);
  // }

  // componentDidMount() {

  // }



  render() {

    const { t } = this.props;

    return (
      <Modal isOpen={this.props.show} className="cart-modal">
        <Navbar className="theme-background-color" light expand style={{paddingLeft:0,paddingRight:0}}>
          <Nav navbar style={{margin:'auto'}}>
            <NavItem>
              <p className="p text-white top-title">{t('开户协议')}</p>
            </NavItem>
          </Nav>
          <Nav style={{position:'absolute',right:15,color:'white'}}>
            <div onClick={this.props.onClose}>{t('common.close')}</div>
          </Nav>
        </Navbar>
        <div className="page-content-44">
          <div style={{padding:'15px',fontSize:12}}>
            <p>{t('立即开通本平台游戏账户，享受最优惠的各项红利!')}</p>
            <ul style={{paddingLeft:25}}>
              <li>{t('本平台只接受合法游戏年龄的客户申请。同时我们保留要求客户提供其年龄证明的权利。')}</li>
              <li>{t('在本站进行注册时所提供的全部信息必须在各个方面都是准确和完整的。在使用借记卡或信用卡时，持卡人的姓名必须与在网站上注册时的一致。')})</li>
              <li>{t('在开户后进行一次有效存款，您即成为本平台有效会员!')}</li>
              <li>{t('存款免手续费，开户最低入款金额10人民币，最高单次入款金额50000人民币。')}</li>
              <li>{t('成为本站有效会员后，客户均可随时通过电邮、联系在线客服、在本平台游戏站点上留言联系客服等方式，随时向本平台提供最新的个人资料。')}</li>
              <li>{t('本平台如发现个别会员有重复申请账号行为时，本平台有权将这些账户视为一个联合账户。本平台保留取消、收回会员所有优惠红利，以及优惠红利所产生的盈利之权利。本平台系统严格要求玩家、住址、电邮、电话号码、支付卡/信用卡均为唯一性，任何共享计算机环境（例如：网吧、其他公共用计算机等）只能够拥有一个会员账号，各项优惠只适用于每位客户在本平台游戏站点唯一的账户。')}</li>
              <li>{t('本平台是提供互联网投注服务的机构。请会员在注册前参考当地政府的法律，在游戏不被允许的地区，如有会员在本平台注册、投注，均为会员个人行为，本平台不负责、承担任何相关联带责任。')}</li>
              <li>{t('无论是个人或是团体，如有任何威胁、滥用本平台优惠的行为，本平台将保留权利取消、收回由优惠产生的红利，并保留权利追讨最高50%手续费。')}</li>
              <li>{t('所有本平台游戏站点的优惠是特别为玩家而设，在玩家注册信息有争议时，为确保双方利益、杜绝身份盗用行为，本平台保留权利要求客户向我们提供充足有效的文件， 并以各种方式辨别客户是否符合资格享有我们的任何优惠。')}
              </li>
              <li>{t('客户一经注册开户，将被视为接受所有颁布在本平台游戏网站上的规则与条例。')}</li>
            </ul>
            <p className="text-danger">{t('如发现个别会员在同系统的多个娱乐城上开设多个会员账户，并进行套利下注,经核实后,本平台将冻结参与的会员账号并将所有下注盈利取消！')} </p>

          </div>
        </div>
      </Modal>
    );

  };
}

export default withTranslation('common')(RegisterTermModal);