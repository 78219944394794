import React from 'react';
/*import { getColor } from 'utils/colors';*/
import { withTranslation } from "react-i18next";
import HeaderNav from '../components/Layout/HeaderNav'
import GameMenuList from '../components/GameMenuList';
import {SaveMemberInfo, saveIndexData, saveJumpObj} from '../actions/HomePageAction';
import {
  getMemberInfo,
  getMemberBalance,
  getPing,
  saveNewAccConfigsService,
  transferPoint,
} from '../services/HomeService';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReactLoading from 'react-loading';
import GameFetcher from '../components/GameFetcher';
import { store } from '../index';

const today = new Date();
const lastWeek = new Date(
  today.getFullYear(),
  today.getMonth(),
  today.getDate() - 7,
);

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    SaveMemberInfo,
    saveIndexData
  }, dispatch)
);

const mapStateToProps = (state) => {
  return {
    memberInfo: state.memberInfo,
    utils: state.utils,
  };
};

const jsonSync = async (jsonInput) => {
  return await jsonInput.json();
};

class DashboardMainPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // gameMember :cookie.load('gameMember'),
      balanceMoney:"0.00",
      networkPing : 0,
      loginName : "",
      userImg: "",
      userImgExist:false,
    };
  }

  componentDidMount() {
    let dataObj = store.getState().indexData.jumpObj;
    if(dataObj == undefined){
      store.dispatch(saveJumpObj({}));
    }
    dataObj = store.getState().indexData.jumpObj;
    dataObj.loginGameId = null;
    store.dispatch(saveJumpObj(dataObj));
    this.loadData();
    // this is needed, because InfiniteCalendar forces window scroll
    window.scrollTo(0, 0);
    getMemberInfo();
    getPing();
    if(window.$mode === 2){
      this.tranferPointToGame();
    }else{
      getMemberBalance();
    }
  }

  tranferPointToGame(){
    transferPoint();
    setTimeout(function(){
      getMemberBalance();
    }, 2000)
  }

  async loadData(){
    let obj = await GameFetcher(window.$gameUrl + "/rest/member/myinfo", {
      method: "Get",
      headers: {
        'Accept':'application/json, text/plain, */*',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      credentials: 'include'
    });

    let member = await jsonSync(obj);
    if(member!=null) saveNewAccConfigsService(member.result.loginName, member.result.siteagent);
    // if(this.props.memberInfo.AccountConfigs["accountConfigs"+this.props.memberInfo.UserInfo.userInfo].isBackMusic){
    //   const audioEl = document.getElementsByClassName("audio-element")[0];
    //   audioEl.play();
    //   audioEl.addEventListener('ended', function () {
    //     audioEl.currentTime = 0;
    //     audioEl.play();
    //   }, false);
    // }
  }

  refresh = async () =>{
    let milliseconds = 0;
    let started = new Date().getTime();

	let promise = new Promise((resolve, reject) => {
      // getMemberInfo();
      getPing();
      getMemberBalance();

      setTimeout(() => resolve("done!"), 1000)
    });


    let result = await promise; // wait until the promise resolves (*)
  }

  render() {

    /*const primaryColor = getColor('primary');
    const secondaryColor = getColor('secondary');*/

    return (
      <>
        {this.props.utils.isSuccessLoad ? null :
          <div className={'loading-popup-overlay'}>
            <ReactLoading type={"spin"} style={{width:"50px",height:"50px",margin:'auto',top:'50%',left:'50%', position:'fixed',transform: 'translate(-50%, -50%)'}}/>
          </div>
        }
        <div>
          <HeaderNav balanceMoney={this.props.memberInfo.MemberInfo.balanceMoney} networkPing={this.props.memberInfo.MemberInfo.networkPing} ms={this.props.memberInfo.MemberInfo.ms} loginName={this.props.memberInfo.MemberInfo.loginName} userImg={this.props.memberInfo.MemberInfo.userImg} userImgExist={this.props.memberInfo.MemberInfo.userImgExist}/>
          <GameMenuList refresh={this.refresh} {...this.props}/>
        </div>
      </>
    );
  }
}
const ConnectedDashboardMainPage = connect(mapStateToProps, mapDispatchToProps)(DashboardMainPage);
export default withTranslation('common')(ConnectedDashboardMainPage)
