import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { intToString } from '../../services/HomeService';
import Fetcher from '../../components/Fetcher';

const images = require.context('../../assets/image/', true);

const mapStateToProps = (state) => {
    return {
        indexData: state.indexData,
        memberInfo: state.memberInfo,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({
    }, dispatch)
);

class VoucherNoticeModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: true,
        };
    }

    componentDidMount() {
        this.updateModalHeight();
    }

    componentDidUpdate() {
        //this.updateModalHeight();
    }

    updateModalHeight(){
        let voucherModal = document.getElementById('quan-list');
        if(voucherModal){
          let top = (window.innerHeight-voucherModal.clientHeight)/2;
          voucherModal.style.top = top+"px";
        }
    }

    closeNotice = (type) => {
        // this.state.show = false;
        this.setState({show:false});
        Fetcher(window.$wgtDomain + "/clear-voucher", {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
            },
            responseType:'json',
            // credentials: 'include',
            referrer: 'h5'
        })
        .then(res => res.json())
        .then((res) => {
            this.setState({show : false});
            if(res.success && type==1){
                window.location.href = window.location.href.split('#')[0]+ '#welfare';
            }
        });
    }

    render() {
        let indexData = this.props.indexData;
        const { t,i18n } = this.props;
        var currency = this.props.memberInfo.MemberInfo.currency === undefined? '￥':this.props.memberInfo.MemberInfo.currency;
        var currAbbr = this.props.memberInfo.MemberInfo.currAbbr === undefined? 0:this.props.memberInfo.MemberInfo.currAbbr;
        return (
          <Modal isOpen={indexData.indexData.voucherList && indexData.indexData.voucherList.length > 0 && this.state.show} className="vouchernotice-dialog" wrapClassName="vouchernotice-modal">
            <div id="quan-list" className="quan-list voucher-list ng-cloak">
                <div className={"quan-box-"+i18n.language}>
                    {indexData.indexData && indexData.indexData.voucherList && indexData.indexData.voucherList.map((vou, index) =>
                    <div className="quan-li" key={index}>
                        <div className="quan-li-left">
                            <small>{currency}</small><span>{intToString(vou.voucherAmount,currAbbr)}</span>
                        </div>
                        <div className="quan-li-right">
                            <span>{vou.voucherName}</span> {vou.validityDay==0?<span style={{color:'red'}}>({vou.validityShow})</span>:<span>({vou.validityShow})</span>} 
                        </div>
                    </div>
                    )}
                    <a className="quan-btn" onClick={this.closeNotice.bind(this,1)}><span style={{color:'white'}}>{t('去看看')}</span></a>
                </div>
                <a className="quan-close voucher-list-close" onClick={this.closeNotice.bind(this,0)}><img alt="" src={images("./quan-close.png")} /></a>
            </div>
          </Modal>
        )
    }
}

const ConnectedVoucherNoticeModal = connect(mapStateToProps, mapDispatchToProps)(VoucherNoticeModal);
export default withTranslation('common')(ConnectedVoucherNoticeModal);