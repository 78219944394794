import sidebarBgImage from 'assets/img/sidebar/sidebar-4.jpg';
import React from 'react';
import {
  MdSend,
  MdKeyboardArrowRight,
  MdSettings, MdOutlineMenu
} from 'react-icons/md';
import { NavLink, withRouter } from 'react-router-dom';
import {
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,Button, Col, Row,
} from 'reactstrap';
import bn from '../../utils/bemnames';
import { withTranslation, Trans } from "react-i18next";
import { store } from '../../index';
import { bindActionCreators } from 'redux';
import { UpdateAlert } from '../../actions/HomePageAction';
import { connect } from 'react-redux';
import LanguageSwitcher from '../../components/LanguageSwitcher';
import { intToString } from '../../services/HomeService';
import Drawer from 'react-bottom-drawer'
import profile from '../../assets/image/sideMenuIcon/profile.png';
import pwOff from '../../assets/image/sideMenuIcon/pwOff.png';
import pwOn from '../../assets/image/sideMenuIcon/pwOn.png';
import bet from '../../assets/image/sideMenuIcon/bet.png';
import auto from '../../assets/image/sideMenuIcon/auto.png';
import rewardDes from '../../assets/image/sideMenuIcon/rewardDes.png';
import pastResult from '../../assets/image/sideMenuIcon/pastResult.png';
import responsible from '../../assets/image/sideMenuIcon/responsible.png';
import setting from '../../assets/image/sideMenuIcon/setting.png';
import { GoChevronRight } from "react-icons/go";

const mapStateToProps = (state) => {
  return {
    alert: state.alert,
    memberInfo: state.memberInfo,
  };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    UpdateAlert,
  }, dispatch)
);

const sidebarBackground = {
  backgroundColor:'#f4f4f4'
};

const navItems = [
  // { to: '/', name: 'dashboard', exact: false, Icon: MdSend },
  { to: '/bettingrecord', name: 'bettingrecord', exact: false, Icon: MdSend },
  { to: '/cashflowpage', name: 'cashflowrecord', exact: false, Icon: MdSend },
  { to: '/awardcenters', name: 'openaward', exact: false, Icon: MdSend },  
  { to: '/settings', name: 'settings', exact: false, Icon: MdSettings },
 // { to: '/responsible', name: '理性博弈设置', exact: false, Icon: MdSettings },
];

const bem = bn.create('sidebar');

class Sidebar extends React.Component {
  state = {
    isOpenComponents: true,
    isOpenContents: true,
    isOpenPages: true,
    showAmount: false,
  };

  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];

      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  refresh = () => {
    window.location.href = window.location.href.split('#')[0];
  }

  clearCache = () => {
    const { t } = this.props;
    this.props.alert.content = t('clear_cache_alert_msg');
    this.props.alert.title = t('notice');
    this.props.alert.alertType = 'clearCache';
    this.props.alert.showAlert = true;
    this.props.UpdateAlert(this.props.alert);
  }

  rightToggle = () => {
    this.props.rightToggleFunc();
  };

  showAmount() {
    this.setState({
      showAmount: !this.state.showAmount,
    });
  }

  goto(value) {
    this.props.history.push(value);
  }

  render() {
    const { t } = this.props;
    const currency = this.props.memberInfo?.MemberInfo.currency === undefined ? (store.getState().memberInfo.MemberInfo.currency === undefined ?'￥':store.getState().memberInfo.MemberInfo.currency) : this.props.memberInfo?.MemberInfo.currency;
    return (
      //<aside className={bem.b()} data-image={sidebarBgImage}>
        <>
        <Drawer
            variant="temporary"
            isVisible={this.props.rightToggle}
            hideScrollbars={true}
            onClose={this.rightToggle}
          >
            <>
                <div style={{backgroundColor: '#F2F1F6', borderRadius: '10px',padding: '5px'}}>
                  <Row>
                    <Col xs="3" style={{textAlign: 'center', padding: '0px 0px 0px 3%'}}><img src={profile} alt="" style={{width:'55px'}}/></Col>
                    <Col xs="9" style={{paddingTop: '5px'}}>
                      <Row><Col style={{padding: '0px'}}>{this.props.memberInfo.MemberInfo.loginName}</Col></Row>
                      <Row>
                      <Row>
                        <Col style={{padding: '0px'}}>{t('余额')}: {currency}{this.state.showAmount ? <>{intToString((Number.isNaN(this.props.memberInfo.MemberInfo.balanceMoney) ? '0.00' : this.props.memberInfo.MemberInfo.balanceMoney), this.props.memberInfo?.MemberInfo?.currAbbr)}</> : <>****</>}                        
                          <img style={{width:'25px'}} onClick={this.showAmount.bind(this)} src={this.state.showAmount ?pwOn:pwOff} alt=""/>
                        </Col>
                      </Row>
                      </Row>
                    </Col>
                  </Row>
                </div>
                <div style={{paddingBottom:'10px'}}>
                  <Row>
                    <Col xs="6" style={{paddingTop:'10px'}}>
                      <Button className='sideMenu-button' onClick={()=>this.goto('bettingrecord')}>
                        <Row className='sideMenu-button-row'>
                          <Col xs="4" style={{padding:'0px'}}><img src={bet} alt="" style={{width:'55px'}}/></Col>
                          <Col xs="8" style={{padding:'0px'}}>{t('bettingrecord')}</Col>
                        </Row>
                      </Button>
                    </Col>
                    <Col xs="6" style={{paddingTop:'10px'}}>
                      <Button className='sideMenu-button'>
                        <Row className='sideMenu-button-row'  onClick={()=>this.goto('purseRecords')}>
                          <Col xs="4" style={{padding:'0px'}}><img src={auto} alt="" style={{width:'55px'}}/></Col>
                          <Col xs="8" style={{padding:'0px'}}>{t('挂机记录')}</Col>
                        </Row>
                      </Button>
                    </Col>
                    
                  </Row>
                </div>
                <Row style={{borderTop: '10px solid #f1f2f6'}}>
                  <div onClick={()=>this.goto('cashflowpage')}>
                    <Row className='sideMenu-selection-row'>
                      <Col xs="1" className='sideMenu-selection-icon'><img src={rewardDes} alt="" style={{width:'30px'}}/></Col>
                      <Col className='sideMenu-selection-row-title'>{t('cashflowrecord')}</Col>
                      <Col xs="1" className='sideMenu-selection-right'><GoChevronRight className='sideMenu-selection-right-icon'/></Col>
                    </Row>
                  </div>
                  <div onClick={() => this.goto('awardcenters')}>
                    <Row className='sideMenu-selection-row'>
                      <Col xs="1" className='sideMenu-selection-icon'><img src={pastResult} alt="" style={{width:'30px'}}/></Col>
                      <Col className='sideMenu-selection-row-title'>{t('Award Result')}</Col>
                      <Col xs="1" className='sideMenu-selection-right'><GoChevronRight className='sideMenu-selection-right-icon'/></Col>
                    </Row>
                  </div>
                  <div onClick={()=>this.goto('settings')}>
                    <Row className='sideMenu-selection-row'>
                      <Col xs="1" className='sideMenu-selection-icon'><img src={setting} alt="" style={{width:'30px'}}/></Col>
                      <Col className='sideMenu-selection-row-title'>{t('settings')}</Col>
                      <Col xs="1" className='sideMenu-selection-right'><GoChevronRight className='sideMenu-selection-right-icon'/></Col>
                    </Row>
                  </div>
                  <LanguageSwitcher />
                  {(this.props.memberInfo.MemberInfo.restWarning===1||this.props.memberInfo.MemberInfo.pauseWarning===1 || this.props.memberInfo.MemberInfo.profitWarning===1 || this.props.memberInfo.MemberInfo.sleepWarning===1)?
                  <div onClick={()=>this.goto('responsible')}>
                    <Row className='sideMenu-selection-row'>
                      <Col xs="1" className='sideMenu-selection-icon'><img src={responsible} alt="" style={{width:'30px'}}/></Col>
                      <Col className='sideMenu-selection-row-title'>{t('理性博弈设置')}</Col>
                      <Col xs="1" className='sideMenu-selection-right'><GoChevronRight className='sideMenu-selection-right-icon'/></Col>
                    </Row>
                  </div>
                  :null}
                  <div style={{padding:'10px'}}>
                    <Button className='sideMenu-selection-button' onClick={this.clearCache.bind(this)}>
                      {t('Clear Cache')}
                    </Button>
                  </div>
                </Row>
              </>
          </Drawer>
          </>
        // <div className={bem.e('background')} style={sidebarBackground} />
        // <div className={bem.e('content')}>
        //   <Navbar style={{padding:0}}>
        //     <div style={{lineHeight:'44px',width:'100%',textAlign:'center'}}>
        //         <Trans className="text-white" i18nKey="more">{t('more')}</Trans>
        //     </div>
        //   </Navbar>

        //   <Nav vertical>
        //     {navItems.map(({ to, name, exact, Icon }, index) => (
        //       <NavItem key={index} className={bem.e('nav-item')}>
        //         <BSNavLink
        //           id={`navItem-${name}-${index}`}
        //           //className="text-uppercase"
        //           tag={NavLink}
        //           to={to}
        //           activeClassName="active"
        //           exact={exact}
        //         >
        //           <span ><Trans i18nKey={name}/></span>
        //           <MdKeyboardArrowRight className="side-nav-icon" />
        //         </BSNavLink>
        //       </NavItem>
        //     ))}
        //     {(this.props.memberInfo.MemberInfo.restWarning===1||this.props.memberInfo.MemberInfo.pauseWarning===1 || this.props.memberInfo.MemberInfo.profitWarning===1 || this.props.memberInfo.MemberInfo.sleepWarning===1)?
        //           <NavItem key='3' className={bem.e('nav-item')}>
        //           <BSNavLink
        //             id={`navItem-responsible-3`}
        //             //className="text-uppercase"
        //             tag={NavLink}
        //             to='/responsible'
        //             activeClassName="active"
        //             exact='false'
        //           >
        //             <span ><Trans i18nKey='理性博弈设置'/></span>
        //             <MdKeyboardArrowRight className="side-nav-icon" />
        //           </BSNavLink>
        //         </NavItem>
        //           :null}
        //     <NavItem className={bem.e('nav-item')}>
        //       <span className="nav-link">
        //         <span style={{fontWeight: 400}}onClick={this.clearCache}><Trans i18nKey='Clear Cache'/></span>
        //       </span>
        //     </NavItem>
        //     <NavItem className={bem.e('nav-item')}>
        //       <span className="nav-link" onClick={this.refresh} style={{fontWeight:400}}><Trans i18nKey='refresh'/></span>
        //     </NavItem>
        //     <LanguageSwitcher />
        //   </Nav>
        // </div>
    );
  }
}

const ConnectedSidebar = connect(mapStateToProps, mapDispatchToProps)(Sidebar);
export default withRouter(withTranslation('common')(ConnectedSidebar));
