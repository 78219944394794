var data_sel = [];

var methodid ="";
var otype = "";
var SHUZI = ["一","二","三","四","五","六","七","八","十"];
var ARRDATA = {
  A_01: ['万位', '千位', '百位', '十位', '个位'],
  A_02: [{
    index: 2,
    name: "利润率追号"
  }, {
    index: 1,
    name: "同倍追号"
  }, {
    index: 3,
    name: "翻倍追号"
  }],
  A_03: [{
    value: 0,
    name: "请选择"
  }, {
    value: 5,
    name: "5期"
  }, {
    value: 10,
    name: "10期"
  }, {
    value: 15,
    name: "15期"
  }, {
    value: 20,
    name: "20期"
  }, {
    value: 25,
    name: "25期"
  }, {
    value: 50,
    name: "50期"
  }, {
    value: 'ALL',
    name: "全部"
  }],
  A_04: [{
    id: true,
    name: "已结算"
  }, {
    id: false,
    name: "未结算"
  }],
  A_05: [{
    id: null,
    name: "全部"
  }, {
    id: 1,
    name: "已完成"
  }, {
    id: 0,
    name: "未完成"
  }]
}

export function getObjKeys(obj, cond) {
	var arr = [];
	cond = cond || function() {
		return true
	};
	for ( var k in obj) {
		if (obj.hasOwnProperty(k) && cond(k, obj[k])) {
			arr.push(k);
		}
	}
	return arr;
}
export function getObjValue(obj, cond) {
	var arr = [];
	cond = cond || function() {
		return true
	};
	for ( var k in obj) {
		if (obj.hasOwnProperty(k) && cond(k, obj[k])) {
			arr.push(obj[k]);
		}
	}
	return arr;
}
Array.prototype.each = function(fn) {
	fn = fn || Function.K;
	var a = [];
	var args = Array.prototype.slice.call(arguments, 1);
	for (var i = 0; i < this.length; i++) {
		var res = fn.apply(this, [ this[i], i ].concat(args));
		if (res != null)
			a.push(res);
	}
	return a;
};

/**
 * 得到一个数组不重复的元素集合<br/> 唯一化一个数组
 * 
 * @returns {Array} 由不重复元素构成的数组
 */
Array.prototype.uniquelize = function() {
	var ra = new Array();
	for (var i = 0; i < this.length; i++) {
		if (!ra.contains(this[i])) {
			ra.push(this[i]);
		}
	}
	return ra;
};

/**
 * 求两个集合的补集 {%example <script> var a = [1,2,3,4]; var b = [3,4,5,6];
 * alert(Array.complement(a,b)); </script> %}
 * 
 * @param {Array}
 *            a 集合A
 * @param {Array}
 *            b 集合B
 * @returns {Array} 两个集合的补集
 */
Array.complement = function(a, b) {
	return Array.minus(Array.union(a, b), Array.intersect(a, b));
};

/**
 * 求两个集合的交集 {%example <script> var a = [1,2,3,4]; var b = [3,4,5,6];
 * alert(Array.intersect(a,b)); </script> %}
 * 
 * @param {Array}
 *            a 集合A
 * @param {Array}
 *            b 集合B
 * @returns {Array} 两个集合的交集
 */
Array.intersect = function(a, b) {
	return a.uniquelize().each(function(o) {
		return b.contains(o) ? o : null
	});
};

/**
 * 求两个集合的差集 {%example <script> var a = [1,2,3,4]; var b = [3,4,5,6];
 * alert(Array.minus(a,b)); </script> %}
 * 
 * @param {Array}
 *            a 集合A
 * @param {Array}
 *            b 集合B
 * @returns {Array} 两个集合的差集
 */
Array.minus = function(a, b) {
	return a.uniquelize().each(function(o) {
		return b.contains(o) ? null : o
	});
};

/**
 * 求两个集合的并集 {%example <script> var a = [1,2,3,4]; var b = [3,4,5,6];
 * alert(Array.union(a,b)); </script> %}
 * 
 * @param {Array}
 *            a 集合A
 * @param {Array}
 *            b 集合B
 * @returns {Array} 两个集合的并集
 */
Array.union = function(a, b) {
	return a.concat(b).uniquelize();
};

Array.prototype.contains = function(element) {
	for (var i = 0; i < this.length; i++) {
		if (this[i] == element) {
			return true;
		}
	}
	return false;
}

/**
 * 
 */

// 组合数计算
function Combination(n, m) {
	m = parseInt(m);
	n = parseInt(n);
	if (m < 0 || n < 0) {
		return false;
	}
	if (m == 0 || n == 0) {
		return 1;
	}
	if (m > n) {
		return 0;
	}
	if (m > n / 2.0) {
		m = n - m;
	}

	var result = 0.0;
	for (var i = n; i >= (n - m + 1); i--) {
		result += Math.log(i);
	}
	for (var i = m; i >= 1; i--) {
		result -= Math.log(i);
	}
	result = Math.exp(result);
	return Math.round(result);
}

/**
 * 计算排列组合的个数
 * 
 * @author mark
 * 
 * @param integer
 *            $iBaseNumber 基数
 * @param integer
 *            $iSelectNumber 选择数
 * 
 * @return mixed
 * 
 */
function GetCombinCount(iBaseNumber, iSelectNumber) {
	if (iSelectNumber > iBaseNumber) {
		return 0;
	}
	if (iBaseNumber == iSelectNumber || iSelectNumber == 0) {
		return 1;// 全选
	}
	if (iSelectNumber == 1) {
		return iBaseNumber;// 选一个数
	}
	var iNumerator = 1;// 分子
	var iDenominator = 1;// 分母
	for (var i = 0; i < iSelectNumber; i++) {
		iNumerator *= iBaseNumber - i;// n*(n-1)...(n-m+1)
		iDenominator *= iSelectNumber - i;// (n-m)....*2*1
	}
	return iNumerator / iDenominator;
}

// 移动字符串
function movestring(sString) {
	var top = '';// 开始符串
	var middle = '01'// 中间字符串
	var bottom = '';// 结束符串
	var tmpone = '';
	var tmptwo = '';
	var find = false;// 是否找到分隔字符串
	var findfirst = false;// 找到第一个字符
	for (var m = 0; m < sString.length; m++) {
		if (find == false) {
			top += sString.substr(m, 1);
		}
		if (find == false && sString.substr(m, 1) == '1') {
			findfirst = true;
		} else if (find == false && findfirst == true
				&& sString.substr(m, 1) == '0') {
			find = true;
		} else if (find == true) {
			bottom += sString.substr(m, 1);
		}
	}
	top = top.substr(0, top.length - 2);
	for (var n = 0; n < top.length; n++) {
		if (top.substr(n, 1) == '1') {
			tmpone += top.substr(n, 1);
		} else if (top.substr(n, 1) == '0') {
			tmptwo += top.substr(n, 1);
		}
	}
	top = tmpone + tmptwo;
	return top + middle + bottom;
}

/**
 * 获取指定组合的所有可能性
 * 
 * 例子：5选3 $aBaseArray = array('01','02','03','04','05');
 * ----getCombination($aBaseArray,3) 1.初始化一个字符串：11100;--------1的个数表示需要选出的组合
 * 2.将1依次向后移动造成不同的01字符串，构成不同的组合，1全部移动到最后面，移动完成：00111.
 * 3.移动方法：每次遇到第一个10字符串时，将其变成01,在此子字符串前面的字符串进行倒序排列,后面的不变：形成一个不同的组合.
 * 如：11100->11010->10110->01110->11001->10101->01101->10011->01011->00111
 * 一共形成十个不同的组合:每一个01字符串对应一个组合---如11100对应组合01 02 03;01101对应组合02 03 05
 * 
 * 
 * @param array
 *            aBaseArray 基数数组
 * @param int
 *            iSelectNum 选数
 * @author mark
 * 
 */
function getCombination(aBaseArray, iSelectNum) {
	var iBaseNum = aBaseArray.length;
	var aResult = new Array();
	var aString = new Array();
	if (iSelectNum > iBaseNum) {
		return aResult;
	}
	if (iSelectNum == 1) {
		return aBaseArray;
	}
	if (iBaseNum == iSelectNum) {
		aResult[0] = aBaseArray.join(",");
		return aResult;
	}
	var sString = '';
	var sLastString = '';
	var sTempStr = '';
	for (var i = 0; i < iSelectNum; i++) {
		sString += '1';
		sLastString += '1';
	}
	for (var j = 0; j < iBaseNum - iSelectNum; j++) {
		sString += '0';
	}
	for (var k = 0; k < iSelectNum; k++) {
		sTempStr += aBaseArray[k] + ',';
	}
	aResult[0] = sTempStr.substr(0, sTempStr.length - 1);
	var count = 1;
	sTempStr = '';
	while (sString.substr(sString.length - iSelectNum, iSelectNum) != sLastString) {
		sString = movestring(sString);
		for (var k = 0; k < iBaseNum; k++) {
			if (sString.substr(k, 1) == '1') {
				sTempStr += aBaseArray[k] + ',';
			}
		}
		aResult[count] = sTempStr.substr(0, sTempStr.length - 1);
		sTempStr = '';
		count++;
	}
	return aResult;
}


export function getSelectBetNums(mname, max_place, minchosen, positionSel, data_sel) {
	var tmp_nums = 1;
	var nums = 0, i = 0, j = 0;
	switch (mname) { // 根据玩法分类不同做不同处理
	case "SBTH":
        if (data_sel[0].length >= 3) {
            nums += Combination(data_sel[0].length, 3);
        }
        break;
    case "SBTHDT":
        var N = data_sel[0].length;
        var Z = data_sel[1].length;
        if (N + Z < 4) {
            nums = 0;
        } else {
            nums = Combination(Z, 3 - N);
        }
        break;
    case "SBTHHZ":
        var V = {
            6 : 1,
            7 : 1,
            8 : 2,
            9 : 3,
            10 : 3,
            11 : 3,
            12 : 3,
            13 : 2,
            14 : 1,
            15 : 1
        };
        var T = data_sel[0].length;
        if (T > 0) {
            for (j = 0; j < T; j++) {
                nums += V[parseInt(data_sel[0][j], 10)]
            }
        }
        break;
    case "EBTH":
        if (data_sel[0].length >= 2) {
            nums += Combination(data_sel[0].length, 2)
        }
        break;
	case "WXZU120":
		var s = data_sel[0].length;
		if (s > 4) {
			nums += Combination(s, 5)
		}
		break;
	case "EBTHDT":
	case "ETHDX":{
		if (data_sel[0].length >= minchosen[0]
		&& data_sel[1].length >= minchosen[1]) {
			var a=[],b=[];
			data_sel[0].map((n, i) => {
				a.push(n.replace(/11/gi, 1).replace(/22/gi, 2).replace(/33/gi, 3).replace(/44/gi, 4).replace(/55/gi, 5).replace(/66/gi, 6));
			})
			data_sel[1].map((n, i) => {
				b.push(n)
			});
			var h = Array.intersect(a, b).length;
			tmp_nums = Combination(a.length, minchosen[0])
					* Combination(b.length, minchosen[1]);
			if(h>0){
				tmp_nums-= Combination(h,1);
			}
			nums += tmp_nums;
		}
		break;
	}
	case "WXZU60":
	case "WXZU30":
	case "WXZU20":
	case "WXZU10":
	case "WXZU5":
		if (data_sel[0].length >= minchosen[0]
				&& data_sel[1].length >= minchosen[1]) {
			var h = Array.intersect(data_sel[0], data_sel[1]).length;
			tmp_nums = Combination(data_sel[0].length, minchosen[0])
					* Combination(data_sel[1].length, minchosen[1]);
			if (h > 0) {
				if (mname == "WXZU60") {
					tmp_nums -= Combination(h, 1)
							* Combination(data_sel[1].length - 1, 2)
				} else {
					if (mname == "WXZU30") {
						tmp_nums -= Combination(h, 2) * Combination(2, 1);
						if (data_sel[0].length - h > 0) {
							tmp_nums -= Combination(h, 1)
									* Combination(data_sel[0].length - h, 1)
						}
					} else {
						if (mname == "WXZU20") {
							tmp_nums -= Combination(h, 1)
									* Combination(data_sel[1].length - 1, 1)
						} else {
							if (mname == "WXZU10" || mname == "WXZU5") {
								tmp_nums -= Combination(h, 1)
							}
						}
					}
				}
			}
			nums += tmp_nums
		}
		break;
	case 'ZXHZ':
		// 直选和值特殊算法
	case 'RXZXSSC3HZ':
		// 任选三直选和值
		var cc = {
			0 : 1,
			1 : 3,
			2 : 6,
			3 : 10,
			4 : 15,
			5 : 21,
			6 : 28,
			7 : 36,
			8 : 45,
			9 : 55,
			10 : 63,
			11 : 69,
			12 : 73,
			13 : 75,
			14 : 75,
			15 : 73,
			16 : 69,
			17 : 63,
			18 : 55,
			19 : 45,
			20 : 36,
			21 : 28,
			22 : 21,
			23 : 15,
			24 : 10,
			25 : 6,
			26 : 3,
			27 : 1
		};
	case 'ZUHZ':
		// 混合组选特殊算法
	case 'RXZUSSC3HZ':
		if (mname == 'ZUHZ' || mname == 'RXZUSSC3HZ') { // 任选三组选和值
			cc = {
				1 : 1,
				2 : 2,
				3 : 2,
				4 : 4,
				5 : 5,
				6 : 6,
				7 : 8,
				8 : 10,
				9 : 11,
				10 : 13,
				11 : 14,
				12 : 14,
				13 : 15,
				14 : 15,
				15 : 14,
				16 : 14,
				17 : 13,
				18 : 11,
				19 : 10,
				20 : 8,
				21 : 6,
				22 : 5,
				23 : 4,
				24 : 2,
				25 : 2,
				26 : 1
			};
		}
		for (i = 0; i <= max_place; i++) {
			var s = data_sel[i].length;
			for (j = 0; j < s; j++) {
				nums += cc[parseInt(data_sel[i][j], 10)];
			}
		}

		if (mname == 'RXZXSSC3HZ' || mname == 'RXZUSSC3HZ') { // 任选三直选和值,任选三组选和值
			nums *= positionSel.length == 0 ? 0 : Combination(
					positionSel.length, 3);
		}
		break;
	case 'ZUS':
		// 组三
	case 'RXZUSANSSC3':
		for (i = 0; i <= max_place; i++) {
			var s = data_sel[i].length;
			if (s > 1) { // 组三必须选两位或者以上
				nums += s * (s - 1);
			}
		}

		if (mname == 'RXZUSANSSC3') {
			nums *= positionSel.length == 0 ? 0 : Combination(
					positionSel.length, 3);
		}
		break;
	case 'ZUL':
		// 组六
	case 'RXZUSIXSSC3':
		for (i = 0; i <= max_place; i++) {
			var s = data_sel[i].length;
			if (s > 2) { // 组六必须选三位或者以上
				nums += s * (s - 1) * (s - 2) / 6;
			}
		}
		if (mname == 'RXZUSIXSSC3') {
			nums *= positionSel.length == 0 ? 0 : Combination(
					positionSel.length, 3);
		}
		break;
	case 'DDTM3':{
		for (i = 0; i <= max_place; i++) {
			var s = data_sel[i].length;
			if (s > 2) { // 组六必须选三位或者以上
				nums += s * (s - 1) * (s - 2) / 6;
			}
		}
		break;
	}
	case 'ZH5':
	case 'ZH4':
		// 组合4
	case 'ZH3':
		for (i = 0; i <= max_place; i++) {
			if (data_sel[i].length == 0) { // 有位置上没有选择
				tmp_nums = 0;
				break;
			}
			tmp_nums *= data_sel[i].length;
		}
		nums = tmp_nums * parseInt(mname.charAt(mname.length - 1));
		break;
	case "SXZU24":
		var s = data_sel[0].length;
		if (s > 3) {
			nums += Combination(s, 4)
		}
		break;
	case 'SXZU6':
		if (data_sel[0].length >= minchosen[0]) {
			// C(n,2)
			nums += Combination(data_sel[0].length, minchosen[0]);
		}
		break;
	case "SXZU12":
	case "SXZU4":
		if (data_sel[0].length >= minchosen[0]
				&& data_sel[1].length >= minchosen[1]) {
			var h = Array.intersect(data_sel[0], data_sel[1]).length;
			tmp_nums = Combination(data_sel[0].length, minchosen[0])
					* Combination(data_sel[1].length, minchosen[1]);
			if (h > 0) {
				if (mname == "SXZU12") {
					tmp_nums -= Combination(h, 1)
							* Combination(data_sel[1].length - 1, 1)
				} else {
					if (mname == "SXZU4") {
						tmp_nums -= Combination(h, 1)
					}
				}
			}
			nums += tmp_nums
		}
		break;
	case 'BDW2':
		// 二码不定位
	case 'ZU2':
		// 2位组选
		for (i = 0; i <= max_place; i++) {
			var s = data_sel[i].length;
			if (s > 1) { // 二码不定位必须选两位或者以上
				nums += s * (s - 1) / 2;
			}
		}
		break;
	case 'ZXHZ2':
		// 直选和值2
		cc = {
			0 : 1,
			1 : 2,
			2 : 3,
			3 : 4,
			4 : 5,
			5 : 6,
			6 : 7,
			7 : 8,
			8 : 9,
			9 : 10,
			10 : 9,
			11 : 8,
			12 : 7,
			13 : 6,
			14 : 5,
			15 : 4,
			16 : 3,
			17 : 2,
			18 : 1
		};
		for (i = 0; i <= max_place; i++) {
			var s = data_sel[i].length;
			for (j = 0; j < s; j++) {
				nums += cc[parseInt(data_sel[i][j], 10)];
			}
		}
		break;
	case 'ZUHZ2':
		// 组选和值2
		cc = {
			0 : 0,
			1 : 1,
			2 : 1,
			3 : 2,
			4 : 2,
			5 : 3,
			6 : 3,
			7 : 4,
			8 : 4,
			9 : 5,
			10 : 4,
			11 : 4,
			12 : 3,
			13 : 3,
			14 : 2,
			15 : 2,
			16 : 1,
			17 : 1,
			18 : 0
		};
		for (i = 0; i <= max_place; i++) {
			var s = data_sel[i].length;
			for (j = 0; j < s; j++) {
				nums += cc[parseInt(data_sel[i][j], 10)];
			}
		}
		break;
	case 'DWD':
		// 定位胆所有在一起特殊处理
		for (i = 0; i <= max_place; i++) {
			nums += data_sel[i].length;
		}
		break;
	case 'SDZX3':
		// 山东11运前三直选
		nums = 0;
		if (data_sel[0].length > 0 && data_sel[1].length > 0
				&& data_sel[2].length > 0) {
			for (i = 0; i < data_sel[0].length; i++) {
				for (j = 0; j < data_sel[1].length; j++) {
					for (var k = 0; k < data_sel[2].length; k++) {
						if (data_sel[0][i] != data_sel[1][j]
								&& data_sel[0][i] != data_sel[2][k]
								&& data_sel[1][j] != data_sel[2][k]) {
							nums++;
						}
					}
				}
			}
		}
		break;
	case 'SDZU3':
		// 山东11运前三组选
		for (i = 0; i <= max_place; i++) {
			var s = data_sel[i].length;
			if (s > 2) { // 组六必须选三位或者以上
				nums += s * (s - 1) * (s - 2) / 6;
			}
		}
		break;
	case 'SDZX2':
		// 山动十一运前二直选
		nums = 0;
		if (data_sel[0].length > 0 && data_sel[1].length > 0) {
			for (i = 0; i < data_sel[0].length; i++) {
				for (j = 0; j < data_sel[1].length; j++) {
					if (data_sel[0][i] != data_sel[1][j]) {
						nums++;
					}
				}
			}
		}
		break;
	case 'SDZU2':
		// 山东十一运前二组选
		for (i = 0; i <= max_place; i++) {
			var s = data_sel[i].length;
			if (s > 1) { // 组六必须选三位或者以上
				nums += s * (s - 1) / 2;
			}
		}
		break;
	case 'SDBDW':
	case 'SDDWD':
	case 'SDDDS':
	case 'SDCZW':
	case 'SDRX1':
		// 任选1中1
		for (i = 0; i <= max_place; i++) {
			nums += data_sel[i].length;
		}
		break;
	case 'SDRX2':
		// 任选2中2
		for (i = 0; i <= max_place; i++) {
			var s = data_sel[i].length;
			if (s > 1) { // 二码不定位必须选两位或者以上
				nums += s * (s - 1) / 2;
			}
		}
		break;
	case 'SDRX3':
		// 任选3中3
		for (i = 0; i <= max_place; i++) {
			var s = data_sel[i].length;
			if (s > 2) { // 必须选三位或者以上
				nums += s * (s - 1) * (s - 2) / 6;
			}
		}
		break;
	case 'SDRX4':
		// 任选4中4
		for (i = 0; i <= max_place; i++) {
			var s = data_sel[i].length;
			if (s > 3) { // 必须选四位或者以上
				nums += s * (s - 1) * (s - 2) * (s - 3) / 24;
			}
		}
		break;
	case 'SDRX5':
		// 任选5中5
		for (i = 0; i <= max_place; i++) {
			var s = data_sel[i].length;
			if (s > 4) { // 必须选四位或者以上
				nums += s * (s - 1) * (s - 2) * (s - 3) * (s - 4) / 120;
			}
		}
		break;
	case 'SDRX6':
		// 任选6中6
		for (i = 0; i <= max_place; i++) {
			var s = data_sel[i].length;
			if (s > 5) { // 必须选四位或者以上
				nums += s * (s - 1) * (s - 2) * (s - 3) * (s - 4) * (s - 5)
						/ 720;
			}
		}
		break;
	case 'SDRX7':
		// 任选7中7
		for (i = 0; i <= max_place; i++) {
			var s = data_sel[i].length;
			if (s > 6) { // 必须选四位或者以上
				nums += s * (s - 1) * (s - 2) * (s - 3) * (s - 4) * (s - 5)
						* (s - 6) / 5040;
			}
		}
		break;
	case 'SDRX8':
		// 任选8中8
		for (i = 0; i <= max_place; i++) {
			var s = data_sel[i].length;
			if (s > 7) { // 必须选四位或者以上
				nums += s * (s - 1) * (s - 2) * (s - 3) * (s - 4) * (s - 5)
						* (s - 6) * (s - 7) / 40320;
			}
		}
		break;
	// 下面是北京快乐吧
	case 'BJRX2':
		// 北京快乐8 任选2
		for (i = 0; i <= max_place; i++) {
			var s = data_sel[i].length;
			if (s > 1) { // 必须选 两位到八位
				nums += s * (s - 1) / 2;
			}
		}
		break;
	case 'BJRX3':
		// 北京快乐8 任选3
		for (i = 0; i <= max_place; i++) {
			var s = data_sel[i].length;
			if (s > 2) { // 必须选 三位到八位
				nums += s * (s - 1) * (s - 2) / 6;
			}
		}
		break;
	case 'BJRX4':
		// 北京快乐8 任选4
		for (i = 0; i <= max_place; i++) {
			var s = data_sel[i].length;
			if (s > 3) { // 必须选 四位到八位
				nums += s * (s - 1) * (s - 2) * (s - 3) / 24;
			}
		}
		break;
	case 'BJRX5':
		// 北京快乐8 任选5
		for (i = 0; i <= max_place; i++) {
			var s = data_sel[i].length;
			if (s > 4) { // 必须选 五位到八位
				nums += s * (s - 1) * (s - 2) * (s - 3) * (s - 4) / 120;
			}
		}
		break;
	case 'BJRX6':
		// 北京快乐8 任选6
		for (i = 0; i <= max_place; i++) {
			var s = data_sel[i].length;
			if (s > 5) { // 必须选 六位到八位
				nums += s * (s - 1) * (s - 2) * (s - 3) * (s - 4) * (s - 5)
						/ 720;
			}
		}
		break;
	case 'BJRX7':
		// 北京快乐8 任选7
		for (i = 0; i <= max_place; i++) {
			var s = data_sel[i].length;
			if (s > 6) { // 必须选 七位到八位
				nums += s * (s - 1) * (s - 2) * (s - 3) * (s - 4) * (s - 5)
						* (s - 6) / 5040;
			}
		}
		break;

	case "RXZXSSC2":
		//var aCodePosition = [];
		// 任选二直选
		if(max_place==4){
			var wan = data_sel[0].length;
			if (wan > 0)
				positionSel.push("1");
			var qian = data_sel[1].length;
			if (qian > 0)
				positionSel.push("2");
			var bai = data_sel[2].length;
			if (bai > 0)
				positionSel.push("3");
			var shi = data_sel[3].length;
			if (shi > 0)
				positionSel.push("4");
			var ge = data_sel[4].length;
			if (ge > 0)
				positionSel.push("5");
			// 万*千 + 万*百 + 万*十 + 万*个 + 千*百 + 千*十 + 千*个 + 百*十 + 百*个 + 十*个
			nums += wan * qian + wan * bai + wan * shi + wan * ge + qian * bai
					+ qian * shi + qian * ge + bai * shi + bai * ge + shi * ge;
		}
		if(max_place==3){
			var qian = data_sel[0].length;
			if (qian > 0)
				positionSel.push("1");
			var bai = data_sel[1].length;
			if (bai > 0)
				positionSel.push("2");
			var shi = data_sel[2].length;
			if (shi > 0)
				positionSel.push("3");
			var ge = data_sel[3].length;
			if (ge > 0)
				positionSel.push("4");
			
			// 千*百 + 千*十 + 千*个 + 百*十 + 百*个 + 十*个
			nums += qian * bai + qian * shi + qian * ge + bai * shi + bai * ge + shi * ge;
		}
		break;
	case "RXZUSSC2":
		// 任选二组选
		for (i = 0; i <= max_place; i++) {
			var s = data_sel[i].length;
			if (s > 1) {
				nums += s * (s - 1) / 2;
			}
		}
		var select_num = positionSel.length;
		var multi = 0;
		switch (select_num) {
		case 0:
			multi = 0;
			break;
		case 1:
			multi = 0;
			break;
		case 2:
			multi = 1;
			break;
		case 3:
			multi = 3;
			break;
		case 4:
			multi = 6;
			break;
		case 5:
			multi = 10;
			break;
		}
		nums = nums * multi;
		break;
	// 任选二-直选和值
	case "RXZXSSC2HZ":
		cc = {
			0 : 1,
			1 : 2,
			2 : 3,
			3 : 4,
			4 : 5,
			5 : 6,
			6 : 7,
			7 : 8,
			8 : 9,
			9 : 10,
			10 : 9,
			11 : 8,
			12 : 7,
			13 : 6,
			14 : 5,
			15 : 4,
			16 : 3,
			17 : 2,
			18 : 1
		};
		for (i = 0; i <= max_place; i++) {
			var s = data_sel[i].length;
			for (j = 0; j < s; j++) {
				nums += cc[parseInt(data_sel[i][j], 10)];
			}
		}
		nums *= positionSel.length == 0 ? 0
				: Combination(positionSel.length, 2);
		break;
	// 任选二-组选和值
	case "RXZUSSC2HZ":
		cc = {
			0 : 0,
			1 : 1,
			2 : 1,
			3 : 2,
			4 : 2,
			5 : 3,
			6 : 3,
			7 : 4,
			8 : 4,
			9 : 5,
			10 : 4,
			11 : 4,
			12 : 3,
			13 : 3,
			14 : 2,
			15 : 2,
			16 : 1,
			17 : 1,
			18 : 0
		};
		for (i = 0; i <= max_place; i++) {
			var s = data_sel[i].length;
			for (j = 0; j < s; j++) {
				nums += cc[parseInt(data_sel[i][j], 10)];
			}
		}
		nums *= positionSel.length == 0 ? 0
				: Combination(positionSel.length, 2);
		break;
	// 任选三-直选复式
	case "RXZXSSC3":
		positionSel = [];
		for (i = 0; i <= max_place; i++) {
			var codelen = data_sel[i].length;
			if (codelen > 0) {
				positionSel.push(i);
			}
		}
		var aPositionCombo = getCombination(positionSel, 3);
		var iComboLen = aPositionCombo.length;
		var aCombo = [];
		var iLen = 0;
		var tmpNums = 1;
		for (j = 0; j < iComboLen; j++) {
			aCombo = aPositionCombo[j].split(",");
			iLen = aCombo.length;
			tmpNums = 1;
			for (h = 0; h < iLen; h++) {
				tmpNums *= data_sel[aCombo[h]].length;
			}
			nums += tmpNums;
		}
		for (var i = 0; i < positionSel.length; i++) {
			positionSel[i] = positionSel[i] + 1;
		}
		break;
	// 任选四-直选复式
	case "RXZXSSC4":
		positionSel = [];
		for (i = 0; i <= max_place; i++) {
			var codelen = data_sel[i].length;
			if (codelen > 0) {
				positionSel.push(i);
			}
		}
		var aPositionCombo = getCombination(positionSel, 4);
		var iComboLen = aPositionCombo.length;
		var aCombo = [];
		var iLen = 0;
		var tmpNums = 1;
		for (j = 0; j < iComboLen; j++) {
			aCombo = aPositionCombo[j].split(",");
			iLen = aCombo.length;
			tmpNums = 1;
			for (h = 0; h < iLen; h++) {
				tmpNums *= data_sel[aCombo[h]].length;
			}
			nums += tmpNums;
		}
		for (var i = 0; i < positionSel.length; i++) {
			positionSel[i] = positionSel[i] + 1;
		}
		break;
	// 任选四-组选-组选24
	case "RXZU24SSC4":
		var s = data_sel[0].length;
		if (s > 3) {
			nums += Combination(s, 4);
		}
		nums *= positionSel.length == 0 ? 0
				: Combination(positionSel.length, 4);
		break;
	// 任选四-组选-组选12
	case "RXZU12SSC4":
	case "RXZU4SSC4":
		if (data_sel[0].length >= minchosen[0]
				&& data_sel[1].length >= minchosen[1]) {
			var h = Array.intersect(data_sel[0], data_sel[1]).length;
			tmp_nums = Combination(data_sel[0].length, minchosen[0])
					* Combination(data_sel[1].length, minchosen[1]);
			if (h > 0) {
				if (mname == "RXZU12SSC4") {
					tmp_nums -= Combination(h, 1)
							* Combination(data_sel[1].length - 1, 1);
				} else {
					if (mname == "RXZU4SSC4") {
						tmp_nums -= Combination(h, 1);
					}
				}
			}
			nums += tmp_nums;
		}
		nums *= positionSel.length == 0 ? 0
				: Combination(positionSel.length, 4);
		break;
	// 任选四-组选-组选6
	case "RXZU6SSC4":
		if (data_sel[0].length >= minchosen[0]) {
			nums += Combination(data_sel[0].length, minchosen[0]);
		}
		nums *= positionSel.length == 0 ? 0
				: Combination(positionSel.length, 4);
		break;
	case "PK10GYJ":
		// PK10 冠亚军
		nums = 0;
		if (data_sel[0].length > 0 && data_sel[1].length > 0) {
			for (i = 0; i < data_sel[0].length; i++) {
				for (j = 0; j < data_sel[1].length; j++) {
					if (data_sel[0][i] != data_sel[1][j]) {
						nums++;
					}
				}
			}
		}
		break;
	case "PK10QSM":
		nums = 0;
		if (data_sel[0].length > 0 && data_sel[1].length > 0
				&& data_sel[2].length > 0) {
			for (i = 0; i < data_sel[0].length; i++) {
				for (j = 0; j < data_sel[1].length; j++) {
					for (k = 0; k < data_sel[2].length; k++) {
						if (data_sel[0][i] != data_sel[1][j]
								&& data_sel[0][i] != data_sel[2][k]
								&& data_sel[1][j] != data_sel[2][k]) {
							nums++;
						}
					}
				}
			}
		}
		break;
	case "PK10DWD1TO5":
	case "PK10DWD6TO10":
		for (i = 0; i <= max_place; i++) {
			nums += data_sel[i].length;
		}
		break;
	case "PK10DWD1TO10":
        
        for (i = 0; i <= max_place; i++) {
            nums += data_sel[i].length;
        }
        break;
	default:
		// 默认情况
		for (i = 0; i <= max_place; i++) {
			if (data_sel[i].length == 0) { // 有位置上没有选择
				tmp_nums = 0;
				break;
			}
			tmp_nums *= data_sel[i].length;
		}
		nums = tmp_nums;
		break;
	}
	return {nums:nums,position:positionSel};
}

function input_deal(num, methodname) {
	var s = num;
	
	s = s.replace(/[^\s\r,;，；　０１２３４５６７８９0-9]/g, "").trim();
	var m = s;
	switch (methodname) {
	case 'SDZX3':
	case 'SDZU3':
	case 'SDZX2':
	case 'SDRX1':
	case 'SDRX2':
	case 'SDRX3':
	case 'SDRX4':
	case 'SDRX5':
	case 'SDRX6':
	case 'SDRX7':
	case 'SDRX8':
	case 'SDZU2':
		s = s.replace(/[\r\n,;，；]/g, "|").replace(/(\|)+/g, "|");
		break;
	case 'PK10GYJ':
		s = s.replace(/[\r\n,;，；]/g, "|").replace(/(\|)+/g, "|");
		break;
	case 'PK10QSM':
		s = s.replace(/[\r\n,;，；]/g, "|").replace(/(\|)+/g, "|");
		break;
	default:
		s = s.replace(/[\s\r,;，；　]/g, "|").replace(/(\|)+/g, "|");
		break;
	}
	
	s = s.replace(/０/g, "0").replace(/１/g, "1").replace(/２/g, "2").replace(
			/３/g, "3").replace(/４/g, "4").replace(/５/g, "5").replace(/６/g, "6")
			.replace(/７/g, "7").replace(/８/g, "8").replace(/９/g, "9");
	if (s == "") {
		return []; // 清空数据
	} else {
		return s.split("|");
	}
}

var _SortNum1 = function(a, b) { // 数字大小排序
	var C;
	if (C != "input") {
       
    }
    var N = parseInt(N, 10);
    var M = parseInt(M, 10);
    if (isNaN(N) || isNaN(M)) {
        return true
    }
    return (N - M)
};
export const _SortNum = (gamePlayObject) => {
	return function(a, b) { // 数字大小排序
		if (gamePlayObject.otype != 'input') {
			if(gamePlayObject.playid=="KW3"){
				a = a.replace(/5单0双/g, 0).replace(/4单1双/g, 1).replace(/3单2双/g, 2).replace(/2单3双/g, 3).replace(/1单4双/g, 4).replace(/0单5双/g, 5);
					if (methodid != "KSHZ" && methodid != "SBTHHZ") {
						a = a.replace(/11/gi, 11).replace(/22/gi, 22).replace(/33/gi, 33).replace(/44/gi, 44).replace(/55/gi, 55).replace(/66/gi, 66)
					}
					a = a.replace(/大/g, -1).replace(/小/g, 0).replace(/单/g, 1).replace(/双/g, 2).replace(/\s/g, "");
					b = b.replace(/5单0双/g, 0).replace(/4单1双/g, 1).replace(/3单2双/g, 2).replace(/2单3双/g, 3).replace(/1单4双/g, 4).replace(/0单5双/g, 5);
					b = b.replace(/111/gi, 1).replace(/222/gi, 2).replace(/333/gi, 3).replace(/444/gi, 4).replace(/555/gi, 5).replace(/666/gi, 6);
					if (methodid != "KSHZ" && methodid != "SBTHHZ") {
						b = b.replace(/11/gi, 11).replace(/22/gi, 22).replace(/33/gi, 33).replace(/44/gi, 44).replace(/55/gi, 55).replace(/66/gi, 66)
					}
					b = b.replace(/大/g, 0).replace(/小/g, 1).replace(/单/g, 2).replace(/双/g, 3).replace(/\s/g, "")
			}else{
				
			a = a.replace(/5单0双/g, 0).replace(/4单1双/g, 1).replace(/3单2双/g, 2)
					.replace(/2单3双/g, 3).replace(/1单4双/g, 4).replace(/0单5双/g, 5);
			a = a.replace(/大/g, 0).replace(/小/g, 1).replace(/单/g, 2).replace(/双/g,
					3).replace(/\s/g, "");
			b = b.replace(/5单0双/g, 0).replace(/4单1双/g, 1).replace(/3单2双/g, 2)
					.replace(/2单3双/g, 3).replace(/1单4双/g, 4).replace(/0单5双/g, 5);
			b = b.replace(/大/g, 0).replace(/小/g, 1).replace(/单/g, 2).replace(/双/g,
					3).replace(/\s/g, "");
			}
		}
		a = parseInt(a, 10);
		b = parseInt(b, 10);
		if (isNaN(a) || isNaN(b)) {
			return true;
		}
		return (a - b);
	}
};
var dumpNum = function(isdeal) {
    var l = data_sel[0].length;
    var err = [];
    var news = []; //除去重复号后的结果
    var tmp = [];
    if (l == 0) {
        return err;
    }
    for (var i = 0; i < l; i++) {
        var key = data_sel[0][i];
        if (tmp.hasOwnProperty(key)) tmp[key] += 1;
        else tmp[key] = 1;
    }

    for (var i = 0; i < l; i++) {
        var key = data_sel[0][i];
        if (err.indexOf(key) != -1) {
            continue;
        }

        if (tmp[key] > 1) {
            err.push(key);
        }

        news.push(key);
    }
    if (isdeal) { //如果是做删除重复号的处理
        data_sel[0] = news;
    }
    return err;
};

var _ErbuTonghao = function(Q, M) {
    if (M != 2) {
        return false
    }
    var P = "";
    var N = "";
    var O = 0;
    for (O = 0; O < M; O++) {
        if (O == 0) {
            P = Q.substr(O, 1)
        }
        if (O == 1) {
            N = Q.substr(O, 1)
        }
    }
    if (P == N) {
        return false
    }
    return true
};
var _SanbuTonghao = function(R, M) {
    if (M != 3) {
        return false
    }
    var Q = "";
    var O = "";
    var N = "";
    var P = 0;
    for (P = 0; P < M; P++) {
        if (P == 0) {
            Q = R.substr(P, 1)
        }
        if (P == 1) {
            O = R.substr(P, 1)
        }
        if (P == 2) {
            N = R.substr(P, 1)
        }
    }
    if (Q == O || O == N || N == Q) {
        return false
    } else {
        return true
    }
    return false
};
var _ErTonghaoDanxuan = function(R, M) {
    if (M != 3) {
        return false
    }
    var Q = "";
    var O = "";
    var N = "";
    var P = 0;
    for (P = 0; P < M; P++) {
        if (P == 0) {
            Q = R.substr(P, 1)
        }
        if (P == 1) {
            O = R.substr(P, 1)
        }
        if (P == 2) {
            N = R.substr(P, 1)
        }
    }
    if (Q == O && O == N) {
        return false
    }
    if (Q == O || O == N || N == Q) {
        return true
    }
    return false
};
function resetodds(area,n){
	if(area.selectNums[n.no])
  	  area.selectOdds[n.no] = n.tip;
    else
  	  delete area.selectOdds[n.no];
}
export const ApiAll = {
    getRank: function(e, o) {
        var t = [];
        return function e(o, n, a) {
            if (0 == a) return t.push(o.join(","));
            for (var i = 0,
            r = n.length; i <= r - a; i++) e(o.concat(n[i]), n.slice(i + 1), a - 1)
        } ([], e, o),
        t
    }
};