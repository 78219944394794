import i18next from 'i18next';
import { SetDemoUser,SaveMemberInfo} from '../actions/HomePageAction';
import { store } from '../index';
import { checkThridLogin, saveNewAccConfigsService } from '../services/HomeService';
import { alert, alertWithFunction, changeBalanceAlert, closeAlert, confirmAlertWithFunction, refreshAlert, validCodeAlert} from './AlertService';
import Fetcher from '../components/Fetcher';
import {trackEvent} from '@adjustcom/adjust-web-sdk';
import { Storage } from '@capacitor/storage';
import {AppsFlyer} from "appsflyer-capacitor-plugin";

const tran = (input) => {
  return i18next.t(input);
}

const isNotEmpty = function(value){
  return value && true&&value.trim()!=="";
}

const isEmpty = function(value){
  return !isNotEmpty(value);
}

export const validatePhoneWap = function(event) {
  let a = event.parentElement.nextSibling;
  let inputValue = event.value;
  let phoneCode = document.getElementById('phoneCode');
  if (isEmpty(inputValue)) {
    a.innerHTML = tran('请输入手机号');
    a.style.display = 'block';
    if (phoneCode)
      phoneCode.style.display = 'none';
    return false;
  }
  if (!/^\d{9,15}$/.test(inputValue)) {
    a.innerHTML = tran('手机号格式输入错误!');
    a.style.display = 'block';
    if (phoneCode)
      phoneCode.style.display = 'none';
    return false;
  }
  if(inputValue.length >= 9){
    if (phoneCode)
      phoneCode.style.display = 'block';
  }
  a.innerHTML = '';
  a.style.display = 'none';
  return true;
};

export const validateEmailWap = function(event) {
  let a;
  let inputValue;
  if(event.currentTarget){
    a = event.currentTarget.parentElement.nextSibling;
    inputValue = event.target.value;
  }else{
    a = event.parentElement.nextSibling;
    inputValue = event.value;
  }

  let emailCode = document.getElementById('emailCode');
  if (isEmpty(inputValue)) {
    a.innerHTML = tran('请输入邮箱地址！');
    a.style.display = 'block';
    if (emailCode)
      emailCode.style.display = 'none';
    return false;
  }
  if (!/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(inputValue)) {
    a.innerHTML = tran('您输入的邮箱格式不正确!');
    a.style.display = 'block';
    if (emailCode)
      emailCode.style.display = 'none';
    return false;
  }
  a.innerHTML = '';
  a.style.display = 'none';
  if(event.target)
    if(event.target.getAttribute('id') === 'emailInfo'){
      if(inputValue.length>=5){
        if (emailCode)
          emailCode.style.display = 'block';
      }
    }
    return true;
};

export const validateDrawMoneyPwd = function(event) {
  let a = event.currentTarget.parentElement.nextSibling;
  let inputValue = event.target.value;
  if (isEmpty(inputValue)) {
    a.innerHTML = tran('请输入安全密码！');
    a.style.display = 'block';
    return false;
  }
  if (/^[0-9]d*$/.test(inputValue)) {
    a.innerHTML = tran('密码必须是纯数字!');
    a.style.display = 'block';
    return false;
  }else if(inputValue.length >= 6 && inputValue.length <= 6 && /^[0-9]{6}$/.test(inputValue)){
    a.style.display = 'none';
    return true;
  }else{
    let msg = (inputValue.length < 6 || inputValue.length>6) ? tran("密码长度只能为6个字元"):tran("须为6个数字！");
    a.innerHTML = msg;
    a.style.display = 'block';
    return false;
  }
};

export const phoneBtn = function() {
  if (validatePhoneWap(document.getElementById('phoneId')) === false) return false;
  if (document.getElementById('phoneRegCode'))
    if (!document.getElementById('phoneRegCode').value) {
      alert(tran('请填写手机验证码'), tran('MSG.T_00'));
      return false;
    }

  let form = document.querySelector('#phoneForm');
  let data = new URLSearchParams(new FormData(form)).toString();
  const urlParams = new URLSearchParams(data);

  let memberInfo = store.getState().memberInfo;
  let langid = memberInfo.MemberInfo.langid?memberInfo.MemberInfo.langid:'';
  try{
    if (window.$isApp && window.$isIonic) {
      (async () => {
        const isAdjust = await Storage.get({ key: 'adjustIsAdjust' });
        if(isAdjust.value=="1"){
          const adjustEvent = await Storage.get({ key: 'adjustEventToken' });
          const adjustEventToken = JSON.parse(adjustEvent.value);
          if(adjustEventToken.a_register){
            trackEvent({
              eventToken: adjustEventToken.a_register
            })
          }
        }
      })()
    }
  }catch(e){
    console.log(e);
  }
  
  fetch(window.$wgtDomain + '/memreg/save?langid='+langid, {
    method: 'POST',
    headers: new Headers({
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/x-www-form-urlencoded'
    }),
    body: urlParams,
    responseType: 'json',
    // credentials: 'include',
  }).then((response) => response.json())
    .then((response) => {
      if(response.status===1 || response.status===3){
        if (response.gaiatoken !== null) {
          let memberInfo = store.getState().memberInfo;
          memberInfo.token = response.gaiatoken;
          store.dispatch(SaveMemberInfo(memberInfo));
        }

        try{
          if (window.$isApp && window.$isIonic) {
            if(window.$appsFlyerAppId!=""){
              AppsFlyer.logEvent({
                eventName: 'af_complete_registration',
                eventValue: {
                  uid: document.getElementById('phoneId')
               }
              })
              .then(r => console.log('AppsFlyer Reg Event ~~>' + r.res))
              .catch(err => console.log('AppsFlyer Reg Event err ~~>' + err));
            }
          }
        }catch(e){
          console.log(e);
        }
        
        refreshAlert(tran('您已完成注册！'),tran('notice'));
      }else if(response.status===2){
        refreshAlert(tran('请登录您的注册邮箱，激活您的账号！'),tran('MSG.T_00'));
      }else{
        alert(response.msg,tran('MSG.T_00'));
      }
    });
};

export const emailBtn = function() {
  if (validateEmailWap(document.getElementById('emailId')) === false) return false;
  if (document.getElementById('emailRegCode'))
    if (!document.getElementById('emailRegCode').value) {
      alert(tran('请填写邮箱验证码'), tran('MSG.T_00'));
      return false;
    }

  let form = document.querySelector('#emailForm');
  let data = new URLSearchParams(new FormData(form)).toString();
  const urlParams = new URLSearchParams(data);
  let memberInfo = store.getState().memberInfo;
  let langid = memberInfo.MemberInfo.langid?memberInfo.MemberInfo.langid:'';
  try{
    if (window.$isApp && window.$isIonic) {
      (async () => {
        const isAdjust = await Storage.get({ key: 'adjustIsAdjust' });
        if(isAdjust.value=="1"){
          const adjustEvent = await Storage.get({ key: 'adjustEventToken' });
          const adjustEventToken = JSON.parse(adjustEvent.value);
          if(adjustEventToken.a_register){
            trackEvent({
              eventToken: adjustEventToken.a_register
            })
          }
        }
      })()
    }
  }catch(e){
    console.log(e);
  }
  
  fetch(window.$wgtDomain + '/memreg/save?langid='+langid, {
    method: 'POST',
    headers: new Headers({
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/x-www-form-urlencoded'
    }),
    body: urlParams,
    responseType: 'json',
    // credentials: 'include',
  }).then((response) => response.json())
    .then((response) => {
      if (response.status === 1 || response.status === 3) {
        if (response.gaiatoken !== null) {
          let memberInfo = store.getState().memberInfo;
          memberInfo.token = response.gaiatoken;
          store.dispatch(SaveMemberInfo(memberInfo));
        }

        try{
          if (window.$isApp && window.$isIonic) {
            if(window.$appsFlyerAppId!=""){
              AppsFlyer.logEvent({
                eventName: 'af_complete_registration',
                eventValue: {
                  uid: document.getElementById('emailId')
               }
              })
              .then(r => console.log('AppsFlyer Reg Event ~~>' + r.res))
              .catch(err => console.log('AppsFlyer Reg Event err ~~>' + err));
            }
          }
        }catch(e){
          console.log(e);
        }

        alertWithFunction(tran('您已完成注册！'), tran('MSG.T_00'),"resendEmail");
      } else if(response.status===2){
        refreshAlert(tran('请登录您的注册邮箱，激活您的账号！'), tran('MSG.T_00'))
      } else {
        alert(response.msg, tran('MSG.T_00'));
      }
    });
};

export const resendEmail= function(userPwd){

  const data = new URLSearchParams();
  data.set('email', document.getElementById('emailId').value);
  data.set('type', 1);
  data.set('sendType', 'detail');
  data.set('sendCode', userPwd);

  Fetcher(window.$wgtDomain + '/memreg/send-email', {
    method: 'POST',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: data,
    responseType: 'json',
    // credentials: 'include',
  }).then((response) => {
      window.location.href = window.location.href.split('#')[0];
    })
};

export const loginSendCodeSend = function(){
  let loginName = document.getElementById('username').value;
  if(isEmpty(loginName)) {
    alert(tran('账号名格式错误'),"");
    return;
  }

  if(/^1\d{10}$/.test(loginName)){ // 如果是手机号
    if(document.getElementById("sendCodeBtn").textContent === " 重新发送 "){
      let param = {type:1,value:loginName,code:'',needcode:true,sendtype:'login',domid:'sendCodeBtn'};
      validCodeAlert("",tran("请输入验证码"),"alertSendCode",param);
    }else{
        sendcode(1,loginName,'',false,'login','sendCodeBtn');
    }
  } else if(/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(loginName)){  // 如果是邮箱
    if(document.getElementById("sendCodeBtn").textContent === " 重新发送 "){
      let param = {type:0,value:loginName,code:'',needcode:true,sendtype:'login',domid:'sendCodeBtn'};
      validCodeAlert("",tran("请输入验证码"),"alertSendCode",param);
    }else{
      sendcode(0,loginName,'',false,'login','sendCodeBtn');
    }
  }else{
    alert(tran("请输入正确的手机/邮箱"),tran("格式错误"));
    return;
  }
};

export const phoneSend = function(){
  let phone = document.getElementById('phoneId').value;
  if (validatePhoneWap(document.getElementById('phoneId')) === false) return false;
  if(document.getElementById("phoneIdSend").textContent === " 重新发送 "){
    let param = {type:1,value:phone,code:'',needcode:true,sendtype:'detail',domid:'phoneIdSend'};
    validCodeAlert("",tran("请输入验证码"),"alertSendCode",param);
  }else{
    sendcode(1,phone,'',false,'detail','phoneIdSend');
  }
};

export const phoneInfoSend = function(){
  let phone = document.getElementById('phoneInfo').value;
  
  if (validatePhoneWap(document.getElementById('phoneInfo')) === false) return false;
  if(document.getElementById("phoneInfoSend").textContent === " 重新发送 "){
    let param = {type:1,value:phone,code:'',needcode:true,sendtype:'detail',domid:'phoneInfoSend'};
    validCodeAlert("",tran("请输入验证码"),"alertSendCode",param);
  }else{
    sendcode(1,phone,'',false,'detail','phoneInfoSend');
  }
};

export const emailInfoSend = function(){
  let email = document.getElementById('emailInfo').value;
  if (validateEmailWap(document.getElementById('emailInfo')) === false) return false;
  if(document.getElementById("emailInfoSend").textContent === " 重新发送 "){
    let param = {type:0,value:email,code:'',needcode:true,sendtype:'detail',domid:'emailInfoSend'};
    validCodeAlert("",tran("请输入验证码"),"alertSendCode",param);
  }else{
    sendcode(0,email,'',false,'detail','emailInfoSend');
  }
};

export const emailSend = function(){
  let email = document.getElementById('emailId').value;
  if (validateEmailWap(document.getElementById('emailId')) === false) return false;
  if(document.getElementById("emailIdSend").textContent === " 重新发送 "){
    let param = {type:0,value:email,code:'',needcode:true,sendtype:'detail',domid:'emailIdSend'};
    validCodeAlert("",tran("请输入验证码"),"alertSendCode",param);
  }else{
    sendcode(0,email,'',false,'detail','emailIdSend');
  }
};

export const sendcode = function(type,value,code,needcode,sendtype,domid){
  if(needcode){
    code = code || "error";
    if(code==='error'){
      alert(tran("请输入验证码"),tran("notice"));
      return false;
    }
  }
  let sendbtn = document.getElementById(domid);
  const data = new URLSearchParams();
  data.set('type', 0);
  data.set('sendType', sendtype||'register');
  data.set('validCode', code);
  if(type){
    data.set('phone', value);
  }else{
    data.set('email', value);
  }

  Fetcher(window.$wgtDomain + '/memreg/'+(type?"resend-phone":"send-email"),{
    method: "POST",
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body:data,
    responseType:"json",
    // credentials: 'include',
    referrer: 'h5'
  }).then((response) => response.json())
    .then((response) => {
      if(response.status===2){
        alert(response.msg,tran("notice"));
      }else if(response.status===1){
        sendValid(60,sendbtn);
      }else{
        if(response.status===0){
          sendValid(60-response.num,sendbtn);
        }else{
          sendbtn.innerHTML = tran(" 重新发送 ");
          sendbtn.setAttribute("disabled", "");
        }
      }
    })
};

export const alertSendCode = function(param){
  let type = param.type;
  let value = param.value;
  let needcode = param.needcode;
  let code = param.code;
  let sendtype = param.sendtype;
  let domid = param.domid;
  closeAlert();
  if(needcode){
    code = code || "error";
    if(code==='error'){
      alert(tran("请输入验证码"),tran("notice"));
      return false;
    }
  }
  let sendbtn = document.getElementById(domid);
  const data = new URLSearchParams();
  data.set('type', 0);
  data.set('sendType', sendtype||'register');
  data.set('validCode', code);
  if(type){
    data.set('phone', value);
  }else{
    data.set('email', value);
  }

  Fetcher(window.$wgtDomain + '/memreg/'+(type?"resend-phone":"send-email"),{
    method: "POST",
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body:data,
    responseType:"json",
    // credentials: 'include',
    referrer: 'h5'
  }).then((response) => response.json())
    .then((response) => {
      if(response.status===2){
        alert(response.msg,tran("notice"));
      }else if(response.status===1){
        sendValid(60,sendbtn);
      }else{
        if(response.status===0){
          sendValid(60-response.num,sendbtn);
        }else{
          sendbtn.innerHTML = tran(" 重新发送 ");
          sendbtn.setAttribute("disabled", "");
        }
      }
    })
};

export const sendValid = function(t,dom) {
  if(!t) {
    dom.innerHTML = tran(" 重新发送 ");
    dom.removeAttribute("disabled");
  }
  else {
    dom.innerHTML = tran(" 重新发送 ")+"(" + t +") ";
    dom.setAttribute("disabled", "disabled");
    setTimeout(function(){
      sendValid(--t,dom);
    },1000);
  }
};

export const showChangeBalance = function(sign,id,onsuccess,currencySymbol){
  let param = {sign:sign, id:id, onsuccess:onsuccess,currencySymbol:currencySymbol};
  let title = tran("对用户"+(sign==0?"存款":"取款"));
  let content = tran((sign==0?"存入":"取出")+"金额");
  changeBalanceAlert(content,title,"changeBalanceConfirm", param);
};

export const intToString = function(number,shortform){
  number = parseFloat((Math.round(number * 100) / 100).toFixed(2));
  if ( number.toString().length > 3 && shortform == 1) {
    var abs = Math.abs( number );
    if ( abs >= Math.pow( 10, 15) ) {
      // quadrillion
      number = parseFloat(( number / Math.pow( 10, 15 ) ).toFixed( 2 )) + "Q";                                
    } else if ( abs < Math.pow( 10, 15 ) && abs >= Math.pow( 10, 12 ) ) {
      // trillion
      number = parseFloat(( number / Math.pow( 10, 12 ) ).toFixed( 2 )) + "T";
    } else if ( abs < Math.pow( 10, 12 ) && abs >= Math.pow( 10, 9 ) ) {
      // billion
      number = parseFloat(( number / Math.pow( 10, 9 ) ).toFixed( 2 )) + "B";
    } else if ( abs < Math.pow( 10, 9 ) && abs >= Math.pow( 10, 6 ) ) {
      // million
      number = parseFloat(( number / Math.pow( 10, 6 ) ).toFixed( 2 )) + "M";
    } else if ( abs < Math.pow( 10, 6 ) && abs >= Math.pow( 10, 3 ) ) {
      // thousand
      number = parseFloat(( number / Math.pow( 10, 3 ) ).toFixed( 2 )) + "K";
    }
    return number;
  } else {
    let memberInfo = store.getState().memberInfo;
    if(memberInfo.MemberInfo.currency == "R$"){
      number = (Math.round(number * 100) / 100).toFixed( 2 );
      var parts = number.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      return parts.join(",");
    }
    var parts = number.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }
}

export const changeBalanceConfirm = function(param){
  let money = param.money;
  let sign = param.sign;
  let currencySymbol = param.currencySymbol;
  let contents = tran("确定取出金额");
  if(sign==0){
    contents = tran("确定存入金额");
  }
  let content = contents+currencySymbol + intToString(Math.trunc(money),1)+"?"
  closeAlert();
  if(money == null || money == 0){
    alert(tran("请输入正确的金额"),tran("notice"));
  }else{
    confirmAlertWithFunction(tran("notice"), content, changeBalance, param, true, true);
  }
};

export const changeBalance = function(param){
  const data = new URLSearchParams();
  data.set('money', param.money);
  data.set('remark', param.remark);
  data.set('sign', param.sign);
  data.set('id', param.id);

  Fetcher(window.$wgtDomain + '/member-center/team/update-balance', {
    method: 'POST',
    headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: data,
    // credentials: 'include',
    referrer: 'h5'
  })
  .then((response) => response.json())
  .then((response) => {
      console.log(response);

      if(response.success){
        // alert("操作成功!","");

        Fetcher(window.$wgtDomain + '/wap/refresh-user', {
          method: 'POST',
          headers: {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/x-www-form-urlencoded',
          },
          // credentials: 'include',
          referrer: 'h5'
        })
        .then((response2) => response2.json())
        .then((response2) => {
          // DO NOTHING
          // store.dispatch(RefreshUser(response2));
        });
        confirmAlertWithFunction("",tran("操作成功!"),param.onsuccess, null, false, false);

      } else {
        alert(response.msg,"");
      }
  })
  .catch((error) => {
      //console.log("error teamindex")
      console.error(error);

      alert(tran('MSG.E_15'),'');
  });
}

export const demoLoginService = function(){
  
  const data = new URLSearchParams();
  data.set("code", "");

  fetch(window.$wgtDomain + '/demo-login', {
    method: 'POST',
    headers: new Headers({
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/x-www-form-urlencoded',
    }),
    // credentials: 'include',
    body: data,
    referrer: 'h5'
  })
    .then((response) => response.json())
    .then(async (response) => {
      if(!response.status){
        alert(response.msg,tran("loginpage.loginfailed"))
      }else{
        let loginName = response.msg;

        if (response.gaiatoken !== null) {
          let memberInfo = store.getState().memberInfo;
          memberInfo.token = response.gaiatoken;
          store.dispatch(SaveMemberInfo(memberInfo));
        }

        saveNewAccConfigsService(loginName, store.getState().modal?.loginModal?.result?.authRegisterMember?.siteId);

        SetDemoUser({demoUserName:response.msg});

        await checkThridLogin(true);
        window.location.href = window.location.href.split('#')[0];
      }
    })
    .catch((error) => {
      console.log("error login")
      console.error(error);
    });
}