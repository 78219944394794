import React from 'react';
import PropTypes from 'utils/propTypes';
import { withTranslation } from 'react-i18next';
import { ysccolor,yxxpic } from '../../services/AwardService';

const osdImages = require.context('../../assets/image/OSD', true);
const yxxImages = require.context('../../assets/image/YXX', true);
const yscImages = require.context('../../assets/image/YSC', true);

class BettingRecordModalDetails extends React.Component{

  // constructor(props) {
  //   super(props);
  // }

  render() {
    const { t } = this.props;
    let betting_content = t(this.props.betting_content);
    let pos, check_len = 20;
    //break the text to next line if exceed length
    if (betting_content && betting_content.length > check_len) {
      for (let i = check_len; i < betting_content.length; i += check_len) {
        pos = betting_content.indexOf(",", i);//find komma after position set
        if (pos !== -1)
          betting_content = betting_content.substring(0, pos) + ",\n" + betting_content.substring(pos + 1);//add next line
      }
    }
    return(
      <>
        <div className="d-flex justify-content-between" style={{flexDirection:'row'}}>
          <div style={{fontSize:16}}>{this.props.ref_num_title}</div>
          <div style={{fontSize:13}} className={`text-${this.props.color}`}>{this.props.ref_num_}</div>
        </div>

        <div className="d-flex justify-content-between" style={{flexDirection:'row'}}>
          <div style={{fontSize:16}}>{this.props.betting_time_title}</div>
          <div style={{fontSize:13}} className={`text-${this.props.color}`}>{this.props.betting_time}</div>
        </div>

        <div className="d-flex justify-content-between" style={{flexDirection:'row'}}>
          <div style={{fontSize:16}}>{this.props.game_mode_title}</div>
          <div style={{fontSize:13}} className={`text-${this.props.color}`}>{this.props.game_mode}</div>
        </div>

        <div className="d-flex justify-content-between" style={{flexDirection:'row'}}>
          <div style={{fontSize:16}}>{this.props.betting_content_title}</div>
          <div style={{fontSize:13}} className={`text-${this.props.color} text-break`}>{betting_content}</div>
        </div>

        <div className="d-flex justify-content-between" style={{flexDirection:'row'}}>
          <div style={{fontSize:16}}>{this.props.betting_amount_title}</div>
          <div style={{fontSize:13}} className={`text-${this.props.color}`}>{this.props.betting_amount}</div>
        </div>

        <div className="d-flex justify-content-between" style={{flexDirection:'row'}}>
          <div style={{fontSize:16}}>{this.props.valid_betting_amount_title}</div>
          <div style={{fontSize:13}} className={`text-${this.props.color}`}>{this.props.valid_betting_amount}</div>
        </div>

        <div className="d-flex justify-content-between" style={{flexDirection:'row'}}>
          <div style={{fontSize:16}}>{this.props.bet_award_title}</div>
          <div style={{fontSize:13}} className={`text-${this.props.color}`}>{this.props.bet_award}</div>
        </div>

        <div className="d-flex justify-content-between" style={{flexDirection:'row'}}>
          <div style={{fontSize:16}}>{this.props.bet_result_title}</div>
          <div style={{fontSize:13}} className={`text-${this.props.color}`}>
          { this.props.bet_playid && this.props.bet_playid.substr(0,3) === 'YSC' ?
              this.props.showBetResult!==undefined && this.props.showBetResult ===1 && this.props.bet_result!==t('待开奖')?
              <>
                  <div className="lct-award-num">{this.props.bet_result}</div>
                  <img className="lct-award-num" src={yscImages('./'+ysccolor(this.props.bet_result)+'.png')} style={{marginBottom:5, marginLeft:3}} alt=""/>
              </>
                :this.props.bet_result
              :
              this.props.bet_playid && this.props.bet_playid.substr(0,3) === 'OSD' ?
                this.props.showBetResult!==undefined && this.props.showBetResult ===1 && this.props.bet_result!==t('待开奖')?
                  this.props.bet_result.split(",").map((number, childIndex) => {
                    return (
                      <>
                        {<img style={{ width: '25px', height: '25px', marginRight:'2px', marginTop: '5px', marginBottom: '5px' }} alt="" src={osdImages('./'+number+'.png')}/>}
                      </>                                  
                    )
                  }) 
                :
                this.props.bet_result
              :
              this.props.bet_playid && this.props.bet_playid.substr(0,3) === 'YXX' ?
                this.props.showBetResult!==undefined && this.props.showBetResult ===1 && this.props.bet_result!==t('待开奖')?
                  this.props.bet_result.split(",").map((number, childIndex) => {
                    return (
                      <>
                        {<img style={{ width: '35px', height: '35px', marginRight:'2px', marginTop: '5px', marginBottom: '5px' }} alt="" src={yxxImages('./'+yxxpic(number)+'.png')}/>}
                      </>                                  
                    )
                  }) 
                :
                this.props.bet_result
              :
              this.props.bet_result}</div>
        </div>
        <div className="d-flex justify-content-between" style={{flexDirection:'row'}}>
          <div style={{fontSize:16}}>{this.props.bet_period_title}</div>
          <div style={{fontSize:13}} className={`text-${this.props.color}`}>{this.props.bet_period}</div>
        </div>

        <div className="d-flex justify-content-between" style={{flexDirection:'row'}}>
          <div style={{fontSize:16}}>{this.props.game_name_title}</div>
          <div style={{fontSize:13}} className={`text-${this.props.color}`}>{this.props.game_name}</div>
        </div>
      </>
    )
  }
}

BettingRecordModalDetails.propTypes = {
  ref_num_title: PropTypes.string,
  ref_num_: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),

  betting_time_title: PropTypes.string,
  betting_time: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),

  game_mode_title: PropTypes.string,
  game_mode: PropTypes.string,

  betting_content_title: PropTypes.string,
  betting_content: PropTypes.string,

  valid_betting_amount_title: PropTypes.string,
  valid_betting_amount: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),

  bet_award_title: PropTypes.string,
  bet_award: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),

  bet_result_title: PropTypes.string,
  bet_result: PropTypes.string,

  bet_period_title: PropTypes.string,
  bet_period: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),

  game_name_title: PropTypes.string,
  game_name: PropTypes.string,

  color: PropTypes.oneOf([
    'dark','muted'
  ]),
};

export default withTranslation('common')(BettingRecordModalDetails);