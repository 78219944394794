import * as actionType from './ActionType';

const saveHomePageGameList = (obj) => {
    return {
        type: 'STORE_GAMELIST_OBJ',
        input: obj,
    }
}

const saveIndexData = (obj) => {
    return {
      type: 'STORE_INDEX_DATA',
        input: obj,
    }
}

const saveIndexInit = (obj) => {
    return {
        type: actionType.STORE_INDEX_INIT,
        input: obj,
    }
}

const saveGameListPage = (obj) => {
    return {
        type: actionType.STORE_GAME_LIST_PAGE,
        input: obj,
    }
}

const saveBannerList = (obj) => {
    return {
        type: actionType.STORE_BANNER_LIST,
        input: obj,
    }
}

const saveGaiaGameList = (obj) => {
    return {
        type: actionType.STORE_GAIA_GAME_LIST,
        input: obj,
    }
}

const SaveMemberInfo = (memberInfo) => {
    return {
        type: actionType.GET_BALANCE,
        input: memberInfo,
    }
}

const UpdateAlert = (alert) => {
    return {
        type: 'UPDATE_ALERT',
        input: alert,
    }
}

const ShowLoginModal = (modal) => {
    return {
        type: 'SHOW_LOGIN_MODAL',
        input: modal,
    }
}

const ShowForgotPasswordModal = (modal) => {
    return {
        type: 'SHOW_FORGOTPASSWORD_MODAL',
        input: modal,
    }
}

const ShowSafePasswordModal = (modal) => {
    return {
        type: 'SHOW_SAFEPASSWORD_MODAL',
        input: modal,
    }
}

const ShowRegisterModal = (modal) => {
    return {
        type: 'SHOW_REGISTER_MODAL',
        input: modal,
    }
}

const ShowSecurityResetModal = (modal) => {
    return {
        type: 'SHOW_SECURITY_RESET_MODAL',
        input: modal,
    }
}

const ShowSidebarModal = (modal) => {
    return {
        type: 'SHOW_SIDEBAR_MODAL',
        input: modal,
    }
}

const SetRememberPassword = (obj) => {
    return {
        type: actionType.SET_REMEMBER_PASSWORD,
        input: obj,
    }
}

const SetGesturePassword = (obj) => {
    return {
        type: actionType.SET_GESTURE_PASSWORD,
        input: obj,
    }
}

const SetGestureUserInfo = (obj) => {
    return {
        type: actionType.SET_GESTURE_USER_INFO,
        input: obj,
    }
}

const SetSecUserName = (obj) => {
    return {
        type: actionType.SET_SEC_USERNAME,
        input: obj,
    }
}

const SetUserInfo = (obj) => {
    return {
        type: actionType.SET_USER_INFO,
        input: obj,
    }
}

const SetAccountConfigs = (obj) => {
    return {
        type: actionType.SET_ACCOUNT_CONFIGS,
        input: obj,
    }
}

const SetAccountConfigsDefault = (obj) => {
    return {
        type: actionType.SET_ACCOUNT_CONFIGS_DEFAULT,
        input: obj,
    }
}

const SetDemoUser = (obj) => {
    return {
        type: actionType.SET_DEMO_USER,
        input: obj,
    }
}

const RefreshUser = (obj) => {
    return {
        type: actionType.REFRESH_USER,
        input: obj,
    }
}

const updateNotice = (obj) => {
    return {
        type: 'STORE_NOTICE_OBJ',
        input: obj,
    }
}

const saveFindGameJSON = (obj) => {
    return {
        type: 'SAVE_FIND_GAME_JSON',
        input: obj,
    }
}

const saveAwardResultListPage = (obj,time) => {
    return {
        type: actionType.STORE_AWARD_RESULT_LIST_PAGE,
        input: obj,
        time : time,
    }
}

const updatePromotion = (obj) => {
    return {
        type: 'UPDATE_PROMO',
        input: obj,
    }
}

const ShowIdentityConfirmation = (obj) => {
    console.log(JSON.stringify(obj));
    return {
        type: 'IDENTITY_CONFIRMATION',
        input: obj,
    }
}

const saveJumpObj = (obj) => {
    return {
        type: actionType.STORE_JUMP_OBJ,
        input: obj,
    }
}

export {saveHomePageGameList, saveIndexData, saveIndexInit, saveGameListPage, saveBannerList, saveGaiaGameList, SaveMemberInfo,
    UpdateAlert, ShowLoginModal, ShowRegisterModal, ShowForgotPasswordModal, ShowSafePasswordModal,
    SetRememberPassword, SetSecUserName, SetUserInfo, SetAccountConfigs, SetDemoUser,
    ShowSecurityResetModal, RefreshUser, SetAccountConfigsDefault, updateNotice, saveFindGameJSON, saveAwardResultListPage,SetGesturePassword,SetGestureUserInfo
    ,updatePromotion, ShowIdentityConfirmation, ShowSidebarModal, saveJumpObj};
