import { store } from '../index';
import { saveThirdGames } from '../actions/GameAction';
import Fetcher from '../components/Fetcher';

const getGameProviders = function() {
  const data = new URLSearchParams();
  data.append('mode', "wap");
  fetch(window.$wgtDomain + '/game/getProviders', {
    method: 'post',
    headers: new Headers({
      'Accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9',
    }),
    responseType: 'json',
    referrer: 'h5',
    body: data,
  }).then(res => res.json()).then((result) => {
    let thirdGames = store.getState().gameList.thirdGames;
    let providerSortList = [];
    let gameProviders = Object.keys(result.providers).sort(function(a,b){return result.providers[a]-result.providers[b]})
    gameProviders.forEach(function(key,value) {
      providerSortList.push({"id":result.providers[key],"name":key});
    });
    thirdGames.providers = providerSortList;
    store.dispatch(saveThirdGames(thirdGames));
  });
}

const getGameTypes = function() {
  const data = new URLSearchParams();
  data.append('mode', "wap");
  fetch(window.$wgtDomain + '/game/getGameTypes', {
    method: 'post',
    headers: new Headers({
      'Accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9',
    }),
    responseType: 'json',
    referrer: 'h5',
    body: data,
  }).then(res => res.json()).then((result) => {
    let thirdGames = store.getState().gameList.thirdGames;
    let gameTypeSortList = [];
    let gameTypes = Object.keys(result.gameTypes).sort(function(a,b){return result.gameTypes[a]-result.gameTypes[b]})
    gameTypes.forEach(function(key,value) {
      gameTypeSortList.push({"id":result.gameTypes[key],"name":key});
    });
    thirdGames.gameTypes = gameTypeSortList;
    store.dispatch(saveThirdGames(thirdGames));
  });
}

const getGameCategories = function() {
  const data = new URLSearchParams();
  data.append('mode', "wap");
  fetch(window.$wgtDomain + '/game/getGameCategories', {
    method: 'post',
    headers: new Headers({
      'Accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9',
    }),
    responseType: 'json',
    referrer: 'h5',
    body: data,
  }).then(res => res.json()).then((result) => {
    let thirdGames = store.getState().gameList.thirdGames;
    let categorySortList = [];
    let gameCategories = Object.keys(result.gameCategories).sort(function(a,b){return result.gameCategories[a]-result.gameCategories[b]});
    gameCategories.forEach(function(key,value) {
      categorySortList.push({"id":result.gameCategories[key],"name":key});
    });
    thirdGames.gameCategories = categorySortList;
    store.dispatch(saveThirdGames(thirdGames));
  });
}

const getAllGames = async function() {
  const data = new URLSearchParams();
  data.append('mode', "wap");
  data.append('isLobby', 1);
  await Fetcher(window.$wgtDomain + '/game/searchGames', {
    method: 'post',
    headers: new Headers({
      'Accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9',
    }),
    responseType: 'json',
    referrer: 'h5',
    body: data,
  }).then(res => res.json()).then((result) => {
    let thirdGames = store.getState().gameList.thirdGames;
    thirdGames.allGames = result;
    store.dispatch(saveThirdGames(thirdGames));
  });
}

const getNewAllGames = async function(result,favList,recentList) {
    let thirdGames = store.getState().gameList.thirdGames;
    thirdGames.allGames = result;
    let providerlist = store.getState().gameList.thirdGames?.providers;
    if(result?.result != undefined){
      let newTypeList = [];
      let gameTypeSortList = [];
      result.result.forEach(function(key,value) {
        gameTypeSortList.push({"id":Object.keys(key)[0],"name":Object.keys(key)[0]});
        newTypeList.push(Object.keys(key)[0]);
      });
      thirdGames.gameTypes = gameTypeSortList;
      thirdGames.allGames.typelist = newTypeList;
      if(favList){
        thirdGames.allGames.favlist = favList;
      }
      if(recentList){
        thirdGames.allGames.recentlist = recentList;
      }
    }
    if(store.getState()?.gameList?.thirdGames?.loadShowGames == undefined){
    let games = result?.result;
    let gameArr = [];
    if(result !== undefined){
      games.forEach(function(key,value) {
        if(Object.keys(key)[0]!=='Sports'){
          gameArr.push({ "gameId": value, "result": Object.entries(key) });
        }
      });
    }
    thirdGames.loadShowGames = gameArr;
   }
    store.dispatch(saveThirdGames(thirdGames));
}

export { getGameProviders, getGameTypes, getGameCategories, getAllGames ,getNewAllGames}