import React, { Component } from 'react'
import IdleTimer from 'react-idle-timer'
import {store} from '../index'
import { UpdateAlert } from '../actions/HomePageAction';
import { withTranslation } from 'react-i18next';
import GameFetcher from '../components/GameFetcher';

class IdleCheck extends Component {
  constructor(props) {
    super(props)
    this.idleTimer = null
    this.onIdle = this._onIdle.bind(this)
  }
  render() {
    return (
      <div className={'idleTimer'}>
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onIdle={this.onIdle}
          timeout={1000 * 60 * 60}
          events={
            [
              'mousemove',
              'keydown',
              'wheel',
              'DOMMouseScroll',
              'mouseWheel',
              'mousedown',
              'touchstart',
              'touchmove',
              'MSPointerDown',
              'MSPointerMove'
            ]
          }
        />
        {this.props.children}
      </div>
    )
  }

  _onIdle(e) {
    let indexInit = store.getState().indexInit.indexInit;
    if(indexInit && indexInit.loginUser !== null){
    const { t } = this.props;
    let alertObj = store.getState().alert;
    alertObj.alert.content = t('login_time_out');
    alertObj.alert.title = t('notice');
    alertObj.alert.showAlert = true;
    store.dispatch(UpdateAlert(alertObj));
    GameFetcher(window.$gameUrl + "/rest/user/login-out", {
      method: "GET",
      headers: new Headers({
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      // credentials: 'include',
    }).then(res => res.json())
      .then((res) => {
      })
      .catch((error) => {
        console.error(error);
      })
    // console.log('user is idle', e)
    // console.log('last active', this.idleTimer.getLastActiveTime())
    }
  }
}

export default withTranslation('common')(IdleCheck)