import React from 'react';
import { Row, Col, ListGroup, ListGroupItem, Button } from 'reactstrap';
import BettingRecord from '../components/BettingRecord';
import BettingRecordHeader from '../components/Layout/BettingRecordHeader';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import ReactLoading from 'react-loading';
import { withLastLocation } from 'react-components/react-router-last-location';
import { connect } from 'react-redux';
import LocalizeAwardResult from '../components/GameLayout/Popup/LocalizeAwardResult';
import { showAwardLocalizeResult } from '../services/AwardService';
import { store } from '../index';
import InfiniteScroll from "react-infinite-scroll-component";
import { intToString } from '../services/HomeService';
import {
  MdKeyboardArrowDown,
  MdPhotoFilter, MdAutorenew
} from 'react-icons/md';
import i18next from 'i18next';
import GameFetcher from '../components/GameFetcher';

const mapStateToProps = (state) => {
  return {
    indexInit: state.indexInit.indexInit,
    memberInfo: state.memberInfo,
    game: store.getState().game,
    home: state.home
  };
};

class BettingRecordPage extends React.Component {

  constructor(props) {
    super(props);
    this.onClickDateChange = this.onClickDateChange.bind(this);
    this.callApi = this.callApi.bind(this);
    this.onToggle = this.onToggle.bind(this);
    this.onClickAwardChange = this.onClickAwardChange.bind(this);
    this.selectGame = this.selectGame.bind(this);
    this.myRef = React.createRef();
    let result1 = {};
    if (!props.indexInit.apiList) {
      // props.history.goBack();
    } else {
      for (let i = 0; i < Object.keys(props.indexInit.apiList).length; i++) {
        if (Object.keys(props.indexInit.apiList)[i] === 'safelt') {
          result1[Object.keys(props.indexInit.apiList)[i]] = Object.values(props.indexInit.apiList)[i];
        }
      }
      for (let i = 0; i < Object.keys(props.indexInit.apiList).length; i++) {
        if (Object.keys(props.indexInit.apiList)[i] !== 'safelt') {
          result1[Object.keys(props.indexInit.apiList)[i]] = Object.values(props.indexInit.apiList)[i];
        }
      }
    }
    this.state = {
      result: true,
      dataSource: [],
      currPageNo: 1,
      hasMore: true,
      title: "today",
      tabLine: "left",
      loading: false,
      status: true,
      winStatus: "-1",
      dayType: "0",
      toggleEvent: Date.now(),
      toggleEvent2: Date.now(),
      condition: false,
      selected: "0",
      tdb: "#f85032",
      tdw: "#f85032",
      ydb: "#212121",
      ydw: "#e1e1e1",
      twb: "#212121",
      tww: "#e1e1e1",
      lwb: "#212121",
      lww: "#e1e1e1",
      tmb: "#212121",
      tmw: "#e1e1e1",
      lmb: "#212121",
      lmw: "#e1e1e1",

      selDayType: "",
      selApiPlatKey: Object.keys(result1)[0],
      selApiPlatName: Object.values(result1)[0],
      apiList: result1,
      activeIndex: 0,
      onToggle: false,
      value: -1,
      gameId: "",
      gameName: "",
      condition1: false,
    };
  }

  selectGame(event) {
    let selectedGameObj = {
      playId: event.currentTarget.getAttribute('playid'),
      splayId: event.currentTarget.getAttribute('splayid'),
      playType: event.currentTarget.getAttribute('playtype'),
      splayType: event.currentTarget.getAttribute('splaytype'),
      id: event.currentTarget.getAttribute('id'),
      isCatch: event.currentTarget.getAttribute('isCatch'),
      isZongHe: event.currentTarget.getAttribute('isZongHe'),
      zongHeType: event.currentTarget.getAttribute('zongHeType'),
      gameName: event.currentTarget.getAttribute('gameName'),
    };

    this.setState({
      gameId: selectedGameObj.id,
      id: selectedGameObj.id,
      currPageNo: 1,
      dataSource: [],
      gameName: event.currentTarget.getAttribute('gameName')
    }, this.callApi);
    this.onToggle();
  }


  onClickAwardChange(event) {
    let value;

    switch (event.target.value) {
      case "-1":
        value = 'allstatus';
        break;
      case "5":
        value = 'won';
        break;
      case "1":
        value = 'lost';
        break;
      case "0":
        value = 'pending';
        break;
      default:
        return;
    }
    if(event.target.value == 0){
      this.setState({
        winStatus: event.target.value,
        value: event.target.value,
        currPageNo: 1,
        dataSource: [],
        status : false
      }, this.callApi);
    }else{
      this.setState({
        winStatus: event.target.value,
        value: event.target.value,
        currPageNo: 1,
        dataSource: [],
        status : true
      }, this.callApi);
    }
  }

  updateGameAwardObj(gameAwardObj) {
    this.setState({
      gameAwardObj: gameAwardObj,
    });
  }

  goto(event) {
    this.props.initLoadingAction();
    let id = window.setTimeout(function () {
    }, 0);
    while (id--) {
      window.clearTimeout(id); // will do nothing if no timeout with id is present
    }
    clearInterval(this.state.gameSaveInterval);
    clearInterval(this.state.gameInterval);
    this.props.history.push(event.target.getAttribute('value'));
    this.props.closeLoadingAction();
  }

  onSelectApi(e) {
    let elementKeyValue = e.target.getAttribute('keyvalue');

    this.setState({
      selApiPlatKey: elementKeyValue,
      selApiPlatName: this.state.apiList[elementKeyValue],
      currPageNo: 1,
      dataSource: [],
    }, this.callApi);

    this.onToggle2();
  }

  onClickDateChange(e) {
    if (e.target.value === "0") {
      this.setState({
        dayType: e.target.value,
        title: "today",
        currPageNo: 1,
        dataSource: [],
        selected: '0',
        tdb: "#f85032",
        tdw: "#f85032",
        ydb: "#212121",
        ydw: "#e1e1e1",
        twb: "#212121",
        tww: "#e1e1e1",
        lwb: "#212121",
        lww: "#e1e1e1",
        tmb: "#212121",
        tmw: "#e1e1e1",
        lmb: "#212121",
        lmw: "#e1e1e1"
      }, this.callApi);

    } else if (e.target.value === "1") {
      this.setState({
        dayType: e.target.value,
        title: "yesterday",
        currPageNo: 1,
        dataSource: [],
        selected: '1',
        tdb: "#212121",
        tdw: "#e1e1e1",
        ydb: "#f85032",
        ydw: "#f85032",
        twb: "#212121",
        tww: "#e1e1e1",
        lwb: "#212121",
        lww: "#e1e1e1",
        tmb: "#212121",
        tmw: "#e1e1e1",
        lmb: "#212121",
        lmw: "#e1e1e1"
      }, this.callApi);

    } else if (e.target.value === "2") {
      this.setState({
        dayType: e.target.value,
        title: "thisweek",
        currPageNo: 1,
        dataSource: [],
        selected: '2',
        tdb: "#212121",
        tdw: "#e1e1e1",
        ydb: "#212121",
        ydw: "#e1e1e1",
        twb: "#f85032",
        tww: "#f85032",
        lwb: "#212121",
        lww: "#e1e1e1",
        tmb: "#212121",
        tmw: "#e1e1e1",
        lmb: "#212121",
        lmw: "#e1e1e1"
      }, this.callApi);

    } else if (e.target.value === "3") {
      this.setState({
        dayType: e.target.value,
        title: "lastweek",
        currPageNo: 1,
        dataSource: [],
        selected: '3',
        tdb: "#212121",
        tdw: "#e1e1e1",
        ydb: "#212121",
        ydw: "#e1e1e1",
        twb: "#212121",
        tww: "#e1e1e1",
        lwb: "#f85032",
        lww: "#f85032",
        tmb: "#212121",
        tmw: "#e1e1e1",
        lmb: "#212121",
        lmw: "#e1e1e1"
      }, this.callApi);

    } else if (e.target.value === "4") {
      this.setState({
        dayType: e.target.value,
        title: "thismonth",
        currPageNo: 1,
        dataSource: [],
        selected: '4',
        tdb: "#212121",
        tdw: "#e1e1e1",
        ydb: "#212121",
        ydw: "#e1e1e1",
        twb: "#212121",
        tww: "#e1e1e1",
        lwb: "#212121",
        lww: "#e1e1e1",
        tmb: "#f85032",
        tmw: "#f85032",
        lmb: "#212121",
        lmw: "#e1e1e1"
      }, this.callApi);

    } else if (e.target.value === "5") {
      this.setState({
        dayType: e.target.value,
        title: "lastmonth",
        currPageNo: 1,
        dataSource: [],
        selected: '5',
        tdb: "#212121",
        tdw: "#e1e1e1",
        ydb: "#212121",
        ydw: "#e1e1e1",
        twb: "#212121",
        tww: "#e1e1e1",
        lwb: "#212121",
        lww: "#e1e1e1",
        tmb: "#212121",
        tmw: "#e1e1e1",
        lmb: "#f85032",
        lmw: "#f85032"
      }, this.callApi);
    }
    this.onToggle2();
  }

  onPressSettlement(dateTypes) {
    this.setState({
      loading: true,
      currPageNo: 1,
      dataSource: [],
      status: true
    });
    GameFetcher(window.$gameUrl + "/rest/game/lottery/loadbetrecord?status=true&dayType=" + dateTypes, {
      method: "Post",
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      credentials: 'include'
    })
      .then((response) => response.json().then((data) => {
        if (data.status) {
          let dataSource = this.state.dataSource;
          if (data.result.page.page === this.state.currPageNo) {
            if (data.result.page.records) {
              dataSource = dataSource.concat(data.result.page.records);
              this.setState({
                currPageNo: this.state.currPageNo + 1,
                dataSource: dataSource,
                loading: false
              });
            }
          }

        }
      }))
      .catch((error) => {
        console.error("error: " + error);
      });

    this.setState({
      tabLine: "left",
      status: true,
      loading: false
    });
  }

  onPressUnSettlement(dateTypes) {
    this.setState({
      loading: true,
      currPageNo: 1,
      dataSource: [],
      status: false
    });
    GameFetcher(window.$gameUrl + "/rest/game/lottery/loadbetrecord?status=false&dayType=" + dateTypes, {
      method: "Post",
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      credentials: 'include'
    })
      .then((response) => response.json().then((data) => {
        if (data.status) {
          let dataSource = this.state.dataSource;
          if (data.result.page.page === this.state.currPageNo) {
            if (data.result.page.records) {
              dataSource = dataSource.concat(data.result.page.records);
              this.setState({
                currPageNo: this.state.currPageNo + 1,
                dataSource: dataSource,
                loading: false
              });
            }
          }

        }
      }))
      .catch((error) => {
        console.error(error);
      });

    this.setState({
      tabLine: "right",
      status: false,
      loading: false
    });
  }

  onClickRefresh() {

    this.setState({
      loading: true,
      currPageNo: 1,
      dataSource: [],
    }, this.callApi);

    setTimeout(() => {
      this.setState({ loading: false });
    }, 400)
    this.myRef.current.scrollTo(0, 0);
  }

  callApi() {
    this.setState({ loading: true });
    const param = new URLSearchParams();
    param.set('pageNo', this.state.currPageNo);
    param.set('dayType', this.state.dayType);
    param.set('status', this.state.status);
    param.set('winStatus', this.state.winStatus);
    param.set('valueType', this.state.valueType);
    if (this.state.gameId != "") {
      param.set('gameId', this.state.gameId);
    }
    GameFetcher(window.$gameUrl + "/rest/game/lottery/loadbetrecord", {
      method: "Post",
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      // credentials: 'include',
      body: param
    })
      .then((response) => response.json().then((data) => {
        if (data.status) {
          let dataSource = this.state.dataSource;
          if (data.result.page.page === this.state.currPageNo) {
            if (data.result.page.pages === this.state.currPageNo) {
              this.setState({ hasMore: false })
            }
            let resultList = data.result.page.records;
            if (resultList) {
              if (this.state.value != -1)
                resultList = resultList.filter(item => item.winStatus == this.state.value);
              dataSource = dataSource.concat(resultList);
              this.setState({
                currPageNo: this.state.currPageNo + 1,
                dataSource: dataSource,
                loading: false
              });
            }
          }

        }
      }))
      .catch((error) => {
        console.error(error);
      });
  }

  componentDidMount() {
    if (this.props.lastLocation == null) {
      this.props.history.push('/');
    }
    this.callApi()
  }

  onToggle = () => {
    this.setState({
      toggleEvent: Date.now(),
      condition1: !this.state.condition1
    });
  };

  onToggle2 = () => {
    this.setState({
      toggleEvent2: Date.now(),
      condition: !this.state.condition
    });
  };

  showAwardResult = (rc) => {
    showAwardLocalizeResult(rc);
  };

  updateIntervalId(gameInterval, gameSaveInterval) {
    this.setState({
      gameInterval: gameInterval,
      gameSaveInterval: gameSaveInterval,
    });
  }

  handleChangeIndex = input => {
    this.state.activeIndex = input;
    this.setState({ activeIndex: input }, this.changeTab());
  };


  handleActiveIndexUpdate = (input) => {
    this.state.activeIndex = input;
    this.setState({ activeIndex: input }, this.changeTab());
  };

  clickOutside() {
    if (this.state.condition) {
      this.onToggle2();
    }
  }

  formatNum = input => {
    if (input !== undefined) {
      return intToString(input, this.props.memberInfo?.MemberInfo?.currAbbr);
    }
  };

  close = async () =>{
    this.props.closeSubPage();
  }

  render() {
    const { t } = this.props;
    const { activeIndex } = this.state;
    const winHeight = window.innerHeight;
    const pageHeight = winHeight - 92;
    let divs = ['parentDiv1', 'parentDiv2'];

    let live = (window.location.href.includes("/live")?true:false);
    let liveHeight= (winHeight)-90;
    let changeGameHeight = live?liveHeight:window.innerHeight - 92;
    let dataAttribute = {
      splayid: this.props.splayId,
      playid: this.props.playId,
      id: this.props.id,
    };


    return (
      <div>
        {
          this.props.game.popupObj != null && this.props.game.popupObj.showAwardLocalizeResult ?
            <LocalizeAwardResult onModalClose={this.onModalClose} {...this.props} />
            :
            null
        }
        <BettingRecordHeader {...this.props} dataSource={this.state.dataSource} apiList={this.state.apiList}
          onClickDateChange={this.onClickDateChange}
          selected={this.state.selected} title={this.state.title}
          toggleEvent2={this.state.toggleEvent2} onToggle2={this.onToggle2} condition={this.state.condition}
          tdb={this.state.tdb} tdw={this.state.tdw} ydb={this.state.ydb} ydw={this.state.ydw}
          twb={this.state.twb} tww={this.state.tww} lwb={this.state.lwb} lww={this.state.lww}
          tmb={this.state.tmb} tmw={this.state.tmw} lmb={this.state.lmb} lmw={this.state.lmw}
          close={this.close}
          />
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>

            {<div style={{ backgroundColor: live?'transparent':'#fff', borderBottom: "2px solid rgb(212, 212, 212)"}}>
              <center>
                <th style={{ height: "36px", paddingTop: "10px" }}><tr>
                  <button style={{ display: 'inline-block', marginRight: 3, fontSize: '14px',backgroundColor:live?'white':'',color:live?'black':''}}
                    className="color-black btn btn-outline-primary btn-sm" type="button"
                    onClick={this.onToggle} condition={this.state.condition}>
                    {this.state.gameId ? t(this.state.gameName) : t('allgames')}<MdKeyboardArrowDown size={20} /></button>
                </tr></th>

                <th style={{ height: "36px", width: "80px", paddingTop: "14px" }}><tr>
                  <select className="basic-single-status select-box" classNamePrefix="select"
                    {...this.props} dataSource={this.state.dataSource}
                    value={this.state.value} onChange={this.onClickAwardChange} selectedValue={this.state.value}
                    condition={this.state.condition}>
                    <option value="-1">{t('allstatus')}</option>
                    <option value="5">{t('won')}</option>
                    <option value="1">{t('lost')}</option>
                    <option value="0">{t('pending')}</option>
                  </select>

                </tr></th>

                <div style={{display:this.state.condition1?'block':'none'}}>
                    <div className="my-collapsible" style={{
                      position: 'fixed',
                      top: live?'':'94px',
                      left: '0px',
                      backgroundColor: 'white',
                      width: '100%',
                      zIndex: 2,
                    }}>
                      <div className="my-collapsible__content">

                        <div className="my-collapsible__content-inner"
                          style={{ height: changeGameHeight, overflow: 'auto' }}>
                          <div>
                            <button style={{ width: "90%", display: 'inline-block', marginRight: 3, fontSize: '14px' }}
                              className="color-black btn btn-outline-primary btn-sm" type="button" value="result1"
                              onClick={this.selectGame}>{t('allgames')}</button>
                          </div>

                          {this.props.home.gameList.map((item, index) => {
                            if (index != 0) {
                              let imageUrl;
                              try {
                                if(this.props.memberInfo.MemberInfo.gamecategory === null || this.props.memberInfo.MemberInfo.gamecategory === 1){
                                  imageUrl = window.$uidomain+`/safe_${item.id}.png`;
                                }else{
                                  imageUrl = window.$uidomain+`/en_safe_${item.id}.png`;
                                }
                              } catch (ex) {
                                imageUrl = null;
                              }
                              return (
                                <div key={index}>
                                  <h3>
                                    <img src={imageUrl} /><label>{i18next.language != 'zh' ? t(item.id) : item.gameName}</label>
                                  </h3>
                                  <div className="change-game-list">
                                    {item.gameList.map((game, index) =>
                                      <a style={{ width: i18next.language != 'zh' ? 'calc(50% - 5px)' : '' }}
                                        key={index} {...dataAttribute}
                                        className={this.props.game.mainGameObj.gameName === game.gameName ? 'active' : ''} id={game.id}
                                        splayid={game.splayId} splaytype={game.splayType} playtype={game.playType}
                                        iszonghe={game.isZongHe ? 'true' : 'false'} zonghetype={game.zongHeType} iscatch={game.isCatch ? 'true' : 'false'}
                                        gamename={game.gameName} playid={game.playId} buttontype={'selectGame'}
                                        onClick={this.selectGame}
                                      ><span className="change-game-text"
                                        buttontype={'selectGame'}>{t(game.gameName)}</span></a>,
                                    )}
                                  </div>
                                </div>
                              );
                            } else {
                              return null;
                            }
                          },
                          )}

                        </div>
                      </div>
                    </div>
                </div>

              </center>
            </div>}


            {this.state.loading === true ?
              <ReactLoading type={"spin"} className={"loadingSpinner"} style={{ width: "49px", height: "49px" }} />
              : false}

            {this.state.dataSource.length > 0 ?
              <div id="parentDiv" ref={this.myRef} style={{ height: live?liveHeight:pageHeight, overflow: 'auto' }}>
                <InfiniteScroll
                  dataLength={this.state.dataSource.length}
                  next={this.callApi}
                  hasMore={this.state.hasMore}
                  scrollableTarget="parentDiv"
                >
                  {this.state.dataSource.map((item) => {
                    const getDate = () => {
                      let date = moment(item.createDate);
                      return date.format("YYYY/MM/DD");
                    };

                    return (
                      <ListGroup key={item.id}>
                        <React.Fragment >
                          <ListGroupItem style={{ border: 0, padding: 2, paddingLeft: 15, paddingTop: 14, paddingBottom: 0, content: ""
                                ,backgroundColor:live?'transparent':'',color:live?'white':'',borderBottom:live?'1px solid rgb(117, 117, 117)':''}}>
                            <div className="betTable">
                              <BettingRecord
                                title={t(item.gameName)}
                                subtitle={t(item.betContent)}
                                subtitle2={item.playname}
                                betamount={intToString(item.betAmount, this.props.memberInfo?.MemberInfo?.currAbbr)}
                                betaward={intToString(item.betAward, this.props.memberInfo?.MemberInfo?.currAbbr)}
                                date={getDate()}
                                color='dark'
                                item={item}
                                statusStr={item.statusStr}
                                showAwardResult={this.showAwardResult}
                                imageSrc={item.gamePictureMod}
                                gameid={item.gameId}
                                gamecategory ={this.props.memberInfo.MemberInfo.gamecategory}
                              />
                            </div>
                          </ListGroupItem>
                        </React.Fragment>
                        <Button className="betting-settlement" style={{
                          borderRadius: 100, width: "2.5rem", height: "2.5rem", borderStyle: 'none', position: 'fixed', bottom: '15px',
                          right: '15px', padding: '0'
                        }} onClick={() => { this.onClickRefresh() }}>
                          <MdAutorenew size={24} style={{ color: '#fff' }} />
                        </Button>
                      </ListGroup>
                    )
                  })}
                </InfiniteScroll>
              </div>

              : (
                <div style={{ textAlign: 'center', paddingTop: 50, paddingBottom: 50, lineHeight: 1 }}>
                  <MdPhotoFilter size={110} style={{ color: '#ccc' }} />
                  <div style={{ lineHeight: 1, color: '#ccc', fontSize: 20 }}>{t('no_record_found')}</div>
                </div>
              )}
          </Col>
        </Row>
      </div>
    );
  }
}

const ConnectedBettingRecordPage = connect(mapStateToProps)(BettingRecordPage);
export default withLastLocation(withTranslation('common')(ConnectedBettingRecordPage));