import React from 'react';
import { withTranslation } from 'react-i18next';
import { MdArrowBack, MdCollections, MdNearMe } from 'react-icons/md';
import { connect } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import { Button, Modal } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { SetDemoUser, SetRememberPassword, SetSecUserName, ShowLoginModal, SaveMemberInfo } from '../../actions/HomePageAction';
import { loginSendCodeSend, demoLoginService } from '../../services/AccountInfoService';
import { alert, doubleVerify } from '../../services/AlertService';
import { closeLoginModal, showForgotPasswordModal, showRegisterModal,
  showSecurityResetModal, saveNewAccConfigsService, checkThridLogin, getLang, fbAPI, telegramLoginWidget } from '../../services/HomeService';
import i18next from 'i18next';
import {store} from '../../index';
// import { FaFacebookF } from "react-icons/fa";
// import FacebookLogin from 'react-facebook';
import { FacebookProvider, LoginButton } from 'react-facebook';
import LoadingOverlay from "../Loading";
import styled, { css } from "styled-components";
import { loadGapiInsideDOM } from 'gapi-script';
import TelegramLoginButton from 'react-telegram-login';
import {trackEvent} from '@adjustcom/adjust-web-sdk';
import { Storage } from '@capacitor/storage';
import {AppsFlyer} from "appsflyer-capacitor-plugin";
import '../../styles/login.scss';

const images = require.context('../../assets/image/', true);
LoadingOverlay.propTypes = undefined;
const DarkBackground = styled.div`
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  ${props =>
    props.disappear &&
    css`
      display: block; /* show */
    `}
`;

const mapStateToProps = (state) => {
  return {
    modal: state.modal,
    indexData: state.indexData,
    indexInit: state.indexInit,
    memberInfo: state.memberInfo,
  };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    ShowLoginModal,
    SetRememberPassword,
    SetSecUserName,
    SetDemoUser,
    saveNewAccConfigsService,
  }, dispatch)
);

class NameLoginContent extends React.Component {

  constructor() {
    super();
    this.state = {
      isShowPassword: false
    };
  }

  componentDidMount() {
    if(!window.$isApp){
      this.props.insertGapiScript();
      // this.props.initFb_JS_Sdk();
    }
  }

  isShowPasswordSwitch = () => {
    this.setState({isShowPassword:!this.state.isShowPassword});
  }

  render(){
    const { t, indexData } = this.props;
    const loginModal = this.props.loginModal;
    const loginState = this.props.loginState;
    let isiOSApp = window.$isApp && !this.props.isAndroid;
    let isShowPassType = this.state.isShowPassword ? 'text' : 'password';
    let isShowPassImg = this.state.isShowPassword ? 'open' : 'close';
    let indexInit = store.getState().indexInit.indexInit;        
    return(
      <>
        <div className={indexInit.wapThemePath === 'royal'?'royal':'default'}>
              <div className="login-pic">
                  {this.props?.indexData?.indexData?.multiSitePic && <img src={window.$wgtDomain + this.props.indexData.indexData.multiSitePic[i18next.language]} style={{maxWidth:'100%'}} alt=""/>}
              </div>
              <div className="login-mainbox">
                <div className="login-line">
                  <div className="login-subline">
                    <div className='login-underline'>
                    <span className="icon-user login-icon"/>
                    {isiOSApp ?
                      //ONLY FOR IOS APP
                      <input type="text" inputMode="search" className="login-input inputPType2"
                        placeholder={t("loginpage.NameLoginContent.username")} id="username" name="username"
                        value={loginState.username}
                        onKeyUp={this.props.handleUsernameKeyUp}
                      />
                      :
                      //IF web H5  OR  ANDROID APP
                      <input type="text" inputMode="search"  className="login-input inputPType1"
                        placeholder={t("loginpage.NameLoginContent.username")} id="username" name="username"
                        value={loginState.username}
                        onChange={this.props.handleUsername}
                      />
                    }
                    </div>
                  </div>
                </div>

                <div className="login-line">
                  <div className="login-subline">
                    <div className="login-underline">
                    <span className="icon-password login-icon"/>
                    {isiOSApp ?
                      //ONLY FOR IOS APP
                      <input type={isShowPassType} className="login-input inputPType2"
                        placeholder={t("loginpage.NameLoginContent.password")} name="password"
                        onKeyUp={this.props.handlePasswordKeyUp}
                        value={loginState.password} />
                      :
                      //IF web H5  OR  ANDROID APP
                      <input type={isShowPassType}  className="login-input inputPType1"
                        placeholder={t("loginpage.NameLoginContent.password")} name="password"
                        onChange={this.props.handlePassword}
                        value={loginState.password} />
                    }
                    <img alt="" src={this.props.getImage(`eye-${isShowPassImg}.png`)}
                        className="icon-eye" onClick={this.isShowPasswordSwitch}/>
                    </div>
                  </div>
                </div>
                {(loginModal && loginModal.isNeedValidCode) || (loginState && loginState.isNeedValidCode)?
                <div className="login-line">
                  <div className="login-subline">
                    <div className="login-underline">
                      <span className="login-code">
                        <MdCollections size={24} style={{ display: 'inline-block', verticalAlign: 'middle', position: 'relative' }}/>
                      </span>
                      <input onClick={this.props.showValidCode} type="number" className="login-input" placeholder={t("loginpage.NameLoginContent.verifycode")} 
                      name="validCode" onFocus={this.props.inputOnFocus} onBlur={this.props.inputOnBlur} onChange={this.props.handleValidCode} 
                      value={loginState.validCode} />
                      <a className="phone-email-validcode-a emailSend" id="getValidCode" ></a>
                      <img src="" alt="" style={{display:'block',height: '36px',position: 'absolute',right: 0,bottom: 0}} id="loginValidCode" onClick={this.props.showValidCode}/>
                      <input type="hidden" id="isNeedValidCode" value={loginState.isNeedValidCode} />
                    </div>
                  </div>
                </div>
                :null
                }

                <div className="list-block" style={{margin:0,padding:'0 30px'}}>
                <ul>
                    <li>
                      <div style={{display:'inline-flex',width:'90%'}}>
                      <label className="label-checkbox item-content" style={{ marginLeft:'3px',paddingLeft:45,paddingRight:60,minHeight:'unset', width:'100%'}}>
                        <input type="checkbox" name="agree" value="Books"
                              style={{marginTop: '17px', height: 0, width: 0 }} id="rememberpassword"
                              defaultChecked={this.props.memberInfo.RememberPassword?this.props.memberInfo.RememberPassword.rememberlogin:false} />
                        <div className="item-media" style={{lineHeight:'18px'}}><i className="icon icon-form-checkbox" style={{float:'left',marginRight:5}}/><span style={{fontSize:12, color:'#999',width:'100%'}}>{t("loginpage.NameLoginContent.rememberpassword")}</span></div>
                      </label>
                      </div>
                    </li>
                </ul>
                </div>

                <div style={{ margin: '16px 0', padding: '0 30px'}} onClick={this.props.loginPost}>
                  <div className="login-button">
                  {t('loginpage.NameLoginContent.login')}
                  </div>
                </div>

                <div className="social-login-parent">
                  <div className="social-login-container">
                    {!window.$isApp && this.props.indexData.indexData.socialLoginOpen && indexData?.indexData?.socialLoginFacebookClientId ?
                      <FacebookProvider appId={indexData?.indexData?.socialLoginFacebookClientId}>
                        <LoginButton
                          scope="public_profile,email"
                          onError={fbAPI}
                          onSuccess={window.$wgtDomain} //{"https://localhost:3000"}
                          >
                          <div className="social-login-widget">
                            <button className="social-login-button">
                              <div className="social-login-content"></div>
                              <img alt="" src={this.props.getImage('sociallogin-facebook.png')} />
                            </button>
                            <div className="social-login-tooltip">{t('sociallogin.widget.facebook')}</div>
                          </div>
                        </LoginButton>
                      </FacebookProvider>
                    : null }
                    {!window.$isApp && this.props.indexData.indexData.socialLoginOpen && indexData?.indexData?.socialLoginGoogleClientId?
                      <div className="social-login-widget" onClick={this.props.googleClick}>
                        <button className="social-login-button">
                          <div className="social-login-content">
                            <div id="loginButton" className="google-button">Login with Google</div>
                          </div>
                          <img alt="" src={this.props.getImage('sociallogin-google.png')} />
                        </button>
                        <div className="social-login-tooltip">{t('sociallogin.widget.google')}</div>
                      </div>
                    : null}
                    {!window.$isApp && this.props.indexData.indexData.socialLoginOpen && indexData?.indexData?.socialLoginTelegramUsername ?
                      <div className="social-login-widget">
                        <button className="social-login-button">
                          <div className="social-login-content">
                            <TelegramLoginButton dataOnauth={telegramLoginWidget} botName={indexData?.indexData?.socialLoginTelegramUsername} />
                          </div>
                          <img alt="" src={this.props.getImage('sociallogin-telegram.png')} />
                        </button>
                        <div className="social-login-tooltip">{t('sociallogin.widget.telegram')}</div>
                      </div>
                    : null }
                  </div>
                </div>

                <div style={{ margin: '16px 0', padding: '0 30px' }}>
                  <div style={{ display: 'inherit' }}>
                    <div style={{ float: 'left', fontSize: '12px' }} onClick={this.props.openRegister}>{t("loginpage.NameLoginContent.register")}</div>
                    <div style={{ float: 'right', fontSize: '12px', textAlign:'right' }} onClick={this.props.openForgotPassword}>{t("loginpage.NameLoginContent.forgetpassword")}</div>
                    </div>
                </div>

                {this.props.indexInit.indexInit && this.props.indexInit.indexInit.freePlay==='1'?
                <div style={{ margin: '16px 0', padding: '0 16px' }}>
                  <div style={{ textAlign: 'center', marginTop: '80px' }}>
                    <div style={{ margin: '16px 0', padding: '0 16px' }} >
                      <span style={{color: '#bbb',padding: '1px 50px',overflow: 'hidden',background: '#fff',fontSize: '14px',}}/>
                      <span onClick={this.props.demoLogin} className="login-trial">
                          <MdNearMe/>
                      </span>
                      <div style={{ margin: '30px 0 0', padding: '0', color: '#aaa' }} onClick={this.props.demoLogin}>{t("loginpage.NameLoginContent.testplaylogin")}<br></br>
                      <span style={{fontSize:'14px'}}>{t('试玩金额仅供体验，金额是不会带入正式账号。')}</span>
                      </div>
                    </div>
                  </div>
                </div>
                :null
                }
              </div>
            </div>
      </>
    )
  }
}

class PhoneLoginContent extends React.Component {
  render(){
    const { t } = this.props;
    const loginModal = this.props.loginModal;
    const loginState = this.props.loginState;
    return(
      <>
        <div style={{ margin: '16px 0' }}>
              <div style={{ padding: '0', margin: '0', position: 'relative' }}>
                <div style={{marginTop:'10px',padding: '0 30px',minHeight: '48px',display: 'flex',justifyContent: 'space-between',position: 'relative',}}>
                  <div style={{alignSelf: 'flex-end',minHeight: '36px',marginBottom: '6px',padding: '0',}}>
                    <div className="icon-user" style={{display: 'inline-block',verticalAlign: 'middle',position: 'relative', fontSize:'27px'}}/>
                  </div>
                  <div style={{display: 'block',marginBottom: '4px',paddingBottom: '0',marginLeft: '20px',paddingRight: '16px',position: 'relative',width: '100%' }}>
                    <div style={{width: '100%',position: 'relative',marginBottom: '4px',minHeight: '36px',borderBottom: '1px solid rgba(0,0,0,.12)', }}>
                      <input type="text" style={{border: 'none',display: 'block',padding: '0',margin: '0',height: '36px',fontSize: '14px',width:'100%'}} placeholder={t("loginpage.PhoneLoginContent.username")} name="username" onChange={this.props.handleUsername} value={loginState.username}/>
                    </div>
                  </div>
                </div>

                <div style={{padding: '0 30px',minHeight: '48px',display: 'flex',justifyContent: 'space-between',position: 'relative',}}>
                  <div style={{alignSelf: 'flex-end',minHeight: '36px',marginBottom: '6px',padding: '0',}}>
                    <div className="icon-password"  style={{ display: 'inline-block', verticalAlign: 'middle', position: 'relative', fontSize:'27px' }}/>
                  </div>
                  <div style={{display: 'block',marginBottom: '4px',paddingBottom: '0',marginLeft: '20px',paddingRight: '16px',position: 'relative',width: '100%',}}>
                    <div style={{width: '100%',position: 'relative',marginBottom: '4px',minHeight: '36px',borderBottom: '1px solid rgba(0,0,0,.12)',}}>
                    <input type="number" style={{border: 'none',display: 'block',padding: '0',margin: '0',height: '36px',fontSize: '14px',width:'100%'}} id="sendCode" name="sendCode" placeholder={t("loginpage.PhoneLoginContent.smsverificationcode")} onFocus={this.props.inputOnFocus} onBlur={this.props.inputOnBlur} onChange={this.props.handleSendCode} value={loginState.sendCode}/>
                    <span className="phone-email-validcode-span">|</span>
                    <a id="sendCodeBtn" className="phone-email-validcode-a phoneSend" onClick={this.props.showSendCode}>{t("loginpage.PhoneLoginContent.smssend")}</a>
                    </div>
                  </div>
                </div>
                {(loginModal && loginModal.isNeedValidCode) || (loginState && loginState.isNeedValidCode)?
                <div style={{padding: '0 30px',minHeight: '48px',display: 'flex',justifyContent: 'space-between',position: 'relative',}}>
                  <div style={{alignSelf: 'flex-end',minHeight: '36px',marginBottom: '6px',padding: '0',}}>
                    <MdCollections size={24} style={{ display: 'inline-block', verticalAlign: 'middle', position: 'relative' }}/>
                  </div>
                  <div style={{display: 'block',marginBottom: '4px',paddingBottom: '0',marginLeft: '20px',paddingRight: '16px',position: 'relative',width: '100%',}}>
                    <div style={{width: '100%',position: 'relative',marginBottom: '4px',minHeight: '36px',borderBottom: '1px solid rgba(0,0,0,.12)',}}>
                      <input type="number" style={{border: 'none',display: 'block',padding: '0',margin: '0',height: '36px',fontSize: '14px',width: '100%'}} placeholder="loginpage.NameLoginContent.verifycode" name="validCode" onFocus={this.props.inputOnFocus} onBlur={this.props.inputOnBlur} onChange={this.props.handleValidCode} value={loginState.validCode}/>
                      <span className="phone-email-validcode-span">|</span>
                      <a className="phone-email-validcode-a emailSend" id="getValidCode2" onClick={this.props.showValidCode2}>{t("loginpage.NameLoginContent.getverifycode")}</a>
                      <img src="" alt="" style={{display:'block',height: '36px',position: 'absolute',right: 0,bottom: 0}} id="loginValidCode2" onClick={this.props.showValidCode2}/>
                    </div>
                  </div>
                </div>
                :null
                }

                <div className="store-data list-block" style={{margin:0,padding:'0 30px'}}>
                <ul>
                    <li>
                      <label className="label-checkbox item-content" style={{ marginLeft:'3px',paddingLeft:45,paddingRight:60,minHeight:'unset',width:'100%'}}>
                        <input type="checkbox" name="agree" value="Books"
                              style={{marginTop: '17px', height: 0, width: 0 }} id="rememberpassword"
                              defaultChecked={this.props.memberInfo.RememberPassword?this.props.memberInfo.RememberPassword.rememberlogin:false}/>
                        <div className="item-media" style={{lineHeight:'18px'}}><i className="icon icon-form-checkbox" style={{float:'left',marginRight:5}}/><span style={{fontSize:12, color:'#999',width:'100%'}}>{t("loginpage.NameLoginContent.rememberpassword")}</span></div>
                      </label>
                    </li>
                </ul>
                </div>

                <div style={{ margin: '16px 0', padding: '0 30px' }} onClick={this.props.loginPost}>
                  <div className="theme-background-color" style={{marginTop: '45px',height: '40px',borderRadius:'8px',lineHeight: '40px',color: '#fff',textAlign: 'center',}}>
                    {t("loginpage.NameLoginContent.login")}
                  </div>
                </div>

                <div style={{ margin: '16px 0', padding: '0 30px' }}>
                  <div style={{ display: 'inherit' }}>
                    {loginModal && !loginModal.registerStatus?
                    <div style={{ float: 'left',fontSize: '12px' }} onClick={this.props.openRegister}>{t("loginpage.NameLoginContent.register")}</div>
                    :null
                    }
                    <div style={{ float: 'right', fontSize: '12px',}} onClick={this.props.setActiveIndex}>{t("loginpage.PhoneLoginContent.accountlogin")}</div>
                  </div>
                </div>
                {this.props.indexInit.indexInit && parseInt(this.props.indexInit.indexInit.freePlay)===1?
                <div style={{ margin: '16px 0', padding: '0 16px' }}>
                <div style={{ textAlign: 'center', marginTop: '80px' }}>
                  <div style={{ margin: '16px 0', padding: '0 16px' }} >
                    <span style={{color: '#bbb',padding: '1px 50px',overflow: 'hidden',background: '#fff',fontSize: '14px',}}/>
                    <span onClick={this.props.demoLogin}
                      style={{position: 'absolute', marginLeft: '-74px',marginTop: '-20px',color: '#fff', fontSize:'30px', zIndex:'1',
                      width: '48px', height: '48px',borderRadius: '24px',lineHeight: '45px',backgroundColor: 'var(--theme-color)'}}>
                      <MdNearMe/>
                    </span>
                    <hr style={{height: '2px',lineHeight: '0',overflow: 'hidden',background: '#f0f0f0',border: 'none',marginTop: '-20px',}}/>
                    <div style={{ margin: '30px 0 0', padding: '0', color: '#aaa' }} onClick={this.props.demoLogin}>{t("loginpage.NameLoginContent.testplaylogin")}
                    <br></br>
                    <span style={{fontSize:'14px'}}>{t('试玩金额仅供体验，金额是不会带入正式账号。')}</span>
                    </div>
                  </div>
                </div>
                </div>
                :null
                }
              </div>
            </div>
      </>
    )
  }
}

class LoginModal extends React.Component {

  state = {
    username: '',
    password: '',
    validCode: '',
    sendCode: '',
    isNeedValidCode: false,
    registerStatus: false,
    activeIndex: 0,
    loanding: false,
    bool: false,
    generateValidCode:'',
    count: 0
  };

  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    if(this.props.memberInfo.RememberPassword && this.props.memberInfo.RememberPassword.rememberlogin){
      this.setState({username:this.props.memberInfo.RememberPassword.rememberusername,
                    password:this.props.memberInfo.RememberPassword.rememberpswd,
        })
    }
  }
  /*
  initFb_JS_Sdk(){
    const socialLoginFacebookClientId = this.props.indexData?.indexData?.socialLoginFacebookClientId;
      window.fbAsyncInit = function() {
        let FB;
        if (window.FB) {
          FB = window.FB;
          FB.init({
            appId: socialLoginFacebookClientId,
            cookie: true,
            xfbml: true,
            version: 'v13.0'
          });
        }
      }.bind(this);

      // Load the SDK asynchronously
      (function(d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk.js';
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
  }

  loginWithFacebook() {
    let FB;
    if (window.FB) FB = window.FB;
    if (window.$isApp){
      console.log("isapp", window.location.href);
      //failed to integrate in native app.
      let url="https://www.facebook.com/dialog/oauth?response_type=token&redirect_uri=https://www.ybapi8888.com&scope=email%2Cpublic_profile&state=cj6x9cin5000204xbape1y13l&client_id=330258813983518";
      // let url="https://www.facebook.com/dialog/oauth/?client_id=330258813983518&redirect_uri=https://www.ybapi8888.com?isFb=1&state=123&scope=email&response_type=token";
      window.open(url, '_blank');
      // window.location.assign(url);
    } else {
      FB.login(this.checkLoginState());
    }
  }

  checkLoginState = function() {
    let FB;
    if (window.FB) FB = window.FB;
    FB.getLoginStatus(function(response) {
      this.statusChangeCallback(response);
    }.bind(this));
  }

  // This is called with the results from from FB.getLoginStatus().
  statusChangeCallback(response) {
    if (response.status === 'connected') {
      // Logged into your app and Facebook.
      console.log("Logged into your app and Facebook.", response);
      if (response.authResponse) {
        this.fbAPI();
      }
    } else if (response.status === 'not_authorized') {
      // The person is logged into Facebook, but not your app.
      console.log("The person is logged into Facebook, but not your app.")
    } else {
      // The person is not logged into Facebook, so we're not sure if
      // they are logged into this app or not.
      console.log("The person is not logged into Facebook, so we're not sure if they are logged into this app or not.")
    }
  }

   // Here we run a very simple test of the Graph API after login is
  // successful.  See statusChangeCallback() for when this call is made.
  fbAPI() {
    console.log('fbAPI, Welcome! Fetching your information.... ');
    let FB;
    if (window.FB) FB = window.FB;
    FB.api('/me?fields=email,name,first_name,last_name,name_format,short_name,birthday,gender', function(response) {
      console.log('Successful login for: ', response);

      const data = new URLSearchParams();
      data.set('email', response.email);
      data.set('h5', "h5");
      data.set('isSocialLogin', true);

      //===Validate Login API===
      fetch(window.$wgtDomain + '/auth-social/login-valid', {
        method: 'POST',
        headers: new Headers({
          // 'Accept': 'application/json, text/plain, *\/*',
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
        credentials: 'include',
        body: data,
        referrer: 'h5'
      })
      .then((res) => res.json())
      .then(async (res) => {
        console.log("response from /auth-social/login-valid", res);
        if(res.status!=null){
          if(res.status==0){//got validation error
            console.log("got validation error");

          }else if(res.status==1){//acc found, proceed to login
            console.log("acc found, proceed to login");
            // location.href = "${ctx}/?gaiatoken="+res.gaiatoken;
            if (res.gaiatoken !== null) {
              let memberInfo = store.getState().memberInfo;
              memberInfo.token = response.gaiatoken;
              store.dispatch(SaveMemberInfo(memberInfo));
            }
            // try {
            //   await checkThridLogin(true);
            // } catch (e) {}
            window.location.href = window.location.href.split('#')[0]+"?gaiatoken="+res.gaiatoken;

          }else if(res.status==2){//acc not found, need proceed register
            console.log("acc not found, need proceed register");

            //===Register API===
            fetch(window.$wgtDomain + '/memreg/save', {
              method: 'POST',
              headers: new Headers({
                'Accept': 'application/json, text/plain, *\/*',
                'Content-Type': 'application/x-www-form-urlencoded',
              }),
              credentials: 'include',
              body: data,
              referrer: 'h5'
            })
            .then((resReg) => resReg.json())
            .then(async (resReg) => {
              console.log("response from /memreg/save", resReg);
              if(resReg.status!=null && resReg.status==1){//success
                if (resReg.gaiatoken !== null) {
                  let memberInfo = store.getState().memberInfo;
                  memberInfo.token = response.gaiatoken;
                  store.dispatch(SaveMemberInfo(memberInfo));
                }
                window.location.href = window.location.href.split('#')[0]+"?gaiatoken="+resReg.gaiatoken;
                //here need to pop up the 完善资料?
              }
            })
            .catch((error) => {
              console.error(error);
            });
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
    });
  }
  */

  async insertGapiScript(){
    const gapi = await loadGapiInsideDOM();

    const script = document.createElement('script')
    // script.src = 'https://apis.google.com/js/api.js'
    script.src='https://accounts.google.com/gsi/client';
    script.onload = () => {
      this.initializeGoogleSignIn();
    }
    document.body.appendChild(script)
  }

  initializeGoogleSignIn(){
    try {
    console.log('google loading');
    window.gapi.load('client:auth2', () => {
      // window.gapi.auth2.init({
        window.gapi.client.init({
        client_id:this.props.indexData.indexData.socialLoginGoogleClientId,
        scope:'email',
      })
      console.log('google api inited');
    })

    window.gapi.load('signin2', () => {

      window.gapi.signin2.render('loginButton', {
        onsuccess: (googleData) =>{
          console.log('user login finished'+googleData.getBasicProfile().getEmail())

          this.handleLogin(googleData);
        },
        onfailure: () =>{
          console.log('user login failed')
        },
      })

      var div = document.querySelector(".abcRioButtonContents");
      var span1 = div.childNodes[0];
      var span2 = div.childNodes[1];
      span1.innerHTML = "Google";
      span2.innerHTML = "Google";
    });
    }catch (error) {
    }
  }

  handleLogin = (googleData) => {
    console.log('googleData: '+JSON.stringify(googleData.getBasicProfile()));

    if(googleData.getBasicProfile().getEmail() && this.state.bool) {
      const data = new URLSearchParams();
      data.set('email', googleData.getBasicProfile().getEmail());
      data.set('h5', "h5");
      data.set('isSocialLogin', true);

      /*
      ===Validate Login API===
      */
      fetch(window.$wgtDomain + '/auth-social/login-valid', {
        method: 'POST',
        headers: new Headers({
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
        // credentials: 'include',
        body: data,
        referrer: 'h5'
      })
        .then((res) => res.json())
        .then(async (res) => {
          console.log("response from /auth-social/login-valid", res);
          if(res.status!=null){
            if(res.status==0){
              console.log("got validation error");

            }else if(res.status==1){
              try{
                if (window.$isApp && window.$isIonic) {
                  const isAdjust = await Storage.get({ key: 'adjustIsAdjust' });
                  if(isAdjust.value=="1"){
                    const adjustEvent = await Storage.get({ key: 'adjustEventToken' });
                    const adjustEventToken = JSON.parse(adjustEvent.value);
                    if(adjustEventToken.a_login){
                      trackEvent({
                        eventToken: adjustEventToken.a_login
                      })
                    }
                  }
                }
              }catch(e){
                console.log(e);
              }
              try{
                if (window.$isApp && window.$isIonic) {
                  if(window.$appsFlyerAppId!=""){
                    AppsFlyer.logEvent({
                      eventName: 'af_login',
                      eventValue: {
                        uid: googleData.getBasicProfile().getEmail()
                     }
                    })
                    .then(r => console.log('AppsFlyer Login Event ~~>' + r.res))
                    .catch(err => console.log('AppsFlyer Login Event err ~~>' + err));
                  }
                }
              }catch(e){
                console.log(e);
              }
              console.log("acc found, proceed to login");
              if (res.gaiatoken !== null) {
                let memberInfo = store.getState().memberInfo;
                memberInfo.token = res.gaiatoken;
                store.dispatch(SaveMemberInfo(memberInfo));
              }

              window.location.href = window.location.href.split('#')[0]+"?gaiatoken="+res.gaiatoken;

            }else if(res.status==2){
              console.log("acc not found, need proceed register");

              /*
              ===Register API===
              */
              let memberInfo = store.getState().memberInfo;
              let langid = memberInfo.MemberInfo.langid?memberInfo.MemberInfo.langid:'';
              try{
                if (window.$isApp && window.$isIonic) {
                  const isAdjust = await Storage.get({ key: 'adjustIsAdjust' });
                  if(isAdjust.value=="1"){
                    const adjustEvent = await Storage.get({ key: 'adjustEventToken' });
                    const adjustEventToken = JSON.parse(adjustEvent.value);
                    if(adjustEventToken.a_register){
                      trackEvent({
                        eventToken: adjustEventToken.a_register
                      })
                    }
                  }
                }
              }catch(e){
                console.log(e);
              }

              fetch(window.$wgtDomain + '/memreg/save?langid='+langid, {
                method: 'POST',
                headers: new Headers({
                  'Accept': 'application/json, text/plain, */*',
                  'Content-Type': 'application/x-www-form-urlencoded',
                }),
                // credentials: 'include',
                body: data,
                referrer: 'h5'
              })
                .then((resReg) => resReg.json())
                .then(async (resReg) => {
                  console.log("response from /memreg/save", resReg);
                  if(resReg.status!=null && resReg.status==1){//success
                    if (resReg.gaiatoken !== null) {
                      let memberInfo = store.getState().memberInfo;
                      memberInfo.token = res.gaiatoken;
                      store.dispatch(SaveMemberInfo(memberInfo));
                    }

                    try{
                      if (window.$isApp && window.$isIonic) {
                        if(window.$appsFlyerAppId!=""){
                          AppsFlyer.logEvent({
                            eventName: 'af_complete_registration',
                            eventValue: {
                              uid: googleData.getBasicProfile().getEmail()
                           }
                          })
                          .then(r => console.log('AppsFlyer Reg Event ~~>' + r.res))
                          .catch(err => console.log('AppsFlyer Reg Event err ~~>' + err));
                        }
                      }
                    }catch(e){
                      console.log(e);
                    }

                    window.location.href = window.location.href.split('#')[0]+"?gaiatoken="+resReg.gaiatoken;
                    //here need to pop up the 完善资料?
                  }
                })
                .catch((error) => {
                  console.error(error);
                });
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  googleClick = () => {
    this.setState({
      bool: true
    })
  }

  loginPost = function() {
    this.setState({
      loading:true
    })
    let loginName = this.state.username.trim();
    let password = this.state.password.trim();
    let sendCode = this.state.sendCode;
    let loginType = this.state.activeIndex;
    let validCode = this.state.validCode;
    let isNeedValidCode = this.state.isNeedValidCode;

    const isEmpty = function(value){
      return !(value && true&&value.trim()!=="");
    }

    //邮箱
    if(loginType != 0){
      if (/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(loginName)) {
        loginType = 2;
      }
    }
    if ((isEmpty(loginName) || loginName.length < 6 || loginName.length > 14 || /[^a-z0-9]/.test(loginName)) && loginType==0) {
      this.setState({ loading:false });
      alert(this.props.t('loginpage.msg.validate1'), this.props.t('loginpage.loginaccount'));
      return;
    }
    if ((isEmpty(password)  || password.length < 6 || password.length > 16 || !/^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)[0-9A-Za-z]{6,16}$/.test(password)) && loginType==0) {
      this.setState({ loading:false });
      alert(this.props.t('loginpage.msg.validate2'), this.props.t('loginpage.loginaccount'));
      return;
    }

    const data = new URLSearchParams();
    data.set('username', loginName);
    data.set('password', password);
    data.set('loginType', loginType);
    data.set('validCode', validCode);
    data.set('sendCode', sendCode)
    data.set('loginPlatform', 'H5');
    data.set('isNeedValidCode', isNeedValidCode);
    data.set('generateValidCode',this.state.generateValidCode);
    let memberInfo = store.getState().memberInfo;
    let langid = memberInfo.MemberInfo.langid?memberInfo.MemberInfo.langid:'';
    fetch(window.$wgtDomain + '/wap/login-valid?langid='+langid, {
      method: 'POST',
      headers: new Headers({
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      // credentials: 'include',
      body: data,
      referrer: 'h5'
    })
      .then((response) => response.json())
      .then(async (response) => {
        if(response.isNeedDmic){
          const { t } = this.props;
          doubleVerify(t('psdmanagepage.msg.plseentersavepwd'), this.postAjax.bind(this));


        }else{
          this.setState({
            loading:true
          })
        if (response.status) {
          try{
            if (window.$isApp && window.$isIonic) {
              const isAdjust = await Storage.get({ key: 'adjustIsAdjust' });
              if(isAdjust.value=="1"){
                const adjustEvent = await Storage.get({ key: 'adjustEventToken' });
                const adjustEventToken = JSON.parse(adjustEvent.value);
                if(adjustEventToken.a_login){
                  trackEvent({
                    eventToken: adjustEventToken.a_login
                  })
                }
              }
            }
          }catch(e){
            console.log(e);
          }
        
          try{
            if (window.$isApp && window.$isIonic) {
              if(window.$appsFlyerAppId!=""){
                AppsFlyer.logEvent({
                  eventName: 'af_login',
                  eventValue: {
                    uid: loginName
                 }
                })
                .then(r => console.log('AppsFlyer Login Event ~~>' + r.res))
                .catch(err => console.log('AppsFlyer Login Event err ~~>' + err));
              }
            }
          }catch(e){
            console.log(e);
          }
          
          getLang();
          if(document.getElementById("rememberpassword").checked){
            let param = {rememberusername:loginName,rememberpswd:password,rememberlogin:true};
            this.props.SetRememberPassword(param);
          } else {
            let param = {rememberusername:"",rememberpswd:"",rememberlogin:false};
            this.props.SetRememberPassword(param);
          }
          if (response.gaiatoken !== null) {
            let memberInfo = store.getState().memberInfo;
            memberInfo.token = response.gaiatoken;
            store.dispatch(SaveMemberInfo(memberInfo));
          }
          saveNewAccConfigsService(loginName, this.props.modal.loginModal.result?.authRegisterMember?.siteId);

          if(response.isMigrateReset){
            let param = {secUsername:loginName};
            this.props.SetSecUserName(param);
            this.openSecurityReset();
          } else {
            try {
              await checkThridLogin(true);
            } catch (e) {}
            window.location.href = window.location.href.split('#')[0];
          }
          this.setState({
            loading:false
          })
        } else {
          this.setState({validCode:''});
          this.setState({isNeedValidCode : response.isNeedValidCode == null ? true : response.isNeedValidCode});
          this.setState({
            loading:false
          })
          alert(response.msg == this.props.t("common.msg.valuecannotempty") ? this.props.t("loginpage.pleaseenterpassword") : response.msg, this.props.t("loginpage.loginfailed"))
          return;
        }
      }
        this.setState({
          loading:false
        })
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          loading:false
        })
      });
  };

  postAjax(dmic){
    let loginName = this.state.username.trim();
    let password = this.state.password.trim();
    let sendCode = this.state.sendCode;
    let loginType = this.state.activeIndex;
    let validCode = this.state.validCode;

    this.setState({
      loading:true
    })

    const isEmpty = function(value){
      return !(value && true&&value.trim()!=="");
    }

    //邮箱
    if(loginType != 0){
      if (/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(loginName)) {
        loginType = 2;
      }
    }
    if ((isEmpty(loginName) || loginName.length < 6 || loginName.length > 14 || /[^a-z0-9]/.test(loginName)) && loginType==0) {
      this.setState({ loading:false });
      alert(this.props.t('loginpage.msg.validate1'), this.props.t('loginpage.loginaccount'));
      return;
    }
    if ((isEmpty(password)  || password.length < 6 || password.length > 16 || !/^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)[0-9A-Za-z]{6,16}$/.test(password)) && loginType==0) {
      this.setState({ loading:false });
      alert(this.props.t('loginpage.msg.validate2'), this.props.t('loginpage.loginaccount'));
      return;
    }

    const data = new URLSearchParams();
    data.set('username', loginName);
    data.set('password', password);
    data.set('loginType', loginType);
    data.set('validCode', validCode);
    data.set('sendCode', sendCode)
    data.set('loginPlatform', 'H5');
    data.set('dmicTokens', dmic);
    data.set('generateValidCode',this.state.generateValidCode);
    let memberInfo = store.getState().memberInfo;
    let langid = memberInfo.MemberInfo.langid?memberInfo.MemberInfo.langid:'';
    fetch(window.$wgtDomain + '/wap/login-valid?langid='+langid, {
      method: 'POST',
      headers: new Headers({
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      // credentials: 'include',
      body: data,
      referrer: 'h5'
    })
      .then((response) => response.json())
      .then(async (response) => {
        if (response.status) {
          try{
            if (window.$isApp && window.$isIonic) {
              const isAdjust = await Storage.get({ key: 'adjustIsAdjust' });
              if(isAdjust.value=="1"){
                const adjustEvent = await Storage.get({ key: 'adjustEventToken' });
                const adjustEventToken = JSON.parse(adjustEvent.value);
                if(adjustEventToken.a_login){
                  trackEvent({
                    eventToken: adjustEventToken.a_login
                  })
                }
              }
            }
          }catch(e){
            console.log(e);
          }

          try{
            if (window.$isApp && window.$isIonic) {
              if(window.$appsFlyerAppId!=""){
                AppsFlyer.logEvent({
                  eventName: 'af_login',
                  eventValue: {
                    uid: loginName
                 }
                })
                .then(r => console.log('AppsFlyer Login Event ~~>' + r.res))
                .catch(err => console.log('AppsFlyer Login Event err ~~>' + err));
              }
            }
          }catch(e){
            console.log(e);
          }

          getLang();
          if(document.getElementById("rememberpassword").checked){
            let param = {rememberusername:loginName,rememberpswd:password,rememberlogin:true};
            this.props.SetRememberPassword(param);
          } else {
            let param = {rememberusername:"",rememberpswd:"",rememberlogin:false};
            this.props.SetRememberPassword(param);
          }
          if (response.gaiatoken !== null) {
            let memberInfo = store.getState().memberInfo;
            memberInfo.token = response.gaiatoken;
            store.dispatch(SaveMemberInfo(memberInfo));
          }
          saveNewAccConfigsService(loginName, this.props.modal.loginModal.result?.authRegisterMember?.siteId);

          if(response.isMigrateReset){
            let param = {secUsername:loginName};
            this.props.SetSecUserName(param);
            this.openSecurityReset();
          } else {
            try {
              await checkThridLogin(true);
            } catch (e) {}
            window.location.href = window.location.href.split('#')[0];
          }
          this.setState({
            loading:false
          })
        } else {
          this.setState({validCode:''});
          this.setState({isNeedValidCode : response.isNeedValidCode == null ? true : response.isNeedValidCode});
          this.setState({
            loading:false
          })
          alert(response.msg == this.props.t("common.msg.valuecannotempty") ? this.props.t("loginpage.pleaseenterpassword") : response.msg, this.props.t("loginpage.loginfailed"))
          return;
        }


      })
      .catch((error) => {
        console.error(error);
        this.setState({
          loading:false
        })
      });
  }

  demoLogin =function(){
    demoLoginService();
  };

  closeModal(){
    closeLoginModal();
    this.setState({activeIndex:0});
  }

  openRegister(){
    showRegisterModal();
  }

  openForgotPassword(){
    showForgotPasswordModal();
  }

  openSecurityReset(){
    showSecurityResetModal();
  }

  handleActiveIndexUpdate = (input) => {
    this.setState({ activeIndex: input });
  };

  inputOnFocus(event) {
    let a = event.currentTarget.parentElement;
    a.classList.add('focus-state');
  }

  inputOnBlur(event) {
    let a = event.currentTarget.parentElement;
    a.classList.remove('focus-state');
  }

  isKeyboardFunctionalEventKey(eventKey){
    eventKey = eventKey.toLowerCase();
    if(eventKey=='control'||eventKey=='alt'||eventKey=='meta'||eventKey=='enter'||
    eventKey=='arrowup'||eventKey=='arrowdown'||eventKey=='arrowleft'||eventKey=='arrowright'||
    eventKey=='escape'||eventKey=='shift'||eventKey=="capslock"||eventKey=="tab"||eventKey=="unidentified"){
      return true;
    }else return false;
  }

  handleUsernameKeyUp(event) {
    let eventKey = event.key.toLowerCase();
    let currValue=this.state.username;
    if(this.isKeyboardFunctionalEventKey(eventKey)){
      event.preventDefault();
    }else{
      if(eventKey=='backspace'){
        currValue = currValue.slice(0, -1);
      }else{
        currValue += event.key;
      }
      this.setState({ username: currValue });
    }
  }

  handlePasswordKeyUp(event) {
    let eventKey = event.key.toLowerCase();
    let currValue=this.state.password;
    if(this.isKeyboardFunctionalEventKey(eventKey)){
      event.preventDefault();
    }else{
      if(eventKey=='backspace'){
        currValue = currValue.slice(0, -1);
      }else{
        currValue += event.key;
      }
      this.setState({ password: currValue });
    }
  }

  handleUsername(event) {
    this.setState({ username: event.target.value });
  }

  handlePassword(event) {
    this.setState({ password: event.target.value });
  }

  handleSendCode(event) {
    this.setState({ sendCode: event.target.value });
  }

  handleValidCode(event) {
    this.setState({ validCode: event.target.value });
  }

  showValidCode = function(event){
    let randomCode = new Date().getTime()
    this.setState({
      generateValidCode: randomCode
    })
    let validCodeImg = document.getElementById('loginValidCode');
    validCodeImg.setAttribute("src" , window.$wgtDomain+'/valid-code?_t=' + randomCode);
    validCodeImg.style.display = "block";

    let getValidCodeLabel = document.getElementById('getValidCode');
    getValidCodeLabel.style.display = "none";
  }

  showValidCode2 = function(event){
    let randomCode = new Date().getTime()
    this.setState({
      generateValidCode: randomCode
    })
    let validCodeImg = document.getElementById('loginValidCode2');
    validCodeImg.setAttribute("src" , window.$wgtDomain+'/valid-code?_t=' + randomCode);
    validCodeImg.style.display = "block";

    let getValidCodeLabel = document.getElementById('getValidCode2');
    getValidCodeLabel.style.display = "none";
  }

  showSendCode(){
    loginSendCodeSend();
  }

  setActiveIndex(input){
    this.setState({activeIndex:input});
  }

  getImage(imagewithtype){
    try{
      return images('./'+imagewithtype);
    } catch (err) {
      console.error(err);
      return window.$wgtDomain+"/imagenotfound.png"
		}
  }

  isAndroid() {
    if (navigator.userAgent.match('iPhone' || 'iPod' || 'iPad') != null) {
      return false;
    }
    return true;
  }

  render() {
    const { t } = this.props;
    const {activeIndex} = this.state;
    const loginState = this.state;
    const loginModal = this.props.modal.loginModal.result;
    const indexInit = this.props.indexInit;
    const indexData = this.props.indexData;
    let swipeItemsContent = [];
    if(loginModal){

      let hasPhoneLogin = !(loginModal?.authRegisterMember.phoneNumberSendVerify == 0 && loginModal?.authRegisterMember.emailSendVerify == 0);

      swipeItemsContent.push(<NameLoginContent key={0} loginModal={loginModal} hasPhoneLogin={hasPhoneLogin} indexInit={indexInit} activeIndex={activeIndex}
        setActiveIndex={this.setActiveIndex.bind(this,1)} loginState={loginState} inputOnFocus={this.inputOnFocus.bind(this)} inputOnBlur={this.inputOnBlur.bind(this)}
        handleUsername={this.handleUsername.bind(this)} handlePassword={this.handlePassword.bind(this)} handleValidCode={this.handleValidCode.bind(this)}
        showValidCode={this.showValidCode.bind(this)} loginPost={this.loginPost.bind(this)} openRegister={this.openRegister.bind(this)} openForgotPassword={this.openForgotPassword.bind(this)}
        demoLogin={this.demoLogin.bind(this)} getImage={this.getImage.bind(this)}
        handleUsernameKeyUp={this.handleUsernameKeyUp.bind(this)} handlePasswordKeyUp={this.handlePasswordKeyUp.bind(this)}
        isAndroid={this.isAndroid()} insertGapiScript={this.insertGapiScript.bind(this)} googleClick={this.googleClick.bind(this)} indexData={indexData}
        // initFb_JS_Sdk={this.initFb_JS_Sdk.bind(this)} loginWithFacebook={this.loginWithFacebook.bind(this)}
        {...this.props}/>)
      if(hasPhoneLogin){
        swipeItemsContent.push(<PhoneLoginContent key={1} loginModal={loginModal} indexInit={indexInit} activeIndex={activeIndex} setActiveIndex={this.setActiveIndex.bind(this,0)}
        loginState={loginState} inputOnFocus={this.inputOnFocus.bind(this)} inputOnBlur={this.inputOnBlur.bind(this)} handleUsername={this.handleUsername.bind(this)}
        handleSendCode={this.handleSendCode.bind(this)} handleValidCode={this.handleValidCode.bind(this)} showValidCode2={this.showValidCode2.bind(this)}
        loginPost={this.loginPost.bind(this)} openRegister={this.openRegister.bind(this)} openForgotPassword={this.openForgotPassword.bind(this)}
        demoLogin={this.demoLogin.bind(this)} showSendCode={this.showSendCode.bind(this)} getImage={this.getImage.bind(this)} {...this.props}/>)
      }
    }

    return (
      <Modal fade={false} isOpen={this.props.modal.loginModal.show} className="cart-modal">
        <div className="theme-background-color" style={{
          color: '#fff',
          flexDirection: 'row',
          padding: '10px',
          lineHeight: '24px',
          display: 'block',
          textAlign: 'center',
        }}>
          <div style={{ float: 'left', position: 'absolute', left: 15, top: 0, lineHeight: '44px' }}>
            <Button outline style={{ outline: 'none', border: 0, alignSelf: 'flex-start', padding: 0 }}>
              <MdArrowBack size={24} onClick={this.closeModal}/>
            </Button>
          </div>
          <div className="center" style={{ fontSize: 18 }}>{t('loginpage.loginaccount')}</div>
        </div>

        <div>
        {loginModal ?
              <div>
                {this.state.loading === true ?
                  // <ReactLoading type={'spin'} className={'loadingSpinner'} style={{ width: '49px', height: '49px' }}/>
                  <DarkBackground  disappear={this.state.loading}>
                  <LoadingOverlay active={true} spinner={true}>
                  </LoadingOverlay>
                  </DarkBackground>
                : false}
                <SwipeableViews index={activeIndex} onChangeIndex={this.handleActiveIndexUpdate} animateHeight={true} disableLazyLoading={true}
                                className="login-content page-content-44">
                  {swipeItemsContent}
                </SwipeableViews>
              </div>
              : null
        }

        </div>
      </Modal>
    );
  };
}

const ConnectedLoginModal = connect(mapStateToProps, mapDispatchToProps)(LoginModal);
export default withTranslation('common')(ConnectedLoginModal);