import React from 'react';
import { connect } from 'react-redux';
import ReactLoading from 'react-loading';

const mapStateToProps = (state) => {
  return { 
    utils: state.utils,
  };
};

class Loading extends React.Component {
  /*constructor(props) {
    super(props);
  }*/

  render() {
    return (
      <>
          <div className={'loading-popup-overlay'}>
            <ReactLoading type={"spin"} style={{width:"50px",height:"50px",margin:'auto',top:'50%',left:'50%', position:'fixed',transform: 'translate(-50%, -50%)',backgroundColor: 'transparent'}}/>
          </div>       
      </>
    );
  }
}
export default connect(mapStateToProps)(Loading);
