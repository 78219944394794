import React from 'react';
import { Button, Modal } from 'reactstrap';
import { MdArrowBack } from 'react-icons/md';
import { withTranslation } from 'react-i18next';

class AgentPromotionModal extends React.Component {
    // constructor(props) {
    //     super(props);
    // }
    
    render() {
        const { t } = this.props;
        const selectedDetails = this.props.selectedDetails ? this.props.selectedDetails : {};
        const platforms = this.props.platforms;

        // console.log("isShare value: " + this.props.isShare);
        // console.log(this.props.isShare)

        const detailsUi = <>
            <div style={{
                backgroundColor: 'var(--theme-color)',
                color: '#fff',
                flexDirection: 'row',
                padding: '10px',
                lineHeight: '24px',
                display: 'block',
                textAlign: 'center',
            }}>
                <div style={{ float: 'left', position: 'absolute', left: 15, top: 0, lineHeight: '44px' }}>
                    <Button outline style={{ outline: 'none', border: 0, alignSelf: 'flex-start', padding: 0 }}>
                        <MdArrowBack size={24} onClick={this.props.closeModal} />
                    </Button>
                </div>
                <div className="center" style={{ fontSize: 18 }}>{t('agentpromotiontab2.share')}</div>
            </div>

            <div className="agent-promotion-modal">
                { Object.keys(selectedDetails).length !== 0 ? Object.entries(selectedDetails.rebateRateMap).map((item, index) => {
                    const key = item[0], value = item[1];
                    return (
                        <div key={index} className="item-content">
                            <div className="item-inner">
                                <div className="item-title">{t(platforms[key])}</div>
                                <div className="item-after">{value}</div>
                            </div>
                        </div>
                    )
                }) : null }
            </div>
        </>

        const shareUi = <>
            <div style={{
                backgroundColor: 'var(--theme-color)',
                color: '#fff',
                flexDirection: 'row',
                padding: '10px',
                lineHeight: '24px',
                display: 'block',
                textAlign: 'center',
            }}>
                <div style={{ float: 'left', position: 'absolute', left: 15, top: 0, lineHeight: '44px' }}>
                    <Button outline style={{ outline: 'none', border: 0, alignSelf: 'flex-start', padding: 0 }}>
                        <MdArrowBack size={24} onClick={this.props.closeModal} />
                    </Button>
                </div>
                <div className="center" style={{ fontSize: 18 }}>{t('agentpromotiontab2.share')}</div>
            </div>

            <div className="page-content">
                <div className="content-block text-center" style={{padding: '80px 40px 0px 40px'}}>
                    <p>
                        <img src={window.$wgtDomain + '/promotion/qr-code-diy?str=' + encodeURIComponent(selectedDetails.url)} alt=""/>
                    </p>
                    <p style={{padding: '20px'}}>
                        <a style={{color: 'var(--theme-color)'}}>{selectedDetails.url}</a>
                    </p>
                </div>
                <div className="content-block" style={{padding: '16px 40px 0px 40px'}}>
                    <a className="f7-button button-fill apm-button" style={{height: '40px'}}>{t('使用防屏蔽地址')}</a>
                </div>
                <div className="content-block" style={{padding: '16px 40px 0px 40px'}}>
                    <div className="buttons-row">
                    <a className="apm-button1" 
                        onClick={() => {
                            //navigator.clipboard.writeText(selectedDetails.url);
                            let textField = document.createElement('textarea');
                            textField.innerText = selectedDetails.url;
                            document.body.appendChild(textField);
                            textField.select();
                            document.execCommand('copy');
                            textField.remove();
                            this.props.normalAlert(t('复制成功!快去和小伙伴们分享吧~'));
                        }}
                    ><i className="icon icon-copy" style={{fontSize: '24px', paddingRight: '10px'}}></i>{t('offlinepayinfopage.copy')}</a>
                    <a className="apm-button1"
                        onClick={() => {
                            //navigator.clipboard.writeText(selectedDetails.url);
                            let textField = document.createElement('textarea');
                            textField.innerText = selectedDetails.url;
                            document.body.appendChild(textField);
                            textField.select();
                            document.execCommand('copy');
                            textField.remove();
                            this.props.normalAlert(t('复制成功!快去和小伙伴们分享吧~'));
                        }}
                    ><i className="icon icon-share" style={{fontSize: '24px', paddingRight: '10px'}}></i>{t('邀请好友')}</a>
                    </div>
                </div>
            </div>
        </>

        return (
            <Modal isOpen={this.props.showModal} className="cart-modal">
                { this.props.isShare == 1 ? shareUi : detailsUi }
            </Modal>
        );
    };
}

export default withTranslation('common')(AgentPromotionModal);