import React from 'react';
import PropTypes from 'utils/propTypes';
import { withTranslation } from 'react-i18next';

class CashFlowModalDetails extends React.Component{

  // constructor(props) {
  //   super(props);
  // }

  render() {
    const { t } = this.props;
    //break the text to next line if exceed length
    return(
      <>
        <div className="d-flex justify-content-between" style={{flexDirection:'row'}}>
          <div style={{fontSize:16}}>{this.props.ref_num_title}</div>
          <div style={{fontSize:16}} className={`text-${this.props.color}`}>{this.props.ref_num}</div>
        </div>

        <div className="d-flex justify-content-between" style={{flexDirection:'row'}}>
          <div style={{fontSize:16}}>{this.props.betting_time_title}</div>
          <div style={{fontSize:16}} className={`text-${this.props.color}`}>{this.props.betting_time}</div>
        </div>

        <div className="d-flex justify-content-between" style={{flexDirection:'row'}}>
          <div style={{fontSize:16}}>{this.props.trade_type_name_title}</div>
          <div style={{fontSize:16}} className={`text-${this.props.color}`}>{t(this.props.trade_type_name)}</div>
        </div>

        <div className="d-flex justify-content-between" style={{flexDirection:'row'}}>
          <div style={{fontSize:16}}>{this.props.trade_target_title}</div>
          <div style={{fontSize:16}} className={`text-${this.props.color} text-break`}>{t(this.props.trade_target)}</div>
        </div>

        <div className="d-flex justify-content-between" style={{flexDirection:'row'}}>
          <div style={{fontSize:16}}>{this.props.trade_amount_title}</div>
          <div style={{fontSize:16}} className={`text-${this.props.color}`}>{this.props.trade_amount}</div>
        </div>

        <div className="d-flex justify-content-between" style={{flexDirection:'row'}}>
          <div style={{fontSize:16}}>{this.props.freeze_money_title}</div>
          <div style={{fontSize:16}} className={`text-${this.props.color}`}>{this.props.freeze_money}</div>
        </div>

        <div className="d-flex justify-content-between" style={{flexDirection:'row'}}>
          <div style={{fontSize:16, position:'absolute'}}>{this.props.remark_title}</div>
          <div style={{fontSize:16, marginLeft: 70, textAlign: '-webkit-right'}} className={`text-${this.props.color}`}>{this.props.remark}</div>
        </div>
      </>
    )
  }
}

CashFlowModalDetails.propTypes = {
  ref_num_title: PropTypes.string,
  ref_num: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),

  betting_time_title: PropTypes.string,
  betting_time: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),

  trade_type_name_title: PropTypes.string,
  trade_type_name_: PropTypes.string,

  trade_target_title: PropTypes.string,
  trade_target: PropTypes.string,

  freeze_money_title: PropTypes.string,
  freeze_money: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),

  remark_title: PropTypes.string,
  remark: PropTypes.string,

  color: PropTypes.oneOf([
    'dark','muted'
  ]),
};

export default withTranslation('common')(CashFlowModalDetails);