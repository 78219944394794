import React from 'react';
import { withTranslation } from 'react-i18next';
import Draggable from 'react-draggable';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
/*import CustomLink from '../CustomLink';*/
import { lineDisplayFunctionList } from '../Modal/LinesDisplayModal';
import { clearCacheAlert } from '../../services/AlertService';
import { showLoginModal, showRegisterModal } from '../../services/HomeService';
import styled, { keyframes } from 'styled-components';
import { rotateInDownRight } from 'react-animations';
import { saveJumpObj } from '../../actions/HomePageAction';
import { store } from '../../index';
import GameFetcher from '../GameFetcher';

const bounceAnimation = keyframes`${rotateInDownRight}
0% {z-index: 999999997;}
100% {z-index: 999999997;}
`;

const BouncyDiv = styled.div`
  animation: 1s ${bounceAnimation};
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
`;




const mapStateToProps = (state) => {
  return {
    indexInit: state.indexInit,
  };
};


const images = require.context('../../assets/image/page_icon/floatingbutton/', true);

let float_x;
let float_y;

let windowHeight = window.innerHeight;
let windowWidth = window.innerWidth;
float_x = 10;
float_y = windowHeight / 2;

class FloatingButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      floatX: 0,
      floatY: 0,
      show: false,
      publicChatStatus: 0,
    };

    this.triggerLineDisplay = this.triggerLineDisplay.bind(this);
    this.triggerLongQueen = this.triggerLongQueen.bind(this);
  };

  componentDidMount() {
    this.setState({
      floatX: float_x,
      floatY: float_y,
    });
    this.checkChatStatus();
  };

  checkChatStatus() {
    GameFetcher(window.$gameUrl + '/rest/member/getPublicChat', {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      // credentials: 'include',
    }).then((response) => response.json())
      .then((response) => {
        this.setState({ publicChatStatus: response.result.publicChatStatus });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handleDrag = (e, ui) => {
      float_x = float_x+ui.deltaX;
      float_y = float_y+ui.deltaY;
      e.target.style.transition = '';
  };

  handleStop(e) {
    //这里是调位子的
    let windowWidthHalf = windowWidth / 2;
    if (float_x > windowWidthHalf - 44) {
      float_x = windowWidth - 54;
    } else if (float_x < windowWidthHalf) {
      float_x = 10;
    }

    if (float_y > windowHeight - 160) {
      float_y = windowHeight - 160;
    } else if (float_y < 0) {
      float_y = 0;
    }

    //这里是调位子的
    e.target.style.transition = 'all 0.3s';

    //这里是判断是不是click action
    if (this.state.floatX == float_x && this.state.floatY == float_y) {
      let val = e.target.getAttribute('value');

      if(val == 'deposit'){
        if (this.props.indexInit.indexInit.loginUser) {
          window.location.href = window.location.href.split('#')[0] + '#/recharge-index';
        } else {
          showLoginModal();
        }
      }else if(val == 'withdraw'){
        if (this.props.indexInit.indexInit.loginUser) {
          window.location.href = window.location.href.split('#')[0] + '#/offline-draw';
        } else {
          showLoginModal();
        }
      }else if(val == 'register'){
        showRegisterModal();
      }else if(val == 'floatingBtn'){
        this.setState({
          show: true,
        });
      }
      else if(val == 'publicChat'){
        if (this.props.indexInit.indexInit.loginUser) {
          window.location.href = window.location.href.split('#')[0] + '#IM-page';
        } else {
          showLoginModal();
        }
      }
    } else {
      this.setState({
        floatX: float_x,
        floatY: float_y,
      });
    }
  };

  onClick() {
    this.setState({
      show: !this.state.show,
    });
  };

  refresh = () => {
    let dataObj = store.getState().indexData.jumpObj;
    dataObj.loginGameId = null;
    dataObj.openPromo = null;
    store.dispatch(saveJumpObj(dataObj));
    window.location.href = window.location.href.split('#')[0];
  };

  clearCache = () => {
    this.onClick();
    clearCacheAlert();
  };

  triggerLineDisplay() {
    this.onClick();
    lineDisplayFunctionList[1]();
  };

  triggerLongQueen() {
    this.onClick();
    this.props.history.push('/longQueen');
  };

  goChat() {
    this.onClick();
    if (this.props.indexInit.indexInit.loginUser) {
      window.location.href = window.location.href.split('#')[0] + '#IM-page';
    } else {
      showLoginModal();
    }
  };

  render() {
    const { floatX, floatY } = this.state;
    const { t } = this.props;
    let indexInit = this.props.indexInit;
    return (
      <>
      {this.props.location.pathname?(this.props.location.pathname === "/games")?
        <Draggable
          handle=".handle"
          scale={1}
          onStart={this.handleStart}
          onDrag={this.handleDrag}
          onStop={this.handleStop.bind(this)}
          defaultPosition={{ x: floatX, y: floatY }}
          position={{ x: floatX, y: floatY }}>
          <div className="handle floatButton noselect" style={{display: !this.state.show ? 'flex' : 'none',flexDirection: 'column', boxShadow:'none'}}>
            {/* <img className="cus-float-btn" style={{ marginBottom:'5px',height:'auto', borderRadius: '50px', paddingBottom: '0px' }} src={images(`./floatBtn.png`)} alt="" value="floatingBtn"/> */}
            {/* cash in and cash out floating button to show when login and not trial user */}
            {/* <img className="cus-float-btn" style={{ width:'48px', height:'auto', paddingBottom: '0', display: this.props.indexInit.indexInit.loginUser?(this.props.indexInit.indexInit.loginMsg.isTestUser?'none':'block'):'none' }} src={images(`./topup.png`)} alt="" value="deposit" />
            <img className="cus-float-btn" style={{ width:'48px', height:'auto', paddingBottom: '0', display: this.props.indexInit.indexInit.loginUser?(this.props.indexInit.indexInit.loginMsg.isTestUser?'none':'block'):'none' }} src={images(`./cashout.png`)} alt="" value="withdraw" /> */}
            {/* cash in and cash out floating button show only when user to third party game */}
            {this.state.publicChatStatus===1?
            <img className="cus-float-btn" style={{ marginBottom:'5px',height:'auto', borderRadius: '50px', paddingBottom: '0px' }} src={images(`./chat.png`)} alt="" value="publicChat"/>
            :null}
            {(!indexInit.indexInit.loginMsg.isTestUser && indexInit.indexInit.loginUser)?
            <img className="cus-float-btn" style={{ width:'40px', height:'auto', marginLeft:'2px',paddingBottom: '0', display: this.props.location.pathname?(this.props.location.pathname == "/games" ?'block':'none'):'none' }} src={images(`./topup.png`)} alt="" value="deposit" />
            :null}
            <img className="cus-float-btn" style={{ width:'48px', height:'auto', paddingBottom: '0', display: this.props.location.pathname?(this.props.location.pathname == "/third-login" ?'block':'none'):'none' }} src={images(`./cashout.png`)} alt="" value="withdraw" />
            <img className="cus-float-btn" style={{ width:'48px', height:'auto', marginLeft:'-3px', display: this.props.indexInit.indexInit.loginMsg.isTestUser?'block':(this.props.indexInit.indexInit.loginUser?'none':'block') }} src={images(`./signup.png`)} alt="" value="register"  />
          </div>
        </Draggable>
        :null:null
        }
        <div style={{ display: this.state.show ? 'block' : 'none' }}>
          <div onClick={this.onClick.bind(this)} className="floatButtonOverlay" />
          <BouncyDiv>
            <img className="floatButtonClose" src={images('./close.png')} alt="" onClick={this.onClick.bind(this)} />
          </BouncyDiv>
          <BouncyDiv>
            <div className="fixe fixeClearCache">
              <img src={images('./clearcache.png')} alt="" onClick={this.clearCache} />
            </div>
            <div className="fixe fixeText fixeClearCacheText">
              <p>
                <span>{t('clearcache')}</span>
              </p>
            </div></BouncyDiv>

          {/*上面部分 top part*/}
          {/*非试玩会员 + 未登录  non-test user + non login*/}
          {(indexInit && indexInit.indexInit && indexInit.indexInit.loginUser && !indexInit.indexInit.loginMsg.isTestUser) || (!indexInit && indexInit.indexInit || !indexInit.indexInit.loginUser) ?
            <>

              <BouncyDiv>
                <div className="fixe fixeHome">
                  <img src={images('./home.png')} alt="" onClick={this.refresh} />
                </div>
                <div className="fixe fixeText fixeHomeText">
                  <p>
                    <span>{t('home')}</span>
                  </p>
                </div> </BouncyDiv>

              <BouncyDiv>
                <div className="fixe fixeRefresh">
                  <img src={images('./refresh.png')} alt="" onClick={this.refresh} />
                </div>
                <div className="fixe fixeText fixeRefreshText">
                  <p>
                    <span>{t('refresh')}</span>
                  </p>
                </div></BouncyDiv>
            </>
            :
            <>
              {/*试玩会员 test user*/}
              <BouncyDiv>
                <div className="fixe fixeHomeTest">
                  <img src={images('./home.png')} alt="" onClick={this.refresh} />
                </div>
                <div className="fixe fixeText fixeHomeTextTest">
                  <p>
                    <span>{t('home')}</span>
                  </p>
                </div> </BouncyDiv>

              <BouncyDiv>
                <div className="fixe fixeRefreshTest">
                  <img src={images('./refresh.png')} alt="" onClick={this.refresh} />
                </div>
                <div className="fixe fixeText fixeRefreshTextTest">
                  <p>
                    <span>{t('refresh')}</span>
                  </p>
                </div></BouncyDiv>
            </>
          }

          {indexInit && indexInit.indexInit && indexInit.indexInit.loginUser && !indexInit.indexInit.loginMsg.isTestUser ?
            <>
              {/*非试玩会员 non-test user*/}
              <BouncyDiv>
                <div className="fixe fixeLine">
                  <img src={images('./line.png')} alt="" onClick={this.triggerLineDisplay} />
                </div>
                <div className="fixe fixeText fixeLineText">
                  <p>
                    <span>{t('line')}</span>
                  </p>
                </div></BouncyDiv>
            </>
            : indexInit && indexInit.indexInit && indexInit.indexInit.loginUser && indexInit.indexInit.loginMsg.isTestUser ?
              <>
                {/*试玩会员 test user*/}
                <BouncyDiv>
                  <div className="fixe fixeLine">
                    <img src={images('./line.png')} alt="" onClick={this.triggerLineDisplay} />
                  </div>
                  <div className="fixe fixeText fixeLineText">
                    <p>
                      <span>{t('line')}</span>
                    </p>
                  </div></BouncyDiv>

              </>
              :
              <>
                {/*未登录 non-login*/}
                <BouncyDiv>
                  <div className="fixe fixeLine">
                    <img src={images('./line.png')} alt="" onClick={this.triggerLineDisplay} />
                  </div>
                  <div className="fixe fixeText fixeLineText">
                    <p>
                      <span>{t('line')}</span>
                    </p>
                  </div></BouncyDiv>
              </>

          }
        </div>
      </>
    );
  }
}
const ConnectedFloatingButton = connect(mapStateToProps)(FloatingButton);
export default withRouter(withTranslation('common')(ConnectedFloatingButton));
