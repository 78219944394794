import { store } from '../index';

function updateOptions(options) {
    const update = { ...options };
    if(store.getState().memberInfo.gametoken!==undefined && store.getState().memberInfo.gametoken!==null) {
      update.headers = {
        ...update.headers,
        gametoken: store.getState().memberInfo.gametoken,
      };
    }
    return update;
  }
  
  export default function Fetcher(url, options) {
    return fetch(url, updateOptions(options));
  }