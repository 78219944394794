import React from 'react';
import PropTypes from 'utils/propTypes';
import Typography from 'components/Typography';
import { lustcitycolor, lustcityfun, ysccolor,yxxpic,eurolotto } from '../services/AwardService';

const yxxImages = require.context('../assets/image/YXX', true);
const osdImages = require.context('../assets/image/OSD', true);
const AwardDetailPage = ({ title, description, right,playid,parentindex,total, ...restProps }) => {
  let fun,color,path;
  if(playid === "LCT" || playid === "LOT"){
    fun = lustcityfun(description[0]);
    color = lustcitycolor(description[0]);
    path = "LUSTCITY";
  } else if (playid === "YSC") {
    color = ysccolor(description[0]);
    path = "YSC";
  } else if (playid === "YXX") {
    color = yxxpic(description[0]);
    path = "YXX";
  }

  return (
    <>
    <div {...restProps}>
      <div className="overflow-hidden" style={{display:'flex'}}>
        <div className="col-7" style={{fontSize: 15,lineHeight:'24px',padding:0}}>
          {title}
        </div>
        <div className="col-5" style={{padding:0,textAlign:'right'}}>
          {right && typeof right === 'string' ? (
            <Typography style={{fontSize:14,color:'#757575',lineHeight:'24px',marginRight:16,marginBottom:0}}>{right}</Typography>
          ) : (
            right
          )}
        </div>
      </div>
      <div style ={{ flexDirection: 'row', marginTop: 8,marginBottom: '1rem'}}>
        {
          playid !== "STM" ?
            (playid !== "LCT" && playid !== "YSC" && playid !== "YXX" && playid !== "OSD")  ?
              description.map((item,index)=>{
                const itemValue = item + "";
                return (
                  <>
                    <div key={index} className={playid === "KW3" ? "dice dice"+itemValue : playid === "BSC" ? "racing r"+itemValue : (playid === "LHC" || playid === "LOT" || playid === "JHC") ? "LHC-ball-small-full lhc-b-"+itemValue : eurolotto(playid) ?"LHC-ball-small-full lhc-b-01":parentindex === 0 ? "red-ball" : "red-ball-em"}>
                      {item}
                    </div>
                    {/*LHC || LOT*/}
                    {index === description.length-2 && (playid === "LHC" || playid === "LOT" || eurolotto(playid) || playid === "JHC") ?
                      <div style={{display:'inline-block',marginRight:5,color:'black'}}>+</div>
                      :
                      null
                    }
                    {/*DDC*/}
                    {
                      (playid === "DDC" || playid==="QQC") && index === 2 ?
                        <>
                          <div style={{display:'inline-block',marginRight:5,color:'black'}}>
                            =
                          </div>
                            <div className={parentindex === 0 ? "red-ball" : "red-ball-em"}>
                            {total}
                          </div>
                        </>
                        :
                        (playid === "DDC" || playid==="QQC") && index !== 2 ?
                          <div style={{display:'inline-block',marginRight:5,color:'black'}}>
                            +
                          </div>
                          :
                          null
                    }
                    {/* {
                      playid === "FTW" && index == description.length-1 ?
                        <>
                          <div style={{display:'inline-block',marginRight:5,color:'black'}}>
                            |
                          </div>
                          <div className={parentindex === 0 ? "red-ball" : "red-ball-em"}>
                            {total}
                          </div>
                          <div className={parentindex === 0 ? "blue-ball" : "blue-ball-em"}>
                            {parseInt(total)%4==0?4:parseInt(total)%4}
                          </div>
                        </>
                        :
                        null
                    } */}
                  </>
                )
            })
            :
            (playid === "YXX") ? 
              description.map((item,index)=>{
              // const itemValue = item + "";
              return (
              <>
                {<img style={{ width: '35px', height: '34px', borderRadius: '10px', marginBottom: '5px' }} alt="" src={yxxImages('./'+yxxpic(item)+'.png')}/>}
              </>
              )
            })
            :
            (playid === "OSD") ? 
              description.map((item,index)=>{
              return (
              <>
                {<img style={{ width: '28px', height: '28px',marginRight: '3px'}} alt="" src={osdImages('./'+item+'.png')}/>}
              </>
              )
            })
            :
            <>
              {playid === "LCT" &&
              <img className="lct-award-num" src={require(`../assets/image/${path}/${fun}.png`)}
                   style={{ marginBottom: 5, marginRight: 3 }} alt=""/>
              }
              <div className="lct-award-num">{description[0]}</div>
              <img className="lct-award-num" src={require(`../assets/image/${path}/${color}.png`)} style={{marginBottom:5, marginLeft:3}} alt=""/>
            </>
        : 
        (parseFloat(description[1])-parseFloat(description[0])).toFixed(2)>0?
            <>
            <span className="stm-trending-up" >{description[1]}</span>
            <img src={require(`../assets/image/trending_up.png`)} style={{width:20, height:20, marginBottom:5, marginLeft:3}} alt=""/>
            </>
            :
            <>
            <span className="stm-trending-down" >{description[1]}</span>
            <img src={require(`../assets/image/trending_down.png`)} style={{width:20, height:20, marginBottom:5, marginLeft:3}} alt=""/>
            </> 
      }
      </div>
    </div>
      <div style={{borderBottom: '1px solid #D7D7D7',marginRight: '15px'}}>
      </div>
    </>
  );
};

AwardDetailPage.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  right: PropTypes.node,
};

export default AwardDetailPage;
