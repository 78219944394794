// todo remove suppression on multiple exports
// eslint-disable-next-line import/prefer-default-export
export const options =
[
  { value: 'en', label: 'English' },
  { value: 'vi', label: '越南' },
  { value: 'th', label: '泰文' },  
  { value: 'zh', label: '中文繁體' },
  { value: 'in', label: '印语' }, 
  { value: 'id', label: '印度尼西亚语' },
  { value: 'pt', label: '葡萄牙语' },
  { value: 'es', label: '西班牙语' },
  { value: 'fr', label: '法语' },
  { value: 'ru', label: '俄语' },
];
