import React from 'react';
import {
  Nav,
  Navbar,
  NavItem,
} from 'reactstrap';
import bn from '../../utils/bemnames';
import { withTranslation } from "react-i18next";
import {
  MdArrowDropDown,
  MdArrowDropUp,
  MdDehaze
} from 'react-icons/md';
import { store } from '../../index';


const bem = bn.create('header');

class Header extends React.Component {
  state = {
    isOpenNotificationPopover: false,
    isNotificationConfirmed: false,
    isOpenUserCardPopover: false,
  };

  constructor(props) {
    super(props)
    this.state = {
      condition: false
    }
    this.handleGameDropdownButton = this.handleGameDropdownButton.bind(this)
  }
  handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-overlay').classList.toggle('cr-overlay--open');
    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
    document.querySelector('body').classList.toggle('overflow-lock');
  };

  handleGameDropdownButton = event => {
    event.preventDefault();
    this.setState({
      condition: !this.state.condition
    })
  };

  rightToggle = () => {
    this.props.rightToggle();
  };

  render() {
    let siteName = store.getState().utils.siteName;
    let icon;
    if(this.state.condition) {
      icon = <MdArrowDropUp size={25}/>;
    } else {
      icon = <MdArrowDropDown size={25}/>;
    }
    return (
      <Navbar light expand style={{padding:0,height:44}}>
        <Nav navbar className="mr-2">
        </Nav>

        <Nav navbar style={{position:'absolute',justifyContent:'center',width:'100%',height:44}}>
            <NavItem>
            <p className="p text-white" style={{marginBottom:0,fontSize:18,lineHeight:'44px'}}>{siteName}</p>
            </NavItem>
        </Nav>

        <Nav navbar className={bem.e('nav-right')} style={{zIndex:4}}>
          <div onClick={()=>this.rightToggle()}>
            <MdDehaze size={25} style={{color:'white',marginRight:15}}/>
          </div>
        </Nav>
      </Navbar>
    );
  }
}

export default withTranslation('common')(Header);
