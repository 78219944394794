import i18next from 'i18next';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { MdArrowBack } from 'react-icons/md';
import { Button, Col, ListGroup, ListGroupItem, Modal, Row } from 'reactstrap';
import { convertByTimeZone, intToString } from '../../services/HomeService';
import BettingRecordModalDetails from './BettingRecordModalDetails';

const isZongHePlayType = (gamePayId) =>{
  let isZongHePlay = false;
  switch(gamePayId.substring(0,3)){
    case 'V6Z':
      isZongHePlay = true;
      break;
    case 'V5Z':
      isZongHePlay = true;
      break;
    case 'D4D':
      isZongHePlay = true;
      break;      
    default:
      isZongHePlay = false;
      break;
  }
  return isZongHePlay;
};

class BettingRecordModal extends React.Component {

  constructor(props) {
    super(props);
    this.onPress = this.onPress.bind(this);
  }

  onPress = () => {
    this.props.showAwardResult(this.props.item)
  };

  formatNum = input => {
    if (input !== undefined) {
    return intToString(input, this.props.memberInfo?.MemberInfo?.currAbbr);
    }
  };

  render() {
    const { t } = this.props;

    if (!this.props.show) {
      return null;
    }

    const getTimeStamp = () => {
      return convertByTimeZone(this.props.item.createDate, 'YYYY/MM/DD HH:mm:ss');
    };

    let live = (window.location.href.includes("/live")?true:false);
    const winHeight = window.innerHeight;
    let liveHeight= (winHeight)-40;

    return (
      <Modal
        isOpen={this.props.show}
        toggle={this.props.open_popup_toggle()}
        className="bet-modal"
        style={{height:'100%',top:0,width:'100%',overflow:'auto'}}>
        <div className='bet-details-header modal-header' style={{ color: '#fff', flexDirection: 'row', padding: '10px', lineHeight: '24px', display: 'block', textAlign: 'center' }}>{t('betting_record_details')}
          <div className="left" style={{ float: 'left' }}>
            <Button outline style={{ outline: 'none', border: 0, alignSelf: 'flex-start', padding: 0 }}
                    onClick={this.props.open_popup_toggle()}>
              <MdArrowBack size={24}/>
            </Button>
          </div>
        </div>

        <div>
          <div style={{overflowY:live?'auto':'',overflowX:live?'hidden':'', height:live?liveHeight:''}}>
            <Row>
              <Col lg={12} md={6} sm={6} xs={12}>
                <ListGroup>
                  <React.Fragment>
                    <ListGroupItem style={{ border: 0 }}>
                      <BettingRecordModalDetails
                        ref_num_title={t('ref_num_title')}
                        ref_num_={this.props.item.billNo}
                        color='muted'
                      />
                    </ListGroupItem>

                    <ListGroupItem style={{ border: 0}}>
                      <BettingRecordModalDetails
                        betting_time_title={t('betting_time_title')}
                        betting_time={getTimeStamp()}
                        color='muted'
                      />
                    </ListGroupItem>

                    <ListGroupItem style={{ border: 0 }}>
                      <BettingRecordModalDetails
                        game_mode_title={t('game_mode_title')}
                        game_mode={i18next.language !== 'zh' ? ((this.props.item.gamePlayId.substring(0,3) === 'YXX' || this.props.item.gamePlayId.substring(0,3) === 'OSD') ? this.props.item.playName : t(this.props.item.gamePlayId)) : this.props.item.playName}
                        color='muted'
                      />
                    </ListGroupItem>

                    <ListGroupItem style={{ border: 0 }}>
                      <BettingRecordModalDetails
                        betting_content_title={t('betting_content_title')}
                        betting_content={this.props.item.betContent}
                        color='muted'
                      />
                    </ListGroupItem>

                    <ListGroupItem style={{ border: 0 }}>
                      <BettingRecordModalDetails
                        betting_amount_title={t('betting_amount_title')}
                        betting_amount={this.formatNum(this.props.item.betAmount)}
                        color='muted'
                      />
                    </ListGroupItem>

                    <ListGroupItem style={{ border: 0 }}>
                      <BettingRecordModalDetails
                        valid_betting_amount_title={t('valid_betting_amount_title')}
                        valid_betting_amount={this.formatNum(this.props.item.validAmount)}
                        color='muted'
                      />
                    </ListGroupItem>

                    <ListGroupItem style={{ border: 0 }}>
                      <BettingRecordModalDetails
                        bet_award_title={t('payout')}
                        bet_award={this.formatNum(this.props.item.betAward)}
                        color='muted'
                      />
                    </ListGroupItem>
                    {this.props.item.winStatus !== 0 && isZongHePlayType(this.props.item.gamePlayId)  ?
                     <ListGroupItem style={{ border: 0 }}>
                       <div className="d-flex justify-content-between" style={{flexDirection:'row'}}>
                        <div style={{fontSize:16}}>{t('bet_result_title')}</div>
                        <div style={{fontSize:13}} className={'text-muted'}>
                        <button className="button button-fill button-small button-outline"
                        style={{
                      fontSize: 10, height: '25px', lineHeight: '25px',
                      borderRadius: '4px', float: 'left', color: '#ffff',
                      minWidth: '64px',padding:'0px 0px'
                    }}
                    value={this.props.item.id}
                    onClick={this.onPress}>{t('明细')}
                    </button></div></div>
                    </ListGroupItem>
                    :
                    this.props.item.winStatus === 0 && this.props.item.statusName === '个人取消' ?
                      <ListGroupItem style={{ border: 0 }}>
                        <BettingRecordModalDetails
                          bet_result_title={t('bet_result_title')}
                          bet_result={t('本人撤单')}
                          color='muted'
                        />
                      </ListGroupItem>
                      :this.props.item.winStatus==1 && this.props.item.statusName !=='正常' && this.props.item.remark !=='个人取消' && this.props.item.remark !== '' ?
                          <ListGroupItem style={{ border: 0 }}>
                            <BettingRecordModalDetails
                              bet_result_title={t('bet_result_title')}
                              bet_result={t('系统撤单')}
                              color='muted'
                            />
                          </ListGroupItem>
                        :this.props.item.winStatus === 0 && this.props.item.statusName === '系统取消' ?
                        <ListGroupItem style={{ border: 0 }}>
                        <BettingRecordModalDetails
                          bet_result_title={t('bet_result_title')}
                          bet_result={t('系统撤单')}
                          color='muted'
                        />
                      </ListGroupItem>
                      :(
                          <ListGroupItem style={{ border: 0 }}>
                          <BettingRecordModalDetails
                          bet_result_title={t('bet_result_title')}
                          bet_result={t(this.props.item.awardResult)}
                          bet_playid={this.props.item.gamePlayId}
                          showBetResult={1}
                          color='muted'
                          />
                          </ListGroupItem>
                      )}

                    <ListGroupItem style={{ border: 0 }}>
                      <BettingRecordModalDetails
                        bet_period_title={t('bet_period_title')}
                        bet_period={this.props.item.periodNo}
                        color='muted'
                      />
                    </ListGroupItem>

                    <ListGroupItem style={{ border: 0 }}>
                      <BettingRecordModalDetails
                        game_name_title={t('game_name_title')}
                        game_name={t(this.props.item.gameName)}
                        color='muted'
                      />
                    </ListGroupItem>
                  </React.Fragment>
                </ListGroup>
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
    );
  }
}

export default withTranslation('common')(BettingRecordModal);