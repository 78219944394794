import React from 'react';
import { withTranslation } from 'react-i18next';
import { store } from '../../index';
import { updateNotice, UpdateAlert } from '../../actions/HomePageAction';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SwipeableViews from 'react-swipeable-views';
import { Modal } from 'reactstrap';
import Slider from 'react-slick';
import Fetcher from '../../components/Fetcher';
import { MdCancel } from 'react-icons/md';

// import 'slick-carousel/slick/slick-theme.css';
// import 'slick-carousel/slick/slick.css';

const images = require.context('../../assets/image/', true);

const mapStateToProps = (state) => {
    return {
        alertObj: state.alert,
        indexData: store.getState().indexData,
        home: state.home,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({
        updateNotice,
        UpdateAlert,
    }, dispatch)
);

class NoticeModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            noticeList: [],
            currentNoticeIndex: 0,
            show: false,
            titie: '',
            content: '',
            activeIndex: 0,
            loginGameId: this.props?.indexData?.jumpObj?.loginGameId !== undefined ? this.props.indexData.jumpObj.loginGameId : null
        };
        this.popupNotice = this.popupNotice.bind(this);
        this.showDialog = this.showDialog.bind(this);
        this.closeNotice = this.closeNotice.bind(this);
        // this.slide = this.slide.bind(this);
    }

    componentDidMount() {
        this.getCheckIP();
    //    this.showDialogNotice();
    }

    getSessionLastTime(id) {
        let noticeHistory = store.getState().home.noticeHistory;
        if (noticeHistory && Array.isArray(noticeHistory)) {
            return noticeHistory.find(notice => notice.id == id);
        } else {
            return null
        }
    }

    getCheckIP() {
        fetch(window.$wgtDomain + "/checkip", {
            method: 'get',
            headers: new Headers({
                'Accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9'
            }),
            responseType:'json',
            // credentials: 'include',
            referrer: 'h5'
        })
          .then(
            (result) => {
                if(result && !result.statusText.includes('not_ok')){
                    this.showDialogNotice();
                }
            },
            (error) => {
                console.error(error)
            }
          )
    }

    showDialogNotice() {
        Fetcher(window.$wgtDomain + "/member-center/notice/dialog-notice", {
            method: "Get",
            headers: new Headers({
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            // credentials: 'include',
            referrer: 'h5'
        }).then((response) => response.json())
            .then((response) => {
                // console.log('showDialogNotice:'+response.length);
                //response = this.filterNotice(response);
                if ((!response) || (response.length < 1)) return;
                this.showDialog(response);
            })
    }

    popupNotice() {
        const currentState = this.state;
        if ((currentState.currentNoticeIndex + 1) > currentState.noticeList.length) return;
        let content = currentState.noticeList[currentState.currentNoticeIndex].content;
        if (content) {
            content = content.replace('img src="', 'img src="' + window.$wgtDomain);
        }
        currentState.content = content;
        currentState.title = currentState.noticeList[currentState.currentNoticeIndex].title;
        currentState.show = true;
        currentState.currentNoticeIndex = currentState.currentNoticeIndex + 1;
        this.setState(currentState);
    }

    showDialog(res) {
        store.dispatch(updateNotice(res));
        const currentState = this.state;
        currentState.noticeList = res;
        currentState.currentNoticeIndex = 0;
        this.setState(currentState, this.popupNotice());
    }

    filterNotice(arr) {
        if (!arr || !arr.length) return new Array();
        for (let i = arr.length - 1; i >= 0; i--) {
            let a = arr[i];
            // 设置为每次显示的显示
            if (a.displayType == 0 && a.displayTime == 0 && this.getSessionLastTime(a.id)) {
                arr.splice(i, 1);
            }
        }
        return arr;
    }

    htmlDecode(input){
        let htmlElement = input;
        // if(!window.$isApp) {
            htmlElement = htmlElement.replace(/src="\/uploads/g, "src=\""+window.$wgtDomain+"/uploads")
            htmlElement = htmlElement.replace("src='/uploads", "src='"+window.$wgtDomain+"/uploads");
        // }
        return htmlElement;
    }

    closeNotice(){
        // const currentState = this.state;
        // if ((currentState.currentNoticeIndex + 1) > currentState.noticeList.length){
            this.setState({show : false})
        // }else{
            // this.popupNotice();
        // }
    }

    getImage(imagewithtype){
        try{
          return images('./'+imagewithtype);
        } catch (err) {
          return images('./quan-close.png');
        }
    }

    // slide(y){
    //     y > 0 ? (
    //        this.slider.slickNext()
    //     ) : (
    //        this.slider.slickPrev()
    //     )
    // }
    // componentWillMount(){
    //     window.addEventListener('wheel', (e) => {
    //         this.slide(e.wheelDelta);
    //         console.log('slide:'+e.target)
    //     })
    // }

    render() {
        const { t } = this.props;
        const { activeIndex, noticeList } = this.state;
        const options = {
            dots: false,
            infinite: true,
            speed: 500,
            autoplay: false,
            autoplaySpeed: 2000,
            slidesToShow: 1,
            slidesToScroll: 1,
            afterChange: function(index) {
                sliderChange(index);
              }
        };
        const sliderChange = (newIndex) => {
            this.setState({activeIndex: newIndex});
            // console.log(
            //     `Slider Changed to: ${newIndex + 1}`
            //   );
        }
        return (
            <>
            <div style={{ backgroundColor: '#182333' }}>
                {noticeList.length>0 && this.props.home.noticeHistory !== 'MAINTAIN'?
                <Modal isOpen={this.state.show && this.state.loginGameId==null} className="notice-dialog" wrapClassName="notice-modal">
                    <div className="notice-header">{t('公告列表')}</div>
                    <SwipeableViews animateHeight={true}
                                    disableLazyLoading={true}
                                    style={{height:500}}>
                        <Slider {...options} className="carouselStyle noticeCarousel" /*ref={slider => this.slider = slider}*/>
                            {noticeList.map((item, index) => {
                                return (
                                    <React.Fragment key={index} >
                                        <div style={{height:'400px'}}>
                                            <div className="notice-title">{item.title}</div>
                                            <div className="notice-content" style={{height:'100%'}} dangerouslySetInnerHTML={{ __html: this.htmlDecode(item.content) }}/>
                                        </div>
                                    </React.Fragment>
                                );
                            })
                            }
                        </Slider>
                    </SwipeableViews>
                    <div className="notice-confirm" style={{color:'#182333',marginTop:'-30px'}}>{activeIndex+1} / {noticeList.length}</div>
                    <div className="notice-confirm" onClick={this.closeNotice}>
                        {/* <img src={this.getImage(`cancel.png`)} alt="" style={{width:'35px',height:'35px',maxWidth:'100%',maxHeight:'100%'}}/> */}
                        <MdCancel style={{color:'black',width:'35px',height:'35px',maxWidth:'100%',maxHeight:'100%'}}/>
                    </div>
                </Modal>
                : <></>}
            </div>
            </>
          );
            // <SwipeableViews index={activeIndex} onChangeIndex={this.handleChangeIndex} animateHeight={true}
            // disableLazyLoading={true}
            // className="game-list-content">
        // return (
        //     <Modal isOpen={this.state.show} className="notice-dialog" wrapClassName="notice-modal">
        //       <img src={images(`./pop-out-bg-01.png`)} alt="" style={{width:'100%',minHeight:60}}/>
        //       <div className="notice-header">{t('最新公告')}</div>
        //       <div className="notice-title">{this.state.title}</div>
        //       <div className="notice-content" dangerouslySetInnerHTML={{ __html: this.state.content }}/>
        //       <div className="notice-confirm" onClick={this.closeNotice}>{t('确定')}</div>
        //   </Modal>
        // )
        //   </SwipeableViews>
    }
}

const ConnectedNoticeModal = connect(mapStateToProps, mapDispatchToProps)(NoticeModal);
export default withTranslation('common')(ConnectedNoticeModal);