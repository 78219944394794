// const availableColors = [
//   'blue',
//   'indigo',
//   'purple',
//   'pink',
//   'red',
//   'orange',
//   'yellow',
//   'green',
//   'teal',
//   'cyan',
//   'white',
//   'gray',
//   'gray-dark',
//   'primary',
//   'secondary',
//   'success',
//   'info',
//   'warning',
//   'danger',
//   'light',
//   'dark',
// ];

export const getColor = (availableColor = 'primary') => {
  if (typeof window === 'undefined') {
    return null;
  }

  const color = window
    .getComputedStyle(document.documentElement)
    .getPropertyValue(`--${availableColor}`);

  return color;
};

export const getThemeColors = () => [
  'primary',
  'secondary',
  'success',
  'info',
  'warning',
  'danger',
];

const availableCustomColors = {
  "blue": "#2196f3",
  "lightblue": "#03a9f4",
  "cyan": "#00bcd4",
  "teal": "#009688",
  "green": "#4caf50",
  "lightgreen": "#8bc34a",
  "lime": "#cddc39",
  "yellow": "#ffeb3b",
  "amber": "#ffc107",
  "orange": "#ff9800",
  "deeporange": "#ff5722",
  "brown": "#795548",
  "gray": "#9e9e9e",
  "bluegray": "#607d8b",
  "black": "#000000",
  "red": "#D9534F",
  "pink": "#e91e63",
  "purple": "#9c27b0",
  "deeppurple": "#673ab7",
  "indigo": "#3f51b5",
  "new-skin-green": "#74BF44",
  "new-skin-gold": "#ebbc4c",
  "new-skin-blue": "#304DF0",
  "sport-blue": "#3D9DFF",
  "new-skin-cp": "#D9383D",
  "new-skin-royal": "#739EE1",
  "sodo-blue": "#5c90f4",
  "minigame-blue": "#1a1b39",
  "cp98-white": "#94b5e6",
  "bet365-green": "#14805E",
  "blaze-black": "#161616",
  "blaze-white": "#161616",
  "allslot": "#083238",
  "vietnam-lottery": "#4a9ff7"
}
export const getCustomColor = (customColor = 'red') => {
  return availableCustomColors[customColor] ?? "#f44336";
}