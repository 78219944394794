import React from 'react';
import { Modal } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import { connect } from 'react-redux';
import { UpdateAlert } from '../../actions/HomePageAction';
import { bindActionCreators } from 'redux';
// import i18next from 'i18next';

const mapStateToProps = (state) => {
    return {
        alertObj: state.alert,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({
        UpdateAlert,
    }, dispatch)
);

const lineDisplayFunctionList = [];

class LinesDisplayModal extends React.Component {
    constructor(props) {
        super(props);
        this.triggerClose = this.triggerClose.bind(this);
        this.triggerShow = this.triggerShow.bind(this);
        while (lineDisplayFunctionList.length > 0) { lineDisplayFunctionList.pop(); }
        lineDisplayFunctionList.push(this.triggerClose);
        lineDisplayFunctionList.push(this.triggerShow);
    }

    state = {
        linesResult: window.$linesResult,
        show: false,
    };

    async initLines() {
        try {
            var protocol = window.$wgtDomain.indexOf("https") === 0?"https://":"http://";
            var fetchJsonResult = await fetch(window.$wgtDomain + "/wap/lines?https="+(window.$wgtDomain.indexOf("https") === 0).toString());
            var lines = await fetchJsonResult.text();
            lines = lines.split(",");
            var initLinesResult = [];
            lines.map(async (line, index) => {
                line = line.split("-");
                var ping, startTime = new Date().getTime();
                try {
                    var tUrl = line[line.length - 1];
                    var domains = tUrl.split(".");
                    if (domains.length === 2 && process.env.NODE_ENV !== 'development') {
                        tUrl = "www."+tUrl;
                    }
                    var lineJsonResult = await fetch(protocol + tUrl + '/beat?t=' + startTime);
                    var pingResult = await lineJsonResult.text();
                    if (pingResult === 'ok') {
                        ping = (new Date().getTime()) - startTime;
                    } else {
                        ping = false;
                    }
                } catch (err) {
                    console.error(err);
                    ping = false;
                }
                initLinesResult.push({
                    line: line[line.length - 1],
                    ping: ping,
                });
            })
            window.$linesResult = initLinesResult;
            window.$isInitLinesDone = true;
            this.setState({
                linesResult: initLinesResult,
            })
            return true;
        } catch (err) {
            console.error(err);
            return false;
        }
    }

    componentDidMount() {
        // if (!window.$isInitLinesDone) {
        //     this.initLines();
        // }
    }

    normalAlert(content) {
        const { alertObj, UpdateAlert, t } = this.props;
        alertObj.title = t('subaccountpage.hitsmsg');
        alertObj.alertType = 'normalAlert';
        alertObj.showAlert = true;
        alertObj.content = content;
        alertObj.functionName = false;
        UpdateAlert(alertObj);
    }

    triggerShow() {
        const { t } = this.props;
        if (!window.$isInitLinesDone) {
            this.normalAlert(t('页面线路检测未完成,请稍候再试'));
        } else if (this.state.linesResult && this.state.linesResult.length < 1) {
            this.normalAlert(t('当前无可切换线路'));
        } else {
            this.setState({
                show: true,
            })
        }
    }

    triggerClose() {
        this.setState({
            show: false,
        })
    }

    render() {
        const { t } = this.props;
        let linesResult = this.state.linesResult;
        if (!linesResult) {
            return null;
        } else {
            let fastest = 100000;
            let fastestLine;
            linesResult.map((item) => {
                if (item.ping && fastest > item.ping) {
                    fastest = item.ping;
                    fastestLine = item.line;
                }
            })
            return (
                <Modal isOpen={this.state.show} style={{ height: 'auto' }} wrapClassName="line-modal">
                    <List component="nav" aria-label="secondary mailbox folders">
                        {linesResult.map((item, index) => {
                            const textDisplay = t('线路') + (index + 1) + ' -> ' + (
                                item.ping ?
                                    (item.ping + ' ms' + (fastestLine === item.line ? ' ' + t('(最快)') : ''))
                                    : t('故障')
                            );
                            return (
                                <ListItem button key={index} selected={item.line === window.location.hostname}>
                                    <ListItemText 
                                        primary={
                                            <Typography type="body1" style={item.line === window.location.hostname ? { fontWeight: '600' } : null}>
                                                {textDisplay}
                                            </Typography>
                                        } 
                                        onClick={() => { window.location.href = (window.location.protocol + '//' + item.line) }} />
                                </ListItem>
                            )
                        })}
                        <ListItem button>
                            <ListItemText primary={t('切换线路后，可能需重新登录账号')} />
                        </ListItem>
                        <ListItem button>
                            <ListItemText primary={t('取消')} color={red} onClick={this.triggerClose} />
                        </ListItem>
                    </List>
                </Modal>
            );
        }


    };
}

export { lineDisplayFunctionList }
export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(LinesDisplayModal));