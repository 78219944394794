import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import storage from 'redux-persist/lib/storage';
import { /*saveGameListPage,*/ UpdateAlert } from '../../actions/HomePageAction';
import { triggerSave } from '../../pages/MemberCenter/AgentPromotion/AgentPromotionTab1';
import { deleteFunction } from '../../pages/MemberCenter/AgentPromotion/AgentPromotionTab2';
import { subAccountClose } from '../../pages/MemberCenter/SubAccountPage';
import { changeBalanceConfirm } from '../../services/AccountInfoService';
import { clearBetContent, resetForm } from '../../services/GameService';
import { showLoginModal, showSafePasswordModal /*, showIdentityConfirmation*/ } from '../../services/HomeService';
import { alertSendCode, resendEmail } from '../../services/RegisterService';
/*import { store } from '../../index';*/
import { Browser } from '@capacitor/browser';
import i18next from 'i18next';
import { alert } from '../../services/AlertService';

const mapStateToProps = (state) => {
  return {
    alert: state.alert,
    game: state.game
  };
};

const tran = (input) => {
  return i18next.t(input);
}


const mapDispatchToProps = dispatch => (
  bindActionCreators({
    UpdateAlert,
    clearCacheAction,
    closeLoadingAction,
  }, dispatch)
);

const clearCacheAction = () => {
  return { type: 'CLEAR_CACHE' }
}

const closeLoadingAction = () => {
	return { type: 'SUCCESS_LOAD' }
}

const callBackFunctionList = {
  resendEmail: resendEmail,
  alertSendCode: alertSendCode,
  changeBalanceConfirm: changeBalanceConfirm,
  subAccountClose: subAccountClose,
  AgentPromotionTab1Save: triggerSave,
  AgentPromotionTab2Delete: deleteFunction,
}

const head_images = require.context('../../assets/image/', true);

class AlertModal extends React.Component {

  state={
    url : "",
  };

  constructor(props) {
    super(props);
    this.state = {};
    this.triggerCallBack = this.triggerCallBack.bind(this);
    this.triggerCancelCallBack = this.triggerCancelCallBack.bind(this);
  }

  close() {
    this.props.alert.showAlert = false;
    this.props.alert.containerType = '';
    this.props.alert.modalPadding = '';
    this.props.alert.content = '';
    this.props.alert.title = '';
    this.props.alert.alertType = '';
    this.props.alert.functionName = '';
    this.props.alert.validCodeParam = '';
    this.props.alert.imageURL = '';
    if(this.props.alert.reloadPage != undefined){
      this.props.alert.reloadPage = true;
    }
    if(this.props?.game?.gameBetObject?.game?.playId == 'V6Z' || this.props?.game?.gameBetObject?.game?.playId == 'V5Z' || this.props?.game?.gameBetObject?.game?.playId == 'D4D'){
      this.props.alert.resetNumber = false;
    }

    this.props.UpdateAlert(this.props);
    this.props.closeLoadingAction();
  }

  componentDidMount() {
    if(window.$mode==2){
      fetch(window.$wgtDomain + '/wap-olservice', {
        method: 'post',
        headers: new Headers({
          'Accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9',
        }),
        responseType: 'json',
        // credentials: 'include',
        referrer: 'h5',
      }).then(res => res.json())
        .then(res => {
          this.setState({
            url: res.url,
          })
        });
    }
  }

  goBackReferrer() {
    this.props.alert.showAlert = false;
    this.props.alert.containerType = '';
    this.props.alert.modalPadding = '';
    this.props.alert.content = '';
    this.props.alert.title = '';
    this.props.alert.alertType = '';
    this.props.alert.functionName = '';
    this.props.alert.validCodeParam = '';
    this.props.UpdateAlert(this.props);
    let id = window.setTimeout(function() {}, 0);
    while (id--) {
      window.clearTimeout(id);
    }
    this.props.closeLoadingAction();
    window.location.href = storage.getItem('myReferrer');
  }

  goback() {
    const { t } = this.props;
    let counter = 0;
    if(this.props.alert.content == t('未登录或者登录超时')){
      counter++;
    }
    this.props.alert.showAlert = false;
    this.props.alert.containerType = '';
    this.props.alert.modalPadding = '';
    this.props.alert.content = '';
    this.props.alert.title = '';
    this.props.alert.alertType = '';
    this.props.alert.functionName = '';
    this.props.alert.validCodeParam = '';
    this.props.UpdateAlert(this.props);
    let id = window.setTimeout(function() {}, 0);
    while (id--) {
      window.clearTimeout(id);
    }
    this.props.closeLoadingAction();
    if(counter == 0){
      this.props.history.goBack();
    }else{
      window.location.href = window.location.href.split('#')[0] + '#/loginExpiredPage';
    }
  }

  confirm() {
    this.props.alert.showAlert = false;
    this.props.alert.containerType = '';
    this.props.alert.modalPadding = '';
    this.props.alert.content = '';
    this.props.alert.title = '';
    this.props.alert.alertType = '';
    this.props.alert.functionName = '';
    this.props.alert.validCodeParam = '';
    this.props.alert.confirm = true;
    this.props.UpdateAlert(this.props);
    if(this.props.game != undefined) {
      this.props.game.gameBetObject.game.playtype ? clearBetContent(true,this.props.game.gameBetObject) : resetForm(true,this.props.game.gameBetObject);
      if(this.props.game.gameBetObject.game.playId == 'V6Z' || this.props.game.gameBetObject.game.playId == 'V5Z' || this.props.game.gameBetObject.game.playId == 'D4D'){
        this.props.alert.resetNumber = true;
      }
    }
    if(this.props.alert.betContent != undefined) {
      this.props.alert.betContent.game.playtype ? clearBetContent(true, this.props.alert.betContent) : resetForm(true, this.props.alert.betContent);
    }
    this.props.closeLoadingAction();
  }

  goBackMainPage() {
    this.props.alert.showAlert = false;
    this.props.alert.containerType = '';
    this.props.alert.modalPadding = '';
    this.props.alert.content = '';
    this.props.alert.title = '';
    this.props.alert.alertType = '';
    this.props.alert.functionName = '';
    this.props.alert.validCodeParam = '';
    if(this.props.alert.reloadPage != undefined){
      this.props.alert.reloadPage = true;
    }
    this.props.UpdateAlert(this.props);
    // this.props.history.push('/');
    window.location.href = window.location.href.split('#')[0];
  }

  testPlayConfirm() {
    this.clearPropsAlertInfo();
    this.props.UpdateAlert(this.props);
    showLoginModal();
    this.props.closeLoadingAction();
  }

  safePasswordConfirm() {
    this.clearPropsAlertInfo();
    this.props.UpdateAlert(this.props);
    showSafePasswordModal();
    this.props.closeLoadingAction();
  }

  safePasswordInputConfirm() {
    let safePsdInput=document.getElementById("safePsdInput").value;
    let param = this.props.alert.functionParam;
    let fn = this.props.alert.functionName;
    let safePsdStrParam = this.props.alert.safePsdStrParam;
    this.props.alert.functionName = '';
    let safePsdParam = safePsdStrParam=="" ? 'drawMoneyPwd' : safePsdStrParam;
    param.set(safePsdParam, safePsdInput);
    fn(param);
    return;
  }

  addBankConfirm() {
    this.clearPropsAlertInfo();
    this.props.UpdateAlert(this.props);
    this.props.history.push('/my-bank-add');//show AddBankModal
    this.props.closeLoadingAction();
  }

  clearPropsAlertInfo(){
    this.props.alert.showAlert = false;
    this.props.alert.containerType = '';
    this.props.alert.modalPadding = '';
    this.props.alert.content = '';
    this.props.alert.title = '';
    this.props.alert.alertType = '';
    this.props.alert.functionName = '';
    this.props.alert.validCodeParam = '';
  }

  dmicToken(){
    let dmicTokenInput=document.getElementById("dmicTokenInput").value;
    // let param = this.props.alert.dmicParam;
    let fn = this.props.alert.functionName;
    this.props.alert.functionName = '';

    fn(dmicTokenInput);
    return;
  }

  identifyConfirm(){
    this.clearPropsAlertInfo();
    this.props.UpdateAlert(this.props);
    this.props.history.push('/identity-confirmation');
    this.props.closeLoadingAction();
  }

  clearCache(){
    this.props.clearCacheAction();
    window.location.href = window.location.href.split('#')[0];
  }

  refresh(){
    window.location.href = window.location.href.split('#')[0];
  }

  triggerCancelCallBack(){
    if(typeof(this.props.alert.cancelFunctionName) === 'function'){
      let fn = this.props.alert.cancelFunctionName;
      fn();
      this.close();
      return;
    }
  }

  triggerCallBack() {
    let param;
    if(this.props.alert.functionName === 'alertSendCode'){
      param = this.props.alert.validCodeParam;
      let validCode = document.getElementById('validCode');
      if(validCode){
        param.code = validCode.value;
      }
    }else if(this.props.alert.functionName === 'changeBalanceConfirm'){
      param = this.props.alert.changeBalanceParam;
      let money = document.getElementById('money');
      if(money){
        param.money = money.value;
      }
      let remark = document.getElementById('remark');
      if(remark){
        param.remark = remark.value;
      }
    }else if(typeof(this.props.alert.functionName) === 'function'){
      param = this.props.alert.functionParam;
      let fn = this.props.alert.functionName;
      this.props.alert.functionName = '';
      fn(param);
      this.close();
      return;
    } else if (this.props.alert.functionName === 'subAccountClose') {
      param = this.close.bind(this);
    } else if (this.props.alert.functionName === 'AgentPromotionTab1Save'
      || this.props.alert.functionName === 'AgentPromotionTab2Delete')
    {
      param = {};
      if(this.props.alert.newpromotion){
        param.newpromotion = this.props.alert.newpromotion;
        this.props.alert.newpromotion = param.newpromotion ?? "";
      }
      if(this.props.alert.selectedPickerOption)
        param.selectedPickerOption = this.props.alert.selectedPickerOption;

      callBackFunctionList[this.props.alert.functionName][0](param);
      this.close();
      return;
    }
    callBackFunctionList[this.props.alert.functionName](param);
  }

  inputOnFocus(event) {
    let a = event.currentTarget.parentElement;
    a.classList.add('focus-state');
  }

  inputOnBlur(event) {
    let a = event.currentTarget.parentElement;
    a.classList.remove('focus-state');
  }

  jumpToUrl (){
    let url = this.state.url;
    if(this.isValidHttpUrl(url)){
      if(window.$isIonic){
        Browser.open({ url: url});
      }
      else if(window.$isApp) {
        let plus = window.$plus;
        plus.runtime.openURL(url);
      }else{
        window.open(url);
      }
    }else{
      alert(tran('无效链接'));
    }
  };

  isValidHttpUrl(string) {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  }

  render() {
    const { t } = this.props;
    let contentMsg = this.props.alert.content ? this.props.alert.content : '';
    let live = (window.location.href.includes("/live")?true:false);
    return (
      <>
        {this.props.alert.alertType ?
          <>
            <div className="game-popup-overlay game-alert-overlay"/>
            <div
              className={live?'game-popup-container-live':'game-popup-container'+' game-alert-container ' + (this.props.alert.alertType === 'confirmAlert' ? 'width100' : null)}>
              <div className="game-popup game-alert">

                {this.props.alert.alertType === 'normalAlert' ?
                  <>
                    <div className="apv-title">{t(this.props.alert.title)}</div>
                    {
                      this.props.alert.isContentHtml ?
                       <p dangerouslySetInnerHTML={{ __html: this.props.alert.content }}></p>
                       :
                       contentMsg.includes(":") ? <p>{this.props.alert.content}</p> : <p>{t(this.props.alert.content)}</p>
                    }
                    {this.props.alert.functionName ?
                      <div className="theme-color" style={{ textAlign: 'right', marginTop: 20, fontWeight: 700 ,float:'right'}}
                           onClick={this.triggerCallBack}>{t('Confirm')}
                      </div> :
                      <div className="theme-color" style={{ textAlign: 'right', marginTop: 20, fontWeight: 700 ,float:'right'}}
                           onClick={this.close.bind(this)}>{t('Confirm')}
                      </div>
                    }
                    {this.state.url && this.props.alert.title === t('loginpage.loginfailed') ?
                      <div style={{marginTop: 20, fontWeight: 700 ,paddingLeft:10,paddingRight:10, color: '#fff',borderRadius: 10,float:'right',background: '#D9534F',marginRight:'10px'}}
                           onClick={this.jumpToUrl.bind(this)}>{t('联系客服')}
                      </div>
                      :
                      ""
                    }
                  </>
                  :
                  this.props.alert.alertType === 'longQueenAlert' ?
                  <>
                    <div className="apv-title">{t(this.props.alert.title)}</div>
                    <p dangerouslySetInnerHTML={{ __html: this.props.alert.content }}></p>
                    <div className="theme-color" style={{ textAlign: 'right', marginTop: 20, fontWeight: 700 }}
                         onClick={this.close.bind(this)}>{t('Confirm')}
                    </div>
                  </>
                  :
                  this.props.alert.alertType === 'goBackAlert' ?
                    <>
                      <div className="apv-title">{t(this.props.alert.title)}</div>
                      <p>{t(this.props.alert.content)}</p>
                      <div className="theme-color" style={{ textAlign: 'right', marginTop: 20, fontWeight: 700 }}
                           onClick={this.goback.bind(this)}>{t('Confirm')}
                      </div>
                    </>
                    :
                    this.props.alert.alertType === 'confirmAlert' ?
                      <>
                        <div className="apv-title">{t(this.props.alert.title)}</div>
                        <p dangerouslySetInnerHTML={{ __html: this.props.alert.content }}/>
                        <div className="theme-color" style={{ textAlign: 'right', marginTop: 20, fontWeight: 700 }}>
                          <span style={{ marginRight: 10 }} onClick={this.confirm.bind(this)}>{t('Confirm')}</span>
                          <span onClick={this.close.bind(this)}>{t('Cancel')}</span>
                        </div>
                      </>
                      :
                      this.props.alert.alertType === 'clearCache' ?
                        <>
                          <div className="apv-title">{t(this.props.alert.title)}</div>
                          <p>{t(this.props.alert.content)}</p>
                          <div className="theme-color" style={{ textAlign: 'right', marginTop: 20, fontWeight: 700 }}
                               onClick={this.clearCache.bind(this)}>{t('Confirm')}
                          </div>
                        </>
                        :
                        this.props.alert.alertType === 'refreshAlert' ?
                          <>
                            <div className="apv-title">{t(this.props.alert.title)}</div>
                            <p>{t(this.props.alert.content)}</p>
                            <div className="theme-color" style={{ textAlign: 'right', marginTop: 20, fontWeight: 700 }}
                                 onClick={this.refresh.bind(this)}>{t('Confirm')}
                            </div>
                          </>
                          :
                          this.props.alert.alertType === 'validCodeAlert' ?
                            <>
                              <div className="apv-title">{t(this.props.alert.title)}</div>
                              <p><img src={window.$wgtDomain+'/valid-code?t='+new Date().getTime()} alt=""/></p>
                              <div className="item-input-field"><input type="text" className="modal-text-input" id="validCode"/></div>
                              <div className="theme-color" style={{ textAlign: 'right', marginTop: 20, fontWeight: 700 }}>
                                <span style={{ marginRight: 10 }} onClick={this.triggerCallBack}>{t('Confirm')}</span>
                                <span onClick={this.close.bind(this)}>{t('Cancel')}</span>
                              </div>
                            </>
                            :
                            this.props.alert.alertType === 'testPlayAlert' ?
                            <>
                              <img src={head_images('./testuseralert.png')} style={{height:'100px'}} alt=""/>
                              <p dangerouslySetInnerHTML={{ __html: this.props.alert.content }}/>
                              <div className="theme-color" style={{ textAlign: 'right', marginTop: 20}}>
                                <span style={{ marginRight: 30 }} onClick={this.testPlayConfirm.bind(this)}><b>{t('立即登入/注册')}</b></span>
                                <span onClick={this.close.bind(this)}>{t('Cancel')}</span>
                              </div>
                            </>
                            :
                            this.props.alert.alertType === 'safePasswordAlert' ?
                            <>
                              <div className="apv-title">{t(this.props.alert.title)}</div>
                              <p dangerouslySetInnerHTML={{ __html: this.props.alert.content }}/>
                              <div className="theme-color" style={{ textAlign: 'right', marginTop: 20}}>
                                <span style={{ marginRight: 30 }} onClick={this.close.bind(this)}>{t('Cancel')}</span>
                                <span onClick={this.safePasswordConfirm.bind(this)}>{t('立即设置')}</span>
                              </div>
                            </>
                            :
                            this.props.alert.alertType === 'safePasswordAlertWithInput' ?
                            <>
                              <div className="apv-title">{t(this.props.alert.title)}</div>
                              <p dangerouslySetInnerHTML={{ __html: this.props.alert.content }}/>
                              <div className="item-input-field">
                                <input type="password" placeholder={t('Password')} name="safePsdInput" id="safePsdInput" maxLength="16" minLength="6" className="modal-text-input custom-placeholder" onFocus={this.inputOnFocus} onBlur={this.inputOnBlur} onInput={(event) => event.target.value = event.target.value.replace(/[^\w]/g,'')} />
                              </div>
                              <div className="theme-color" style={{ textAlign: 'right', marginTop: 20}}>
                                <span style={{ marginRight: 30 }} onClick={this.close.bind(this)}>{t('Cancel')}</span>
                                <span style={{ fontWeight: 700 }} onClick={this.safePasswordInputConfirm.bind(this)}>{t('Confirm')}</span>
                              </div>
                            </>
                            :
                            this.props.alert.alertType === 'addBankAlert' ?
                            <>
                              <div className="apv-title">{t(this.props.alert.title)}</div>
                              <p dangerouslySetInnerHTML={{ __html: this.props.alert.content }}/>
                              <div className="theme-color" style={{ textAlign: 'right', marginTop: 20}}>
                                <span style={{ marginRight: 30 }} onClick={this.close.bind(this)}>{t('暂时不去')}</span>
                                <span onClick={this.addBankConfirm.bind(this)}>{t('立即绑定')}</span>
                              </div>
                            </>
                            :
                            this.props.alert.alertType === 'confirmAlertWithFunction' ?
                            <>
                              <div className="apv-title">{t(this.props.alert.title)}</div>
                              <p dangerouslySetInnerHTML={{ __html: this.props.alert.content }}/>
                              <div className="theme-color" style={{ textAlign: 'right', marginTop: 20}}>
                                {this.props.alert.isShowCancelBtn?
                                  <>
                                  {this.props.alert.isReverseBtn?
                                    <>
                                    <span style={{ marginRight: 30 }} onClick={this.close.bind(this)}>{t('Cancel')}</span>
                                    <span style={{ fontWeight:'700'}} onClick={this.triggerCallBack}>{t('Confirm')}</span>
                                    </>
                                    :
                                    <>
                                    <span style={{ marginRight: 30 }} onClick={this.triggerCallBack}>{t('Confirm')}</span>
                                    <span onClick={this.close.bind(this)}>{t('Cancel')}</span>
                                    </>
                                  }
                                  </>
                                  :
                                  <span onClick={this.triggerCallBack}>{t('Confirm')}</span>
                                }
                              </div>
                            </>
                            :
                              this.props.alert.alertType === 'changeBalanceAlert' ?
                              <>
                                <div className="apv-title">{t(this.props.alert.title)}</div>
                                <div className="input-field modal-input-double" style={{borderBottom:'1px solid lightgray'}}>
                                  <input type="text" id="money" name="money" placeholder={t(this.props.alert.content)} className="modal-text-input" />
                                </div>
                                <div className="input-field modal-input-double" style={{borderBottom:'1px solid lightgray'}}>
                                  <input type="text" id="remark" name="remark" placeholder={t("备注")} className="modal-text-input" />
                                </div>
                                <div className="theme-color" style={{ textAlign: 'right', marginTop: 20}}>
                                  <span style={{ marginRight:30 }} onClick={this.close.bind(this)}>{t('Cancel')}</span>
                                  <span style={{ fontWeight:'700'}} onClick={this.triggerCallBack}>{t('Confirm')}</span>
                                </div>
                              </>
                              :
                                this.props.alert.alertType === 'subAccountAlert' ?
                                <>
                                  <div className="apv-title">{t(this.props.alert.title)}</div>
                                  <div style={{textAlign: 'center'}}>
                                    <img src={head_images('./ico-success.png')} style={{width: "64px", height:"64px"}} alt=""/><br/>
                                    {t('开户成功,')} <a onClick={this.triggerCallBack} style={{color: '#2044f9'}}>{t('点击复制并关闭')}</a><br/>
                                    <span id="info" style={{textAlign: 'left'}}>
                                      <p>{t('平台帐号：')}{this.props.alert.data.account}</p>
                                      <p>{t('密码：')}{this.props.alert.data.password}</p>
                                      <p>{t('登入网址：')}http://{this.props.alert.data.url}</p>
                                    </span>
                                  </div>
                                  <div className="theme-color" style={{ textAlign: 'right', marginTop: 20}}>
                                    <span style={{ fontWeight:'700'}} onClick={this.close.bind(this)}>{t('Confirm')}</span>
                                  </div>
                                </>
                                : this.props.alert.alertType === 'onlinePayAlert' ?
                                  <>
                                    <div className="apv-title">{t(this.props.alert.title)}</div>
                                    <p dangerouslySetInnerHTML={{ __html: this.props.alert.content }}/>
                                    <div className="theme-color" style={{ textAlign: 'right', marginTop: 20}}>
                                      <span style={{ marginRight: 30 }} onClick={this.refresh.bind(this)}>{t('已完成充值')}</span>
                                      <span style={{ fontWeight:'700'}} onClick={this.triggerCallBack}>{t('充值遇到问题')}</span>
                                    </div>
                                  </>
                                  :
                                  this.props.alert.alertType === 'repeatGameStop' ?
                                  <>
                                    <p dangerouslySetInnerHTML={{ __html: this.props.alert.content }}/>
                                    <div className="theme-color" style={{ textAlign: 'right', marginTop: 20}}>
                                      <span style={{ marginRight: 30 }} onClick={this.close.bind(this)}>{t('Confirm')}</span>
                                    </div>
                                  </>:
                                  this.props.alert.alertType === 'doubleVerify' ?
                                  <>
                                    <div className="apv-title">{t(this.props.alert.title)}</div>
                                    <p dangerouslySetInnerHTML={{ __html: this.props.alert.content }} style={{ color: 'var(--theme-color)', marginTop: 20,position: 'relative',bottom: '25px'}}/>
                                    <input type="text" placeholder={t("psdconterpage.enterencrypt")} name="dmicTokenInput" id="dmicTokenInput" maxLength="6" minLength="6" className="modal-text-input custom-placeholder"
                                    style={{backgroundColor:'#f2f2f2',position: 'relative',bottom: '32px'}}/>

                                  <div style={{ display: 'inline-block', marginLeft: '20px', width: '40%', padding:'5px',color: 'var(--theme-color)',backgroundColor: 'white',borderRadius: '6px',border: 'solid 2px var(--theme-color)' }}>
                                    <span style={{ marginRight: 1 }} onClick={this.close.bind(this)}>{t('Cancel')}</span>
                                    </div>
                                    <div className="theme-background-color" style={{ display: 'inline-block', marginLeft: '20px', width: '40%',border: 'transparent',color: 'white',backgroundColor: 'red',padding: '5px',borderRadius: '6px' }}>
                                    <span style={{ fontWeight: 1 }} onClick={this.dmicToken.bind(this)}>{t('Confirm')}</span>
                                    </div>
                                  </>
                                  :
                                  this.props.alert.alertType === 'identityConfirmation' ?
                                  <>
                                    <div><p dangerouslySetInnerHTML={{ __html: t("温馨提醒") }} style={{marginTop: '0px'}}/></div>
                                    <div><p dangerouslySetInnerHTML={{ __html: this.props.alert.content }} style={{margin: '20px 0px'}}/></div>
                                    <div style={{ display: 'inline-block', marginLeft: '20px', width: '40%', padding:'5px',color: 'var(--theme-color)',backgroundColor: 'white', }}>
                                    <span style={{ marginLeft: '80px', whiteSpace: 'nowrap' }} onClick={this.close.bind(this)}>{t('Cancel')}</span>
                                    </div>
                                    <div style={{ display: 'inline-block', marginLeft: '25px', width: '40%',border: 'transparent',color: 'var(--theme-color)',backgroundColor: 'white !important',padding: '5px' }}>
                                    <span style={{ fontWeight: 1 }} onClick={this.identifyConfirm.bind(this)}>{t('Confirm')}</span>
                                  </div>
                                  </>
                                  :
                                  this.props.alert.alertType === 'confirmAlertWithBothFunction' ?
                                  <>
                                    <div className="apv-title">{t(this.props.alert.title)}</div>
                                    <p dangerouslySetInnerHTML={{ __html: this.props.alert.content }}/>
                                    <div className="theme-color" style={{ textAlign: 'right', marginTop: 20}}>
                                      {this.props.alert.isShowCancelBtn?
                                        <>
                                        {this.props.alert.isReverseBtn?
                                          <>
                                          <span style={{ marginRight: 30 }} onClick={this.triggerCancelCallBack}>{t('Cancel')}</span>
                                          <span style={{ fontWeight:'700'}} onClick={this.triggerCallBack}>{t('Confirm')}</span>
                                          </>
                                          :
                                          <>
                                          <span style={{ marginRight: 30 }} onClick={this.triggerCallBack}>{t('Confirm')}</span>
                                          <span onClick={this.triggerCancelCallBack}>{t('Cancel')}</span>
                                          </>
                                        }
                                        </>
                                        :
                                        <span onClick={this.triggerCallBack}>{t('Confirm')}</span>
                                      }
                                    </div>
                                  </>
                                  :
                                  this.props.alert.alertType === "sucessConfirmation" ?
                                  <>
                                    <img style={{ width: '80px'}} src={this.props.alert.imageURL}/>
                                    <div style={{ fontSize: '30px', fontWeight: 'bold', marginTop: '15px'}}>{this.props.alert.content}</div>
                                    <button style={{ marginTop: '15px', backgroundColor: '#6595de', color: 'white', height: '40px', width: '150px', borderRadius: '6px'}} onClick={this.close.bind(this)}>{t('Confirm')}</button>
                                  </>
                                  :
                                  this.props.alert.alertType === "failConfirmation" ?
                                  <>
                                    <img style={{ width: '80px'}} src={this.props.alert.imageURL}/>
                                    <div style={{ fontSize: '30px', fontWeight: 'bold', marginTop: '15px'}}>{this.props.alert.title}</div>
                                    <div style={{ fontSize: '15px', marginTop: '15px'}}>{this.props.alert.content}</div>
                                    <button style={{ marginTop: '15px', backgroundColor: '#6595de', color: 'white', height: '40px', width: '150px', borderRadius: '6px'}} onClick={this.close.bind(this)}>{t('Confirm')}</button>
                                  </>
                                  :
                                  this.props.alert.alertType === "rtpConfirmation" ?
                                  <>
                                    <div style={{ fontSize: '15px'}}>{t(this.props.alert.title)}: {this.props.alert.content}</div>
                                    <button style={{ marginTop: '15px', backgroundColor: '#202020', color: 'white', height: '40px', width: '150px', borderRadius: '3px'}} onClick={this.close.bind(this)}>{t('Confirm')}</button>
                                  </>
                                  :
                                  null
                }
              </div>
            </div>
          </>
          : null
        }
        {/*检查是否登录专用的alert*/}
        {this.props.alert.alert ?
          <>
            {this.props.alert.alert.showAlert ?
              <>
                <div className="game-popup-overlay game-alert-overlay"/>
                 <div className={live?'game-popup-container-live':'game-popup-container'+' game-alert-container'}>
                  <div className="game-popup game-alert">
                    <div className="apv-title">{t(this.props.alert.alert.title)}</div>
                    <p>{t(this.props.alert.alert.content)}</p>
                    <div className="theme-color" style={{ textAlign: 'right', marginTop: 20, fontWeight: 700 }}
                         onClick={this.goBackMainPage.bind(this)}>{t('Confirm')}
                    </div>
                  </div>
                </div>
              </>
              :
              null
            }
          </>
          :
          null
        }
      </>
    );
  }
}

const ConnectedAlertModal = connect(mapStateToProps, mapDispatchToProps)(AlertModal);
export default withRouter(withTranslation('common')(ConnectedAlertModal));
