import React from 'react';
import { Modal } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import Keyboard from 'react-simple-keyboard';
import '../../styles/index.css';

class SimpleNumberPickerModal extends React.Component {

  state = {
    numberPickerValue:[1,2,3,4,5,6,7,8,9],
    pickInputValue: '',
  };

  // constructor(props) {
  //   super(props);
  // }

  // componentDidMount() {

  // }



  render() {
    const { t } = this.props;
    let decimalPoint = this.props.currency==="R$"?",":".";
    return (
        <Modal isOpen={this.props.show} style={{height:'auto'}} wrapClassName="number-picker">
            <div className="theme-background-color" style={{width:'100%',height:'48px'}}>
              <div style={{textAlign:'center', position: 'absolute',width: '100%',justifyContent: 'center',display: 'flex',lineHeight:'48px',color:'white',fontSize: '20px'}}>
                <div className={'noselect'}>{this.props.value}</div>
              </div>
              <div style={{position:'absolute',right:'15px',}} className={'noselect'}>
                <div style={{fontSize:14,lineHeight:'48px',height:'48px',color:'white',}} className={'noselect'} onClick={this.props.complete}>{t('complete')}</div>
              </div>
            </div>
            <Keyboard
              onKeyPress={this.props.selectPickNum}
              theme={"hg-theme-default hg-layout-default"}
              layout= {{
                default: ["1 2 3", "4 5 6", "7 8 9", decimalPoint+" 0 {bksp}"]
              }}
              display= {{
                '{bksp}': '<',
                '.': this.props.isDecimalPlace?decimalPoint:' '
              }}
              buttonAttributes={[
                {
                  attribute: "numberpickershow",
                  value: "true",
                  buttons: "."
                },
                {
                  attribute: "numberpickershow",
                  value: "true",
                  buttons: "0"
                },
                {
                  attribute: "numberpickershow",
                  value: "true",
                  buttons: "1"
                },
                {
                  attribute: "numberpickershow",
                  value: "true",
                  buttons: "2"
                },
                {
                  attribute: "numberpickershow",
                  value: "true",
                  buttons: "3"
                },
                {
                  attribute: "numberpickershow",
                  value: "true",
                  buttons: "4"
                },
                {
                  attribute: "numberpickershow",
                  value: "true",
                  buttons: "5"
                },
                {
                  attribute: "numberpickershow",
                  value: "true",
                  buttons: "6"
                },
                {
                  attribute: "numberpickershow",
                  value: "true",
                  buttons: "7"
                },
                {
                  attribute: "numberpickershow",
                  value: "true",
                  buttons: "8"
                },
                {
                  attribute: "numberpickershow",
                  value: "true",
                  buttons: "9"
                },
                {
                  attribute: "numberpickershow",
                  value: "true",
                  buttons: "{bksp}"
                },
              ]}
              buttonTheme={[
                {
                  class: "noselect",
                  buttons: "0 1 2 3 4 5 6 7 8 9 "+decimalPoint+" {bksp}"
                }
              ]}
            /> 
            {/* <div className="picker-modal-inner picker-keypad-buttons"> */}
              {/* {this.state.numberPickerValue.map((item,index)=> {
                return(
                  <span className="picker-keypad-button" key={index} value={item} onClick={this.props.selectPickNum}>
                       <span className="picker-keypad-button-number">{item}</span>
                  </span>
                )
              })}
              {
                this.props.isDecimalPlace?
                <span className="picker-keypad-button picker-keypad-button-dark picker-keypad-dummy-button" value={"."} onClick={this.props.selectPickNum}>
                    <span className="picker-keypad-button-number">.</span>
                </span>
                :
                <span className="picker-keypad-button picker-keypad-button-dark picker-keypad-dummy-button"/>
              } */}
              {/* <span className="picker-keypad-button picker-keypad-button-dark picker-keypad-dummy-button"/> */}
              {/* <span className="picker-keypad-button" value={"0"} onClick={this.props.selectPickNum}>
                    <span className="picker-keypad-button-number">0</span>
              </span>
              <span className="picker-keypad-button picker-keypad-button-dark picker-keypad-delete" onClick={this.props.deletePickNum}>
                    <i className="icon icon-keypad-delete"/>
               </span>
            </div> */}
        </Modal>
    );

  };
}

export default withTranslation('common')(SimpleNumberPickerModal);