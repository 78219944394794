import React from 'react';
import { Button, Nav, Navbar, NavItem } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import bn from 'utils/bemnames';
import { MdArrowBack } from 'react-icons/md';
import { store } from '../../index';
import { saveJumpObj } from '../../actions/HomePageAction';

const bem = bn.create('header');

class HeaderWithBack extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      toggleEvent: Date.now(),
      condition: false
    };
  }

  goBack = () => {
    let dataObj = store.getState().indexData.jumpObj;
    dataObj.openPromo = null;
    store.dispatch(saveJumpObj(dataObj));

    this.props.history.goBack();
  };

  render() {
    let iosVersion = 0;
    if(window.$isIonic){
      if(window.$platform === 'IOS') {
        iosVersion = window.$platformVersion.split('.')[0];
      }
    }
    else if (window.$isApp){
      let plus = window.$plus;
      if(plus.os.name.toUpperCase() == 'IOS') {
        iosVersion = plus.os.version.split('.')[0];
      }
    }
    return (
      <div>
      <Navbar className="theme-background-color" light expand style={parseInt(iosVersion) < 16?{paddingLeft:0,paddingRight:0}:{paddingLeft:0,paddingRight:0,position:'-webkit-sticky'}}>
        <Nav navbar className={bem.e('nav-left')} style={{position:'absolute'}}>
          <NavItem className="mr-2" style={{flexDirection:'row'}}>
            <Button outline style={{outline:'none',border:0,color: '#fff'}} onClick={this.goBack}>
              <MdArrowBack size={24} />
            </Button>
            {this.props.backText ? <span style={{top:3,position:'relative',color:'white'}}>{this.props.backText}</span> : null}
          </NavItem>
        </Nav>

        <Nav navbar className={bem.e('nav-left')} style={{margin:'auto'}}>
          <NavItem>
            <p className="p text-white top-title" style={{fontSize:'14px'}}>{this.props.headerName}</p>
          </NavItem>
        </Nav>
      </Navbar>
      </div>
    );
  }
}

export default withTranslation('common')(HeaderWithBack);
