import React from 'react';
import { connect } from 'react-redux';
import { Translation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { SaveMemberInfo } from '../actions/HomePageAction';
import { Component } from 'react'
import { store } from '../index';
import GameFetcher from '../components/GameFetcher';
import language from '../assets/image/sideMenuIcon/language.png';
import { Col, Row } from 'reactstrap';

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    SaveMemberInfo,
  }, dispatch)
);

const mapStateToProps = (state) => {
  return { memberInfo: state.memberInfo };
};

function onChange(lng, i18n) {
  let memberObj = store.getState().memberInfo.MemberInfo;
  i18n.changeLanguage(lng);
  memberObj.language = lng == "cn"?"zh":lng;
  store.dispatch(SaveMemberInfo(memberObj));
  GameFetcher(window.$gameUrl + '/rest/member/changelang?lang=' + lng, {
    method: 'POST',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    // credentials: 'include',
  }).then((response) => response.json())
    .then((response) => {
      window.location.href = window.location.href.split('#')[0];
    })
    .catch((error) => {
      console.error(error);
    });
}

class LanguageSwitcher extends Component{
  render() {
    let sysLang = this.props.memberInfo.MemberInfo.systemLanguage ;
    if (sysLang == undefined) {
      sysLang = ['zh'];
    }
    return (
      <Translation ns={['common']} wait={true}>
        {(t, { i18n }) => (
          <>
            <Row className='sideMenu-selection-row'>
              <Col xs="1" className='sideMenu-selection-icon'><img src={language} alt="" style={{width:'30px'}}/></Col>
              <Col className='sideMenu-selection-row-title'>{t('language')}</Col>
            </Row>
            <div style={{ background: '#ffff' }}>
              { sysLang.some(s => s === 'zh') ?

                this.props.memberInfo.MemberInfo.language == "zh" ?
                    <button style={{ margin: '5px', width: '75px' }} className="langBtnActive" defaultValue={i18n.language} value="cn" onClick={e => onChange("cn", i18n)}>中文 </button>
                    :
                    <button style={{ margin: '5px', width: '75px' }} className="langBtn" defaultValue={i18n.language} value="cn" onClick={e => onChange("cn", i18n)}>中文 </button>

                :
                <></>
              }
              { sysLang.some(s => s === 'en') ?
                this.props.memberInfo.MemberInfo.language == "en" ?
                    <button value="en" defaultValue={i18n.language} style={{ margin: '5px', width: '75px' }}
                            className="langBtnActive" onClick={e => onChange("en", i18n)}>EN</button>
                    :
                    <button value="en" defaultValue={i18n.language} style={{ margin: '5px', width: '75px' }}
                            className="langBtn" onClick={e => onChange("en", i18n)}>EN</button>

                :
                <></>
              }
              { sysLang.some(s => s === 'th') ?
                this.props.memberInfo.MemberInfo.language == "th" ?
                    <button value="th" defaultValue={i18n.language} style={{ margin: '5px', width: '75px' }}
                            className="langBtnActive" onClick={e => onChange("th", i18n)}>TH</button>
                    :
                    <button value="th" defaultValue={i18n.language} style={{ margin: '5px', width: '75px' }}
                            className="langBtn" onClick={e => onChange("th", i18n)}>TH</button>

                :
                <></>
              }
              { sysLang.some(s => s === 'in') ?
                this.props.memberInfo.MemberInfo.language == "in" ?
                    <button value="in" defaultValue={i18n.language} style={{ margin: '5px', width: '75px' }}
                            className="langBtnActive" onClick={e => onChange("in", i18n)}>IN</button>
                    :
                    <button value="in" defaultValue={i18n.language} style={{ margin: '5px', width: '75px' }}
                            className="langBtn" onClick={e => onChange("in", i18n)}>IN</button>

                :
                <></>
              }                              
              { sysLang.some(s => s === 'vi') ?
                this.props.memberInfo.MemberInfo.language == "vi" ?
                    <button value="vi" defaultValue={i18n.language} style={{ margin: '5px', width: '75px' }}
                            className="langBtnActive" onClick={e => onChange("vi", i18n)}>VN</button>
                    :
                    <button value="vi" defaultValue={i18n.language} style={{ margin: '5px', width: '75px' }}
                            className="langBtn" onClick={e => onChange("vi", i18n)}>VN</button>

                :
                <></>
              }	
              { sysLang.some(s => s === 'id') ?
                this.props.memberInfo.MemberInfo.language == "id" ?
                  <button value="id" defaultValue={i18n.language} style={{ margin: '5px', width: '75px' }}
                        className="langBtnActive" onClick={e => onChange("id", i18n)}>ID</button>
                  :
                  <button value="id" defaultValue={i18n.language} style={{ margin: '5px', width: '75px' }}
                        className="langBtn" onClick={e => onChange("id", i18n)}>ID</button>

                :
                <></>
              }
              { sysLang.some(s => s === 'pt') ?
                this.props.memberInfo.MemberInfo.language == "pt" ?
                  <button value="pt" defaultValue={i18n.language} style={{ margin: '5px', width: '75px' }}
                          className="langBtnActive" onClick={e => onChange("pt", i18n)}>PT</button>
                  :
                  <button value="pt" defaultValue={i18n.language} style={{ margin: '5px', width: '75px' }}
                          className="langBtn" onClick={e => onChange("pt", i18n)}>PT</button>

                :
                <></>
              }
              { sysLang.some(s => s === 'es') ?
                this.props.memberInfo.MemberInfo.language == "es" ?
                    <button value="es" defaultValue={i18n.language} style={{ margin: '5px', width: '75px' }}
                            className="langBtnActive" onClick={e => onChange("es", i18n)}>ES</button>
                    :
                    <button value="es" defaultValue={i18n.language} style={{ margin: '5px', width: '75px' }}
                            className="langBtn" onClick={e => onChange("es", i18n)}>ES</button>

                :
                <></>
              }              
              { sysLang.some(s => s === 'fr') ?
                this.props.memberInfo.MemberInfo.language == "fr" ?
                    <button value="fr" defaultValue={i18n.language} style={{ margin: '5px', width: '75px' }}
                            className="langBtnActive" onClick={e => onChange("fr", i18n)}>FR</button>
                    :
                    <button value="fr" defaultValue={i18n.language} style={{ margin: '5px', width: '75px' }}
                            className="langBtn" onClick={e => onChange("fr", i18n)}>FR</button>

                :
                <></>
              }
              { sysLang.some(s => s === 'ru') ?
                this.props.memberInfo.MemberInfo.language == "ru" ?
                    <button value="ru" defaultValue={i18n.language} style={{ margin: '5px', width: '75px' }}
                            className="langBtnActive" onClick={e => onChange("ru", i18n)}>RU</button>
                    :
                    <button value="ru" defaultValue={i18n.language} style={{ margin: '5px', width: '75px' }}
                            className="langBtn" onClick={e => onChange("ru", i18n)}>RU</button>

                :
                <></>
              }
            </div>
          </>
        )}
      </Translation>
    );
  }
}

const ConnectedLanguage = connect(mapStateToProps, mapDispatchToProps)(LanguageSwitcher);
export default ConnectedLanguage;
