import { store } from '../index';

function updateOptions(options) {
    const update = { ...options };
    if(store.getState().memberInfo.token!==undefined && store.getState().memberInfo.token!==null) {
      update.headers = {
        ...update.headers,
        gaiatoken: store.getState().memberInfo.token,
      };
    }
    return update;
  }
  
  export default function Fetcher(url, options) {
    if(store.getState().memberInfo.token!==undefined && store.getState().memberInfo.token!==null) {
      if(url.indexOf('?')!=-1){
        url = url+"&gaiatoken="+store.getState().memberInfo.token;
      }
      else{
        url = url+"?gaiatoken="+store.getState().memberInfo.token;
      }
    }
    return fetch(url, updateOptions(options));
  }