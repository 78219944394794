import { saveIndexInit, SaveMemberInfo, SetAccountConfigsDefault } from '../actions/HomePageAction';
import { store } from '../index';
import i18next from 'i18next';
import { alert, refreshAlert, alertWithFunction, validCodeAlert, closeAlert } from './AlertService';
import Fetcher from '../components/Fetcher';
import GameFetcher from '../components/GameFetcher';
import { closeRegisterModal ,closeLoginModal} from './HomeService';
import { Device } from '@capacitor/device';
import {trackEvent} from '@adjustcom/adjust-web-sdk';
import { Storage } from '@capacitor/storage';
import {AppsFlyer} from "appsflyer-capacitor-plugin";

const tran = (input) => {
  return i18next.t(input);
}

export const isNotEmpty = function(value){
  return value && true&&value.trim()!=="";
}

export const isEmpty = function(value){
  return !isNotEmpty(value);
}

export const validateLoginName = function(event){
  let a = event.currentTarget.parentElement.nextSibling;
  let inputValue = event.target.value;
  if(isNotEmpty(inputValue) && inputValue.length>=6&&inputValue.length<=14 && !/[^a-z0-9]/.test(inputValue)){

    const data = new URLSearchParams();
    data.append('loginName', inputValue);
    let memberInfo = store.getState().memberInfo;
    let langid = memberInfo.MemberInfo.langid?memberInfo.MemberInfo.langid:'';
    Fetcher(window.$wgtDomain + "/memreg/validonly-loginname?langid="+langid,{
      method: "POST",
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      // credentials: 'include',
      body:data,
      responseType:"json",
      referrer: 'h5'
    }).then((response) => response.json())
      .then((response) => {
        if(response.status) {
          a.style.display = "none";
          return true;
        }else{
          a.innerHTML = response.content;
          a.style.display = "block";
          return false;
        }
      })
  }else{
    a.innerHTML = tran("帐号为6-14个字元的小写英文字母或数字！");
    a.style.display = "block";
    return false;
  }
};
export const validateLoginPwd = function(event){
  let a = event.currentTarget.parentElement.nextSibling;
  let inputValue = event.target.value;
  if(isNotEmpty(inputValue)){
    if(inputValue.trim() === document.getElementById('regusername').value){
      a.innerHTML = tran("密码不能与帐号相同！");
      a.style.display = "block";
      return false;
    }else if(!/^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)[0-9A-Za-z]+$/.test(inputValue)){
      a.innerHTML = tran("密码必须是英文字母与数字的组合！");
      a.style.display = "block";
      return false;
    }else if(inputValue.length >= 6 && inputValue.length <= 16 && /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)[0-9A-Za-z]{6,16}$/.test(inputValue)){
      a.style.display = "none";
      return true;
    }else{
      let msg = inputValue.length < 6 ? tran("密码长度不能少于6个字元"):tran("须为6～16个英文字母与数字的组合！");
      a.innerHTML = msg;
      a.style.display = "block";
      return false;
    }
  }else{
    a.innerHTML = tran("密码长度不能少于6个字元");
    a.style.display = "block";
    return false;
  }
};

export const validatePhoneLoginPwd = function(event){
  let a = event.currentTarget.parentElement.nextSibling;
  let inputValue = event.target.value;
  if(isNotEmpty(inputValue)){
    if(!/^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)[0-9A-Za-z]+$/.test(inputValue)){
      a.innerHTML = tran("密码必须是英文字母与数字的组合！");
      a.style.display = "block";
      return false;
    }else if(inputValue.length >= 6 && inputValue.length <= 16 && /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)[0-9A-Za-z]{6,16}$/.test(inputValue)){
      a.style.display = "none";
      return true;
    }else{
      let msg = inputValue.length < 6 ? tran("密码长度不能少于6个字元"):tran("须为6～16个英文字母与数字的组合！");
      a.innerHTML = msg;
      a.style.display = "block";
      return false;
    }
  }else{
    a.innerHTML = tran("密码长度不能少于6个字元");
    a.style.display = "block";
    return false;
  }
};

export const validateCheckPwd = function(event){
  let a = event.currentTarget.parentElement.nextSibling;
  let inputValue = event.target.value;
  if(inputValue.trim() !== document.getElementById('loginPwd').value){
    a.innerHTML = tran("确认密码错误！请重新输入！");
    a.style.display = "block";
    return false;
  }else{
    a.style.display = "none";
    return true;
  }
};

export const validateCheckPhonePwd = function(event){
  let a = event.currentTarget.parentElement.nextSibling;
  let inputValue = event.target.value;
  if(inputValue.trim() !== document.getElementById('phoneLoginPwd').value){
    a.innerHTML = tran("确认密码错误！请重新输入！");
    a.style.display = "block";
    return false;
  }else{
    a.style.display = "none";
    return true;
  }
};

export const validateRealName = function(event){
  let a = event.currentTarget.parentElement.nextSibling;
  let inputValue = event.target.value;
  let regVerify = store.getState().modal.registerModal.result;
  if(isEmpty(inputValue)) {
    a.innerHTML = tran("请输入真实姓名!");
    a.style.display = "block";
    return false;
  }
  if(inputValue!==""&&!/^.{2,60}$/.test(inputValue)){
    a.innerHTML = tran("真实姓名格式为2-60字");
    a.style.display = "block";
    return false;
  }
  else{
    if(regVerify.valrealnameOpen===1){
      const data = new URLSearchParams();
      data.append('realname', inputValue);
      Fetcher(window.$wgtDomain + "/memreg/validonly-realname",{
        method: "POST",
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        // credentials: 'include',
        body:data,
        responseType:"json",
        referrer: 'h5'
      }).then((response) => response.json())
        .then((response) => {
          if(response.status) {
            a.style.display = "none";
            return true;
          }else{
            a.innerHTML = response.content;
            a.style.display = "block";
            return false;
          }
      })
    } else {
      a.innerHTML = "";
      a.style.display = "none";
      return true;
    }
  }
};

export const validateCode = function(event){
  let validCodeImg = document.getElementById('validCodeImg');
  validCodeImg.setAttribute("src" , window.$wgtDomain+'/valid-code?_t=' + event);
  validCodeImg.style.display = "block";

  let getValidCodeLabel = document.getElementById('getValidCode');
  getValidCodeLabel.style.display = "none";
};

export const  validateNickname = function(event){
  let a = event.currentTarget.parentElement.nextSibling;
  let inputValue = event.target.value;
  if(isEmpty(inputValue)){
    a.innerHTML =  tran("请输入昵称!");
    a.style.display = "block";
    return false;
  }
  if (inputValue !== '' && !/^.{2,30}$/.test(inputValue)) {
    a.innerHTML =  tran("昵称格式为 2-30 字");
    a.style.display = "block";
    return false;
  }
  a.style.display = "none";
  return true;
};

export const validatePhoneWap = function(event) {
  let a = event.parentElement.nextSibling;
  let inputValue = event.value;
  let regVerify = store.getState().modal.registerModal.result;
  let phoneCodeId = document.getElementById('phoneCodeId');
  if (isEmpty(inputValue)) {
    a.innerHTML = tran('请输入手机号');
    a.style.display = 'block';
    if (phoneCodeId)
      phoneCodeId.style.display = 'none';
    return false;
  }
  if (!/^\d{9,15}$/.test(inputValue)) {
    a.innerHTML = tran('格式需为国际区号+手机号！');
    a.style.display = 'block';
    if (phoneCodeId)
      phoneCodeId.style.display = 'none';
    return false;
  }
  if(regVerify.authRegisterMember.phoneNumberSendVerify===1 && inputValue.length >= 9){
    if (phoneCodeId)
      phoneCodeId.style.display = 'block';
  }
  a.innerHTML = '';
  a.style.display = 'none';
  return true;
};

export const validateEmailWap = function(event) {
  //console.log(event);
  let a;
  let inputValue;
  if(event.currentTarget){
    a = event.currentTarget.parentElement.nextSibling;
    inputValue = event.target.value;
  }else{
    a = event.parentElement.nextSibling;
    inputValue = event.value;
  }

  let regVerify = store.getState().modal.registerModal.result;
  let emailCode = document.getElementById('emailCode');
  if (isEmpty(inputValue)) {
    a.innerHTML = tran('请输入邮箱地址！');
    a.style.display = 'block';
    if (emailCode)
      emailCode.style.display = 'none';
    return false;
  }
  if (!/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(inputValue)) {
    a.innerHTML = tran('您输入的邮箱格式不正确!');
    a.style.display = 'block';
    if (emailCode)
      emailCode.style.display = 'none';
    return false;
  }
  a.innerHTML = '';
  a.style.display = 'none';
  if(event.target)
    if(event.target.getAttribute('id') === 'emailInfo'){
      if(regVerify.authRegisterMember.emailSendVerify===1 && inputValue.length>=5){
        if (emailCode)
          emailCode.style.display = 'block';
      }
    }
    return true;
};

export const validateDrawMoneyPwd = function(event) {
  let a = event.currentTarget.parentElement.nextSibling;
  let inputValue = event.target.value;
  if (isEmpty(inputValue)) {
    a.innerHTML =  tran('请输入安全密码！');
    a.style.display = 'block';
    return false;
  }
  if(inputValue.length >= 6 && inputValue.length <= 16 && /^[0-9A-Za-z]{6,16}$/.test(inputValue)){
    a.style.display = 'none';
    return true;
  }
  else{
    a.innerHTML = tran("安全密码必须由6～16位英文字母或数字组成");
    a.style.display = 'block';
    return false;
  }
};

export const regBtn = function(regVerify){

  GameFetcher(window.$gameUrl + "/rest/user/login-out", {
    method: "GET",
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    // credentials: 'include',
  })
  .then(res => res.json())
    .then((res) => {
  })
    .catch((error) => {
      console.error(error);
  })

  let keyCookieValue='';
  if (regVerify.authRegisterMember.sponsorRequired === 1) {
    if (document.getElementById('regKeyCookie')) {
      if (document.getElementById('regKeyCookie').value) {
        alert(tran('请填写邀请码'),tran('MSG.T_00'));
        return false;
      }
    }
    if(document.getElementById('regKeyCookie'))
      keyCookieValue = document.getElementById('regKeyCookie').getAttribute('value');
  }
  if (document.getElementById('regKeyInput')) {
    if (document.getElementById('regKeyInput').value) {
      let cookieValue = document.getElementById('regKeyInput').value;
      if (cookieValue.length > 9) {
        alert(tran('邀请码格式不正确'),tran('MSG.T_00'));
        return false;
      }
      keyCookieValue = cookieValue;
    }
  }
  //console.log(document.getElementById('regusername'));
  if(document.getElementById('regusername').value ===""){
    alert(tran('账号名格式错误'),tran('MSG.T_00'));
    return false;
  }
  // if(document.getElementById('realName'))
  //   if(document.getElementById('realName').value && validateRealName(document.getElementById('realName'))===false) return false;
  // if(document.getElementById('phoneInfo'))
  //   if(document.getElementById('phoneInfo').value && validatePhoneWap(document.getElementById('phoneInfo'))===false) return false;
  // if(document.getElementById('emailInfo'))
  //   if(document.getElementById('emailInfo').value && validateEmailWap(document.getElementById('emailInfo'))===false) return false;

  if(document.getElementById('loginPwd').value !== document.getElementById('checkLoginPwd').value){
    alert(tran('确认密码要与密码一致!'),tran('MSG.T_00'));
    return false;
  }

  if(document.getElementById('loginPwd').value.length < 6){
    alert(tran('密码长度不能少于6个字元!'),tran('MSG.T_00'));
    return false;
  }

  if (regVerify.authRegisterMember.phoneNumberSendVerify === 1 && document.getElementById('phoneInfo').value && !document.getElementById('phoneCode').value) {
    alert(tran('请填写手机验证码'),tran('MSG.T_00'));
    return false;
  }

  if (regVerify.authRegisterMember.emailSendVerify === 1 && document.getElementById('emailInfo').value && !document.getElementById('emailCode').value) {
    alert(tran('请填写邮箱验证码'),tran('MSG.T_00'));
    return false;
  }

  if(!document.getElementById('validCode').value){
    alert(tran('请填写验证码'),tran('MSG.T_00'));
    return false;
  }

  if (regVerify.authRegisterMember.accountProtocolOpen === 1) {
    if(!document.getElementById("protoco").checked){
      alert(tran('请勾选接受相关条款和隐私协议!'),tran('MSG.T_00'));
      return false;
    }
  }
  let protocolCheck;
  if (document.getElementById('protoco')){
    if (document.getElementById('protoco').checked) {
      protocolCheck = 1;
    }else {
      protocolCheck = 0;
    }
  }else {
    protocolCheck = 0;
  }


  let isDisable = false;
  let disabled = false;
  if (document.getElementById('regKeyCookie')) {
    if (document.getElementById('regKeyCookie').value) {
      if (document.getElementById('regKeyCookie').disabled) {
        isDisable = true;
        let i;
        let keyCookieInput = document.getElementsByClassName('keyCookieInput');
        for (i = 0; i < keyCookieInput.length; i++) {
          keyCookieInput[i].disabled = false;
        }
        disabled = true;
      }
    }
  }

  let form = document.querySelector('#regForm');
  let data = new URLSearchParams();//new FormData(form)).toString();

  data.set('protocolCheck', protocolCheck);
  data.set('keyCookie', keyCookieValue);
  data.set('loginName', document.getElementById('regusername')?.value);
  data.set('loginPwd', document.getElementById('loginPwd')?.value);
  data.set('confirmLoginPwd', document.getElementById('checkLoginPwd')?.value);
  data.set('validCode', document.getElementById('validCode')?.value)
  data.set('realName', document.getElementById('realName')?.value == undefined? "": document.getElementById('realName')?.value);
  data.set('nickName', document.getElementById('nickName')?.value == undefined? "": document.getElementById('nickName')?.value);
  data.set('birthday', document.getElementById('birthday')?.value == undefined? "":document.getElementById('birthday')?.value);
  data.set('phone', document.getElementById('phoneInfo')?.value == undefined? "":document.getElementById('phoneInfo')?.value);
  data.set('drawMoneyPwd', document.getElementById('drawMoneyPwd')?.value == undefined? "":document.getElementById('drawMoneyPwd')?.value);
  data.set('telegram', document.getElementById('telegram')?.value == undefined? "":document.getElementById('telegram')?.value);
  data.set('email', document.getElementById('emailInfo')?.value == undefined? "": document.getElementById('emailInfo')?.value);
  data.set('sendCode', document.getElementById('phoneCode')?.value == undefined? "": document.getElementById('phoneCode')?.value);
  data.set('generateValidCode',regVerify.generateValidCode);
  data.set('h5', 'h5');
  // let datastr = data.toString()
  // console.log(datastr)
  // let paramDataH5 = 'protocolCheck=' + protocolCheck + '&' + datastr + '&keyCookie=' + keyCookieValue;
  // console.log(paramDataH5)

  // const urlParams = new URLSearchParams(paramDataH5);

  if (isDisable === true) {
    if (disabled === true) {
      if(document.getElementById('regKeyInput'))
        if(document.getElementById('regKeyInput').value){
          document.getElementById('regKeyInput').disabled = true;
      }
    }
  }

  let memberInfo = store.getState().memberInfo;
  let langid = memberInfo.MemberInfo.langid?memberInfo.MemberInfo.langid:'';
  try{
    if (window.$isApp && window.$isIonic) {
      (async () => {
        const isAdjust = await Storage.get({ key: 'adjustIsAdjust' });
        if(isAdjust.value=="1"){
          const adjustEvent = await Storage.get({ key: 'adjustEventToken' });
          const adjustEventToken = JSON.parse(adjustEvent.value);
          if(adjustEventToken.a_register){
            trackEvent({
              eventToken: adjustEventToken.a_register
            })
          }
        }
      })()
    }
  }catch(e){
    console.log(e);
  }

  fetch(window.$wgtDomain + "/memreg/save?langid="+langid,{
    method: "POST",
    headers: new Headers({
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/x-www-form-urlencoded'
    }),
    body:data,
    responseType:"json",
    // credentials: 'include',
    referrer: 'h5'
  }).then((response) => response.json())
    .then((response) => {
      if (response.status === 1 || response.status === 3) {
        if (response.gaiatoken !== null) {
          let memberInfo = store.getState().memberInfo;
          memberInfo.token = response.gaiatoken;
          store.dispatch(SaveMemberInfo(memberInfo));
        }
        fetch(window.$wgtDomain + "/wap/index-init?ischess=f&theme=lottery"+'?&gaiatoken='+store.getState().memberInfo.token, {
          method: 'get',
          headers: {
            'Accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9',
          },
          responseType: 'json',
          // credentials: 'include',
          referrer: 'h5'
        })
          .then(res => res.json())
          .then(
            (result) => {
              console.log(result);
              if (result === 'MAINTAIN') {
                this.setState({ isReady: true });
              } else {
                store.dispatch(saveIndexInit(result));
                let defaultColor = result.wapThemeColor ? result.wapThemeColor : "red";
                const defaultAccSetting = { defaultColor: defaultColor, defaultBetValues: [1, 5, 10, 15, 20, 50, 100], };
                store.dispatch(SetAccountConfigsDefault(defaultAccSetting));
              }
              try{
                if (window.$isApp && window.$isIonic) {
                  if(window.$appsFlyerAppId!=""){
                    AppsFlyer.logEvent({
                      eventName: 'af_complete_registration',
                      eventValue: {
                        uid: result.loginUser.loginName,
                        pid: result.loginUser.parentName
                     }
                    })
                    .then(r => console.log('AppsFlyer Reg Event ~~>' + r.res))
                    .catch(err => console.log('AppsFlyer Reg Event err ~~>' + err));
                  }
                }
              }catch(e){
                console.log(e);
              }
            },
            (error) => {
              console.error(error)
            }
          )
        closeRegisterModal();
        closeLoginModal();
        window.location.href = window.location.href.split('#')[0]+'#/RegisterSuccessPage'+'?gaiatoken='+store.getState().memberInfo.token;
      } else if (response.status === 2) {
        refreshAlert(tran('请登录您的注册邮箱，激活您的账号！'), tran('MSG.T_00'));
      } else {
        alert(response.msg, tran('MSG.T_00'));
      }
    })
};

export const phoneBtn = function(regVerify){
  if (validatePhoneWap(document.getElementById('phoneId')) === false) return false;

  if (regVerify.authRegisterMember.sponsorRequired===1) {
    if (document.getElementById('phoneRegKeyInput')) {
      if (document.getElementById('phoneRegKeyInput').value==='') {
        alert(tran('请填写邀请码'),tran('MSG.T_00'));
        return false;
      }
    }
  }
  if (document.getElementById('phoneRegCode'))
    if (!document.getElementById('phoneRegCode').value) {
      alert(tran('请填写手机验证码'), tran('MSG.T_00'));
      return false;
    }

  if(document.getElementById('phoneLoginPwd').value.length < 6){
    alert(tran('密码长度不能少于6个字元!'),tran('MSG.T_00'));
    return false;
  }
  console.log(document.getElementById('checkPhoneLoginPwd'));
  if(document.getElementById('phoneLoginPwd').value !== document.getElementById('checkPhoneLoginPwd').value){
    alert(tran('确认密码要与密码一致!'),tran('MSG.T_00'));
    return false;
  }

  let form = document.querySelector('#phoneForm');
  let data = new URLSearchParams(new FormData(form)).toString();
  const urlParams = new URLSearchParams(data);

  const validData = new URLSearchParams();
  validData.append('loginName', new FormData(form).get('phone'));
  let memberInfo = store.getState().memberInfo;
  let langid = memberInfo.MemberInfo.langid?memberInfo.MemberInfo.langid:'';
  Fetcher(window.$wgtDomain + "/memreg/validonly-loginname-phonenumber?langid="+langid,{
    method: "POST",
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    // credentials: 'include',
    body:validData,
    responseType:"json",
    referrer: 'h5'
  }).then((response) => response.json())
    .then((response) => {
      if(response.status) {
        let memberInfo = store.getState().memberInfo;
        let langid = memberInfo.MemberInfo.langid?memberInfo.MemberInfo.langid:'';
        try{
          if (window.$isApp && window.$isIonic) {
            (async () => {
              const isAdjust = await Storage.get({ key: 'adjustIsAdjust' });
              if(isAdjust.value=="1"){
                const adjustEvent = await Storage.get({ key: 'adjustEventToken' });
                const adjustEventToken = JSON.parse(adjustEvent.value);
                if(adjustEventToken.a_register){
                  trackEvent({
                    eventToken: adjustEventToken.a_register
                  })
                }
              }
            })()
          }
        }catch(e){
          console.log(e);
        }

        fetch(window.$wgtDomain + '/memreg/save?langid='+langid, {
          method: 'POST',
          headers: new Headers({
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/x-www-form-urlencoded'
          }),
          body: urlParams,
          responseType: 'json',
          // credentials: 'include',
          referrer: 'h5'
        }).then((response) => response.json())
          .then((response) => {
            if (response.status === 1 || response.status === 3) {
              if (response.gaiatoken !== null) {
                let memberInfo = store.getState().memberInfo;
                memberInfo.token = response.gaiatoken;
                store.dispatch(SaveMemberInfo(memberInfo));
              }
              fetch(window.$wgtDomain + "/wap/index-init?ischess=f&theme=lottery"+'?&gaiatoken='+store.getState().memberInfo.token, {
                method: 'get',
                headers: {
                  'Accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9',
                },
                responseType: 'json',
                // credentials: 'include',
                referrer: 'h5'
              })
                .then(res => res.json())
                .then(
                  (result) => {
                    console.log(result);
                    if (result === 'MAINTAIN') {
                      this.setState({ isReady: true });
                    } else {
                      store.dispatch(saveIndexInit(result));
                      let defaultColor = result.wapThemeColor ? result.wapThemeColor : "red";
                      const defaultAccSetting = { defaultColor: defaultColor, defaultBetValues: [1, 5, 10, 15, 20, 50, 100], };
                      store.dispatch(SetAccountConfigsDefault(defaultAccSetting));
                    }
                    try{
                      if (window.$isApp && window.$isIonic) {
                        if(window.$appsFlyerAppId!=""){
                          AppsFlyer.logEvent({
                            eventName: 'af_complete_registration',
                            eventValue: {
                              uid: result.loginUser.loginName,
                              pid: result.loginUser.parentName
                           }
                          })
                          .then(r => console.log('AppsFlyer Reg Event ~~>' + r.res))
                          .catch(err => console.log('AppsFlyer Reg Event err ~~>' + err));
                        }
                      }
                    }catch(e){
                      console.log(e);
                    }
                  },
                  (error) => {
                    console.error(error)
                  }
                )
              closeRegisterModal();
              closeLoginModal();
              window.location.href = window.location.href.split('#')[0]+'#/RegisterSuccessPage'+'?gaiatoken='+store.getState().memberInfo.token;
            } else {
              alert(response.msg, tran('MSG.T_00'));
            }
          });
      }else{
        alert(response.content, tran('MSG.T_00'));
      }
    })
};

export const emailBtn = function() {
  if (validateEmailWap(document.getElementById('emailId')) === false) return false;
  if (document.getElementById('emailRegCode'))
    if (!document.getElementById('emailRegCode').value) {
      alert(tran('请填写邮箱验证码'), tran('MSG.T_00'));
      return false;
    }

  let form = document.querySelector('#emailForm');
  let data = new URLSearchParams(new FormData(form)).toString();
  const urlParams = new URLSearchParams(data);
  let memberInfo = store.getState().memberInfo;
  let langid = memberInfo.MemberInfo.langid?memberInfo.MemberInfo.langid:'';
  try{
    if (window.$isApp && window.$isIonic) {
      (async () => {
        const isAdjust = await Storage.get({ key: 'adjustIsAdjust' });
        if(isAdjust.value=="1"){
          const adjustEvent = await Storage.get({ key: 'adjustEventToken' });
          const adjustEventToken = JSON.parse(adjustEvent.value);
          if(adjustEventToken.a_register){
            trackEvent({
              eventToken: adjustEventToken.a_register
            })
          }
        }
      })()
    }
  }catch(e){
    console.log(e);
  }

  fetch(window.$wgtDomain + '/memreg/save?langid='+langid, {
    method: 'POST',
    headers: new Headers({
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/x-www-form-urlencoded'
    }),
    body: urlParams,
    responseType: 'json',
    // credentials: 'include',
    referrer: 'h5'
  }).then((response) => response.json())
    .then((response) => {
      if (response.status === 1 || response.status === 3) {
        if (response.gaiatoken !== null) {
          let memberInfo = store.getState().memberInfo;
          memberInfo.token = response.gaiatoken;
          store.dispatch(SaveMemberInfo(memberInfo));
        }
        try{
          if (window.$isApp && window.$isIonic) {
            if(window.$appsFlyerAppId!=""){
              AppsFlyer.logEvent({
                eventName: 'af_complete_registration',
                eventValue: {
                  uid: document.getElementById('emailId')
               }
              })
              .then(r => console.log('AppsFlyer Reg Event ~~>' + r.res))
              .catch(err => console.log('AppsFlyer Reg Event err ~~>' + err));
            }
          }
        }catch(e){
          console.log(e);
        }
        alertWithFunction(tran('您已完成注册！'), tran('MSG.T_00'),"resendEmail");
      } else if(response.status===2){
        refreshAlert(tran("请登录您的注册邮箱，激活您的账号！"), tran('MSG.T_00'))
      } else {
        alert(response.msg, tran('MSG.T_00'));
      }
    });
};

export const resendEmail= function(userPwd){

  const data = new URLSearchParams();
  data.set('email', document.getElementById('emailId').value);
  data.set('type', 1);
  data.set('sendType', 'register');
  data.set('sendCode', userPwd);

  Fetcher(window.$wgtDomain + '/memreg/send-email', {
    method: 'POST',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: data,
    responseType: 'json',
    // credentials: 'include',
    referrer: 'h5'
  }).then((response) => {
      window.location.href = window.location.href.split('#')[0];
    })
};

export const phoneSend = function(){
  let phone = document.getElementById('phoneId').value;
  if (validatePhoneWap(document.getElementById('phoneId')) === false) return false;
  const validData = new URLSearchParams();
  validData.append('loginName', phone);
  let memberInfo = store.getState().memberInfo;
  let langid = memberInfo.MemberInfo.langid?memberInfo.MemberInfo.langid:'';
  Fetcher(window.$wgtDomain + "/memreg/validonly-loginname-phonenumber?langid="+langid,{
    method: "POST",
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    // credentials: 'include',
    body:validData,
    responseType:"json",
    referrer: 'h5'
  }).then((response) => response.json())
    .then((response) => {
      if(response.status) {
        sendcode(1,phone,'',false,'register','phoneIdSend');
      }else{
        alert(response.content, tran('MSG.T_00'));
      }
    })
};

export const phoneInfoSend = function(){
  let phone = document.getElementById('phoneInfo').value;
  if (validatePhoneWap(document.getElementById('phoneInfo')) === false) return false;
  const validData = new URLSearchParams();
  validData.append('loginName', phone);
  let memberInfo = store.getState().memberInfo;
  let langid = memberInfo.MemberInfo.langid?memberInfo.MemberInfo.langid:'';
  Fetcher(window.$wgtDomain + "/memreg/validonly-loginname-phonenumber?langid="+langid,{
    method: "POST",
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    // credentials: 'include',
    body:validData,
    responseType:"json",
    referrer: 'h5'
  }).then((response) => response.json())
    .then((response) => {
      if(response.status) {
        sendcode(1,phone,'',false,'register','phoneInfoSend');
      }else{
        alert(response.content, tran('MSG.T_00'));
      }
    })

};

export const emailInfoSend = function(){
  let email = document.getElementById('emailInfo').value;
  if (validateEmailWap(document.getElementById('emailInfo')) === false) return false;
  if(document.getElementById("emailInfoSend").textContent === tran(" 重新发送 ")){
    let param = {type:0,value:email,code:'',needcode:true,sendtype:'register',domid:'emailInfoSend'};
    validCodeAlert("",tran("请输入验证码"),"alertSendCode",param);
  }else{
    sendcode(0,email,'',false,'register','emailInfoSend');
  }
};

export const emailSend = function(){
  let email = document.getElementById('emailId').value;
  if (validateEmailWap(document.getElementById('emailId')) === false) return false;
  if(document.getElementById("emailIdSend").textContent === tran(" 重新发送 ")){
    let param = {type:0,value:email,code:'',needcode:true,sendtype:'register',domid:'emailIdSend'};
    validCodeAlert("",tran("请输入验证码"),"alertSendCode",param);
  }else{
    sendcode(0,email,'',false,'register','emailIdSend');
  }
};

export const sendcode = function(type,value,code,needcode,sendtype,domid){
  if(needcode){
    code = code || "error";
    if(code==='error'){
      alert(tran("请输入验证码"),tran("notice"));
      return false;
    }
  }
  let sendbtn = document.getElementById(domid);
  const data = new URLSearchParams();
  data.set('type', 0);
  data.set('sendType', sendtype||'register');
  data.set('validCode', code);
  if(type){
    data.set('phone', value);
  }else{
    data.set('email', value);
  }

  Fetcher(window.$wgtDomain + '/memreg/'+(type?"resend-phone":"send-email"),{
    method: "POST",
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body:data,
    responseType:"json",
    // credentials: 'include',
    referrer: 'h5'
  }).then((response) => response.json())
    .then((response) => {
      if(response.status===2){
        alert(response.msg,tran("notice"));
      }else if(response.status===1){
        sendValid(60,sendbtn);
      }else{
        if(response.status===0){
          sendValid(60-response.num,sendbtn);
        }else{
          sendbtn.innerHTML = tran(" 重新发送 ");
          sendbtn.setAttribute("disabled", "");
        }
      }
    })
};

export const alertSendCode = function(param){
  let type = param.type;
  let value = param.value;
  let needcode = param.needcode;
  let code = param.code;
  let sendtype = param.sendtype;
  let domid = param.domid;
  closeAlert();
  if(needcode){
    code = code || "error";
    if(code==='error'){
      alert(tran("请输入验证码"),tran("notice"));
      return false;
    }
  }
  let sendbtn = document.getElementById(domid);
  const data = new URLSearchParams();
  data.set('type', 0);
  data.set('sendType', sendtype||'register');
  data.set('validCode', code);
  if(type){
    data.set('phone', value);
  }else{
    data.set('email', value);
  }

  Fetcher(window.$wgtDomain + '/memreg/'+(type?"resend-phone":"send-email"),{
    method: "POST",
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body:data,
    responseType:"json",
    // credentials: 'include',
    referrer: 'h5'
  }).then((response) => response.json())
    .then((response) => {
      if(response.status===2){
        alert(response.msg,tran("notice"));
      }else if(response.status===1){
        sendValid(60,sendbtn);
      }else{
        if(response.status===0){
          sendValid(60-response.num,sendbtn);
        }else{
          sendbtn.innerHTML = tran(" 重新发送 ");
          sendbtn.setAttribute("disabled", "");
        }
      }
    })
};

export const sendValid = function(t,dom) {
  if(!t) {
    dom.innerHTML = tran(" 重新发送 ");
    dom.removeAttribute("disabled");
  }
  else {
    dom.innerHTML = tran(" 重新发送 ")+"(" + t +") ";
    dom.setAttribute("disabled", "disabled");
    setTimeout(function(){
      sendValid(--t,dom);
    },1000);
  }
};

export const saveInvitationCode = async (cookieValue) => {

    (async () => {
      const info = await Device.getInfo();
      const deviceLang = await Device.getLanguageCode();
      const data = new URLSearchParams();
      let dLang = deviceLang.value;
      if(deviceLang.value.indexOf("-")!=-1){
          dLang = deviceLang.value.split("-")[0];
      }
      let visitorInfo = info.model +"_"+ info.operatingSystem + "_" + info.osVersion +"_"+ dLang;
      data.set('keyCookie', cookieValue);
      data.set('visitorInfo', visitorInfo.replace(" ",""));
      fetch(window.$wgtDomain + '/wap/saveInvitationCode', {
        method: "POST",
        headers: new Headers({
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/x-www-form-urlencoded'
        }),
        responseType: 'json',
        referrer: 'h5',
        body: data,
      }).then(res => res.json()).then((result) => {
        if(result && !result.status) console.err("fail to save invitation code!");
      });
    })()
    
  // }).catch(error => {
  //   console.log("VisitorAPI error:"+error);
  // });
}
