import * as actionType from './ActionType';

const selectGameService = (gameJSONObj) => {
    return {
        type: 'CHANGE_GAME',
        input: gameJSONObj,
    }
}

const changePlayId = (playId) => {
    return {
        type: 'CHANGE_PLAYID',
        input: playId,
    }
}

const changeMethodId = (methodId) => {
    return {
        type: 'CHANGE_METHODID',
        input: methodId,
    }
}

const changeGameType = () => {
    return {
        type: 'CHANGE_SELECTEDTYPE'
    }
}

const updateBetRecord = (betObj) => {
    return {
        type: 'UPDATE_BETRECORD',
        input: betObj,
    }
}

const changeTraceType = () =>{
    return {
        type: 'CHANGE_TRACETYPE',
    }
}

const changeIsStop= () =>{
    return {
        type: 'CHANGE_ISSTOP',
    }
}

const updatePopupObj = (popupObj) => {
    return {
        type: 'UPDATE_POPUP',
        input: popupObj,
    }
}

const updateLuzituObj = (inputObj) => {
    return {
        type: 'UPDATE_LUZITU',
        input: inputObj,
    }
}

const saveRecommendGames = (obj) => {
    return {
        type: actionType.STORE_RECOMEND_GAMES,
        input: obj,
    }
}

const saveThirdGames = (obj) => {
    return {
        type: actionType.STORE_THIRD_GAMES,
        input: obj,
    }
}

export {selectGameService, changePlayId, changeMethodId, updateBetRecord, changeGameType, changeTraceType, changeIsStop, updatePopupObj,updateLuzituObj,
        saveRecommendGames, saveThirdGames};