import React from 'react';
import PropTypes from 'utils/propTypes';
import { MdKeyboardArrowRight } from 'react-icons/md';
import CashFlowModel from './Modal/CashFlowModel';
import { withTranslation } from 'react-i18next';

class CashFlowRecord extends React.Component {

  state = {
    show: false,
    statusStr: this.props.statusStr,
  };

  constructor(props) {
    super(props);
    this.open_popup_toggle = this.open_popup_toggle.bind(this);
  }

  open_popup_toggle = () => () => {
    if (!this.state.show) {
      this.setState({ show: true });
    } else {
      return this.setState({ show: false });
    }
  };

  showAwardResult = (rc) => {
    this.props.showAwardResult(rc);
  }


  render() {
    const { t } = this.props;

    return (
        <>
        {this.props.tradeNo != null?
          <><div onClick={this.open_popup_toggle()} className="d-flex justify-content-between"
            style={{ flexDirection: 'row' }}>
            <div style={{ fontSize: '15px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontWeight: 'bold' }}>{this.props.title}</div>
            <div style={{ fontSize: '15px', whiteSpace: 'nowrap', fontWeight: 'bold' }}>{this.props.defaultCurr}{this.props.tradeMoney}
              <a style={{ color: '#757575' }}>
                <MdKeyboardArrowRight size={22} />
              </a>
              {this.state.show ?
                <div style={{
                  position: 'fixed',
                  width: '100%',
                  left: 0,
                  top: 0,
                  height: '100vh',
                  background: 'rgba(0,0,0,0.4)',
                  zIndex: 9
                }} />
                :
                null}
              <CashFlowModel show={this.state.show} open_popup_toggle={this.open_popup_toggle}
                item={this.props.item} showAwardResult={this.showAwardResult} />
            </div>
          </div><div className="d-flex justify-content-between" style={{ flexDirection: 'row' }}>
              <p style={{ fontSize: '14px', marginRight: 5, color: '#757575' }}>{t('单号')}: {this.props.tradeNo}</p>
              <div style={{ fontSize: '14px', whiteSpace: 'nowrap', color: '#757575' }}>{this.props.date}</div>
            </div></>
          :
          null}
        </>
    );
  }
}

CashFlowRecord.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  subtitle2: PropTypes.string,
  betamount: PropTypes.string,
  betaward: PropTypes.string,
  date: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.date.isRequired,
  ]),
  color: PropTypes.oneOf([
    'dark',
    'dark',
    'dark',
    'dark',
    'dark',
    'dark',
  ]),
};

CashFlowRecord.defaultProps = {
  title: '',
  subtitle: '',
  subtitle2: '',
  betamount: '',
  betaward: '',
  date: 0,
  color: 'dark',
};

export default withTranslation('common')(CashFlowRecord);
