import { createStore, combineReducers } from 'redux';
import { applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { storeGameObject,storeLuzituInfo } from '../reducers/GameReducer';
import { storeHomePageGameList, storeIndexData, storeIndexInit,storeGameListPage, storeBannerList, storeGaiaGameList, storeMemberInfo, updateAlert , storeUtilsInfo , updateModal, storeFindGameJSON, storeAwardResultListPage, updatePromotion } from '../reducers/HomePageReducer';
import { storeUserCentreData } from '../reducers/UserCentreReducer';
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { persistStore, persistReducer } from 'redux-persist';
//import Cookies from 'universal-cookie';

const middleware = [];
const enhancers = [];
const reducers = {
  game: storeGameObject,
  home: storeHomePageGameList,
  indexData: storeIndexData,
  findGameJSON: storeFindGameJSON,
  indexInit: storeIndexInit,
  gameList: storeGameListPage,
  usercentre: storeUserCentreData,
  memberInfo: storeMemberInfo,
  utils: storeUtilsInfo,
  alert: updateAlert,
  luzitu: storeLuzituInfo,
  modal: updateModal,
  awardResult: storeAwardResultListPage,
  promo: updatePromotion,
  bannerList: storeBannerList,
  gaiaGame: storeGaiaGameList
  // add other app reducers
};

const appReducers = combineReducers(reducers);

const rootReducers = (state, action) => {
  if (action.type === 'CLEAR_CACHE') {
    //let allCookie = new Cookies().getAll();
    // for (let item in allCookie) {
    //   new Cookies().remove(item);
    // }
    storage.removeItem('persist:root');
    storage.removeItem('i18nextLng');
    state.game = {};
    state.home = {};
    state.utils = {};
  } else if (action.type === 'PUSH_ERROR') {
    let currentError = state.error;
    if (currentError) {
      currentError.push(action.input);
    } else {
      currentError = [];
      currentError.push(action.input);
    }
    return {...state, error: currentError}
  }
  return appReducers(state, action);
}

const persistConfig = {
  // Root
  key: 'root',
  // Storage Method (React Native)
  storage,
  timeout: null,
  // Whitelist (Save Specific Reducers)
//   whitelist: [
//     'authReducer',
//   ],
  // Blacklist (Don't Save Specific Reducers)
  blacklist: [
    'utils',
    'modal',
    'alert',
  ],
};
// Middleware: Redux Persist Persisted Reducer
const persistedReducer = persistReducer(persistConfig, rootReducers);


const configureStore = () => {
  const store = createStore(
    persistedReducer,
    composeWithDevTools(applyMiddleware(...middleware), ...enhancers)
  );
  return store;
};

// Middleware: Redux Persist Persister
const persistor = (configureStore) => {
  return persistStore(configureStore);
}

export { configureStore, persistor };

