import React from 'react';
import { Button, Modal } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { MdArrowBack} from 'react-icons/md';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ShowLoginModal,SetSecUserName } from '../../actions/HomePageAction';
import { closeLoginModal, closeSecurityResetModal } from '../../services/HomeService';
import {alert} from '../../services/AlertService';
import Fetcher from '../../components/Fetcher';

const mapStateToProps = (state) => {
  return {
    modal: state.modal,
    indexInit: state.indexInit,
    memberInfo: state.memberInfo,
  };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    ShowLoginModal,
    SetSecUserName,
  }, dispatch)
);

class SecurityResetPage extends React.Component {

  state = {
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {

  }

  
  securityResetPost = function() {
    let realName = document.getElementById("realname").value;
    let loginPwd = document.getElementById("srnewPassword").value;
    let confirmLoginPwd = document.getElementById("srsurePassword").value;
    let DMPwd = document.getElementById("DMPwd").value;
    let confirmDMPwd = document.getElementById("confirmDMPwd").value;

    if(loginPwd != confirmLoginPwd){
      alert("确认密码要与密码一致!","");
      return;
    }

    if(DMPwd != confirmDMPwd){
      alert("确认安全密码要与安全密码一致!","");
      return;
    }

    const data = new URLSearchParams();
    data.set('username', this.props.memberInfo.SecUsername?this.props.memberInfo.SecUsername.secUsername:"");
    data.set('realname', realName);
    data.set('newPassword', loginPwd);
    data.set('surePassword', confirmLoginPwd);
    data.set('newDMPassword', DMPwd)
    data.set('sureDMPassword', confirmDMPwd);

    Fetcher(window.$wgtDomain + '/wap/security-reset', {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      // credentials: 'include',
      body: data,
      referrer: 'h5'
    })
    .then((response) => response.json())
    .then((response) => {
      console.log(response);
				if(response.success){
          this.props.SetSecUserName({secUsername:""});
					window.location.href = window.location.href.split('#')[0];
				}else{
					alert(response.msg,"安全重置失败");
				}
    })
    .catch((error) => {
      console.log("error security reset")
      console.error(error);
    });
  };

  closeModal(){
    closeLoginModal();
    closeSecurityResetModal();
  }
  
  render() {
    //const { t } = this.props;

    return (
      <Modal fade={false} isOpen={this.props.modal.securityResetModal.show} className="cart-modal">
          <div className="page-content">
          <div style={{
            backgroundColor: 'var(--theme-color)',
            color: '#fff',
            flexDirection: 'row',
            padding: '10px',
            lineHeight: '24px',
            display: 'block',
            textAlign: 'center',
          }}>
            <div style={{ float: 'left', position: 'absolute', left: 15, top: 0, lineHeight: '44px' }}>
              <Button outline style={{ outline: 'none', border: 0, alignSelf: 'flex-start', padding: 0 }}>
                <MdArrowBack size={24} onClick={this.closeModal}/>
              </Button>
            </div>
            <div className="center" style={{ fontSize: 18 }}>安全重置</div>
          </div>
          <form>
            <div className="list-block inputs-list">
              <ul>
                <li className="item-content">
                  <div className="item-inner"> 
                    <div className="item-title label">会员账号</div>
                    <div className="item-input">
                      <input type="text" name="username" value={this.props.memberInfo.SecUsername?this.props.memberInfo.SecUsername.secUsername:''} readOnly/>
                    </div>
                  </div>
                </li>
                <li className="item-content">
                  <div className="item-inner"> 
                    <div className="item-title label">真实姓名</div>
                    <div className="item-input">
                      <input type="text" name="realname" id="realname" placeholder="请填写真实姓名以便进行核对" maxLength="12"/>
                    </div>
                  </div>
                </li>
                <li className="item-content">
                  <div className="item-inner"> 
                    <div className="item-title label">密码</div>
                    <div className="item-input">
                      <input type="password" id="srnewPassword" name="loginPwd" placeholder="6-14位英文字母和数字的组合"
                        maxLength="14"/>
                    </div>
                  </div>
                </li>
                <li className="item-content">
                  <div className="item-inner"> 
                    <div className="item-title label">确认密码</div>
                    <div className="item-input">
                      <input type="password" id="srsurePassword" name="confirmLoginPwd" placeholder="与密码一致"
                        maxLength="14"/>
                    </div>
                  </div>
                </li>
                <li className="item-content">
                  <div className="item-inner"> 
                    <div className="item-title label">安全密码</div>
                    <div className="item-input">
                      <input type="password" id="DMPwd" name="DMPwd" placeholder="取款时使用到的密码，非常重要，请务必牢记!" maxLength="6"/>
                    </div>
                  </div>
                </li>
                <li className="item-content">
                  <div className="item-inner"> 
                    <div className="item-title label">确认安全密码</div>
                    <div className="item-input">
                      <input type="password" id="confirmDMPwd" name="confirmDMPwd" placeholder="与安全密码一致" maxLength="6"/>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="content-block"><a href="#" className="button button-fill button-big" onClick={this.securityResetPost.bind(this)}>确定</a></div>
          </form>
        </div>
      </Modal>
    );
  };
}

const ConnectedSecurityResetPage = connect(mapStateToProps, mapDispatchToProps)(SecurityResetPage);
export default withTranslation('common')(ConnectedSecurityResetPage);