import React from 'react';
import PropTypes from 'utils/propTypes';
import { Card, CardBody, CardText, CardHeader } from 'reactstrap';
import Typography from 'components/Typography';
import {lustcitycolor, lustcityfun, ysccolor,eurolotto, yxxpic} from '../services/AwardService';
// import CardHeader from 'reactstrap/lib/CardHeader';

const yxxImages = require.context('../assets/image/YXX', true);
const osdImages = require.context('../assets/image/OSD', true);

const AwardLivePage = ({ title, description, issue, playid, gameid, gamename, total, getGameImage, gamecategory, gamemode, gamePictureMod, lng, ...restProps }) => {
  let fun,color,path;
  if(playid === "LCT"){
    fun = lustcityfun(description[0]);
    color = lustcitycolor(description[0]);
    path = "LUSTCITY";
  } else if (playid === "YSC") {
    color = ysccolor(description[0]);
    path = "YSC";
  }

  return (

      <table>
        <tbody>
        <Card body {...restProps} style={{ width: '100%',padding: '10px',borderRadius: '0px' , border: 0, backgroundSize: 'cover', background:'transparent' }}>
          <CardHeader style={{ width: '240px', textAlign: 'left', padding: '14px 16px',borderTopLeftRadius: '20px',borderTopRightRadius:'20px',border: 0, backgroundSize: 'cover', backgroundColor: '#000000 !important',background:'rgba(0,0,0,0.6)' }}>
            <div style={{marginLeft:'auto',fontSize:14,color:'white',lineHeight:'3px'}}>{gamename} {issue}</div>
          </CardHeader>
          <CardBody style={{ width: '100%',borderBottomLeftRadius:'20px',borderBottomRightRadius:'20px',border: 0, backgroundSize: 'cover', backgroundColor: '#000000 !important',background:'rgba(0,0,0,0.2)',padding: '6px 0px 14px 15px' }}>
          <CardText tag="div" className="d-flex justify-content-between" style={{marginTop: 2}}>
            <Typography tag="span" className="text-left text-muted small">
              {
                playid !== "STM" ?
                  (playid !== "LCT" && playid !== "YSC" && playid !== "YXX" && playid !== "OSD") ?
                    description.map((item,index)=>{
                      const itemValue = item + "";
                      return (
                        <>
                          <div key={index} className={playid === "KW3" ? "dice dice"+itemValue : playid === "BSC" ? "live-racing live-r"+itemValue : (playid === "LHC" || playid === "LOT" || playid === "JHC") ? "LHC-ball-small-full livelhc-b-"+itemValue : eurolotto(playid) ?"LHC-ball-small-full livelhc-b-01": "red-ball" }
                          style={{fontSize: (lng=='en')?'14px':(lng=='zh')?'11px':(lng=='id')?'14px':(lng=='in')?'10px':'16px', width: (lng=='en')?'20px':(lng=='zh' && playid==='LHC')?'22px':(lng=='zh' && playid!=='LHC')?'18px':(lng=='id')?'24px':(lng=='in')?'18px':'26px', marginRight: '3px', lineHeight: (playid === "LHC" || playid === "LOT" || playid === "JHC" || playid === "BSC")?'16px':'20px', height: '20px'}}>
                            {item}
                          </div>
                          {/*LHC*/}
                          {index === description.length-2 && (playid === "LHC" || playid === "LOT" || eurolotto(playid) || playid === "JHC") ?
                            <div style={{display:'inline-block',marginRight:5,color:'black'}}>+</div>
                          :
                            null
                          }
                          {
                            (playid === "DDC" || playid==="QQC") && index === 2 ?
                              <>
                                <div style={{display:'inline-block',marginRight:5,color:'black'}}>
                                  =
                                </div>
                                <div className={"red-ball"} >
                                  {total}
                                </div>
                              </>
                              :
                              (playid === "DDC" || playid==="QQC") && index !== 2 ?
                                <div style={{display:'inline-block',marginRight:5,color:'black'}}>
                                  +
                                </div>
                              :
                                null
                          }
                        </>
                        )
                    })
                    :
                    (playid === "YXX") ?
                      description.map((item,index)=>{
                        const itemValue = item + "";
                        return (
                        <>
                          {<img style={{ width: '35px', height: '34px', borderRadius: '10px', marginBottom: '5px' }} alt="" src={yxxImages('./'+yxxpic(item)+'.png')}/>}
                        </>
                        )
                      })
                    :
                    (playid === "OSD") ?
                      description.map((item,index)=>{
                        const itemValue = item + "";
                        return (
                        <>
                          {<img style={{ width: '28px', height: '28px',marginRight: '3px'}} alt="" src={osdImages('./'+item+'.png')}/>}
                        </>
                        )
                      })
                    :
                    <>
                      {playid === "LCT" &&
                      <img className="lct-award-num" alt="" src={require(`../assets/image/${path}/${fun}.png`)}
                           style={{ marginBottom: 5, marginRight: 3 }}/>
                      }
                      <div className="lct-award-num">{description[0]}</div>
                      <img className="lct-award-num" alt="" src={require(`../assets/image/${path}/${color}.png`)} style={{marginBottom:5, marginLeft:3}}/>
                    </>
                    :
                    (playid === 'PKS' || playid === 'FTW') ?
                      description.map((item,index)=>{
                        const itemValue = item + "";
                        return (
                        <>
                          <b key={index} style={{ fontWeight: 'normal' }}>
                            <div className={"live-racing r" + itemValue} key={index}>
                              {item}
                            </div>
                          </b>
                        </>
                        )
                      })
                  :
                  (parseFloat(description[1])-parseFloat(description[0])).toFixed(2)>0?
                    <>
                    <span className="stm-trending-up" >{description[1]}</span>
                    <img src={require(`../assets/image/trending_up.png`)} alt="" style={{width:20, height:20, marginBottom:5, marginLeft:3}}/>
                    </>
                    :
                    <>
                    <span className="stm-trending-down" >{description[1]}</span>
                    <img src={require(`../assets/image/trending_down.png`)} alt="" style={{width:20, height:20, marginBottom:5, marginLeft:3}}/>
                    </>
              }
            </Typography>
          </CardText>
          </CardBody>
        </Card>
        </tbody>
      </table>

  );
};

AwardLivePage.propTypes = {
  title: PropTypes.string.isRequired,
  right: PropTypes.node,
};

export default AwardLivePage;
