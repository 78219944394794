import React from 'react';
import { withTranslation } from 'react-i18next';
import { updateNotice, UpdateAlert,saveJumpObj } from '../../actions/HomePageAction';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SwipeableViews from 'react-swipeable-views';
import { Modal } from 'reactstrap';
import Slider from 'react-slick';
import Fetcher from '../../components/Fetcher';
import WheelCom from '../Widget/WheelCom';
import { showLoginModal } from '../../services/HomeService';
import { Browser } from '@capacitor/browser';
import { alert } from '../../services/AlertService';
import i18next from 'i18next';
import { store } from '../../index';

const images = require.context('../../assets/image/', true);

const mapStateToProps = (state) => {
  return {
    alertObj: state.alert,
    indexInit: state.indexInit
  };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    updateNotice,
    UpdateAlert,
  }, dispatch)
);

const tran = (input) => {
  return i18next.t(input);
}

class PromotionModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      activeIndex: 0,
      testData:[],
      initHeight: null,
      popupPromo:[],
    };
    this.closePopup = this.closePopup.bind(this);
    this.showPromotion = this.showPromotion.bind(this);
  }

  componentDidMount() {
    this.showPromotion();
  }

  showPromotion() {
    Fetcher(window.$wgtDomain + '/wap/getPopupPromotion', {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
      },
      // credentials: 'include',
      referrer: 'h5',
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success && response.promo != null) {
          const currentState = this.state;
          if (response.promo != null) {
            let promoRecord = response.promo;
            promoRecord.map((item) => {
              if(item.promotionType == "ROULETTE"){
                currentState.testData.push(item);
              }
            })
          }
          if (response.popupPromoList != null) {
            currentState.show = true;
            currentState.popupPromo= response.popupPromoList;
          }
          this.setState(currentState);
        }
      })
  }

  jumpToUrl = (e) => {
    let url = e.target.getAttribute('value');
    if(this.isValidHttpUrl(url)){
      if(window.$isIonic){
        this.props.history.goBack();
        Browser.open({ url: url });
      }
      else if(window.$isApp) {
        let plus = window.$plus;
        plus.runtime.openURL(url);
      }else{
        window.open(url);
      }
    }else{
      alert(tran('无效链接'));
    }
  };

  isValidHttpUrl(string) {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  }

  closePopup(){
    this.setState({show : false})
  }

  toLoginPage(){
    let promoObj = {};
    promoObj.pathname = '/signin-record';
    let dataObj = store.getState().indexData.jumpObj;
    dataObj.openPromo = promoObj;
    store.dispatch(saveJumpObj(dataObj));
    showLoginModal();
  }

  chgHeight(index){
    const elm = document.getElementsByClassName("imgContainer2"+index);
    this.setState({initHeight:elm[0].offsetHeight});
  }

  render() {
    const { t } = this.props;
    const { activeIndex, testData, popupPromo } = this.state;
    const options = {
      dots: false,
      infinite: true,
      speed: 500,
      autoplay: false,
      autoplaySpeed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1,
      afterChange: function(index) {
        sliderChange(index);
      }
    };
    const sliderChange = (newIndex) => {
      this.setState({activeIndex: newIndex});
      //this.chgHeight(newIndex);
    }
    return (
      <>
        <div style={{ backgroundColor: '#182333' }}>
          {popupPromo.length>0 ?
            <Modal isOpen={this.state.show && this.props.indexInit.indexInit.loginUser==null} className="pm-dialog" wrapClassName="pm-modal">
              <SwipeableViews animateHeight={true}
                              disableLazyLoading={true} style={{paddingBottom:'50px'}}>
                <Slider {...options} className="carouselStyle noticeCarousel">
                  {popupPromo.map((item, index) => {
                    return (
                      <React.Fragment key={index} >
                        {
                          item.status == '3' && item.promotionId == "ROULETTE01"?
                            testData != null && testData.length>0?
                              <div style={{textAlign:'center'}}>
                                <div className={"promotion-roulette-wheel-container "+"imgContainer2"+index}>
                                  <WheelCom promo={testData[0]} titleWord={t('累计积分')} buttonWord={t('抽奖')} popUpSuccessText={t('恭喜！')} popUpSuccessText1={t('您已赢取')} popUpSuccessText2={t('积分')} confirmBtnText={t('确定')} noticeTitle={t('notice')}
                                            isLogin={false} rouletteStyle={2}></WheelCom>
                                </div>
                              </div>
                              :
                              null
                            :
                            item.status == '1'?
                              //每日签到
                              <div className={"imgContainer1"} >
                                <div className={"imgContainer2"+index} style={{display: 'flex', justifyContent: 'center', height:'108vw'}}>
                                  <div style={{width:'78vw'}}><img className={'pm-img'} style={{width:'100%',height:'100%'}} alt="" src={images("./popup2.png")}/></div>
                                  <img className={'pm-img'} style={{position: 'absolute',width: '140px',top: '28%'}} alt="" src={images("./calender-icon.png")} />
                                  <div style={{ position: 'absolute',top: '62%',textAlign:'center'}}>
                                    <div style={{fontSize: '33px', fontWeight: 'bold', color: '#CF820D'}}>{t('dailycheckin')}</div>
                                    <div style={{fontSize: '13px', color: '#CF820D'}}>{t("signinday")}</div>
                                    <button style={{fontSize: '14px', background: 'linear-gradient(45deg, rgb(225,128,0,1) 0%, rgba(255,190,12,1) 100%)', width: '200px', borderRadius: '25px',padding: '8px', color: '#fff', margin:'12px 0px'}} onClick={this.toLoginPage.bind(this)}>{t('signinnow')}</button>
                                    <div style={{ fontSize: '13px',color: '#AFAFAF'}} onClick={this.toLoginPage.bind(this)}>{t('signindetails')}</div>
                                  </div>
                                </div>
                              </div>
                              :
                              item.status == '2'?
                                // // 自定义优惠
                                <div className={"imgContainer1"}>
                                  <div className={"pm-title-container"}>
                                    <div className={"pm-title"}>
                                      <span style={{fontWeight:'bold',color:'white',verticalAlign:'center'}}>{item.promotionName == "" || item.promotionName == null ?  t('优惠活动') :item.promotionName}</span>
                                    </div>
                                  </div>
                                  <div className={"imgContainer2"+index} style={{justifyContent: 'center'}}>
                                    <img className={'pm-img'} alt="" src={window.$wgtDomain + item.promotionPic} value={item.promotionUrl} onClick={this.jumpToUrl.bind(this)}/>
                                  </div>
                                </div>
                                :
                                // // 其他优惠
                                <div className={"imgContainer1"}>
                                  <div className={"pm-title-container"}>
                                    <div className={"pm-title"}>
                                      <span style={{fontWeight:'bold',color:'white',verticalAlign:'center'}}>{item.promotionName == "" || item.promotionName == null ? t('优惠活动'):item.promotionName}</span>
                                    </div>
                                  </div>
                                  <div className={"imgContainer2"+index} style={{justifyContent: 'center'}}>
                                    <img className={'pm-img'} alt="" src={window.$wgtDomain + item.promotionPic} />
                                  </div>
                                </div>
                        }
                        <a className="quan-close voucher-list-close" onClick={this.closePopup} ><img alt="" src={images("./quan-close.png")} /></a>
                      </React.Fragment>

                    );
                  })
                  }
                </Slider>
              </SwipeableViews>

            </Modal>
            : <></>}
        </div>
      </>
    );
  }
}

const ConnectedPromotionModal = connect(mapStateToProps, mapDispatchToProps)(PromotionModal);
export default withTranslation('common')(ConnectedPromotionModal);