const MSG = {
    E_01: "数据加载失败!",
    E_02: "提交失败!",
    E_03: "访问失败,请换个姿势再试试!",
    E_07: "请选择游戏",
    E_08: "确认区有相同的投注内容",
    E_09: "请先添加投注内容",
    E_10: "请输入要查询的会员",
    E_11: "余额不足",
    E_12: "服务器拒绝您的访问",
    E_13: "与服务器连接已经断开,请检查网络或者联系我们的客服!",
    E_14: "服务器繁忙,未能及时响应!",
    E_15: "请求失败!",
    E_16: "投注失败!",
    E_17: "投注金额不能为空!",
    E_18: "获取不到期数!",
    E_19: "加载游戏数据信息失败!",
    E_20: "时间范围不正确!",
    E_21: "请至少选择一注投注号码",
    E_22: "加载返点赔率失败",
    E_23: "加载期数失败",
    S_01: "删除成功!",
    S_02: "提交成功!",
    S_03: "冻结成功!",
    S_04: "解冻成功!",
    S_05: "排序成功!",
    S_06: "生成成功!",
    S_07: "投注成功!",
    S_08: "撤单成功!",
    S_09: "操作成功!",
    W_01: "请选择要删除的记录!",
    W_02: "确定要删除选定的记录?",
    W_03: "请选择要生成的日期",
    W_04: "您还未开奖,不能结算",
    W_05: '成功投注：<span style="color:red">[success]</span>单，失败：<span style="color:red">[fail]</span>单。以下内容投注失败：',
    W_06: '请选择站点',
    W_07: '[content]是否需要清空所有投注项？',
    W_08: '确认要撤单？',
    W_09: '请不要重复刷新,在[seconds]秒后再试试',
    W_10: '至少选择一注',
    W_11: '确定要[status]该用户',
    W_12: '确定要延期[issue]一天吗?',
    T_00: "温馨提醒",
    T_01: "请选择组别",
    T_02: "没有相关记录",
    T_03: "请选择要修改的游戏模版",
    T_04: "[currentissue]期已经截止销售",
    T_05: "游戏还未开售，请选择其他游戏",
    T_06: "该彩种暂停销售，您可以选择其他游戏",
    T_07: "当前游戏暂时停售中，请选择其他游戏",
    T_08: "已删除以下重复号码:",
    T_09: "多玩法不支持利润率追号，请选择单一玩法进行投注",
    T_10: "正在提交",
    T_11: "正在加载数据",
    T_12: "现在进入下一期[nextissue]",
    T_13: '每注号码之间请用一个 空格[ ]、逗号[,] 或者 分号[;] 隔开',
    T_14: '每注号码之间请使用逗号或回车隔开。注意：号码之间要用空格隔开，不足2位要在前面加0。',
    T_15: '最多可选择 %s 个号码',
    T_16: "此玩法不支持利润率追号，请用其他追号方式",
    T_17: "没有该投注点可投",
    T_18: "等待开奖中,不能投注",
    T_19: "正在生成",
    T_20: "生成成功",
    T_21: "生成失败",
    T_22: "未开放游戏",
    T_23: "至少选择{0}到{1}个号码",
    D_01: "开售时间还有",
    D_02: "投注截止",
    D_03: "截止",
    D_04: "开售",
}

export default MSG;