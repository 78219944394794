export const GET_BALANCE = 'GET_BALANCE';
export const STORE_INDEX_DATA = 'STORE_INDEX_DATA';
export const STORE_INDEX_INIT = 'STORE_INDEX_INIT';
export const STORE_GAME_LIST_PAGE = 'STORE_GAME_LIST_PAGE';
export const STORE_BANNER_LIST = 'STORE_BANNER_LIST';
export const STORE_GAIA_GAME_LIST = 'STORE_GAIA_GAME_LIST';
export const STORE_USER_CENTRE_INDEX_DATA = 'STORE_USER_CENTRE_INDEX_DATA';
export const SET_REMEMBER_PASSWORD = 'SET_REMEMBER_PASSWORD';
export const SET_GESTURE_PASSWORD = 'SET_GESTURE_PASSWORD';
export const SET_GESTURE_USER_INFO = 'SET_GESTURE_USER_INFO';
export const SET_SEC_USERNAME = 'SET_SEC_USERNAME';
export const SET_USER_INFO = 'SET_USER_INFO';
export const SET_ACCOUNT_CONFIGS = 'SET_ACCOUNT_CONFIGS';
export const SET_ACCOUNT_CONFIGS_DEFAULT = 'SET_ACCOUNT_CONFIGS_DEFAULT';
export const SET_DEMO_USER = 'SET_DEMO_USER';
export const REFRESH_USER = 'REFRESH_USER';
export const STORE_AWARD_RESULT_LIST_PAGE = 'STORE_AWARD_RESULT_LIST_PAGE';
export const STORE_RECOMEND_GAMES = 'STORE_RECOMEND_GAMES';
export const STORE_THIRD_GAMES = 'STORE_THIRD_GAMES';
export const STORE_JUMP_OBJ = 'STORE_JUMP_OBJ';