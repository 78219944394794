import { store } from '../index';
import i18next from 'i18next';
import { UpdateAlert } from '../actions/HomePageAction';

const tran = (input) => {
  return i18next.t(input);
}

export const alert = function (content,title){
  let alertObj = store.getState().alert;
  alertObj.content = content;
  alertObj.title = title;
  alertObj.alertType = 'normalAlert';
  alertObj.showAlert = true;
  store.dispatch(UpdateAlert(alertObj));
};

export const closeAlert = function (){
  let alertObj = store.getState().alert;
  alertObj.showAlert = false;
  alertObj.containerType = '';
  alertObj.modalPadding = '';
  alertObj.content = '';
  alertObj.title = '';
  alertObj.alertType = '';
  alertObj.functionName = '';
  alertObj.validCodeParam = '';
  store.dispatch(UpdateAlert(alertObj));
}

export const betConfirmAlert = function (content,title,betContent){
  let alertObj = store.getState().alert;
  alertObj.content = content;
  alertObj.alertType = 'confirmAlert';
  alertObj.showAlert = true;
  alertObj.betContent = betContent;
  store.dispatch(UpdateAlert(alertObj));
};

export const goBackAlert = function (content,title){
  let alertObj = store.getState().alert;
  alertObj.content = content;
  alertObj.title = title;
  alertObj.alertType = 'goBackAlert';
  alertObj.showAlert = true;
  store.dispatch(UpdateAlert(alertObj));
};

export const clearCacheAlert = function (){
  let alertObj = store.getState().alert;
  alertObj.content = tran("清除缓存成功!页面将刷新您可能需要重新登陆!");
  alertObj.title = tran("notice");
  alertObj.alertType = 'clearCache';
  alertObj.showAlert = true;
  store.dispatch(UpdateAlert(alertObj));
}

export const refreshAlert = function (content,title){
  let alertObj = store.getState().alert;
  alertObj.content = content;
  alertObj.title = title;
  alertObj.alertType = 'refreshAlert';
  alertObj.showAlert = true;
  store.dispatch(UpdateAlert(alertObj));
}

export const alertWithFunction = function (content,title,functionName){
  let alertObj = store.getState().alert;
  alertObj.content = content;
  alertObj.title = title;
  alertObj.alertType = 'normalAlert';
  alertObj.functionName = functionName;
  alertObj.showAlert = true;
  store.dispatch(UpdateAlert(alertObj));
};

export const validCodeAlert = function (content,title,functionName,param){
  let alertObj = store.getState().alert;
  alertObj.content = content;
  alertObj.title = title;
  alertObj.alertType = 'validCodeAlert';
  alertObj.functionName = functionName;
  alertObj.validCodeParam = param;
  alertObj.showAlert = true;
  store.dispatch(UpdateAlert(alertObj));
}

export const changeBalanceAlert = function (content,title,functionName,param){
  let alertObj = store.getState().alert;
  alertObj.content = content;
  alertObj.title = title;
  alertObj.alertType = 'changeBalanceAlert';
  alertObj.functionName = functionName;
  alertObj.changeBalanceParam = param;
  alertObj.showAlert = true;
  store.dispatch(UpdateAlert(alertObj));
}

export const testPlayAlert = function (){
  let alertObj = store.getState().alert;
  alertObj.content =  tran("您当前是试玩用户，登入享更多权益！");
  alertObj.alertType = 'testPlayAlert';
  alertObj.showAlert = true;
  store.dispatch(UpdateAlert(alertObj));
};

export const safePasswordAlert = function (title,content){
  let alertObj = store.getState().alert;
  alertObj.title = title? title :  tran("温馨提醒");
  alertObj.content = content? content : tran("请先设置安全密码");
  alertObj.alertType = 'safePasswordAlert';
  alertObj.showAlert = true;
  store.dispatch(UpdateAlert(alertObj));
};

export const safePasswordAlertWithInput = function (title,content,functionName,param,safePsdStrParam=""){
  let alertObj = store.getState().alert;
  alertObj.title = title? title :  tran("notice");
  alertObj.content = content? content : tran("请输入安全密码") + ":";
  alertObj.alertType = 'safePasswordAlertWithInput';
  alertObj.showAlert = true;
  alertObj.functionName = functionName;
  alertObj.functionParam = param;
  alertObj.safePsdStrParam = safePsdStrParam;
  store.dispatch(UpdateAlert(alertObj));
};

export const addBankAlert = function (){
  let alertObj = store.getState().alert;
  alertObj.content =  tran("安全密码已设置成功，是与否立即绑定您的银行卡！");
  alertObj.title =  tran("notice");
  alertObj.alertType = 'addBankAlert';
  alertObj.showAlert = true;
  store.dispatch(UpdateAlert(alertObj));
};

export const confirmAlertWithFunction = function (title, content, functionName, param, isShowCancelBtn = true, isReverseBtn = false){
  let alertObj = store.getState().alert;
  alertObj.title = title;
  alertObj.content = content;
  alertObj.alertType = 'confirmAlertWithFunction';
  alertObj.functionName = functionName?functionName:'';
  alertObj.functionParam = param;
  alertObj.showAlert = true;
  alertObj.isShowCancelBtn = isShowCancelBtn;
  alertObj.isReverseBtn = isReverseBtn;
  store.dispatch(UpdateAlert(alertObj));
};

export const confirmAlertWithBothFunction = function (title, content, functionName, param, isShowCancelBtn = true, isReverseBtn = false, cancelFunctionName){
  let alertObj = store.getState().alert;
  alertObj.title = title;
  alertObj.content = content;
  alertObj.alertType = 'confirmAlertWithBothFunction';
  alertObj.functionName = functionName?functionName:'';
  alertObj.functionParam = param;
  alertObj.showAlert = true;
  alertObj.isShowCancelBtn = isShowCancelBtn;
  alertObj.isReverseBtn = isReverseBtn;
  alertObj.cancelFunctionName = cancelFunctionName?cancelFunctionName:'';
  store.dispatch(UpdateAlert(alertObj));
};

export const alertWithHtmlContent = function (content,title){
  let alertObj = store.getState().alert;
  alertObj.content = content;
  alertObj.title = title;
  alertObj.alertType = 'normalAlert';
  alertObj.showAlert = true;
  alertObj.isContentHtml = true;
  store.dispatch(UpdateAlert(alertObj));
};
export const onlinePayAlert = function (title, content, functionName){
  let alertObj = store.getState().alert;
  alertObj.title = title;
  alertObj.content = content;
  alertObj.alertType = 'onlinePayAlert';
  alertObj.functionName = functionName;
  alertObj.functionParam = null;
  alertObj.showAlert = true;
  store.dispatch(UpdateAlert(alertObj));
};

export const repeatGameStop = function (){
  let alertObj = store.getState().alert;
  alertObj.content =  tran("游戏已经停售");
  alertObj.alertType = 'repeatGameStop';
  alertObj.showAlert = true;
  store.dispatch(UpdateAlert(alertObj));
}

export const doubleVerify = function (param,functionName){
  let alertObj = store.getState().alert;
  alertObj.content = tran("psdconterpage.enterencrypt");
  alertObj.alertType = 'doubleVerify';
  alertObj.dmicParam = param;
  alertObj.showAlert = true;
  alertObj.functionName = functionName;
  store.dispatch(UpdateAlert(alertObj));
};

export const identityConfirmation = function (param, functionName){
  let alertObj = store.getState().alert;
  console.log(alertObj);
  alertObj.content = tran("请先完成身份核实认证");
  alertObj.alertType = 'identityConfirmation';
  alertObj.identityConfirmation = param;
  alertObj.showAlert = true;
  alertObj.functionName = functionName;
  store.dispatch(UpdateAlert(alertObj));
}

export const sucessConfirmation = function (imageURL){
  let alertObj = store.getState().alert;
  alertObj.content = "转账成功";
  alertObj.alertType = "sucessConfirmation";
  alertObj.showAlert = true;
  alertObj.imageURL = imageURL;
  store.dispatch(UpdateAlert(alertObj));
}

export const failConfirmation = function (imageURL){
  let alertObj = store.getState().alert;
  alertObj.title = "错误"
  alertObj.content = "安全密码错误";
  alertObj.alertType = "failConfirmation";
  alertObj.showAlert = true;
  alertObj.imageURL = imageURL;
  store.dispatch(UpdateAlert(alertObj));
}

export const rtpConfirmation = function (){
  let alertObj = store.getState().alert;
  alertObj.title = "RTP"
  alertObj.content = Math.floor(Math.random() * (98 - 95 + 1)) + 95;
  alertObj.alertType = "rtpConfirmation";
  alertObj.showAlert = true;
  store.dispatch(UpdateAlert(alertObj));
}
