import React from 'react';
import { Button, Col, ListGroup, ListGroupItem, Modal, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { MdArrowBack } from 'react-icons/md';
import CashFlowModelDetails from './CashFlowModelDetails';
/*import i18next from 'i18next';*/
import { intToString, convertByTimeZone } from '../../services/HomeService';

class CashFlowModel extends React.Component {

  constructor(props) {
    super(props);
    this.onPress = this.onPress.bind(this);
  }

  onPress = () => {
    this.props.showAwardResult(this.props.item)
  };

  formatNum = input => {
    if (input !== undefined) {
    return intToString(input, this.props.memberInfo?.MemberInfo?.currAbbr);
    }
  };

  render() {
    const { t } = this.props;

    if (!this.props.show) {
      return null;
    }

    const getTimeStamp = () => {
      return convertByTimeZone(this.props.item.createDate, 'YYYY/MM/DD HH:mm:ss');
    };

    return (
      <Modal
        isOpen={this.props.show}
        toggle={this.props.open_popup_toggle()}
        className="bet-modal">
        <div className='bet-details-header modal-header' style={{ color: '#fff', flexDirection: 'row', padding: '10px', lineHeight: '24px', display: 'block', textAlign: 'center' }}>{t('betting_record_details')}
          <div className="left" style={{ float: 'left' }}>
            <Button outline style={{ outline: 'none', border: 0, alignSelf: 'flex-start', padding: 0 }}
                    onClick={this.props.open_popup_toggle()}>
              <MdArrowBack size={24}/>
            </Button>
          </div>
        </div>

        <div>
          <div>
            <Row>
              <Col lg={12} md={6} sm={6} xs={12}>
                <ListGroup>
                  <React.Fragment>
                    <ListGroupItem style={{ border: 0 }}>
                      <CashFlowModelDetails
                        ref_num_title={t('单号')}
                        ref_num={this.props.item.tradeNo}
                        color='muted'
                      />
                    </ListGroupItem>

                    <ListGroupItem style={{ border: 0 }}>
                      <CashFlowModelDetails
                        betting_time_title={t('交易日期')}
                        betting_time={getTimeStamp()}
                        color='muted'
                      />
                    </ListGroupItem>

                    <ListGroupItem style={{ border: 0 }}>
                      <CashFlowModelDetails
                        trade_type_name_title={t('交易类型')}
                        trade_type_name={this.props.item.tradeTypeName}
                        color='muted'
                      />
                    </ListGroupItem>

                    <ListGroupItem style={{ border: 0 }}>
                      <CashFlowModelDetails
                        trade_target_title={t('交易方向')}
                        trade_target={this.props.item.tradeTarget}
                        color='muted'
                      />
                    </ListGroupItem>

                    <ListGroupItem style={{ border: 0 }}>
                      <CashFlowModelDetails
                        trade_amount_title={t('交易金额')}
                        trade_amount={this.formatNum(this.props.item.tradeMoney)}
                        color='muted'
                      />
                    </ListGroupItem>

                    <ListGroupItem style={{ border: 0 }}>
                      <CashFlowModelDetails
                        freeze_money_title={t('冻结金额')}
                        freeze_money={this.formatNum(this.props.item.freezeMoney)}
                        color='muted'
                      />
                    </ListGroupItem>

                    <ListGroupItem style={{ border: 0 }}>
                      <CashFlowModelDetails
                        remark_title={t('明细')}
                        remark={this.props.item.remark}
                        color='muted'
                      />
                    </ListGroupItem>
                  </React.Fragment>
                </ListGroup>
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
    );
  }
}

export default withTranslation('common')(CashFlowModel);