import React from 'react';
import { Button, Modal } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { MdArrowBack} from 'react-icons/md';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ShowRegisterModal } from '../../actions/HomePageAction';
import { closeRegisterModal} from '../../services/HomeService';
import SwipeableViews from 'react-swipeable-views';
import SimpleNumberPickerModal from './SimpleNumberPickerModal';
import RegisterTermModal from './RegisterTermModal';
import { validateLoginName , validateLoginPwd ,validatePhoneLoginPwd, validateCheckPwd, validateCheckPhonePwd, validateCode ,validateRealName, validateNickname, validatePhoneWap, validateEmailWap, validateDrawMoneyPwd, regBtn, phoneBtn, emailBtn, phoneSend, phoneInfoSend, emailInfoSend, emailSend} from '../../services/RegisterService';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import i18next from 'i18next';
import Fetcher from '../Fetcher';
import Slider from 'react-slick';

const images = require.context('../../assets/image/', true);

const mapStateToProps = (state) => {
  return {
    modal: state.modal,
    memberInfo: state.memberInfo,
  };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    ShowRegisterModal,
  }, dispatch)
);

const getImage = (imagewithtype) => {
  try{
    return images('./'+imagewithtype);
  } catch (err) {
    console.error(err);
    return window.$wgtDomain+"/imagenotfound.png"
  }
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block !important" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block !important"}}
      onClick={onClick}
    />
  );
}

class RegSwipeContent extends React.Component {

  constructor() {
    super();
    this.state = {
      isShowPassword: false,
      isShowPassword2: false,
      promo: null,
      promoRecord: [],
    };
    this.getAllPromotionEventList();
  }

  isShowPasswordSwitch = () => {
    this.setState({isShowPassword:!this.state.isShowPassword});
  }

  isShowPasswordSwitch2 = () => {
    this.setState({isShowPassword2:!this.state.isShowPassword2});
  }

  getAllPromotionEventList() {
    Fetcher(window.$wgtDomain + '/wap/getAllPromotionEventList', {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
      },
      // credentials: 'include',
      referrer: 'h5',
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success && response.promo != null) {
          this.setState({
            promo: response.promo,
          });
          if (response.promo.records != null) {
            let getRecords = [];
            let n = 0;
            for(let i = 0; i < response.promo.records.length; i++){
              if(response.promo.records[i].promotionType ===  'SINCON'){
                  getRecords[n] = response.promo.records[i];
                  n++;
              }
            }
            this.setState({
              promoRecord: getRecords,
            });
          }
        }
      })
  }
  getPromoName(lang, item){
    var promotionNames = item.promotionName;
    var name="";
    JSON.parse(promotionNames, (key, value) => {
      if (key === lang) {
        name= value;
      }
    });
    return name;
  }

  htmlDecode(input){
    var e = document.createElement('div');
    e.innerHTML = input;
    var returnHtml = '';
    if(e.childNodes[0] != undefined) {
      var inputHtml = e.childNodes[0].nodeValue;
      let result = inputHtml.indexOf("img");
      if(parseInt(result) !== -1) {
        returnHtml = e.childNodes[0].nodeValue;
        var regexp = /<img[^>]+src\s*=\s*['"]([^'"]+)['"][^>]*>/g;
        var src = returnHtml.match(regexp).map(x => x.replace(/.*src="([^"]*)".*/, '$1'));;
        returnHtml = "<img style='max-width: 100%; width: auto; height: auto;' src="+src[0]+" alt=''><br>"
      } else {
        returnHtml = e.childNodes[0].nodeValue;
      }
    }
    return parseInt(e.childNodes.length) === 0 ? "" : returnHtml;
  }

  getJsonTranfer(lang, item){
    var values="";
    JSON.parse(item, (key, value) => {
      if (key === lang) {
        values= value;
      }
    });
    return values;
  }

  render(){
    const { t } = this.props;
    const regVerify = this.props.regVerify;
    let isShowPassType = this.state.isShowPassword ? 'text' : 'password';
    let isShowPassImg = this.state.isShowPassword ? 'open' : 'close';
    let isShowPassType2 = this.state.isShowPassword2 ? 'text' : 'password';
    let isShowPassImg2 = this.state.isShowPassword2 ? 'open' : 'close';
    let promoRecords = this.state.promoRecord;
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      autoplay: false,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    return(
      <form id="regForm">
        {regVerify.regOpen === 1 ?
          <div style={{ height: 'calc(100vh - 87px)', overflow: 'auto' }}>
            <div className="list-block inputs-list" style={{marginBottom:0}}>
              <ul className="form-ul">
                {regVerify.authRegisterMember.sponsorPresent === 0 ?
                  <input type="hidden" value={regVerify.keyCookie} disabled={regVerify.keyCookie} name="keyCookie" id="regKeyCookie" className="keyCookieInput"/> : null}
                {regVerify.authRegisterMember.sponsorPresent === 1 ?
                  <li>
                    <div className="item-content">
                      <div className="item-media">
                      {regVerify.authRegisterMember.sponsorRequired === 1 ? <b className="text-danger">*</b> : null }
                        <span>{t('agentpromotionpage.invitecode')}</span>
                      </div>
                      <div className="item-inner">
                        <div className="item-input item-input-field">
                          <input type="text" maxLength="9" className="input-field keyCookieInput" 
                                 placeholder={t('介绍人, ') + (regVerify.authRegisterMember.sponsorRequired === 1 ? t('必填,') : t('选填,')) + t('只能输入字母或数字')}
                                 onFocus={this.props.inputOnFocus} onBlur={this.props.inputOnBlur} name="keyCookie" id="regKeyInput" value={regVerify.keyCookie} disabled={regVerify.keyCookie}/>
                        </div>
                      </div>
                    </div>
                  </li>
                  :
                  null
                }
                <li>
                  <div className="item-content">
                    <div className="item-media">
                    <b className="text-danger">*</b>
                      <span>{t('teamreportpage.account')}</span>
                    </div>
                    <div className="item-inner">
                      <div className="item-input item-input-field">
                        <input type="text" className="input-field" placeholder={t("6-14个字符,英文小写字母或数字")}
                               maxLength="14" onChange={this.props.handleUsernameReg} id="regusername" name="loginName"
                               onFocus={this.props.inputOnFocus} onBlur={this.props.inputOnBlur}/>
                      </div>
                      <div className="item-title warning" style={{ display: 'none' }}/>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="item-content">
                    <div className="item-media">
                    <b className="text-danger">*</b>
                      <span>{t('loginpage.NameLoginContent.password')}</span>
                    </div>
                    <div className="item-inner">
                      <div className="item-input item-input-field" style={{ display: 'inline-block' }}>
                        <img alt="" src={getImage(`eye-${isShowPassImg}.png`)} style={{right: '0px'}}
                          className="icon-eye" onClick={this.isShowPasswordSwitch}/>
                        <input type={isShowPassType} className="input-field" placeholder={t("6～16个英文字母与数字的组合")}
                               maxLength="16" id="loginPwd" name="loginPwd" onChange={this.props.handlePassword} onFocus={this.props.inputOnFocus} onBlur={this.props.inputOnBlur}/>
                      </div>
                      <div className="item-title warning" style={{ display: 'none' }}/>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="item-content">
                    <div className="item-media">
                    <b className="text-danger">*</b>
                      <span>{t('psdmanagepage.confirmpwd')}</span>
                    </div>
                    <div className="item-inner">
                      <div className="item-input item-input-field"   style={{ display: 'inline-block' }}>
                      <img alt="" src={getImage(`eye-${isShowPassImg2}.png`)} style={{right: '0px'}}
                          className="icon-eye" onClick={this.isShowPasswordSwitch2}/>
                        <input type={isShowPassType2} className="input-field" placeholder={t("与注册密码一致")} maxLength="16" id="checkLoginPwd" name="confirmLoginPwd"
                               onChange={this.props.handleCheckPassword} onFocus={this.props.inputOnFocus} onBlur={this.props.inputOnBlur}/>
                      </div>
                      <div className="item-title warning" style={{ display: 'none' }}/>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="item-content">
                    <div className="item-media">
                    <b className="text-danger">*</b>
                      <span>{t('loginpage.NameLoginContent.verifycode')}</span>
                    </div>
                    <div className="item-inner">
                      <div className="item-input item-input-field item-code">
                        <input type="text" maxLength="6" className="input-field" onFocus={this.props.inputOnFocus}
                               id="validCode"
                               name="validCode"
                               onBlur={this.props.inputOnBlur}/>
                        <span className="phone-email-validcode-span">|</span>
                        <a className="phone-email-validcode-a emailSend" id="getValidCode" onClick={this.props.handleValidCode}>{t('loginpage.NameLoginContent.getverifycode')}</a>
                        <img src="" alt="" style={{display:'none'}} id="validCodeImg"  onClick={this.props.handleValidCode}/>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            {regVerify.authRegisterMember.realNamePresent === 1 ||
            regVerify.authRegisterMember.nickNamePresent === 1 ||
            regVerify.authRegisterMember.birthdayPresent === 1 ||
            regVerify.authRegisterMember.countryPresent === 1 ||
            regVerify.authRegisterMember.passportPresent === 1 ||
            regVerify.authRegisterMember.phoneNumberPresent === 1 ||
            regVerify.authRegisterMember.phoneNumberSendVerify === 1 ||
            regVerify.authRegisterMember.drawPasswordPresent === 1 ||
            regVerify.authRegisterMember.telegramNumberPresent === 1 ||
            regVerify.authRegisterMember.emailPresent === 1 ||
            regVerify.authRegisterMember.emailSendVerify === 1 ||
            regVerify.authRegisterMember.verifymailVerify === 1 ?
              <div className="content-block-title pt0">{t('会员资料')}</div>
              : null}
            <div className="list-block inputs-list" style={{marginTop:0}}>
              <ul className="form-ul">
                {regVerify.authRegisterMember.realNamePresent === 1 ?
                  <li>
                    <div className="item-content">
                      <div className="item-media">
                      {regVerify.authRegisterMember.realNameRequired === 1 ? <b className="text-danger">*</b> : null }
                        <span>{t('detaileditpage.realname')}</span>
                      </div>
                      <div className="item-inner">
                        <div className="item-input item-input-field">
                          <input type="text" className="input-field" id="realName" name="realName"
                                 placeholder={t('请填写真实姓名以便进行核对')}
                                 onFocus={this.props.inputOnFocus} onBlur={this.props.inputOnBlur} onChange={this.props.handleRealName}/>
                        </div>
                        <div className="item-title warning" style={{ display: 'none' }}/>
                      </div>
                    </div>
                  </li>
                  : null
                }
                {regVerify.authRegisterMember.nickNamePresent === 1 ?
                  <li>
                    <div className="item-content">
                      <div className="item-media">
                      {regVerify.authRegisterMember.nickNameRequired === 1 ? <b className="text-danger">*</b> : null }
                        <span>{t('detaileditpage.nickname')}</span>
                      </div>
                      <div className="item-inner">
                        <div className="item-input item-input-field">
                          <input type="text" className="input-field" name="nickName" id="nickName"
                                 placeholder={regVerify.authRegisterMember.nickNameRequired === 1 ? t('offlinepayinfopage.required') : t('offlinepayinfopage.optional')}
                                 onFocus={this.props.inputOnFocus} onBlur={this.props.inputOnBlur} onChange={this.props.handleNickname}/>
                        </div>
                        <div className="item-title warning" style={{ display: 'none' }}/>
                      </div>
                    </div>
                  </li>
                  : null
                }
                {regVerify.authRegisterMember.birthdayPresent === 1 ?
                  <li>
                    <div className="item-content">
                      <div className="item-media">
                      {regVerify.authRegisterMember.birthdayRequired === 1 ? <b className="text-danger">*</b> : null }
                        <span>{t('生日')}</span>
                      </div>
                      <div className="item-inner">
                        <div className="item-input item-input-field">
                          {/*<input type="text" className="input-field" name="birthday"*/}
                          {/*       placeholder={regVerify.authRegisterMember.birthdayRequired === 1 ? '必填' : '选填'}*/}
                          {/*       onFocus={this.props.inputOnFocus} onBlur={this.props.inputOnBlur} value={this.props.startDate}/>*/}
                          <DatePicker
                            className="input-field"
                            id="birthday"
                            placeholderText={regVerify.authRegisterMember.birthdayRequired === 1 ? t('offlinepayinfopage.required') : t('offlinepayinfopage.optional')}
                            onFocus={this.props.inputOnFocus} onBlur={this.props.inputOnBlur}
                            selected={this.props.startDate}
                            onChange={this.props.handleBirthdayChange}
                            dateFormat="yyyy-MM-dd"
                            locale={i18next.language}
                          />
                        </div>
                      </div>
                    </div>
                  </li>
                  : null
                }
                {regVerify.authRegisterMember.phoneNumberPresent === 1 ?
                  <li>
                    <div className="item-content">
                      <div className="item-media"  style={{alignSelf: 'center'}}>
                      {regVerify.authRegisterMember.phoneNumberRequired === 1 ? <b className="text-danger">*</b> : null }
                        <span>{t('手机号码')}</span>
                      </div>
                      <div className="item-inner">
                        <div className="item-input item-input-field">
                          <input type="number" className="input-field" id="phoneInfo" name="phone"
                                 placeholder={regVerify.authRegisterMember.phoneNumberRequired === 1 ? (t('请输入国际区号+手机号') + "," + t('offlinepayinfopage.required')) : (t('请输入国际区号+手机号') + "," + t('offlinepayinfopage.optional'))}
                                 onClick={this.props.showNumberPicker} onChange={this.props.handlePhoneNumber} value={this.props.phoneNumber}
                                 column="phoneInfo" readOnly/>
                        </div>
                        <div className="item-title warning" style={{display: 'none', whiteSpace: 'normal'}}/>
                      </div>
                    </div>
                  </li>
                  : null
                }
                {regVerify.authRegisterMember.phoneNumberSendVerify===1 ?
                  <li style={{display:'none'}} id="phoneCodeId">
                    <div className="item-content">
                      <div className="item-media">
                        <span>{t('detaileditpage.phonevericode')}</span>
                      </div>
                      <div className="item-inner">
                        <div className="item-input item-input-field item-code">
                          <input type="text" className="input-field" onFocus={this.props.inputOnFocus} id="phoneCode"
                                 onBlur={this.props.inputOnBlur}/>
                          <span className="phone-email-validcode-span">|</span>
                          <a className="phone-email-validcode-a phoneSend" id="phoneInfoSend" onClick={this.props.phoneInfoSendOnClick}>{t('loginpage.NameLoginContent.getverifycode')}</a>
                        </div>
                      </div>
                    </div>
                  </li>
                  : null
                }
                {regVerify.authRegisterMember.drawPasswordPresent === 1 ?
                  <li>
                    <div className="item-content">
                      <div className="item-media">
                      {regVerify.authRegisterMember.drawPasswordRequired === 1 ? <b className="text-danger">*</b> : null }
                        <span>{t('psdprotectedpage.safepwd')}</span>
                      </div>
                      <div className="item-inner">
                        <div className="item-input item-input-field">
                          <input type="password" className="input-field" maxLength="16" name="drawMoneyPwd" id="drawMoneyPwd"
                                 placeholder={t('请务必牢记，') + (regVerify.authRegisterMember.drawPasswordRequired === 1 ? t('offlinepayinfopage.required') : t('offlinepayinfopage.optional'))}
                                 onFocus={this.props.inputOnFocus} onBlur={this.props.inputOnBlur} onChange={this.props.handleDrawPwd} onInput={(event) => event.target.value = event.target.value.replace(/[^\w]/g,'')} />
                        </div>
                        <div className="item-title warning" style={{ display: 'none' }}/>
                      </div>
                    </div>
                  </li>
                  : null
                }
                {regVerify.authRegisterMember.telegramNumberPresent === 1 ?
                  <li>
                    <div className="item-content">
                      <div className="item-media">
                      {regVerify.authRegisterMember.telegramNumberRequired === 1 ? <b className="text-danger">*</b> : null }
                        <span>Telegram</span>
                      </div>
                      <div className="item-inner">
                        <div className="item-input item-input-field">
                          <input type="text" className="input-field" placeholder={regVerify.authRegisterMember.telegramNumberRequired === 1 ? t('offlinepayinfopage.required') : t('offlinepayinfopage.optional')} name="telegram" id="telegram"
                                 onFocus={this.props.inputOnFocus} onBlur={this.props.inputOnBlur}/>
                        </div>
                      </div>
                    </div>
                  </li>
                  : null
                }
                {regVerify.authRegisterMember.emailPresent === 1 ?
                  <li>
                    <div className="item-content">
                      <div className="item-media">
                      {regVerify.authRegisterMember.emailRequired === 1 ? <b className="text-danger">*</b> : null }
                        <span>{t('detaileditpage.email')}</span>
                      </div>
                      <div className="item-inner">
                        <div className="item-input item-input-field">
                          <input type="email" className="input-field" id="emailInfo" name="email"
                                 placeholder={t('建议填写常用邮箱，需要激活账号！ ') + (regVerify.authRegisterMember.emailRequired === 1 ? t('offlinepayinfopage.required') : t('offlinepayinfopage.optional'))}
                                 onFocus={this.props.inputOnFocus} onBlur={this.props.inputOnBlur} onChange={this.props.handleEmail}/>
                        </div>
                        <div className="item-title warning" style={{ display: 'none' }}/>
                      </div>
                    </div>
                  </li>
                  : null
                }
                {regVerify.authRegisterMember.emailSendVerify===1 ?
                  <li style={{display:'none'}} id="emailCode">
                    <div className="item-content">
                      <div className="item-media">
                        <span>{t('loginpage.NameLoginContent.verifycode')}</span>
                      </div>
                      <div className="item-inner">
                        <div className="item-input item-input-field item-code">
                          <input type="text" className="input-field" onFocus={this.props.inputOnFocus} name="emailCode"
                                 onBlur={this.props.inputOnBlur}/>
                          <span className="phone-email-validcode-span">|</span>
                          <a className="phone-email-validcode-a emailSend" id="emailInfoSend" onClick={this.props.emailInfoSendOnClick}>{t('loginpage.NameLoginContent.getverifycode')}</a>
                        </div>
                      </div>
                    </div>
                  </li>
                  : null
                }
              </ul>
            </div>
            <div className="store-data list-block">
              <ul>
                {regVerify.authRegisterMember.accountProtocolOpen === 1 ?
                  <li>
                    <label className="label-checkbox item-content">
                      <input type="checkbox" name="agree" value="Books"
                             style={{ marginTop: '17px', height: 0, width: 0 }} id="protoco"/>
                      <div className="item-media"><i className="icon icon-form-checkbox"/></div>
                      <div className="item-inner" style={{ marginLeft: '-30px' }}>
                        <div className="font12" style={{ display:i18next.language!=='zh'?'block':'flex', alignItems: 'center' }}>
                        {t('我已年满18周岁，且同意各项开户条约。')}
                          <label onClick={this.props.TermButton} className="item-link open-popup"
                             style={{ color: 'red', display: 'inline-block',margin:0, }}>{t('“开户协议”')}</label>
                        </div>
                      </div>
                    </label>
                  </li>
                  : null
                }
              </ul>
              {promoRecords.length>0?
                <>
                <div style={{background:'#FAE3E3',margin:' 10px',borderRadius:'5px',display:'flex'}}>
                  <div style={{alignSelf:'center'}}>
                    <img src={getImage(`cashback.png`)} alt="" style={{width:'30px',margin:'auto 5px'}} />
                  </div>
                  <div className="registerUse" style={{width:'85%'}}>
                    <Slider {...settings} className="carouselStyle ">
                      {promoRecords.map((item,index)=>
                        <>
                          <React.Fragment key={index}>
                            <div key={index} style={{fontSize:'16px',fontWeight:'bold',margin:'10px 10px 0 10px'}}>
                              {this.getPromoName(i18next.language, item)}
                            </div>
                            <div style={{margin:'0 0 10px 10px'}} dangerouslySetInnerHTML={{ __html: this.htmlDecode( this.getJsonTranfer(i18next.language, item.promotionDesc)) }}>
                            </div>
                          </React.Fragment>
                        </>
                      )}
                    </Slider>
                  </div>
                </div>
                </>
                :
                ''
                }
              <div className="content-block" style={{marginBottom:30,color:'white'}}>
              <a className="f7-button button-fill register-button" onClick={this.props.regOnClick} style={{color:'white'}}>{t('Confirm')}</a></div>
            </div>
          </div>
          : null
        }
      </form>
    )
  }
}

class PhoneSwipeContent extends React.Component {

  constructor() {
    super();
    this.state = {
      isShowPassword: false,
      isShowPassword2: false,
      promo: null,
      promoRecord: [],
    };
    this.getAllPromotionEventList();
  }

  isShowPasswordSwitch = () => {
    this.setState({isShowPassword:!this.state.isShowPassword});
  }
  isShowPasswordSwitch2 = () => {
    this.setState({isShowPassword2:!this.state.isShowPassword2});
  }

  getAllPromotionEventList() {
    Fetcher(window.$wgtDomain + '/wap/getAllPromotionEventList', {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
      },
      // credentials: 'include',
      referrer: 'h5',
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success && response.promo != null) {
          this.setState({
            promo: response.promo,
          });
          if (response.promo.records != null) {
            let getRecords = [];
            let n = 0;
            for(let i = 0; i < response.promo.records.length; i++){
              if(response.promo.records[i].promotionType ===  'SINCON'){
                getRecords[n] = response.promo.records[i];
                n++;
              }
            }
            this.setState({
              promoRecord: getRecords,
            });
          }
        }
      })
  }
  getPromoName(lang, item){
    var promotionNames = item.promotionName;
    var name="";
    JSON.parse(promotionNames, (key, value) => {
      if (key === lang) {
        name= value;
      }
    });
    return name;
  }

  htmlDecode(input){
    var e = document.createElement('div');
    e.innerHTML = input;
    var returnHtml = '';
    if(e.childNodes[0] !== undefined) {
      var inputHtml = e.childNodes[0].nodeValue;
      let result = inputHtml.indexOf("img");
      if(result !== -1) {
        returnHtml = e.childNodes[0].nodeValue;
        var regexp = /<img[^>]+src\s*=\s*['"]([^'"]+)['"][^>]*>/g;
        var src = returnHtml.match(regexp).map(x => x.replace(/.*src="([^"]*)".*/, '$1'));;
        returnHtml = "<img style='max-width: 100%; width: auto; height: auto;' src="+src[0]+" alt=''><br>"
      } else {
        returnHtml = e.childNodes[0].nodeValue;
      }
    }
    return e.childNodes.length === 0 ? "" : returnHtml;
  }

  getJsonTranfer(lang, item){
    var values="";
    JSON.parse(item, (key, value) => {
      if (key === lang) {
        values= value;
      }
    });
    return values;
  }

  render(){
    const { t } = this.props;
    const regVerify = this.props.regVerify;

    let isShowPassType = this.state.isShowPassword ? 'text' : 'password';
    let isShowPassImg = this.state.isShowPassword ? 'open' : 'close';
    let isShowPassType2 = this.state.isShowPassword2 ? 'text' : 'password';
    let isShowPassImg2 = this.state.isShowPassword2 ? 'open' : 'close';

    let promoRecords = this.state.promoRecord;
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      autoplay: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />
    };
    return(
      <form id="phoneForm">
        {regVerify.phoneOpen === 1 ?
          <div>
            <div className="list-block inputs-list">
              <ul className="form-ul">
                {regVerify.authRegisterMember.sponsorPresent === 0 ?<input type="hidden" value={regVerify.keyCookie} disabled={regVerify.keyCookie} name="keyCookie" className="keyCookieInput"/> : null }
                {regVerify.authRegisterMember.sponsorPresent === 1 ?
                  <li>
                    <div className="item-content">
                      <div className="item-media">
                      {regVerify.authRegisterMember.sponsorRequired === 1 ? <b className="text-danger">*</b> : null }
                        <span>{t('agentpromotionpage.invitecode')}</span>
                      </div>
                      <div className="item-inner">
                        <div className="item-input item-input-field">
                          <input type="text" className="input-field keyCookieInput"
                                 name="keyCookie" maxLength="9" 
                                 placeholder={t('介绍人, ') + (regVerify.authRegisterMember.sponsorRequired === 1 ? t('必填,') : t('选填,')) + t('只能输入字母或数字')}
                                 value={regVerify.keyCookie} disabled={regVerify.keyCookie} id="phoneRegKeyInput"
                                 onFocus={this.props.inputOnFocus} onBlur={this.props.inputOnBlur}/>
                        </div>
                      </div>
                    </div>
                  </li>
                  :
                  null
                }
                <li>
                  <div className="item-content">
                    <div className="item-media" style={{alignSelf: 'center'}}>
                    {regVerify.authRegisterMember.phoneNumberRequired === 1 ? <b className="text-danger">*</b> : null }
                      <span>{t('detaileditpage.phone')}</span>
                    </div>
                    <div className="item-inner">
                      <div className="item-input item-input-field">
                        <input type="number" className="input-field" placeholder={t("请输入国际区号+手机号")}
                               onClick={this.props.showNumberPicker} value={this.props.phoneRegNumber}
                               name="phone"
                               column="phoneId" id="phoneId" readOnly/>
                        <input type="hidden" name="h5" value="h5"/>
                      </div>
                      <div className="item-title warning" style={{ display: 'none', whiteSpace: 'normal' }}/>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="item-content">
                    <div className="item-media">
                      <span>{t('loginpage.NameLoginContent.verifycode')}</span>
                    </div>
                    <div className="item-inner">
                      <div className="item-input item-input-field item-code">
                        <input type="text" className="input-field" onFocus={this.props.inputOnFocus}
                               name="sendCode"
                               onBlur={this.props.inputOnBlur} id="phoneRegCode"/>
                        <span className="phone-email-validcode-span">|</span>
                        <a className="phone-email-validcode-a phoneSend" id="phoneIdSend" onClick={this.props.phoneSendOnClick}>{t('loginpage.NameLoginContent.getverifycode')}</a>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="item-content">
                    <div className="item-media">
                    <b className="text-danger">*</b>
                      <span>{t('loginpage.NameLoginContent.password')}</span>
                    </div>
                    <div className="item-inner">
                      <div className="item-input item-input-field" style={{ display: 'inline-block' }}>
                        <img alt="" src={getImage(`eye-${isShowPassImg}.png`)}
                          className="icon-eye" onClick={this.isShowPasswordSwitch}/>
                        <input type={isShowPassType} className="input-field" placeholder={t("6～16个英文字母与数字的组合")}
                               maxLength="16" id="phoneLoginPwd" name="loginPwd" onChange={this.props.handlePassword} onFocus={this.props.inputOnFocus} onBlur={this.props.inputOnBlur}/>
                      </div>
                      <div className="item-title warning" style={{ display: 'none' }}/>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="item-content">
                    <div className="item-media">
                    <b className="text-danger">*</b>
                      <span>{t('psdmanagepage.confirmpwd')}</span>
                    </div>
                    <div className="item-inner">
                      <div className="item-input item-input-field" style={{ display: 'inline-block' }}>
                        <img alt="" src={getImage(`eye-${isShowPassImg2}.png`)}
                          className="icon-eye" onClick={this.isShowPasswordSwitch2}/>
                        <input type={isShowPassType2} className="input-field" placeholder={t("与注册密码一致")} maxLength="16" id="checkPhoneLoginPwd" name="confirmLoginPwd"
                               onChange={this.props.handleCheckPassword} onFocus={this.props.inputOnFocus} onBlur={this.props.inputOnBlur}/>
                      </div>
                      <div className="item-title warning" style={{ display: 'none' }}/>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            {promoRecords.length>0?
              <>
                <div style={{background:'#FAE3E3',margin:' 10px',borderRadius:'5px',display:'flex'}}>
                  <div style={{alignSelf:'center'}}>
                    <img src={getImage(`cashback.png`)} alt="" style={{width:'30px',margin:'auto 5px'}} />
                  </div>
                  <div className="registerUse" style={{width:'85%'}}>
                    <Slider {...settings} className="carouselStyle ">
                      {promoRecords.map((item,index)=>
                        <>
                          <React.Fragment key={index}>
                            <div key={index} style={{fontSize:'16px',fontWeight:'bold',margin:'10px 10px 0 10px'}}>
                              {this.getPromoName(i18next.language, item)}
                            </div>
                            <div style={{margin:'0 0 10px 10px'}} dangerouslySetInnerHTML={{ __html: this.htmlDecode( this.getJsonTranfer(i18next.language, item.promotionDesc)) }}>
                            </div>
                          </React.Fragment>
                        </>
                      )}
                    </Slider>
                  </div>
                </div>
              </>
              :
              ''
            }
            <div className="content-block" style={{marginBottom:30,color:'white'}}>
              <a className="f7-button button-fill register-button" onClick={this.props.phoneBtnOnClick} style={{color:'white'}}>{t('Confirm')}</a></div>
          </div>
          : null
        }
      </form>
    )
  }
}

class EmailSwipeContent extends React.Component {
  constructor() {
    super();
    this.state = {
      promo: null,
      promoRecord: [],
    };
    this.getAllPromotionEventList();
  }

  getAllPromotionEventList() {
    Fetcher(window.$wgtDomain + '/wap/getAllPromotionEventList', {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
      },
      // credentials: 'include',
      referrer: 'h5',
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success && response.promo != null) {
          this.setState({
            promo: response.promo,
          });
          if (response.promo.records != null) {
            let getRecords = [];
            let n = 0;
            for(let i = 0; i < response.promo.records.length; i++){
              if(response.promo.records[i].promotionType ===  'SINCON'){
                getRecords[n] = response.promo.records[i];
                n++;
              }
            }
            this.setState({
              promoRecord: getRecords,
            });
          }
        }
      })
  }
  getPromoName(lang, item){
    var promotionNames = item.promotionName;
    var name="";
    JSON.parse(promotionNames, (key, value) => {
      if (key === lang) {
        name= value;
      }
    });
    return name;
  }

  htmlDecode(input){
    var e = document.createElement('div');
    e.innerHTML = input;
    var returnHtml = '';
    if(e.childNodes[0] !== undefined) {
      var inputHtml = e.childNodes[0].nodeValue;
      let result = inputHtml.indexOf("img");
      if(result !== -1) {
        returnHtml = e.childNodes[0].nodeValue;
        var regexp = /<img[^>]+src\s*=\s*['"]([^'"]+)['"][^>]*>/g;
        var src = returnHtml.match(regexp).map(x => x.replace(/.*src="([^"]*)".*/, '$1'));;
        returnHtml = "<img style='max-width: 100%; width: auto; height: auto;' src="+src[0]+" alt=''><br>"
      } else {
        returnHtml = e.childNodes[0].nodeValue;
      }
    }
    return e.childNodes.length === 0 ? "" : returnHtml;
  }

  getJsonTranfer(lang, item){
    var values="";
    JSON.parse(item, (key, value) => {
      if (key === lang) {
        values= value;
      }
    });
    return values;
  }
  render(){
    const { t } = this.props;
    const regVerify = this.props.regVerify;
    let promoRecords = this.state.promoRecord;
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      autoplay: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />
    };
    return(
      <form id="emailForm">
        {regVerify.emailOpen === 1 ?
          <div>
            <div className="list-block inputs-list">
              <ul className="form-ul">
                {regVerify.authRegisterMember.sponsorPresent === 0 ?
                  <input type="hidden" value={regVerify.keyCookie} disabled={regVerify.keyCookie} name="keyCookie" className="keyCookieInput"/> : null}
                {regVerify.authRegisterMember.sponsorPresent === 1 ?
                  <li>
                    <div className="item-content">
                      <div className="item-media">
                      {regVerify.authRegisterMember.sponsorRequired === 1 ? <b className="text-danger">*</b> : null }
                        <span>{t('agentpromotionpage.invitecode')}</span>
                      </div>
                      <div className="item-inner">
                        <div className="item-input item-input-field">
                          <input type="text" className="input-field keyCookieInput" maxLength="9" 
                                 placeholder={t('介绍人, ') + (regVerify.authRegisterMember.sponsorRequired === 1 ? t('必填,') : t('选填,')) + t('只能输入字母或数字')}
                                 value={regVerify.keyCookie} disabled={regVerify.keyCookie}
                                 onFocus={this.props.inputOnFocus} onBlur={this.props.inputOnBlur}/>
                        </div>
                      </div>
                    </div>
                  </li>
                  :
                  null
                }
                <li>
                  <div className="item-content">
                    <div className="item-media">
                    {regVerify.authRegisterMember.emailRequired === 1 ? <b className="text-danger">*</b> : null }
                      <span>{t('detaileditpage.email')}</span>
                    </div>
                    <div className="item-inner">
                      <div className="item-input item-input-field">
                        <input type="email" className="input-field" placeholder={t('请输入邮箱')} name="email" id="emailId"
                               onFocus={this.props.inputOnFocus} onBlur={this.props.inputOnBlur} onChange={this.props.handleEmail}/>
                        <input type="hidden" name="h5" value="h5"/>
                      </div>
                      <div className="item-title warning" style={{ display: 'none' }}/>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="item-content">
                    <div className="item-media">
                      <span>{t('loginpage.NameLoginContent.verifycode')}</span>
                    </div>
                    <div className="item-inner">
                      <div className="item-input item-input-field item-code">
                        <input type="text" className="input-field" onFocus={this.props.inputOnFocus}
                               name="sendCode"
                               onBlur={this.props.inputOnBlur} id="emailRegCode"/>
                        <span className="phone-email-validcode-span">|</span>
                        <a className="phone-email-validcode-a emailSend" id="emailIdSend" onClick={this.props.emailSendOnClick}>{t('loginpage.NameLoginContent.getverifycode')}</a>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            {promoRecords.length>0?
              <>
                <div style={{background:'#FAE3E3',margin:' 10px',borderRadius:'5px',display:'flex'}}>
                  <div style={{alignSelf:'center'}}>
                    <img src={getImage(`cashback.png`)} alt="" style={{width:'30px',margin:'auto 5px'}} />
                  </div>
                  <div className="registerUse" style={{width:'85%'}}>
                    <Slider {...settings} className="carouselStyle ">
                      {promoRecords.map((item,index)=>
                        <>
                          <React.Fragment key={index}>
                            <div key={index} style={{fontSize:'16px',fontWeight:'bold',margin:'10px 10px 0 10px'}}>
                              {this.getPromoName(i18next.language, item)}
                            </div>
                            <div style={{margin:'0 0 10px 10px'}} dangerouslySetInnerHTML={{ __html: this.htmlDecode( this.getJsonTranfer(i18next.language, item.promotionDesc)) }}>
                            </div>
                          </React.Fragment>
                        </>
                      )}
                    </Slider>
                  </div>
                </div>
              </>
              :
              ''
            }
            <div className="content-block" style={{marginBottom:30,color:'white'}}>
              <a className="f7-button button-fill register-button" onClick={this.props.emailBtnOnClick} style={{color:'white'}}>{t('Confirm')}</a></div>
          </div>
          : null
        }
        {regVerify.adsOpen === 1 ?
          <img id={regVerify.adsProductId+"_cpa"} src={regVerify.adsUrl} width="1" height="1" border="0" />:null
        }
      </form>
    )
  }
}



class RegisterPage extends React.Component {

  // constructor(props) {
  //   super(props);
  // }

  state = {
    activeIndex: 0,
    numberPickerShow:false,
    phoneNumber:'',
    phoneRegNumber:'',
    pickerCol:'',
    inviteNumber:'',
    startDate: '',
    showTerm:false,
    generateValidCode:''
  };

  // componentDidMount() {
  //   }
  // }

  closeModal(){
    let id = window.setTimeout(function() {}, 0);
    while (id--) {
      window.clearTimeout(id);
    }
    this.setState({
      phoneNumber:'',
      phoneRegNumber:'',
      activeIndex:0
    });
    closeRegisterModal();
  }

  handleActiveIndexUpdate(e) {
    let input = e.currentTarget.getAttribute('ivalue');
    this.setState({ activeIndex: parseInt(input) });
  };

  handleChangeIndex = input => {
    this.setState({ activeIndex: input });
  };

  showNumberPicker(e){
    this.setState({
      numberPickerShow : !this.state.numberPickerShow,
      pickerCol: e.currentTarget.getAttribute('column'),
    })
  }

  clickOutside(){
    if(this.state.numberPickerShow){
      this.setState({
        numberPickerShow : !this.state.numberPickerShow,
      })
    }
  }

  selectPickNum = (input, event) => {
    let iosVersion = 100;
    if(window.$isIonic){
      if(window.$platform == 'IOS') {
        iosVersion = window.$platformVersion.split('.')[0];
      }
    }
    else if (window.$isApp){
      let plus = window.$plus;
      if(plus.os.name.toUpperCase() == 'IOS') {
        iosVersion = plus.os.version.split('.')[0];
      }
    }

    if(event.pointerType=='touch' || parseInt(iosVersion) < 15 || (!window.$isApp && event.pointerType=='mouse') || event.pointerType==undefined){
      if(input =='.'){

      }
      else if(input =='{bksp}'){
          this.deletePickNum();
      }
      else{
        let phoneInput;
        if(this.state.pickerCol === 'phoneInfo'){
          this.setState({
            phoneNumber: this.state.phoneNumber + input
          })
          phoneInput = document.getElementById('phoneInfo');
        }else if (this.state.pickerCol === 'phoneId'){
          this.setState({
            phoneRegNumber: this.state.phoneRegNumber + input
          })
          phoneInput = document.getElementById('phoneId');
        }
        this.handlePhoneNumber(phoneInput);
      }
    }
  };

  deletePickNum = () =>{
    let phoneInput;
    if(this.state.pickerCol === 'phoneInfo'){
      this.setState({
        phoneNumber: this.state.phoneNumber.toString().slice(0, -1)
      })
      phoneInput = document.getElementById('phoneInfo');
    }else if (this.state.pickerCol === 'phoneId'){
      this.setState({
        phoneRegNumber: this.state.phoneRegNumber.toString().slice(0, -1)
      })
      phoneInput = document.getElementById('phoneId');
    }
    this.handlePhoneNumber(phoneInput);
  };

  selectComplete(){
    if(this.state.numberPickerShow){
      this.setState({
        numberPickerShow : !this.state.numberPickerShow,
      })
    }
  }

  inputOnFocus(event) {
    let a = event.currentTarget.parentElement;
    a.classList.add('focus-state');
  }

  inputOnBlur(event) {
    let a = event.currentTarget.parentElement;
    a.classList.remove('focus-state');
  }

  handleUsernameReg(event){
    validateLoginName(event);
  }

  handlePassword(event){
    validateLoginPwd(event);
  }

  handlePhonePassword(event){
    validatePhoneLoginPwd(event);
  }

  handleCheckPassword(event){
    validateCheckPwd(event);
  }

  handleCheckPhonePassword(event){
    validateCheckPhonePwd(event);
  }

  handleValidCode(event){
    let randomCode = new Date().getTime();
    this.setState({
      generateValidCode:randomCode
    })
    validateCode(randomCode);
  }

  handleRealName(event){
    validateRealName(event);
  }

  handleNickname(event){
    validateNickname(event);
  }
  handlePhoneNumber(event){
    validatePhoneWap(event);
  }

  handleEmail(event){
    validateEmailWap(event);
  }

  handleDrawPwd(event){
    validateDrawMoneyPwd(event);
  }

  handleBirthdayChange = date => {
    this.setState({
      startDate: date
    });
  };

  regOnClick(){
    this.props.modal.registerModal.result.generateValidCode = this.state.generateValidCode;
    let regVerify = this.props.modal.registerModal.result;
    regBtn(regVerify);
  }

  phoneBtnOnClick(){
    let regVerify = this.props.modal.registerModal.result;
    phoneBtn(regVerify);
  }

  emailBtnOnClick(){
    let regVerify = this.props.modal.registerModal.result;
    emailBtn(regVerify);
  }

  phoneSendOnClick(){
    phoneSend();
  }

  phoneInfoSendOnClick(){
    phoneInfoSend();
  }

  emailInfoSendOnClick(){
    emailInfoSend();
  }

  emailSendOnClick(){
    emailSend();
  }

  TermButton(){
    console.log("HERE")
    this.setState({showTerm: !this.state.showTerm})
  }

  getImage(imagewithtype){
    try{
      return images('./'+imagewithtype);
    } catch (err) {
      console.error(err);
      return window.$wgtDomain+"/imagenotfound.png"
    }
  }

  goBack = () => {
    window.location.href = window.location.href.split('#')[0];
  };

  goBackDeposit = () => {
    this.props.history.push('/recharge-index');
  };

  render() {
    const { t } = this.props;
    let tabBarItems;
    const { activeIndex } = this.state;
    //const { t } = this.props;
    const regVerify = this.props.modal.registerModal.result;
    let swipeItemsContent = [];
    let tabBarItemsContent = [];
    if(regVerify){
      if(regVerify.regOpen === 1){
        tabBarItemsContent.push(t('账号注册'));
        swipeItemsContent.push(<RegSwipeContent key={0} regVerify={regVerify} handleUsernameReg={this.handleUsernameReg.bind(this)} handlePassword={this.handlePassword.bind(this)} handleCheckPassword={this.handleCheckPassword.bind(this)} handleValidCode={this.handleValidCode.bind(this)} handleRealName={this.handleRealName.bind(this)} handleNickname={this.handleNickname.bind(this)} handlePhoneNumber={this.handlePhoneNumber.bind(this)} handleEmail={this.handleEmail.bind(this)} handleDrawPwd={this.handleDrawPwd.bind(this)} inputOnFocus={this.inputOnFocus.bind(this)} inputOnBlur={this.inputOnBlur.bind(this)} handleBirthdayChange={this.handleBirthdayChange.bind(this)} startDate={this.state.startDate} showNumberPicker={this.showNumberPicker.bind(this)} phoneNumber={this.state.phoneNumber} phoneInfoSendOnClick={this.phoneInfoSendOnClick.bind(this)} emailInfoSendOnClick={this.emailInfoSendOnClick.bind(this)} regOnClick={this.regOnClick.bind(this)} inviteNumber={this.state.inviteNumber} TermButton={this.TermButton.bind(this)} {...this.props}/>)
      }
      if(regVerify.phoneOpen === 1){
        tabBarItemsContent.push(t('手机注册'));
        swipeItemsContent.push(<PhoneSwipeContent key={1}  regVerify={regVerify} inputOnFocus={this.inputOnFocus.bind(this)} inputOnBlur={this.inputOnBlur.bind(this)} handlePassword={this.handlePhonePassword.bind(this)} handleCheckPassword={this.handleCheckPhonePassword.bind(this)} showNumberPicker={this.showNumberPicker.bind(this)} phoneRegNumber={this.state.phoneRegNumber} inviteNumber={this.state.inviteNumber} phoneBtnOnClick={this.phoneBtnOnClick.bind(this)} phoneSendOnClick={this.phoneSendOnClick.bind(this)} {...this.props}/>)
      }
      if(regVerify.emailOpen === 1){
        tabBarItemsContent.push(t('邮箱注册'));
        swipeItemsContent.push(<EmailSwipeContent key={2}  regVerify={regVerify} handleEmail={this.handleEmail.bind(this)} inputOnFocus={this.inputOnFocus.bind(this)} inputOnBlur={this.inputOnBlur.bind(this)} inviteNumber={this.state.inviteNumber} emailBtnOnClick={this.emailBtnOnClick.bind(this)} emailSendOnClick={this.emailSendOnClick.bind(this)} {...this.props}/>)
      }
      tabBarItems = tabBarItemsContent.map((item, index) => {
        return (
          <li key={index} className={'nav-link' + (activeIndex === index ? ' active-link' : "")}>
            <a ivalue={index} onClick={this.handleActiveIndexUpdate.bind(this)}>{item}</a>
            <div className="underline"></div>
          </li>
        )
      })
    }

    return (
          <Modal fade={false} isOpen={this.props.modal.registerModal.show} className="register-modal">
            {/*header*/}
            {this.props.modal.registerModal.show ?
              <>
                <div style={{
                  backgroundColor: 'var(--theme-color)',
                  color: '#fff',
                  flexDirection: 'row',
                  padding: '10px',
                  lineHeight: '24px',
                  display: 'block',
                  textAlign: 'center',
                }} onClick={this.clickOutside.bind(this)}>
                  <div style={{ float: 'left', position: 'absolute', left: 10, top: 0, lineHeight: '44px' }}>
                    <Button outline style={{ outline: 'none', border: 0, alignSelf: 'flex-start', padding: 0 }}>
                      <MdArrowBack size={24} onClick={this.closeModal.bind(this)}/>
                    </Button>
                  </div>
                  <div className="center" style={{ fontSize: 18 }}>{t('会员注册')}</div>
                </div>
                {/*page content*/}
                {regVerify ?
                  <div onClick={this.clickOutside.bind(this)}>
                    <div className="navbar-container">
                      <ul>
                        {tabBarItems}
                      </ul>
                    </div>
                    <SwipeableViews index={activeIndex} onChangeIndex={this.handleChangeIndex} animateHeight={true}
                                    disableLazyLoading={true}
                                    className="register-content register-content-bgcolor">
                      {swipeItemsContent}
                    </SwipeableViews>
                  </div>
                  : null
                }
                {this.state.pickerCol === 'phoneInfo' ?
                  <SimpleNumberPickerModal show={this.state.numberPickerShow} value={this.state.phoneNumber}
                                           selectPickNum={this.selectPickNum.bind(this)}
                                           deletePickNum={this.deletePickNum.bind(this)}
                                           complete={this.selectComplete.bind(this)}/>
                  :
                  this.state.pickerCol === 'phoneId' ?
                    <SimpleNumberPickerModal show={this.state.numberPickerShow} value={this.state.phoneRegNumber}
                                             selectPickNum={this.selectPickNum.bind(this)}
                                             deletePickNum={this.deletePickNum.bind(this)}
                                             complete={this.selectComplete.bind(this)}/>
                    :
                    null
                }
                <RegisterTermModal show={this.state.showTerm} onClose={this.TermButton.bind(this)}/>
              </>
              : null
            }
          </Modal>

    );

  };
}

const ConnectedRegisterPage = connect(mapStateToProps, mapDispatchToProps)(RegisterPage);
export default withTranslation('common')(ConnectedRegisterPage);
