import React from 'react';
import { withTranslation } from "react-i18next";
import bn from '../../../utils/bemnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { UpdateAlert } from '../../../actions/HomePageAction';
import { MdPhotoFilter } from 'react-icons/md';
import AgentPromotionModal from './AgentPromotionModal';
import { Col } from 'reactstrap';
import Fetcher from '../../../components/Fetcher';

const bem = bn.create('header');

const mapStateToProps = (state) => {
    return {
        alertObj: state.alert,
        indexInit: state.indexInit.indexInit,
    };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({
        UpdateAlert,
    }, dispatch)
);

const deleteFunction = [];

class AgentPromotionTab2 extends React.Component {
    constructor(props) {
        super(props);
        this.typechange = this.typechange.bind(this);
        this.edit = this.edit.bind(this);
        this.callDeleteApi =this.callDeleteApi.bind(this);
        this.delete = this.delete.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.toDetails = this.toDetails.bind(this);
        this.copy = this.copy.bind(this);
        this.normalAlert = this.normalAlert.bind(this);
        while (deleteFunction.length > 0) { deleteFunction.pop(); }
        deleteFunction.push(this.callDeleteApi);
    }

    state = {
        newpromotion: {
            accountType: "0",
        },
        promotionData: {
            promotionId: 0
            , promotionacctype: 0
        },
		landinPage: {},
		resList: [],
		activityList: [],
        platforms: [],
        loadFlag: false,
        toDeleteId: null,
        selectedDetails: [],
        showModal: false,
        isShare: false,
    };

    typechange(e) {
        let currState = this.state;
        const type = e.target.value;
        if(type){//prevent undefined error
            currState.newpromotion.accountType = type;
            currState.promotionData.promotionacctype = type;
            this.setState(currState, this.callApiList());
        }
    }

    normalAlert(content) {
        const { alertObj, UpdateAlert } = this.props;
        alertObj.title = this.props.t('subaccountpage.hitsmsg');
        alertObj.alertType = 'normalAlert';
        alertObj.showAlert = true;
        alertObj.content = content;
        alertObj.functionName = false;
        UpdateAlert(alertObj);
    }

    callApiList() {
        let loginUser = this.props.indexInit.loginUser;
        const param = new URLSearchParams();
        if (typeof (this.state.promotionData.promotionacctype) != 'undefined') {
            param.set('accountType', this.state.promotionData.promotionacctype);
            if (typeof (loginUser.loginName) != 'undefined') {
                Fetcher(window.$wgtDomain + '/wap/member-center/promotion/list', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    // credentials: 'include',
                    body: param,
                    referrer: 'h5'
                })
                    .then((response) => {
                        if (!response.ok) {
                        } else {
                            response.json()
                                .then((data) => {
                                    let currState = this.state;
                                    currState.loadFlag = true;
                                    currState.landinPage = data.pagelink;
                                    currState.resList = data.result;
                                    currState.activityList = data.activityList;
                                    currState.platforms = data.platforms;
                                    this.setState(currState);
                                })
                        }
                    })
    
            } else {
                this.normalAlert(this.props.t('subaccountpage.pleaselogin'));
            }
        }
    }

    closeModal() {
        this.setState({ showModal: false });
    }

    toDetails(event) {
        const selectedId = event.currentTarget.getAttribute('selectedkey');
        const isShare = event.currentTarget.getAttribute('isshare');
        console.log(isShare);
        this.setState({
            selectedDetails: this.state.resList.find(res => res.id == selectedId),
            showModal: true,
            isShare: isShare,
        })
    }
    
    copy(event) {
        const keyDisplay = event.currentTarget.getAttribute('keydisplay');
        //navigator.clipboard.writeText(keyDisplay);
        let textField = document.createElement('textarea');
        textField.innerText = keyDisplay;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        this.normalAlert(this.props.t('agentpromotiontab2.copyshare'));
    }

    edit(event) {
        const selectedId = event.currentTarget.getAttribute('selectedkey');
        this.props.setSelecteId(selectedId);
    }

    delete(event) {
        const selectedId = event.currentTarget.getAttribute('selectedkey');
        const keyDisplay = event.currentTarget.getAttribute('keydisplay');
        this.setState({ toDeleteId: selectedId });
        const { alertObj, UpdateAlert } = this.props;
        alertObj.title = this.props.t('subaccountpage.hitsmsg');
        alertObj.alertType = 'confirmAlertWithFunction';
        alertObj.showAlert = true;
        alertObj.content = this.props.t('agentpromotiontab2.confirmdelete') + '[' + keyDisplay + ']' + this.props.t('agentpromotiontab2.promolink') + '?';
        alertObj.isShowCancelBtn = true;
        alertObj.functionName = 'AgentPromotionTab2Delete';
        UpdateAlert(alertObj);
    }

    callDeleteApi() {
        console.log('calling : ' + this.state.toDeleteId)
        Fetcher(window.$wgtDomain + '/wap/member-center/promotion/delete/' + this.state.toDeleteId, {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            // credentials: 'include',
            referrer: 'h5'
        })
            .then((response) => {
                if (!response.ok) {
                } else {
                    response.json()
                        .then((data) => {
                            this.normalAlert(data.msg);
                            if (data.status) {
                                const currState = this.state;
                                currState.loadFlag = false;
                                currState.landinPage = {};
                                currState.resList = [];
                                currState.activityList = [];
                                currState.platforms = [];
                                this.setState(currState, this.callApiList);
                            }
                        })
                }
            })
    }

    componentDidMount() {
        this.callApiList();
    }

    render() {
        const { t } = this.props;
        const currState = this.state;
        let iosVersion = 0;
        if(window.$isIonic){
            if(window.$platform == 'IOS') {
                iosVersion = window.$platformVersion.split('.')[0];
            }
        }
        else if (window.$isApp){
            let plus = window.$plus;
            if(plus.os.name.toUpperCase() == 'IOS') {
                iosVersion = plus.os.version.split('.')[0];
            }
        }
        return (
            <div style={{background: '#f4f4f4'}}>
                <div className="sub-account" className="sub-account page-content-44">
                    <div className="list-block" style={{margin: '0px'}}>
                        <ul>
                            <li>
                                <div className="item-content" style={parseInt(iosVersion) < 16?{}:{position:'-webkit-sticky'}}>
                                    <Col className="item-media">{t('subaccountpage.acctype')}</Col>
                                    <Col xs="9">
                                        <RadioGroup style={{ paddingTop: 10}} row value={this.state.newpromotion.accountType}>
                                        <FormControlLabel classes={{ label: 'font14px' }} value={"0"} control={<Radio style={{color:'var(--theme-color)'}} />} labelPlacement="end" label={t("subaccountpage.agenttype")} onClick={this.typechange} />
                                        <FormControlLabel classes={{ label: 'font14px' }} value={"1"} control={<Radio style={{color:'var(--theme-color)'}} />} labelPlacement="end" label={t("agentpromotiontab1.membertype")} onClick={this.typechange} />
                                        </RadioGroup>
                                    </Col>
                                </div>
                                <div className="clearfix"></div>
                            </li>
                        </ul>
                    </div>
                    <div className="agent-promo-tab2">
                        { currState.resList && currState.resList.length > 0 ? 
                            currState.resList.map((tgm, index) => 
                            <div key={index} className="card DataTotal-card">
                                <div className="card-content">
                                    <div className="list-block media-list">
                                        <ul>
                                            <li>
                                                <label className="label-radio item-content">
                                                    <div className="item-inner">
                                                        <div className="item-title-row">
                                                            <div className="item-title">{t('agentpromotionpage.invitecode')}：<span>{tgm.obfuscateKey}</span></div>
                                                            <div className="item-after">{t('gaiamainpage.bottomhotkeybar.register')}(<span>{tgm.registerNum}</span>)</div>
                                                        </div>
                                                        <div className="item-text" style={{marginTop: '4px'}}>{t('agentpromotionpage.invitecode')}ID：<span>{tgm.key}</span></div>
                                                        <div className="item-text" style={{marginTop: '4px'}}>{t('agentpromotiontab1.landingpage')}：<span>{this.state.landinPage[tgm.landinPage]}</span></div>
                                                    </div>
                                                    <div className="item-after" style={{marginRight: '8px'}} selectedkey={tgm.id} onClick={this.edit}>
                                                        <i className="icon icon-edit" style={{fontSize: '24px'}}></i>
                                                    </div>
                                                    <div className="item-after" style={{marginRight: '8px'}} selectedkey={tgm.id} keydisplay={tgm.key} onClick={this.delete} >
                                                        <i className="icon icon-delete1" style={{fontSize: '24px'}}></i>
                                                    </div>
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    { tgm.accountType === 0 ? 
                                        <a className="link open-popup" style={{fontSize: 14, color: 'var(--theme-color)'}} isshare={0} selectedkey={tgm.id} onClick={this.toDetails}>{t('agentpromotiontab2.viewcommrate')}</a> : null
                                    }
                                    <a className="link btnClip" style={{fontSize: 14, color: 'var(--theme-color)'}} keydisplay={tgm.obfuscateKey} onClick={this.copy}>{t('agentpromotiontab2.copyinvitecode')}</a>
                                    <a className="link open-popup" style={{fontSize: 14, color: 'var(--theme-color)'}} isshare={1} selectedkey={tgm.id} onClick={this.toDetails}>{t('agentpromotiontab2.share')}</a>
                                </div>
                            </div>
                            ) :
                            <div style={{ textAlign: 'center', paddingTop: 50, paddingBottom: 50, lineHeight: 1 }}>
                                <MdPhotoFilter size={110} style={{ color: '#ccc' }} />
                                <div style={{ lineHeight: 1, color: '#ccc', fontSize: 20 }}>{t('no_record_found')}</div>
                            </div>
                        }
                    </div>
                </div>
                <AgentPromotionModal showModal={currState.showModal} closeModal={this.closeModal} isShare={currState.isShare} selectedDetails={currState.selectedDetails}
                    platforms={currState.platforms} normalAlert={this.normalAlert}></AgentPromotionModal>
            </div>
        );
    }
}

export { deleteFunction }
export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(AgentPromotionTab2));