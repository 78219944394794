import cloneDeep from 'lodash.clonedeep';
import moment from 'moment';
import { store } from '../index';
import { updateBetRecord, updatePopupObj,updateLuzituObj } from '../actions/GameAction';
import { preventOverride } from './GameService';
import MSG from '../utils/enums';
import { UpdateAlert } from '../actions/HomePageAction';
import i18next from 'i18next';
import { repeatGameStop } from './AlertService';
import {getMemberPauseWarning,getMemberSleepWarning,getMemberRestWarning} from '../services/HomeService';
import GameFetcher from '../components/GameFetcher';

const t = (input) => {
  return i18next.t(input);
}

const init = (gamePlayObject, gameAwardObj) => {
  let memberInfoObj = store.getState().memberInfo;
  const countdownEl = document.getElementsByClassName("countdown-element")[0];
  let countdownsound = memberInfoObj.AccountConfigs["accountConfigs"+memberInfoObj.UserInfo.userInfo].isCountDownSound;
  if(countdownsound !== null){
    gameAwardObj.countdownSound = countdownsound;
  }else{
    gameAwardObj.countdownSound = true;
  }
  if(countdownEl) countdownEl.pause();
  if(gameAwardObj.countdownSound == true){
    if(countdownEl) countdownEl.muted = false;
  }else{
    if(countdownEl) countdownEl.muted = true;
  }
  gameAwardObj.game = cloneDeep(gamePlayObject.game);
  gameAwardObj.award = cloneDeep(gamePlayObject.award);
  gameAwardObj.init_countdown_sound = false;
  gameAwardObj.openAwardIntervalNum = cloneDeep(gamePlayObject.openAwardIntervalNum);
  gameAwardObj.isloadaward = cloneDeep(gamePlayObject.isloadaward);
  gameAwardObj.isinit = true;
  gameAwardObj.AWARD_NUM = cloneDeep(gamePlayObject.AWARD_NUM);
  gameAwardObj.timer = gamePlayObject.timer;
  let loctime = gameAwardObj.loctime = gamePlayObject.loctime;
  let sertime = gameAwardObj.sertime = gamePlayObject.sertime;
  let timezone = gameAwardObj.timezone = gamePlayObject.timezone;
  let changeTimezone = gameAwardObj.changeTimezone = !timezone || isNaN(timezone) || timezone !== new Date().getTimezoneOffset();
  if (changeTimezone || !loctime || isNaN(loctime) || !sertime || isNaN(sertime) || Math.abs(new Date().getTime() - loctime) > 30 * 60 * 1000) {//超过半个小时,需要校验时间
    // console.log("before reload"+gameAwardObj.timer);
    clearInterval(gameAwardObj.timer);
    getCurrentTime(gameAwardObj,true);
  } else {
    let timestamp = new Date().getTime() - loctime;
    let servertime = sertime;
    gameAwardObj.currentTimes = servertime + timestamp;
    // console.log("before Init"+gameAwardObj.timer);
    clearInterval(gameAwardObj.timer);
    gameAwardObj.timer = setInterval(() => {
      downseconds(gameAwardObj);
    }, 1000);
    // console.log("after Init"+gameAwardObj.timer);
    loadPeriod(null, gameAwardObj);
  }
};

const getCurrentTime = (gameAwardObj,enable) => {
  // console.log('currenttime at AwardService getCurrentTime:'+window.$gameUrl);
  fetch(window.$gameUrl + '/rest/game/lottery/currenttime', fetchConfig)
    .then(res => res.json())
    .then(
      (data) => {
        if (data.status) {
          gameAwardObj.currentTimes = data.result.serverTime + getDateOffset();
          gameAwardObj.sertime = gameAwardObj.currentTimes;
          gameAwardObj.loctime = new Date().getTime();

          if (enable) {
            // console.log("before Current"+gameAwardObj.timer);
            clearInterval(gameAwardObj.timer);
            gameAwardObj.timer = setInterval(() => {
              downseconds(gameAwardObj);
            }, 1000);
            // console.log("after Current"+gameAwardObj.timer);
          }

          let timezone = gameAwardObj.timezone;
          let changeTimezone = !timezone || isNaN(timezone) || timezone != new Date().getTimezoneOffset();
          loadPeriod(changeTimezone, gameAwardObj);
          gameAwardObj.timezone = new Date().getTimezoneOffset();
        }
      },
    );
};

const getDateOffset = () => {
  return 288e5 + (new Date().getTimezoneOffset() * 6e4);
};

const fetchConfig = {
  method: 'get',
  headers: new Headers({
    'Accept': 'application/json, text/plain, */*',
    'Content-Type': 'application/x-www-form-urlencoded',
  }),
  // credentials: 'include',
};

//加载期数
const loadPeriod = (r, gameAwardObj) => {
  let issues = gameAwardObj.issues;
  let currentIssue = typeof r == 'boolean' ? undefined : r;
  let alertObj = store.getState().alert;
  if (!issues || !issues.length || issues.length < 2 || (r && r === true)) {
    // let rules = chaos.cache.getItem(GAMERULE_KEY);
    let rules = gameAwardObj.gamerule_key;
    if (rules) {
      issues = genissues(rules, gameAwardObj.currentTimes, gameAwardObj.game.maxIssue);
      handPeriod(issues, gameAwardObj);
    } else {
      let PERIOD_URL = '/rest/game/lottery/loadissue/' + gameAwardObj.game.id;
      fetch(window.$gameUrl + PERIOD_URL, fetchConfig)
      // fetch("//lotto.ybapi8888.com" + PERIOD_URL, fetchConfig)
        .then(res => res.json())
        .then(
          (data) => {
            if (data.status) {
              if (data.result.issues) {//有期数
                data.result.issues.forEach(function(is, inx) {
                  is.startTime += getDateOffset();
                  is.endTime += getDateOffset();
                  is.awardTime += getDateOffset();
                });
                handPeriod(data.result.issues, gameAwardObj);
              } else if (data.result.rules) {//生成期数
                //chaos.cache.setItem(GAMERULE_KEY, data.result.rules);
                gameAwardObj.gamerule_key = data.result.rules;
                issues = genissues(data.result.rules, gameAwardObj.currentTimes, gameAwardObj.game.maxIssue);
                handPeriod(issues, gameAwardObj);
              } else {
                alertObj.content = t('MSG.E_23');
                alertObj.title = t('MSG.T_00') ;
                alertObj.alertType = 'goBackAlert';
                alertObj.showAlert = true;
                store.dispatch(UpdateAlert(alertObj));
              }
              let pausePopup = getMemberPauseWarning();
              let sleepPopup = getMemberSleepWarning();
              if(pausePopup){
                let popupObj = store.getState().game.popupObj;
                   popupObj.popupType = 'pauseWarning';
                  popupObj.showPopup = true;
                  store.dispatch(updatePopupObj(popupObj));
              }else if(sleepPopup){
                  let popupObj = store.getState().game.popupObj;
                  popupObj.popupType = 'sleepWarning';
                  popupObj.showPopup = true;
                  store.dispatch(updatePopupObj(popupObj));
              }
            } else {
              // chaos.fw7.alert(data.message,MSG.T_00,function(){chaos.fw7.curView.router.back()});
              alertObj.content = data.message ? data.message : t('MSG.E_23');
              alertObj.title = t('MSG.T_00') ;
              alertObj.alertType = 'goBackAlert';
              alertObj.showAlert = true;
              store.dispatch(UpdateAlert(alertObj));
            }
          },
          (error) => {
            alertObj.content = t('MSG.E_23');
            alertObj.title = t('MSG.T_00');
            alertObj.alertType = 'goBackAlert';
            alertObj.showAlert = true;
            store.dispatch(UpdateAlert(alertObj));
            // chaos.fw7.alert(MSG.E_23,MSG.T_00,function(){chaos.fw7.curView.router.back()});
          },
        );
    }
  } else {
    handPeriod(issues, gameAwardObj);
  }
};

Date.prototype.Format = function(fmt) {
  let o = {
    'M+': this.getMonth() + 1, //月份
    'd+': this.getDate(), //日
    'H+': this.getHours(), //小时
    'm+': this.getMinutes(), //分
    's+': this.getSeconds(), //秒
    'q+': Math.floor((this.getMonth() + 3) / 3), //季度
    'S': this.getMilliseconds(), //毫秒
  };
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length));
  for (let k in o)
    if (new RegExp('(' + k + ')').test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)));
  return fmt;
};

const genissues = function(rules, currentTimes, maxIssue) {
  let now = new Date(currentTimes);
  let today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  let d = 24 * 60 * 60 * 1000;
  let t = 1;
  if ((now.getTime() - today.getTime()) / 1000 >= rules.saleStartSeconds)
    t = 2;
  let tomrrow = new Date(today.getTime() + d * t);
  let todayStr = now.Format('yyyyMMdd');
  let issues = [];
  for (let p = rules.periodSeconds * 1000, k = now.getTime() - p + 10 * 1000; k < tomrrow.getTime(); k += p) {
    let issueNo = Math.floor((k - today.getTime()) / (p)) + 1;
    if (parseInt(issueNo) > maxIssue) issueNo = Math.floor(rules.firstIssue);
    else if (parseInt(issueNo) == 0) {
      issueNo = Math.floor(maxIssue);
      today = new Date(today.getTime() - d);
    }
    let startTime = today.getTime() + (issueNo - 1) * p - rules.closeSeconds * 1000;
    let awardTime = today.getTime() + (issueNo * p);
    let endTime = today.getTime() + (issueNo * p) - rules.closeSeconds * 1000;
    let adate = new Date();
    adate.setTime(endTime);
    let awardStr = adate.Format('yyyyMMdd');
    let no = '' + issueNo;
    if(window.$mode == 1){
      while(no.length < rules.firstIssue.length){
        no = '0' + no;
      }
    }
    let nexdate = new Date();
    nexdate.setTime(awardTime);
    today = new Date(nexdate.getFullYear(), nexdate.getMonth(), nexdate.getDate());
    issues.push({
      issue: no,
      fullIssue: awardStr + '-' + no,
      startTime: startTime,
      endTime: endTime,
      awardTime: awardTime,
    });
  }
  return issues;
};

const handPeriod = (issues, gameAwardObj) => {
  let alertObj = store.getState().alert;
  if (!issues || !gameAwardObj.currentTimes) {
    // chaos.fw7.alert(MSG.E_23, MSG.T_00, function(){chaos.fw7.curView.router.back();});
    alertObj.content = t('MSG.E_23');
    alertObj.title = t('MSG.T_00');
    alertObj.alertType = 'goBackAlert';
    alertObj.showAlert = true;
    store.dispatch(UpdateAlert(alertObj));
    return;
  }
  let curissue, lastissue;
  while (issues.length && issues.length > 1) {
    let item = issues[1];
    lastissue = issues[0];
    if (item.endTime < gameAwardObj.currentTimes) {//已封盘
      	if(gameAwardObj.game.closeFlag==-1){
			curissue = item;
			break;
		}
		else{
			issues.shift();
			continue;
		}
    }
    if (item.startTime < gameAwardObj.currentTimes && item.endTime > gameAwardObj.currentTimes) {
      curissue = item;
      break;
    }
    if (item.startTime > gameAwardObj.currentTimes) {
      curissue = item;
      break;
    }
    break;
  }
  gameAwardObj.issues = issues;
  if (!issues.length || issues.length < 2 || !curissue) {//issues 为空,需要再加载网络数据
    setTimeout(loadPeriod(null, gameAwardObj), 10);
    return;
  } else {
    gameAwardObj.issues = issues;
  }

  gameAwardObj.award.period = lastissue || { fullIssue: '', awardTimes: 0, aopen: false };
  gameAwardObj.award.period.isopen = gameAwardObj.award.period.awardTime < gameAwardObj.currentTimes;
  loadAward(gameAwardObj);
  curissue.open = curissue.startTime < gameAwardObj.currentTimes;
  gameAwardObj.game.period = curissue;
  gameAwardObj.game.period.stateDesc = curissue.open ? MSG.D_02 : MSG.D_04;
  gameAwardObj.issues = issues;
  gameAwardObj.purlist = cloneDeep(issues);
  gameAwardObj.purlist.shift();

  gameAwardObj.stopPeriod = gameAwardObj.issues[0].fullIssue;
  gameAwardObj.currentPeriod = gameAwardObj.game.period.fullIssue;
};

//加载开奖号码
const loadAward = (gameAwardObj) => {
  if (gameAwardObj.isloadaward) return;
  gameAwardObj.isloadaward = true;
  let awardUrl = '/rest/game/lottery/loadaward/' + gameAwardObj.game.id;
  let curissue = gameAwardObj.award.period ? gameAwardObj.award.period.fullIssue : '';
  let url = window.$gameUrl + awardUrl;
  url = url + '?currentissue=' + curissue + '&top=100';
  fetch(url, fetchConfig)
    .then(res => res.json())
    .then(
      (data) => {
        store.dispatch({type: 'SUCCESS_LOAD'});
        handAward(gameAwardObj, data);
        initLuzituData(curissue,data);
        gameAwardObj.isloadaward = false;
      },
      (error) => {
        store.dispatch({type: 'SUCCESS_LOAD'});
        let alertObj = store.getState().alert;
        alertObj.content = t("无法进入游戏!");
        alertObj.title = t("提示");
        alertObj.alertType = 'goBackAlert';
        alertObj.showAlert = true;
        store.dispatch(UpdateAlert(alertObj));
        gameAwardObj.isloadaward = false;
      },
    );
};

var preIssue = "";
const initLuzituData = (curissue,data) => {
  storeLztData(data);
  preIssue = curissue;

};

const storeLztData = (data) => {
let lztroadMap = data.result.award;
if(lztroadMap){
  store.dispatch(updateLuzituObj(lztroadMap));
}
};

const handAward = (gameAwardObj, data) => {
  let popupObj = store.getState().game.popupObj;
  if (data.status) {
    if (data.result.award && data.result.award.length > 0) {
      gameAwardObj.award.list = [];
      let awardlength = data.result.award.length;
      for (let i = awardlength; i--;) {
        let e = data.result.award[i];
        let issue = e.issue;
        if (e.issue.indexOf('-') != -1)
          issue = e.issue.split('-')[1];
        let a = e.result.split(',');
        gameAwardObj.award.nums = a;
        gameAwardObj.award.anums = a;
        let hezhi = 0;
        if (gameAwardObj.game.playId == 'DDC' || gameAwardObj.game.playId == 'KW3') {
          let s = 0;
          a.map((an, i) => {
            s += parseInt(an, 10);
          });
          if (gameAwardObj.game.playId == 'DDC')
            a.push(s);
          hezhi = s;
          if (gameAwardObj.game.playId == 'FTW') {
            e.total = hezhi;
            e.fan = hezhi % 4;
            e.fan = e.fan == 0 ? 4 : e.fan;
          }
        }

        if (i == 0) {
          gameAwardObj.award.aopen = (gameAwardObj.award.period && gameAwardObj.award.period.fullIssue == e.issue);
          if (gameAwardObj.award.aopen) {
            gameAwardObj.award.nums = a;
            gameAwardObj.award.anums = a;
            gameAwardObj.award.issue = issue;
            gameAwardObj.award.total = e.total;
            gameAwardObj.award.fan = e.fan;
            gameAwardObj.award.fullIssue = e.issue;
            gameAwardObj.award.openTime= moment(e.openTime).format('YYYY/MM/DD HH:mm');
            setTimeout(function() {
              if (gameAwardObj.award.aopen) {
                //let url = new URL(window.$gameUrl + "/rest/game/lottery/getwin/" + gameAwardObj.game.id +"?issue=" + gameAwardObj.award.fullIssue);
                let url = window.$gameUrl + '/rest/game/lottery/getwin/' + gameAwardObj.game.id+"?issue=" + gameAwardObj.award.fullIssue;
                GameFetcher(url, {
                  method: 'Post',
                  headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/x-www-form-urlencoded',
                  },
                  // credentials: 'include',
                  body: { issue: gameAwardObj.award.fullIssue },
                })
                  .then(res => res.json())
                  .then(
                    (data) => {
                      //提示中奖金额 弹窗
                      if (data.status && data.result.money > 0) {
                        popupObj.prizeNo = gameAwardObj.award.fullIssue;
                        popupObj.showPrize = true;
                        popupObj.prizeMoney = data.result.money;
                        store.dispatch(updatePopupObj(popupObj));
                      }
                    },
                  );
              }
            }, 10000);
          }
        }
        let isInsert = false;
        if (gameAwardObj.award.list[0]?.fullIssue.indexOf('-') != -1 || e.issue.indexOf('-') != -1) {//if contain '-' need compare date and issue
          isInsert = gameAwardObj.award.list[0]?.fullIssue < e.issue;
        } else{
          isInsert = parseInt(gameAwardObj.award.list[0]?.fullIssue) < parseInt(e.issue);//if not contain '-' just compare issue
        }
        if (gameAwardObj.award.list.length == 0 || isInsert) {
          let aw = {
            issue: issue,
            anums: a,
            fullIssue: e.issue,
            issueDate: moment(e.openTime).format('HH:mm'),
            openTime: moment(e.openTime).format('YYYY/MM/DD HH:mm'),
            total: e.total,
            fan: e.fan,
          };
          if (gameAwardObj.game.playId == 'KW3') {
            aw.hezhi = hezhi;
            aw.daxiao = hezhi > 10 ? '大' : '小';
            aw.danshuang = hezhi % 2 == 1 ? '单' : '双';
          }
          gameAwardObj.award.list.splice(0, 0, aw);
        }
      }
    }

  }
};

const showRepeatBet = (rc) => {

  GameFetcher(window.$gameUrl + "/rest/game/lottery/loadamode/"+rc.gameId.toUpperCase(), {
    method: 'POST',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    // credentials: 'include',
  })
  .then((response) => response.json())
  .then((response) => {
    if(response.status){
      let popupObj = store.getState().game.popupObj;
      popupObj.showRepeatBet = true;
      popupObj.repeatBetObj = rc;
      store.dispatch(updatePopupObj(popupObj));
    }else{
      repeatGameStop();
    }
  })
  .catch((error) => {
    console.error(error);
  });

};

const showAwardLocalizeResult = (rc) => {
  let popupObj = store.getState().game.popupObj;
  popupObj.showAwardLocalizeResult = true;
  popupObj.localizedResultObj = rc;
  store.dispatch(updatePopupObj(popupObj));
};

//倒计时
const downseconds = (gameAwardObj) => {
  // console.log(gameAwardObj.timer+"currentTimes:"+moment(gameAwardObj.currentTimes).format('HH:mm:ss'));
  gameAwardObj.currentTimes += 1000;
  let popupObj = store.getState().game.popupObj;
  let memberInfoObj = store.getState().memberInfo;
   //检查休息提醒

  let restWarning = getMemberRestWarning();
       if(restWarning[0].status){
         popupObj.popupType = 'restWarning';
         popupObj.showPopup = true;
         popupObj.restHours = restWarning[0].restHour;
         popupObj.restMin = restWarning[0].restMin;
        store.dispatch(updatePopupObj(popupObj));
   }
  if (gameAwardObj.game.period) {

    gameAwardObj.game.stop = gameAwardObj.game.closeFlag == -1 && (gameAwardObj.game.gameType=='LT09' && gameAwardObj.game.playId !=='JHC' ? !gameAwardObj.game.period.open : gameAwardObj.game.period.endTime < gameAwardObj.currentTimes && gameAwardObj.game.period.awardTime > gameAwardObj.currentTimes);
    if (gameAwardObj.betContent)
      gameAwardObj.betContent.issue = gameAwardObj.game.period.fullIssue;
    gameAwardObj.game.period.open = gameAwardObj.game.period.startTime < gameAwardObj.currentTimes;

    if (gameAwardObj.game.period.open) {
      gameAwardObj.game.period.stateDesc = MSG.D_02;
      gameAwardObj.game.showSeconds = Math.floor(((gameAwardObj.game.closeFlag > -1 || gameAwardObj.game.gameType=='LT09' && gameAwardObj.game.playId !=='JHC' ? gameAwardObj.game.period.endTime : gameAwardObj.game.period.awardTime) - gameAwardObj.currentTimes) / 1000);
      const audioEl = document.getElementsByClassName("audio-element")[0];
      const countdownEl = document.getElementsByClassName("countdown-element")[0];
      if(gameAwardObj.game.showSeconds<=5){
        if(gameAwardObj.init_countdown_sound == false){
          countdownEl.currentTime = 5 - gameAwardObj.game.showSeconds;
          gameAwardObj.init_countdown_sound = true;
        }
        if(memberInfoObj.AccountConfigs["accountConfigs"+memberInfoObj.UserInfo.userInfo].isCountDownSound == true){
          if(audioEl) audioEl.muted = true;
        }

        if(countdownEl){
          countdownEl.play();
        }
      }
      if(countdownEl) {
        countdownEl.addEventListener('ended',
          function() {
            if(audioEl) audioEl.muted = false;
          }
        );
      }
      if (gameAwardObj.game.showSeconds <= 0) {
        if (!gameAwardObj?.issues.length || gameAwardObj?.issues.length < 2) {
          gameAwardObj.game.period = null;
          setTimeout(loadPeriod(null, gameAwardObj), 10);
          return;
        }
        gameAwardObj.issues.shift();
        gameAwardObj.purlist.shift();
        gameAwardObj.game.period = gameAwardObj.issues[1];
        // if (gameAwardObj.game.period && gameAwardObj.isinit && !page_remove) {

        if (gameAwardObj.game.period && gameAwardObj.isinit) {
          gameAwardObj.game.period.stateDesc = MSG.D_02;

          gameAwardObj.stopPeriod = gameAwardObj.issues[0].fullIssue;
          gameAwardObj.currentPeriod = gameAwardObj.game.period.fullIssue;
          //showPeriod(gameAwardObj);
          popupObj.popupType = 'endPeriod';
          popupObj.showPopup = true;
          popupObj.containerType = '';
          popupObj.modalPadding = '';
          store.dispatch(updatePopupObj(popupObj));


          // chaos.fw7.addNotification({
          //     title: MSG.T_04.replace('[currentissue]',gameAwardObj.issues[0].fullIssue),
          //     message: MSG.T_12.replace('[nextissue]',gameAwardObj.game.period.fullIssue),
          //     button:{text:"确认"},
          //     hold:3000
          // });
          gameAwardObj.game.period.open = gameAwardObj.game.period.startTime < gameAwardObj.currentTimes;
          gameAwardObj.award.period = gameAwardObj.issues[0];
          gameAwardObj.award.aopen = false;
          let gameBetObject = store.getState().game.gameBetObject;
          preventOverride(gameBetObject, gameAwardObj);
          Object.assign(gameBetObject, gameAwardObj);
          store.dispatch(updateBetRecord(gameBetObject));
        } else {
          gameAwardObj.game.period = null;
          setTimeout(loadPeriod(null, gameAwardObj), 10);
          return;
        }
      }
      else{
        gameAwardObj.game.showSeconds = Math.floor((gameAwardObj.game.period.endTime - gameAwardObj.currentTimes)/1000);
      }
    } else {
      gameAwardObj.game.showSeconds = Math.floor((gameAwardObj.game.period.startTime - gameAwardObj.currentTimes) / 1000);
      gameAwardObj.game.period.stateDesc = MSG.D_04;
    }
  }
  if (gameAwardObj.award.period) {
    gameAwardObj.award.period.isopen = gameAwardObj.game.gameType=='LT09' && gameAwardObj.game.playId !=='JHC' ?gameAwardObj.award.period.endTime+5000 < gameAwardObj.currentTimes:gameAwardObj.award.period.awardTime < gameAwardObj.currentTimes;
    if (!gameAwardObj.award.fullIssue || (gameAwardObj.award.fullIssue != gameAwardObj.award.period.fullIssue)) {
      randomAward(gameAwardObj);
    }
    if (gameAwardObj.award.period.isopen && !gameAwardObj.award.aopen && gameAwardObj.award.times <= 3) {
      gameAwardObj.award.times--;
      //if (!page_remove)
      //gameAwardObj.loadAward();
      //if (!gameAwardObj.award.times)
      //gameAwardObj.award.times = 3;

      if (!gameAwardObj.openAwardIntervalNum[gameAwardObj.award.period.fullIssue])
        gameAwardObj.openAwardIntervalNum[gameAwardObj.award.period.fullIssue] = 0;
      gameAwardObj.openAwardIntervalNum[gameAwardObj.award.period.fullIssue] += 1;
      if (gameAwardObj.openAwardIntervalNum[gameAwardObj.award.period.fullIssue] && gameAwardObj.openAwardIntervalNum[gameAwardObj.award.period.fullIssue] >= 3) {
        getCurrentTime(gameAwardObj,false);
        gameAwardObj.openAwardIntervalNum[gameAwardObj.award.period.fullIssue] = 0;
      }//if
    }
  }
};

// const showPeriod = (gameAwardObj) => {
//     gameAwardObj.triggerPopup("endPeriod");
// }

const randomAward = (gameAwardObj) => {
  if (gameAwardObj.award.aopen) return;
  gameAwardObj.randomrun = true;
  gameAwardObj.award.nums = [];
  let nums = [];
  for (let k = 0; k < gameAwardObj.game.resultNum; k++)
    nums.push(gameAwardObj.AWARD_NUM[Math.floor(Math.random() * gameAwardObj.AWARD_NUM.length)]);
  if (gameAwardObj.game.playId == 'DDC' || gameAwardObj.game.playId == 'KW3') {
    let s = 0;
    nums.map((an, i) => {
      s += parseInt(an, 10);
    });
    if (gameAwardObj.game.playId == 'DDC')
      nums.push(s);
    if (gameAwardObj.game.playId == 'FTW') {
      gameAwardObj.award.total = s;
      let f = s % 4;
      f = f == 0 ? 4 : f;
      gameAwardObj.award.fan = f;
    }
  }
  gameAwardObj.award.nums = nums;
  if ((gameAwardObj.award.period && gameAwardObj.award.period.isopen && !gameAwardObj.award.aopen)) {
    setTimeout(function() {
      randomAward(gameAwardObj);
    }, 150);
  }
};

const lustcityfun = (num) => {
  let inum = parseInt(num,10);
  let style = "LCT001001005";
  switch(inum){
    case 1:
    case 4:
    case 6:
    case 9:
    case 11:
    case 13:
    case 16:
    case 20:
      style= "LCT001001001";
      break;
    case 2:
    case 5:
    case 10:
    case 12:
    case 15:
    case 19:
      style= "LCT001001002";
      break;
    case 3:
    case 7:
    case 14:
    case 18:
      style= "LCT001001003";
      break;
    case 8:
    case 17:
      style= "LCT001001004";
      break;
  }
    return style;
}

const lustcitycolor = function(num){
  let inum = parseInt(num,10);
  let style = "LCT003001003";
  switch(inum){
    case 1:
    case 3:
    case 5:
    case 7:
    case 9:
    case 11:
    case 13:
    case 15:
    case 17:
    case 19:
      style= "LCT003001002";
      break;
    case 2:
    case 4:
    case 6:
    case 8:
    case 10:
    case 12:
    case 14:
    case 16:
    case 18:
    case 20:
      style= "LCT003001001";
      break;
  }
    return style;
};

const ysccolor = function(num){
  var inum = parseInt(num,10);
  var style = "green-purple";
  switch(inum){
    case 1:
    case 3:
    case 7:
    case 9:
      style= "green";
      break;
    case 2:
    case 4:
    case 6:
    case 8:
      style= "red";
      break;
    case 0:
      style= "red-purple";
      break;
    case 5:
      style= "green-purple";
      break;
  }
  return style;
};

const yscplayid = function(color){
  var id = "";
  switch(color){
    case "green":
      id= "YSC001001001";
      break;
    case "red":
      id= "YSC001001003";
      break;
    case "red-purple":
    case "green-purple":
      id= "YSC001001005";
      break;
    case 0:
      id= "YSC001002001";
      break;
    case 1:
      id= "YSC001002002";
      break;
    case 2:
      id= "YSC001002003";
      break;
    case 3:
      id= "YSC001002004";
      break;
    case 4:
      id= "YSC001002005";
      break;
    case 5:
      id= "YSC001002006";
      break;
    case 6:
      id= "YSC001002007";
      break;
    case 7:
      id= "YSC001002008";
      break;
    case 8:
      id= "YSC001002009";
      break;
    case 9:
      id= "YSC001002010";
      break;
  }
  return id;
}

const eurolotto = function(playid){
  var isEuroLotto = false;
  switch(playid){
    case "UKL":
    case "VKL":
    case "TDB":
    case "SFL":
    case "PWB":
    case "MGM":
    case "VMG":
    case "VPW":
    case "BPS":
    case "BMS":
    case "BTM":
    case "BDS":
      isEuroLotto= true;
      break;
  }
  return isEuroLotto;
};

const yxxpic = function(num){
  var inum = parseInt(num,10);
  var style = "deer";
  switch(inum){
    case 1:
      style="deer";
      break;
    case 2:
      style="gourd";
      break;
    case 3:
      style="rooster";
      break;
    case 4:
      style="fish";
      break;
    case 5:
      style="crab";
      break;
    case 6:
      style="prawn";
      break;
  }
  return style;
};

export {
  init,
  showRepeatBet,
  showAwardLocalizeResult,
  lustcityfun,
  lustcitycolor,
  ysccolor,
  yscplayid,
  eurolotto,
  yxxpic
};
