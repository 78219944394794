import React, { useState, useEffect } from 'react';
import { Wheel } from 'react-components/react-custom-roulette'
import h5Button1 from '../../assets/image/event/h5-button-1.png'
import { alert } from '../../services/AlertService';
import {showLoginModal} from '../../services/HomeService';
import Fetcher from '../../components/Fetcher';
import { store } from '../../index';
import { saveJumpObj } from '../../actions/HomePageAction';

const promotionRewards = ["PBM", "FBM", "FBP", "CP"];//constant
const images = require.context('../../assets/image/', true);

let styleRoulette = [{ backgroundColor: 'rgb(159, 79, 255)', textColor: 'white' }
  , { backgroundColor: 'rgb(255, 71, 79)', textColor: 'white' }
  , { backgroundColor: 'rgb(255, 95, 129)', textColor: 'white' }
  , { backgroundColor: 'rgb(255, 172, 70)', textColor: 'white' }
  , { backgroundColor: 'rgb(255, 229, 70)', textColor: 'white' }
  , { backgroundColor: 'rgb(119, 191, 83)', textColor: 'white' }
  , { backgroundColor: 'rgb(94, 211, 254)', textColor: 'white' }
  , { backgroundColor: 'rgb(53, 97, 254)', textColor: 'white' }];

const styleBtnEnable = {
  backgroundColor: '#5cd91c', position: 'relative', zIndex: 99, height: '50px', width: '200px', borderRadius: '10px', color: 'white', fontWeight: 'bold', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'contain', marginBottom: '10%'
};
const styleBtnDisabled = {
  backgroundColor: '#5cd91c', position: 'relative', zIndex: 99, height: '50px', width: '200px', borderRadius: '10px', color: 'white', fontWeight: 'bold', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'contain', marginBottom: '10%', opacity: 1
};

let pointerPrize, rouletteRewardValue;

const WheelCom = ({ promo, titleWord, buttonWord, popUpSuccessText, popUpSuccessText1, popUpSuccessText2, confirmBtnText, isLogin, noticeTitle, rouletteStyle, updateBalanceNum, ...restProps }) => {

  const [isSpin, setIsSpin] = useState(false);
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [accountPoint, setAccountPoint] = useState(0);
  const [remainingTime, setRemainingTime] = useState(0);
  const [isShowModal, setIsShowModal] = useState(false);
  
  let arrPB = [], dataArrRewards = [], conds;
  if (promo && promo.promotionConds != "[]") {
    conds = Object.values(JSON.parse(promo.promotionConds));
    conds.sort(function (a, b) { return parseInt(b.PB) - parseInt(a.PB) })//sort the PB from largest to lowest
    //console.log("conds",conds)
    let count = 0;
    conds && Object.keys(conds).forEach(function (key) {
      let promoCondsEachRow = conds[key];
      //console.log("promoCondsEachRow",key,promoCondsEachRow)
      promoCondsEachRow && Object.entries(promoCondsEachRow).map((item, index) => {
        if (promotionRewards.includes(item[0])) {
          dataArrRewards.push({ option: item[1], style: { backgroundColor: styleRoulette[count].backgroundColor, textColor: 'white' } });
          count++;
        } else if (item[0] == "PB") {
          arrPB.push(parseInt(item[1]));
        }
      })
    })
  }

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    getRoulettePoints();
  }, []);

  // const getRandomColorIndex = () => {
  //   const styleIndex = Math.floor(Math.random() * styleRoulette.length);
  //   console.log("styleRoulette before",styleRoulette)
  //   let choosenStyle = styleRoulette[styleIndex];
  //   delete styleRoulette[styleIndex];
  //   console.log("styleRoulette after",styleRoulette)
  //   return choosenStyle;
  // }

  const getRoulettePoints = () => {
    if (promo && promo.id && isLogin) {
      const data = new URLSearchParams();
      data.set('id', promo.id);
      console.log("API getRoulettePoints id:", promo.id);
      Fetcher(window.$wgtDomain + '/wap/getRoulettePoints/' + promo.id, {
        method: 'POST',
        headers: {
          'Accept': 'application/json, text/plain, */*',
        },
        // credentials: 'include',
        responseType: 'json',
        //body: data,
        referrer: 'h5',
      })
        .then((response) => response.json())
        .then((response) => {
          // console.log("API Response getRoulettePoints:", response);
          if (response.status) {
            setAccountPoint(response.accountPoint);
            setRemainingTime(response.remainingTime);
            updateBalanceNum(response.balanceNum);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  const preApplyEvent = () => {
    console.log(isLogin, conds, pointerPrize, conds[pointerPrize])
    if (isLogin && conds[pointerPrize]) {
      let rouletteRewardType;
      Object.values(promotionRewards).map((item, index) => {
        if(Object.keys(conds[pointerPrize]).includes(item)){
          rouletteRewardValue = conds[pointerPrize][item];
          rouletteRewardType = item;
          return;
        }
      })

      let param = {
        id: promo.id,
        pointsRoulette: conds[pointerPrize].CLP,
        probabilityRoulette: conds[pointerPrize].PB,
        rouletteRewardType : rouletteRewardType,
        rouletteRewardValue : rouletteRewardValue,
      }
      applyEvent(param);
    }
  }

  const applyEvent = (param) => {
    const data = new URLSearchParams();
    if (param) {
      console.log("applyEvent param", param);
      data.set('id', param.id);
      data.set('pointsRoulette', param.pointsRoulette);
      data.set('probabilityRoulette', param.probabilityRoulette);
      data.set('rouletteRewardType', param.rouletteRewardType);
      data.set('rouletteRewardValue', param.rouletteRewardValue);

      Fetcher(window.$wgtDomain + '/wap/submitPromo', {
        method: 'POST',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          //'Content-Type': 'application/x-www-form-urlencoded',
        },
        // responseType:'json',
        // credentials: 'include',
        body: data,
        referrer: 'h5',
      })
        .then((response) => response.json())
        .then((response) => {
          console.log("Response /wap/submitPromo", response)
          if (response.status) {
            modalOpen();
            //alertWithFunction(response.msg, 'notice', getRoulettePoints());
            getRoulettePoints()
          }else{
            alert(response.msg, noticeTitle)
          }
          setIsSpin(false)
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  const modalOpen = () => {
    setIsShowModal(true);
  }

  const modalClose = () => {
    setIsShowModal(false);
  }

  const handleSpinClick = () => {
    if (isLogin) {
      if(accountPoint< 0 || remainingTime <= 0){
        alert("可用积分不足", noticeTitle)
      } else if(promo.applyLimitStatus === 1 && promo.balanceNum < 1){
        alert('申请次数不足', noticeTitle);
      }else {
        setIsSpin(true)
        pointerPrize = getPercentageIndex(arrPB);
        if (pointerPrize != null && pointerPrize >= 0 && pointerPrize < arrPB.length) {
          setPrizeNumber(pointerPrize)
          setMustSpin(true)
          console.log("conds", conds)
          console.log("pointerPrize", pointerPrize, conds[pointerPrize])
        }
      }
    }else{
      alert("login first", noticeTitle)
    }
  }

  const getPercentageIndex = (input_arrPB) => {
    if (input_arrPB) {
      let var1 = Math.floor((Math.random() * 100) + 1);// returns a random integer from 1 to 100
      let var2 = 0;
      let total = 1;//start from 1
      for (let i = 0; i < input_arrPB.length; i++) {
        total += input_arrPB[i];
        if (var1 < total) {
          var2 = i;
          break;
        }
      }
      return var2;
    } else return -1;
  }

  const toLoginPage = () => {
    let promoObj = {};
    promoObj.promoRecords = promo;
    promoObj.pathname = '/event';
    let dataObj = store.getState().indexData.jumpObj;
    dataObj.openPromo = promoObj;
    store.dispatch(saveJumpObj(dataObj));
    showLoginModal();
  }

  return (
    <>
      <div className="promotionPointRoulette">
        <span style={{ color: "white" }}>{titleWord}</span><br />
        <span style={{ color: "#ffb400", fontSize: "25px" }}>{accountPoint}</span>
      </div>
      <div style={{ display:'flex', justifyContent:'center', alignItems:'center',position:'relative'}}>
        <Wheel
          mustStartSpinning={mustSpin}
          prizeNumber={prizeNumber}
          data={dataArrRewards}
          //textDistance={60}
          //perpendicularText={false}//text直的还是横的
          outerBorderWidth={5}//外面border width
          outerBorderColor={'white'}//外面border color
          // innerRadius={10}//中心店的radius
          // innerBorderColor={'red'}//中心點的color
          // innerBorderWidth={10}//中心點的width
          radiusLineColor={'white'}
          radiusLineWidth={5}
          fontSize={35}
          onStopSpinning={() => {
            setMustSpin(false);
            preApplyEvent();
          }}
        />
        <img src={images("./backgroundlight.png")} className="rouletteBackgroundLightAnimation" style={{width:'100%',position: 'absolute'}}/>
        <img src={images("./wheelcenter.png")} disabled={!isLogin ? false : isSpin} onClick={!isLogin ? toLoginPage : handleSpinClick} style={{ position:'absolute', width:'40%', zIndex:99 }}/>
        <img src={images("./outer-wheel.png")} className="rouletteWheelAnimation" style={rouletteStyle == 1 ? {width:'77%', position:'absolute', zIndex:4 } : rouletteStyle == 2 ? { width:'76%', position:'absolute', zIndex:4 } : {}}/>
        <img src={images("./outer-wheel1.png")} className="rouletteWheelAnimation rouletteWheelAnimationDelay" style={rouletteStyle == 1 ? {width:'77%', position:'absolute', zIndex:4 } : rouletteStyle == 2 ? { width:'76%', position:'absolute', zIndex:4 } : {}}/>
      </div>
      <button style={isSpin ? styleBtnDisabled : styleBtnEnable}
        onClick={!isLogin ? toLoginPage : handleSpinClick} disabled={!isLogin ? false : isSpin}>
        <span style={{ verticalAlign: "super", textShadow: '2px 2px black' }}>{buttonWord}({remainingTime})</span>
      </button>
      {isShowModal ?
        <Modal handleClose={modalClose} confirmBtnText={confirmBtnText}>
          <div style={{ color: '#f0c509', textAlign: 'center', marginBottom: '10px' }}>{popUpSuccessText}</div>
          <div style={{ color: '#ffb400', textAlign: 'center' }}>{popUpSuccessText1}{rouletteRewardValue} {popUpSuccessText2}</div>
        </Modal>
        : ""}
    </>
  )
}
WheelCom.propTypes = {

};
WheelCom.defaultProps = {

};

const Modal = ({ handleClose, children, confirmBtnText }) => {
  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: 1050,
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      outline: 0,
      display: 'block!important'
    }}>
      <div style={{
        position: 'absolute',
        width: '60%',
        height: '35%%',
        backgroundColor: '#ffffff',
        left: '20%',
        top: '40%',
        padding: '20px',
        // backgroundImage: 'linear-gradient(#A30707,#4F0505)',
        borderRadius: '15px',
        border: '3px solid #A36201',
        marginTop: '-75px',
        backgroundColor: '#1e2024',
        boxShadow: '0px 0px 75px 0px #ffa800'
      }}>
        <div onClick={handleClose} style={{ position:'fixed', float:'right', zIndex:99, right:'25%' }}><img src={images("./close_icon.png")} style={{width:'25px'}}/></div>
        <div style={{ display:'flex', justifyContent:'center', alignItems:'center'}}>
          <img src={images("./giftbox.png")} className="roulettePrizeGiftboxAnimation" style={{width: '80%', zIndex: 2 }}/>
          <img src={images("./backlight.png")} className="rouletteBackgroundLightAnimation" style={{width: '75%', position: 'fixed', zIndex: 1 }}/>
          <img src={images("./coins.png")} className="roulettePrizeCoinAnimation" style={{width: '150px', position: 'fixed', zIndex: 3 }}/>
          <img src={images("./stars.png")} className="roulettePrizeStarAnimation" style={{width: '150px', position: 'fixed', zIndex: 4 }}/>
        </div>
        <div style={{marginTop:'15%', bottom: '6%', left: '28%'}}>{children}</div>
        {/* <button style={{ backgroundImage: "url(" + h5Button1 + ")", height: '50px', width: '100%', color: 'white', fontWeight: 'bold', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'contain' }}
          onClick={handleClose}>
          <span style={{ marginTop: '-6px', textShadow: '2px 2px black' }}>{confirmBtnText}</span>
        </button> */}
      </div>
    </div>
  );
};

export default WheelCom;