import { Content } from 'components/Layout';
import React from 'react';

let live = (window.location.href.includes("/live")?true:false);
const EmptyLayout = ({ children, ...restProps }) => (
  <main className="cr-app" {...restProps}>
    <Content fluid style={{display:live?'inline-grid':''}}>{children}</Content>
  </main>
);

export default EmptyLayout;
